import { createDialog, createButtons, closeAlert, removeChildren } from './uiUtils';
import { getPresentationInfo } from '../apiRequests';
import { PresentationInfo, SelectableItem } from '../../../../src/commonTypes';
import { getTitleSlideContent } from './slides/title';
import { getLogisticsSlideContent } from './slides/logistics';
import { getVisionSlideContent } from './slides/vision';
import { getChangeSlideContent } from './slides/change';
import { getStrategySlideContent } from './slides/strategy';
import { getTargetAudiencesSlideContent } from './slides/audience';
import { getPersonaSlideContent } from './slides/persona';
import { getMotivationsSlideContent } from './slides/motivations';
import { getConceptualTerritoriesContent } from './slides/conceptual';
import { getInspirationSlideContent } from './slides/inspiration';
import { getAssetsSlideContent } from './slides/assets';
import * as tutorialEvents from '../tutorials/tutorialEvents';

export const setPresentationLink = (presentationId: string, presentationTitle: string) => {
	const googleSlidesOutput = document.querySelector<HTMLParagraphElement>('#google-slides-output')!;
	googleSlidesOutput.innerHTML = `
					Presentation generated:<br>
					<a href="https://docs.google.com/presentation/d/${presentationId}/edit" target="presentation_${presentationId}">
						${presentationTitle}
					</a>
				`;
	googleSlidesOutput.classList.remove('hidden');
	const syncButton = document.querySelector<HTMLButtonElement>('#google-slides-sync')!;
	syncButton.classList.remove('hidden');
	syncButton.innerHTML = 'Update presentation<span class="preview-only"> (preview only)</span>...';
	if (document.body.dataset.canGenerateSlides === 'true') {
		syncButton.querySelector('.preview-only')?.classList.add('hidden');
	}
	const loadingIndicator = document.querySelector<HTMLParagraphElement>('#slides-loading')!;
	loadingIndicator.classList.add('hidden');
};

const hasSomeSelectedItems = (presentationInfo: PresentationInfo) => {
	const hasSelected = (selectableItems?: Array<SelectableItem<unknown>>) => {
		return selectableItems?.some((item) => item.selected);
	};

	if (
		hasSelected(presentationInfo.personas) ||
		hasSelected(presentationInfo.motivations) ||
		hasSelected(presentationInfo.targetAudiences) ||
		hasSelected(presentationInfo.brandStrategies) ||
		hasSelected(presentationInfo.productBriefs) ||
		hasSelected(presentationInfo.productIdeas) ||
		hasSelected(presentationInfo.images) ||
		hasSelected(presentationInfo.photos) ||
		hasSelected(presentationInfo.links) ||
		hasSelected(presentationInfo.haikus) ||
		hasSelected(presentationInfo.taglines) ||
		hasSelected(presentationInfo.brainstorming) ||
		hasSelected(presentationInfo.commonIdeas)
	) {
		return true;
	}

	if (
		presentationInfo.conceptualTerritories.some((concept) => hasSelected(concept.value.results))
	) {
		return true;
	}

	return false;
};

export const showPresentationPreview = async (
	generatePresentation: (presentation: PresentationInfo) => void
) => {
	const presentationInfo = await getPresentationInfo(document.body.dataset.boardId!);

	const messageP = document.createElement('p');
	messageP.classList.add('minimal-message');
	messageP.innerHTML = 'Preview and edit the sections of your presentation before generating it.';

	const tabsDiv = document.createElement('div');
	tabsDiv.classList.add('tabs');

	const tabsContent = document.createElement('div');

	const tabDefinitions = [
		{
			key: 'title',
			name: 'Title',
			construct: () => getTitleSlideContent(presentationInfo),
		},
		{
			key: 'logistics',
			name: 'Logistics',
			construct: () => getLogisticsSlideContent(presentationInfo),
		},
		{
			key: 'vision',
			name: 'Vision',
			construct: () => getVisionSlideContent(presentationInfo),
		},
		{
			key: 'change',
			name: 'Change',
			construct: () => getChangeSlideContent(presentationInfo),
		},
		{
			key: 'strategy',
			name: 'Strategies',
			construct: () => getStrategySlideContent(presentationInfo),
		},
		{
			key: 'audiences',
			name: 'Audiences',
			construct: () => getTargetAudiencesSlideContent(presentationInfo),
		},
		{
			key: 'personas',
			name: 'Personas',
			construct: () => getPersonaSlideContent(presentationInfo),
		},
		{
			key: 'motivations',
			name: 'Motivations',
			construct: () => getMotivationsSlideContent(presentationInfo),
		},
		{
			key: 'conceptual-territories',
			name: 'Conceptual Territories',
			construct: () => getConceptualTerritoriesContent(presentationInfo),
		},
		{
			key: 'inspiration',
			name: 'Inspiration',
			construct: () => getInspirationSlideContent(presentationInfo),
		},
		{
			key: 'assets',
			name: 'Assets',
			construct: () => getAssetsSlideContent(presentationInfo),
		},
	];

	const showTab = (tabDefinition: (typeof tabDefinitions)[number]) => {
		const content = tabDefinition.construct();
		content.classList.add('tab-content');
		content.classList.add(`tab-${tabDefinition.key}`);
		removeChildren(tabsContent);
		tabsContent.append(content);
	};

	tabDefinitions.forEach((tabDefinition, i) => {
		const button = document.createElement('button');
		if (i === 0) {
			button.classList.add('active');
		}
		button.classList.add('tab-link');
		button.innerText = tabDefinition.name;
		button.addEventListener('click', () => {
			tabsDiv.querySelectorAll('button').forEach((button) => button.classList.remove('active'));
			showTab(tabDefinition);
			button.classList.add('active');
		});
		tabsDiv.appendChild(button);
	});

	showTab(tabDefinitions[0]);

	const nothingSelectedMessage = document.createElement('p');
	nothingSelectedMessage.classList.add('nothing-selected-warning');
	nothingSelectedMessage.innerText =
		'Please select some items from the tabs above to include in the presentation.';
	if (hasSomeSelectedItems(presentationInfo)) {
		nothingSelectedMessage.classList.add('invisible');
	}

	const noPermissionWarning = document.createElement('p');
	noPermissionWarning.classList.add('system-notice');
	noPermissionWarning.innerHTML =
		'<small>In this panel you can see how to configure a presentation. However, generating a Google Slides presentation is not available in this plan. For full-featured access, please <a href="/settings/billing">upgrade</a></small>';

	const noAccessWarning = document.createElement('p');
	noAccessWarning.classList.add('system-notice');
	noAccessWarning.innerHTML =
		'<small>In this panel you can see how to configure a presentation. However, before you can generate a presentation you need to <strong>grant access to Google Slides on the previous screen</strong>.</small>';
	const hasGrantedAccess = document
		.querySelector('#google-slides-sync .preview-only')!
		.classList.contains('hidden');
	if (hasGrantedAccess) {
		noAccessWarning.classList.add('hidden');
	}

	const canGenerateSlides = document.body.dataset.canGenerateSlides === 'true';
	if (canGenerateSlides) {
		noPermissionWarning.classList.add('hidden');
	} else {
		noAccessWarning.classList.add('hidden');
	}

	const buttons = createButtons([
		{
			label: 'Cancel',
			onClick: () => {
				closeAlert();
			},
		},
		{
			label: 'Generate presentation',
			onClick: () => {
				generatePresentation(presentationInfo);
				tutorialEvents.clickedGeneratePresentation();
				closeAlert();
			},
			primary: true,
			disabled: !canGenerateSlides || !hasGrantedAccess,
		},
	]);

	createDialog(
		'Presentation setup',
		[messageP, noPermissionWarning, noAccessWarning, tabsDiv, tabsContent, nothingSelectedMessage],
		buttons
	);

	tutorialEvents.openedPresentationPreview();
};
