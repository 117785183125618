import { endTutorial, isTutorialActive, startTutorial } from './tutorial';
import { closeAllPanels } from '../ui/uiUtils';
import { ensureMenuIsOpen } from '../ui/menuUi';

export const startIdeateTutorial = () => {
	if (isTutorialActive('ideate')) {
		closeAllPanels();
		startTutorial(
			{
				disableInteraction: false,
				steps: [
					{
						title: 'Tutorial 👋',
						intro: 'Welcome to Ideate mode tutorial ✨',
					},
					{
						title: 'Analyses',
						element: '#analyses-container',
						intro:
							'The recommended analyses are highlighted in blue. But you can also select different analyses from the menu by clicking them.',
					},
					{
						title: 'Recommended Analyses',
						element: '#analyses-container .recommended',
						intro:
							'You can click on one of the recommended analyses then the clusters will be generated by AI. Let’s try it!',
					},
					{
						title: 'Cluster',
						element: '#board-content #all-content .clusters g',
						intro:
							'A cluster is a group suggestion generated by AI based on the ideas you created in Inspire mode earlier.',
					},
					{
						title: 'Like for Cluster',
						tooltipClass: 'likeTutorial',
						element: '#board-content #all-content .clusters g .cluster-heart-circle',
						intro:
							'You can click the heart button for the cluster you like and would like to save to an ordered list on the top right ( <span class="heartedListIcon"></span> ).<br><br> You can check the list of the elements you like in this list at any time and add it to your moodboard and notepad once you get to the Synthesise stage of your ideation.',
					},
					{
						title: 'Nodes',
						element: '#board-content #all-content .nodes circle.node',
						intro:
							'You can also play with each node inside the cluster. You can open the radial toolbar by clicking a node.<br><br> In Ideate, the toolbar allows you to <strong>like</strong> or <strong>generate</strong> taglines and haikus that are related to your node. Coming soon: generative images! ',
					},
					{
						title: 'Complete 🎉',
						element: '#board-stage-switcher',
						intro:
							'You have completed the Ideate tutorial! <br><br>When you’re ready, navigate to the Synthesise stage.',
					},
				],
			},
			{
				onexit: endTutorial,
				onbeforechange: ensureMenuIsOpen,
			}
		);
	}
};
