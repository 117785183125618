import {
	startTutorial,
	addTextWithTypingEffect,
	updatePosition,
	isTutorialActive,
	deactivateTutorialButton,
	exitTutorial,
} from './tutorial';
import {
	clearNonBlockingMessage,
	closeAlert,
	closeAllPanels,
	showWordInputPanel,
} from '../ui/uiUtils';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from './tutorialEvents';
import { startBrandingTutorialIdeate } from './brandingIdeateTutorial';
import { startBrandingTutorialSynthesise } from './brandingSynthesiseTutorial';
import Graph from '../graphs/Graph';
import { ensureMenuIsOpen } from '../ui/menuUi';
import { sleep } from '../misc';
import { clickOnTarget, slideLateralThinkingSliderTo } from './tutorialAutomation';

const templateId = 'branding';

export const startBrandingTutorial = (graph: Graph, force = false) => {
	// Make sure that Flowy is not active 
	

	if (force || !graph.getHintsShown()) {
		if (graph.graphMode === 'inspire') {
			startBrandingTutorialInspire();
		} else if (graph.graphMode === 'ideate') {
			startBrandingTutorialIdeate();
		} else if (graph.graphMode === 'moodboard') {
			startBrandingTutorialSynthesise();
		}
	}
};

const startBrandingTutorialInspire = () => {
	if (isTutorialActive('inspire', templateId)) {
		closeAllPanels();

		tutorialEmitter.removeAllListeners();

		if (globalThis.neuroCreate.graph!.nodes.length > 0) {
			// Skip first steps
			if (!globalThis.neuroCreate.graph!.isAiEnabled()) {
				document.querySelector<HTMLInputElement>('#ai-setting')!.click();
			}
			addedWordsFromPanel();
		} else {
			startTutorial(
				{
					tooltipClass: 'welcomeTutorial',
					steps: [
						{
							title: 'Welcome to the Branding tutorial!',
							tooltipClass: 'wide-tooltip',
							completeStep: async () => {},
							intro: `
					First, please watch the video introduction below for a preview and explanation of this branding exercise. Afterwards, we will provide interactive guidance for you to complete the exercise yourself.<br>
<p class="embed"><iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/b0OlzGHuKoY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>`,
						},
						{
							title: 'Now your turn!',
							tooltipClass: 'wide-tooltip',
							completeStep: async () => {},
							intro: `
					Now onto the interactive tutorial...
<br><br>Wherever you see the ➡ icon, you should follow the instruction.
<br><br>
<strong>➡ To begin, click 'Understood'</strong>`,
						},
					],
					doneLabel: `Understood`,
				},
				{
					onexit: () => {
						showWordInputPanel();
						fillTextPanel();
					},
				}
			);
		}
	}
};

const fillTextPanel = (): void => {
	if (isTutorialActive('inspire', templateId)) {
		clearNonBlockingMessage();
		startTutorial(
			{
				disableInteraction: false,
				hideNext: false,
				steps: [
					{
						title: 'Adding nodes without AI',
						element: '.alert-show textarea',
						position: 'bottom',
						completeStep: async () => {},
						intro: `Typically, you will add your own ideas or inspiration to the board to start.
<br><br>
For this branding exercise, we'll start by adding the phrase "Whiskey is legendary with famous musicians".
<br><br>
We'll be looking to design a brand around this idea.`,
					},
					{
						title: `Let's generate a project vision and summary`,
						element: '.alert-show button.primary',
						position: 'left',
						completeStep: async (target) => {
							await sleep(3000);
							(target as HTMLButtonElement).click();
						},
						intro: `<strong>➡ Click 'Next'</strong>`,
					},
				],
			},
			{
				onafterchange: function (target: HTMLElement) {
					if (target.tagName === 'TEXTAREA') {
						addTextWithTypingEffect(
							target as HTMLTextAreaElement,
							'Whiskey is legendary with famous musicians'
						);
					}
				},
			}
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedProjectVisionPanel, openedProjectVisionPanel);
	}
};

const openedProjectVisionPanel = () => {
	if (isTutorialActive('inspire', templateId)) {
		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Project vision`,
						element: '.alert-show button.primary',
						completeStep: async (target) => {
							await sleep(4000);
							(target as HTMLButtonElement).click();
						},
						intro: `On this panel, the AI will automatically generate a project vision and summary.
						<br><br>
This can help to elaborate on the idea you entered.
<br><br>
<strong>➡ Click 'Next'</strong>`,
					},
				],
			});
		}, 50);

		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedAddToBoardPanel, openedAddToBoardPanel);
	}
};

const openedAddToBoardPanel = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Add to board`,
					element: '.alert-show button.primary',
					completeStep: clickOnTarget,
					intro: `For this tutorial, we will use the AI-generated project <strong>summary</strong>.
<br><br>
<strong>➡ Click 'Add to board'</strong>`,
				},
			],
		});

		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedWordsFromPanel, addedWordsFromPanel);
	}
};

const addedWordsFromPanel = () => {
	exitTutorial();
	const graph = globalThis.neuroCreate.graph!;
	if (isTutorialActive('inspire', templateId)) {
		graph.zoomOut();
		graph.updateActive(graph.getNodeWithLabel('musicians')!, true, true);

		// Reset lateral thinking slider
		slideLateralThinkingSliderTo(0);
		graph.setHintsShown();

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Words added`,
					element: '#all-content .nodes',
					completeStep: async () => {},
					intro: `The words have been added to the board in a central box.
<br><br>
Around the central box, keywords from the text have been added.
Commonplace words such as 'is' and 'with' are omitted.
<br><br>
Let's explore these concepts`,
				},
				{
					title: `Using Spark`,
					element: '.menu .arc[data-action=spark]',
					position: 'right',
					completeStep: clickOnTarget,
					intro: `Now let's use the Spark tool to add discover related concepts.
<br><br>
<strong>➡ Click 'Spark'</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedSpark, afterClickSpark);
	}
};

const afterClickSpark = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Spark suggestions`,
					intro: `Spark suggests ideas from the AI. They are shown in pairs, which can be clicked on to add to the board...`,
					element: '#spark-container',
					disableInteraction: true,
					completeStep: async () => {},
				},
				{
					title: `Add a pair to the board`,
					intro: `Let's add the first pair of suggestions.
<br><br>
<strong>➡ Click on "Performers / Artists"</strong>`,
					element: '#spark-container .pairs-list li:first-child',
					position: 'left',
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedTwoNodes, afterSparkPair);
	}
};

const afterSparkPair = () => {
	if (isTutorialActive('inspire', templateId)) {
		closeAllPanels();
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('artists')!,
			true,
			true
		);

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Spark`,
					element: '.menu .arc[data-action=spark]',
					position: 'right',
					intro: `You'll see two new nodes: 'performers' and 'artists'.
<br><br>
Let's spark on 'artists' to get more ideas.
<br><br>
<strong>➡ Click 'Spark'</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedSpark, afterSparkArtists);
	}
};

const afterSparkArtists = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Add a pair to the board`,
					intro: `Let's add the third pair of suggestions.
<br><br>
<strong>➡ Click on "Choreographers / Singers"</strong>`,
					element: '#spark-container .pairs-list li:nth-child(3)',
					position: 'left',
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedTwoNodes, afterSparkArtistsAdd);
	}
};

const afterSparkArtistsAdd = () => {
	if (isTutorialActive('inspire', templateId)) {
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('artists')!,
			true,
			true
		);

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Using Empathise`,
					element: '.menu .arc[data-action=empathise]',
					position: 'right',
					intro: `Now let's use the Empathise tool to add discover new perspectives.
<br><br>
<strong>➡ Click on "Empathise"</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedEmpathise, afterClickEmpathise);
	}
};

const afterClickEmpathise = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Empathise suggestions`,
					intro: `Empathise AI generates words from different perspectives following the 6 Hats design-thinking tool. Clicking on a perspective adds it to the board...`,
					element: '#empathise-container',
					disableInteraction: true,
					completeStep: async () => {},
				},
				{
					title: `Add a perspective to the board`,
					intro: `Let's add "excitement" to the board
<br><br>
<strong>➡ Click on "excitement"</strong>`,
					element: '#empathise-container .pairs-list li[data-word="excitement"]',
					position: 'left',
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, afterEmpathiseAdd);
	}
};

const afterEmpathiseAdd = () => {
	if (isTutorialActive('inspire', templateId)) {
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('excitement')!,
			true,
			true
		);
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Using Link`,
					element: '.menu .arc[data-action=link]',
					intro: `Next we'll use the Link tool. It finds a concept connecting two or more nodes.
<br><br>
<strong>➡ Click on "Link"</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedLink, afterClickLink);
	}
};

const afterClickLink = () => {
	if (isTutorialActive('inspire', templateId) && globalThis.neuroCreate.graph) {
		const whiskeyNode = globalThis.neuroCreate.graph.getNodeWithLabel('Whiskey')!;
		globalThis.neuroCreate.graph.centerTo(whiskeyNode);
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Select another node`,
					element: `#all-content .nodes g#node-${whiskeyNode.uid} circle.node`,
					intro: `<strong>➡ Click on the 'Whiskey' node to select it</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.selectedNode, afterSelectWhiskey);
	}
};

const afterSelectWhiskey = () => {
	if (isTutorialActive('inspire', templateId)) {
		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				doneLabel: `OK, I'll press "Link concepts"`,
				steps: [
					{
						title: `Complete the Link`,
						intro: `<strong>➡ Press "Link concepts" to generate a linking concept.</strong>`,
					},
				],
			});
		}, 200);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, afterLinked);
	}
};

const afterLinked = () => {
	if (isTutorialActive('inspire', templateId)) {
		closeAlert();

		// Reset lateral thinking slider
		slideLateralThinkingSliderTo(0);

		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Linked word added`,
						element: '.menu-open',
						intro: `Thrills was discovered as the link between 'Whiskey' and 'excitement'`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: `Let's dive deeper into Spark`,
						element: '.menu .arc[data-action=spark]',
						intro: `We'll learn about the lateral thinking slider.
<br><br>
<strong>➡ Click 'Spark' on the 'thrills' node</strong>`,
						completeStep: clickOnTarget,
					},
				],
			});
		}, 1000);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedSpark, afterThrillsSpark);
	}
};

const afterThrillsSpark = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Lateral Thinking Slider`,
						element: '#spark-container input.slider',
						position: 'left',
						intro: `Lateral thinking is a way of solving problems using an indirect and creative approach.
					You can slide the lateral thinking sliders to get more indirect suggestions.
					<br><br>
					<strong>➡ Slide it to the end</strong>`,
						completeStep: async () => {
							await sleep(1000);
							slideLateralThinkingSliderTo(100);
						},
					},
					{
						title: `See new suggestions`,
						element: '#spark-container .pairs-list',
						position: 'left',
						intro: `You will notice the suggestions have now updated`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: `Add suggestions to board`,
						element: '#spark-container .pairs-list',
						position: 'left',
						// @ts-expect-error scrollTo is not in the type definitions
						scrollTo: '#spark-container .pairs-list li:last-child',
						intro: `<strong>➡ Click to add "Fancies" / "Cookbooks" to the board</strong>`,
						completeStep: clickOnTarget,
					},
				],
			},
			{
				onafterchange: (target) => {
					updatePosition();
					console.log(target);
					if (target.classList.contains('pairs-list')) {
						slideLateralThinkingSliderTo(100);
						target.scrollTop = target.scrollHeight;
					} else {
						slideLateralThinkingSliderTo(0);
					}
				},
			}
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedTwoNodes, afterThrillsSparkPair);
	}
};

const afterThrillsSparkPair = () => {
	if (isTutorialActive('inspire', templateId)) {
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('cookbooks')!,
			true,
			true
		);

		// Ensure lateral thinking slider is still at the end
		document.querySelector<HTMLInputElement>('#spark-container input.slider')!.value = '100';

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Another Spark example`,
					element: '.menu .arc[data-action=spark]',
					intro: `Let's get some more suggestions.
<br><br>
<strong>➡ Click 'Spark' on 'cookbooks'</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedSpark, afterCookbooksSpark);
	}
};

const afterCookbooksSpark = () => {
	if (isTutorialActive('inspire', templateId)) {
		slideLateralThinkingSliderTo(100);
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Add suggestions to board`,
					element: '#spark-container .pairs-list li:last-child',
					intro: `<strong>➡ Click to add "Memoirs" and "Blogs" to the board</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedTwoNodes, afterCookbooksSparkPair);
	}
};

const afterCookbooksSparkPair = () => {
	if (isTutorialActive('inspire', templateId)) {
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('memoirs')!,
			true,
			true
		);

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Let's like some concepts to add them to the Idea Box`,
					element: '.menu .arc[data-action=like]',
					intro: `<strong>➡ Click 'Like' on 'memoirs'</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedNode, afterLikeMemoirs);
	}
};

const afterLikeMemoirs = () => {
	if (isTutorialActive('inspire', templateId)) {
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('Whiskey')!,
			true,
			true
		);

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Continue liking nodes`,
					element: '.menu .arc[data-action=like]',
					intro: `<strong>➡ Click 'Like' on 'Whiskey'</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedNode, afterLikeWhiskey);
	}
};

const afterLikeWhiskey = () => {
	if (isTutorialActive('inspire', templateId)) {
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('singers')!,
			true,
			true
		);

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Introducing Generate`,
					element: '.menu .arc[data-action=generate]',
					intro: `With <strong>Generate</strong>, the AI can generate taglines or haikus.
<br><br>
<strong>➡ Click 'Generate' on 'singers'</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedGenerate, afterGenerateSingers);
	}
};

const afterGenerateSingers = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `See the Generate results`,
					element: '#generate-container',
					intro: `Taglines and Haikius may be generated. Here, you see the taglines that were generated.`,
					disableInteraction: true,
					completeStep: async () => {},
				},
				{
					title: `See Haiku results`,
					element: 'button[data-task=haiku]',
					intro: `<strong>➡ Click 'Haikus' to generate haikus</strong>`,
					disableInteraction: false,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedHaikus, afterClickHaikus);
	}
};

const afterClickHaikus = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Like a haiku`,
					element: '#generate-container .results-list li:nth-child(3) .heart',
					position: 'left',
					intro: `<strong>➡ Click the heart to add to your Idea Box</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedResult, likedHaiku);
	}
};

const likedHaiku = () => {
	if (isTutorialActive('inspire', templateId)) {
		globalThis.neuroCreate.graph!.updateActive(
			globalThis.neuroCreate.graph!.getNodeWithLabel('Whiskey')!,
			true,
			true
		);
		closeAllPanels();

		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Merge`,
					position: 'left',
					element: '.menu .arc[data-action=merge]',
					intro: `Next we'll see how to merge two nodes.
<br><br>
<strong>➡ Click 'Merge' on 'Whiskey'</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedMerge, afterClickMerge);
	}
};

const afterClickMerge = () => {
	if (isTutorialActive('inspire', templateId) && globalThis.neuroCreate.graph) {
		closeAlert();
		const node = globalThis.neuroCreate.graph.getNodeWithLabel('artists')!;
		globalThis.neuroCreate.graph.centerTo(node);
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Choose another node`,
					intro: `<strong>➡ Click the 'artists' node to merge with it.</strong>`,
					element: `#node-${node.uid} circle.node`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.mergedNodes, afterMerge);
	}
};

const afterMerge = () => {
	if (isTutorialActive('inspire', templateId)) {
		setTimeout(() => {
			const whiskeyArtists = globalThis.neuroCreate.graph!.getNodeWithLabel('Whiskey artists')!;
			globalThis.neuroCreate.graph!.updateActive(whiskeyArtists, true, true);
			globalThis.neuroCreate.graph!.centerTo(whiskeyArtists);

			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Items merged`,
						element: '.menu-open',
						intro: `The items have been merged as 'Whiskey artists'. Now, we can use the AI tools on this phrase instead of only a single word.`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: `More Generate options`,
						element: '.menu .arc[data-action=generate]',
						intro: `<strong>Generate</strong> can also brainstorm, generate images, and find common ideas.
<br><br>
<strong>➡ Click 'Generate' on 'whiskey artists'</strong>`,
						completeStep: clickOnTarget,
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedGenerate, afterGenerateWhiskeyArtists);
		}, 50);
	}
};

const afterGenerateWhiskeyArtists = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Find common ideas`,
					element: 'button[data-task=common]',
					intro: `<strong>➡ Click 'Common Ideas'</strong>`,
					disableInteraction: false,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedCommonIdeas, afterClickCommonIdeas);
	}
};

const afterClickCommonIdeas = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Select another node`,
					element: '#generate-container .common-ideas-input .select',
					position: 'left',
					intro: `To find common ideas we need to select at least one more node.<br/>
<br><br>
<strong>➡ Click on "Select items"</strong>`,
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.selectingItems, clickedSelectingItems);
	}
};

const clickedSelectingItems = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Select another node`,
					position: 'left',
					intro: `Select at least one more node.<br/>
<strong>➡ Select at least one more node.</strong>
<br><br>
<strong>➡ Then, click on "Done"</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.doneSelectingItems, doneSelectingItems);
	}
};

const doneSelectingItems = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Find common ideas`,
					element: '#generate-container .common-ideas-input button.submit',
					position: 'left',
					intro: `<strong>➡ Click "Generate common ideas"</strong>`,
				},
			],
		});
		tutorialEmitter.once(
			TUTORIAL_EVENT_TYPES.clickedCommonIdeasResults,
			clickedGenerateCommonIdeas
		);
	}
};

const clickedGenerateCommonIdeas = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial({
			disableInteraction: false,
			steps: [
				{
					title: `Generate options`,
					position: 'left',
					element: '#generate-container',
					intro: `Fantastic, you've generated some common ideas! Press the heart by any that inspire you.`,
				},
				{
					title: `Generate options`,
					position: 'left',
					element: '#generate-container [data-task="brainstorm"]',
					intro: `You can also use the 'Brainstorm' option to generate ideas. This works in a similar way so we will leave you try it later.`,
					disableInteraction: true,
				},
				{
					title: `Generate options`,
					position: 'left',
					element: '#generate-container [data-task="image-inspire"]',
					intro: `But now, let's look at using the AI to generate images!
<br><br>
<strong>➡ Click "Image"</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedGenerateImage, clickedGenerateImage);
	}
};

const clickedGenerateImage = () => {
	if (isTutorialActive('inspire', templateId)) {
		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Generate an image`,
						element: '#generate-container .generate-image-button',
						position: 'left',
						intro: `The AI will generate an image based on "whiskey artists".
<br><br>
<strong>➡ Click "Generate Image"</strong>
<br><br>
Wait a few seconds for the image to be generated.`,
					},
				],
			});
		}, 50);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedGenerateImageResult, generatedImage);
	}
};

const generatedImage = () => {
	if (isTutorialActive('inspire', templateId)) {
		const whiskeyArtists = globalThis.neuroCreate.graph!.getNodeWithLabel('Whiskey artists')!;
		globalThis.neuroCreate.graph!.updateActive(whiskeyArtists, true, true);
		globalThis.neuroCreate.graph!.centerTo(whiskeyArtists);

		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				steps: [
					{
						title: `Image generated!`,
						element: '#generate-container .results-list',
						position: 'left',
						intro: `This is the image the AI generated for "whiskey artists". You can click the heart to like it, or click on the image to add it to the board.`,
					},
					{
						title: `Using Search`,
						element: '.menu .arc[data-action=search]',
						intro: `Search shows relevant results from around the web.
					<br><br>
					<strong>➡ Click 'Search' on 'Whiskey artists'</strong>`,
						completeStep: clickOnTarget,
					},
				],
			});
		}, 200);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedSearch, afterSearch);
	}
};

const afterSearch = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				steps: [
					{
						title: `See Search results`,
						element: '#search-container',
						intro: `You can see links and images from around the web.
<br><br>
<strong>➡ Click the heart icon to like any results that inspire you.</strong>`,
					},
					{
						title: `Idea Box`,
						element: '#board-menu-hearted',
						intro: `<strong>➡ Click the icon to open your Idea Box and see all the things you have liked</strong>`,
						completeStep: clickOnTarget,
					},
				],
			},
			{
				onbeforechange: ensureMenuIsOpen,
			}
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedIdeaBox, afterOpenIdeaBox);
	}
};

const afterOpenIdeaBox = () => {
	if (isTutorialActive('inspire', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				steps: [
					{
						title: 'Completed inspire 🎉',
						element: '#board-stage-switcher',
						intro: `You have completed the Inspire tutorial!
<br><br>Feel free to explore further and like more concepts, then when ready:
<br><br>
<strong>➡ Click on 'Ideate' to progress to the next stage.</strong>`,
					},
				],
			},
			{
				onexit: () => {
					deactivateTutorialButton();
				},
			}
		);
	}
};
