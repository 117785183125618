import { PresentationInfo } from '../../../../../src/commonTypes';
import {
	createAutogeneratedLabel,
	createDetails,
	createGenerateIdeateLabel,
	createImage,
	createLabel,
	createPreviewParagraph,
	createSelectableCheckbox,
} from './utils';
import { like, savePresentationInfo, userPersonaImage } from '../../apiRequests';

const getPersona = (item: PresentationInfo['personas'][0], presentation: PresentationInfo) => {
	const { value: persona } = item;
	const [personaDetails, personaHeading] = createDetails(`Persona: ${persona.theme}`);

	if (!item.autogenerated) {
		personaHeading.append(createGenerateIdeateLabel());
	} else if (item.autogenerated) {
		personaHeading.append(createAutogeneratedLabel());
	}

	const nameLabel = createLabel('Name');

	const personaCheckbox = createSelectableCheckbox(item, presentation)[0];
	personaHeading.prepend(personaCheckbox);
	if (persona.imageUrl) {
		personaHeading.append(createImage(persona.imageUrl, 50));
	} else {
		const generateImageButton = document.createElement('image-generator-with-quota');
		generateImageButton.hideBackground = true;
		generateImageButton.onClick = () => {
			const boardId = document.body.dataset.boardId!;
			userPersonaImage(boardId, persona).then(async (imageResult) => {
				personaHeading.append(createImage(imageResult.snippet, 50));
				generateImageButton.remove();
				personaDetails.insertBefore(createLabel('Persona Image'), nameLabel);
				persona.imageUrl = imageResult.snippet;
				personaDetails.insertBefore(createImage(persona.imageUrl, 512), nameLabel);
				like(boardId, 'persona', JSON.stringify(persona), null, false, persona.theme);
				await savePresentationInfo(document.body.dataset.boardId!, presentation);
			});
		};
		personaHeading.append(generateImageButton);
	}

	if (persona.imageUrl) {
		personaDetails.append(createLabel('Persona Image'));
		personaDetails.append(createImage(persona.imageUrl, 512));
	}

	personaDetails.append(nameLabel);
	personaDetails.append(createPreviewParagraph(persona.name));

	personaDetails.append(createLabel('Scores'));
	personaDetails.append(
		createPreviewParagraph(
			`Technical Knowledge: ${persona.scores.tech}; Ambition: ${persona.scores.ambition}; Happiness: ${persona.scores.happiness}`
		)
	);

	personaDetails.append(createLabel('Biography'));
	personaDetails.append(createPreviewParagraph(persona.biography));

	personaDetails.append(createLabel('Goals'));
	personaDetails.append(createPreviewParagraph(persona.goals));

	personaDetails.append(createLabel('Influences'));
	personaDetails.append(createPreviewParagraph(persona.influences));

	personaDetails.append(createLabel('Motivations'));
	personaDetails.append(createPreviewParagraph(persona.motivations));

	personaDetails.append(createLabel('Needs & Wants'));
	personaDetails.append(createPreviewParagraph(persona.needs));

	personaDetails.append(createLabel('Pain points'));
	personaDetails.append(createPreviewParagraph(persona.painPoints));

	return personaDetails;
};

export const getPersonaSlideContent = (presentation: PresentationInfo) => {
	const personaContent = document.createElement('div');

	const introP = document.createElement('p');
	introP.innerHTML =
		'<small>Some personas were <strong class="inspire-key">autogenerated</strong> from your project vision. You can create more with <strong class="ideate-key">Ideate > Generate > User persona</strong>.</small>';
	personaContent.append(introP);

	presentation.personas.forEach((item) => {
		personaContent.append(getPersona(item, presentation));
	});

	return personaContent;
};
