import { PresentationInfo } from '../../../../../src/commonTypes';
import {
	createBudgetField,
	createCurrencyField,
	createDateField,
	createLabel,
	createTextField,
} from './utils';
import { savePresentationInfo } from '../../apiRequests';

export const getLogisticsSlideContent = (presentation: PresentationInfo) => {
	const logisticsContent = document.createElement('div');
	presentation.logistics = presentation.logistics || {
		show: false,
	};
	const logistics = presentation.logistics;

	const showLabel = createLabel('Show logistics slide');
	logisticsContent.append(showLabel);
	const checkbox = document.createElement('input');
	checkbox.type = 'checkbox';
	checkbox.checked = logistics?.show || false;
	checkbox.addEventListener('change', (event: Event) => {
		if (event.target !== checkbox) {
			checkbox.checked = !checkbox.checked;
		}
		logistics.show = checkbox.checked;
		savePresentationInfo(document.body.dataset.boardId!, presentation);
	});
	showLabel.prepend(checkbox);

	logisticsContent.append(createLabel('Deliverables'));
	logisticsContent.append(
		createTextField(logistics?.deliverables || '', 1, (event: Event) => {
			logistics.deliverables = (event.target as HTMLTextAreaElement).value;
			savePresentationInfo(document.body.dataset.boardId!, presentation);
		})
	);

	const budgetContainer = document.createElement('div');
	budgetContainer.classList.add('budget-container');
	budgetContainer.append(createLabel('Budget'));
	budgetContainer.append(createCurrencyField('currency', logistics.currency || 'GBP'));
	budgetContainer.append(
		createBudgetField(logistics?.budget || '', (event: Event) => {
			logistics.budget = (event.target as HTMLTextAreaElement).value;
			savePresentationInfo(document.body.dataset.boardId!, presentation);
		})
	);
	logisticsContent.append(budgetContainer);

	logisticsContent.append(createLabel('Delivery Date'));
	logisticsContent.append(
		createDateField(logistics?.deliveryDate, (event: Event) => {
			logistics.deliveryDate = (event.target as HTMLTextAreaElement).value;
			savePresentationInfo(document.body.dataset.boardId!, presentation);
		})
	);

	logisticsContent.append(createLabel('First Review Date'));
	logisticsContent.append(
		createDateField(logistics?.firstReviewDate, (event: Event) => {
			logistics.firstReviewDate = (event.target as HTMLTextAreaElement).value;
			savePresentationInfo(document.body.dataset.boardId!, presentation);
		})
	);

	logisticsContent.append(createLabel('Second Review Date'));
	logisticsContent.append(
		createDateField(logistics?.secondReviewDate, (event: Event) => {
			logistics.secondReviewDate = (event.target as HTMLTextAreaElement).value;
			savePresentationInfo(document.body.dataset.boardId!, presentation);
		})
	);

	logisticsContent.append(createLabel('Assigned Team'));
	logisticsContent.append(
		createTextField(logistics?.team || '', 1, (event: Event) => {
			logistics.team = (event.target as HTMLTextAreaElement).value;
			savePresentationInfo(document.body.dataset.boardId!, presentation);
		})
	);

	return logisticsContent;
};
