import { removeChildren } from './uiUtils';
import { resizeLongSuggestionsToFit } from './toolsUI';
import {
	sparkResultsGenerated,
	TUTORIAL_EVENT_TYPES,
	tutorialEmitter,
} from '../tutorials/tutorialEvents';
import { TopicAnalysis } from '../../../../src/commonTypes';

export const resetSpark = () => {
	const sparkContainer = document.querySelector('#spark-container')!;
	const loadingIndicator = sparkContainer.querySelector('.loading')!;
	const pairsList = sparkContainer.querySelector('.pairs-list')!;

	removeChildren(pairsList); // remove any earlier pairs
	loadingIndicator.classList.remove('hidden');
};

export const showSparkResults = (
	suggestions: Array<TopicAnalysis>,
	addTwoNodes: (words: [TopicAnalysis, TopicAnalysis]) => void,
	sparkSliderValue: number
) => {
	const sparkContainer = document.querySelector('#spark-container')!;
	const pairsList = sparkContainer.querySelector('.pairs-list')!;

	removeChildren(pairsList); // remove any earlier pairs

	const index = Math.max(0, Math.floor((suggestions.length - 10) * (sparkSliderValue / 100)));
	const suggestionsToShow = suggestions.slice(index, index + 10);

	if (suggestionsToShow.length === 0) {
		pairsList.innerHTML = '<p><em>No results</em></p>';
	}

	const pairsToShow = suggestionsToShow.reduce(
		(result, value, index, array) => {
			// split into pairs
			if (index % 2 === 0) {
				const maybeAPair = array.slice(index, index + 2);
				if (maybeAPair.length === 2) {
					result.push(maybeAPair as [TopicAnalysis, TopicAnalysis]);
				}
			}
			return result;
		},
		[] as Array<[firstSuggestion: TopicAnalysis, secondSuggestion: TopicAnalysis]>
	);

	for (const pair of pairsToShow) {
		const pairEl = document.createElement('li');
		const firstWord = document.createElement('span');
		firstWord.innerHTML = pair[0].key;
		const secondWord = document.createElement('span');
		secondWord.innerHTML = pair[1].key;
		pairEl.append(firstWord, secondWord);

		pairEl.onclick = () => {
			addTwoNodes(pair);
		};

		pairsList.append(pairEl);
	}

	sparkResultsGenerated(
		pairsToShow.map(([firstSuggestion, secondSuggestion]) => [
			firstSuggestion.key,
			secondSuggestion.key,
		])
	);

	resizeLongSuggestionsToFit();
};

export const setupSparkResults = (
	suggestions: Array<{ key: string; topics: string[] }>,
	addTwoNodes: (words: [TopicAnalysis, TopicAnalysis]) => void
) => {
	const container = document.querySelector('#spark-container')!;
	const loadingIndicator = container.querySelector('.loading')!;

	loadingIndicator.classList.add('hidden');

	const lateralThinkingSlider = container.querySelector<HTMLInputElement>("input[type='range']")!;

	showSparkResults(suggestions, addTwoNodes, parseInt(lateralThinkingSlider.value));

	lateralThinkingSlider.oninput = () => {
		showSparkResults(suggestions, addTwoNodes, parseInt(lateralThinkingSlider.value));
		tutorialEmitter.emit(
			TUTORIAL_EVENT_TYPES.lateralSliderMoved,
			parseInt(lateralThinkingSlider.value)
		);
	};
};
