import * as d3 from 'd3';
import * as tutorial from '../tutorials/tutorial';
import { Point } from '../../../../src/commonTypes';
import { calcGraphWidthAndHeight } from './graphUtils';

const PAN_STEP = 80;
const TRANSITION_MS = 300;

class Zoomable {
	protected svg: d3.Selection<SVGSVGElement, null, HTMLElement, null>;
	protected baseElement: d3.Selection<SVGGElement, null, HTMLElement, null>;
	protected zoom: d3.ZoomBehavior<SVGSVGElement, null>; // this: void
	protected zoomTransform: undefined | d3.ZoomTransform;
	private firstDraw = true;

	initZoom(shouldZoomToFit: boolean, isExporting = false) {
		const zoomed = ({ transform }: { transform: d3.ZoomTransform }) => {
			this.zoomTransform = transform;
			this.baseElement.attr('transform', transform.toString());

			setTimeout(() => {
				tutorial.updatePosition();
			}, TRANSITION_MS);
		};

		this.zoom = d3.zoom<SVGSVGElement, null>().on('zoom', zoomed);

		this.svg.call(this.zoom).on('dblclick.zoom', null);

		if (shouldZoomToFit) {
			this.zoomToFit(isExporting);
		}
		this.firstDraw = false;
	}

	getMousePosition(event: MouseEvent) {
		let point = d3.pointer(event);
		if (this.zoomTransform) {
			// Entire graph is transformed by zoom, so apply inverse transform to get mouse coordinates
			point = this.zoomTransform.invert(point);
		}
		return point;
	}

	get baseSvgGroup(): SVGGElement {
		return this.baseElement.node()!;
	}

	zoomIn() {
		this.scaleBy(1.25);
	}

	zoomOut() {
		this.scaleBy(0.8);
	}

	// Zoom immediately to a specific scale
	zoomToScale(scale: number) {
		this.zoom.scaleBy(this.svg, scale);
	}

	// Zoom to specific scale with transition
	scaleBy(scale: number) {
		// eslint-disable-next-line @typescript-eslint/unbound-method
		this.svg.transition().duration(TRANSITION_MS).call(this.zoom.scaleBy, scale);
	}

	transform(transform: d3.ZoomTransform) {
		if (this.zoom) {
			// eslint-disable-next-line @typescript-eslint/unbound-method
			this.svg.transition().duration(TRANSITION_MS).call(this.zoom.transform, transform);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	wheel(event: Event) {
		// Do nothing for now
		// See https://github.com/NeuroCreate/webinnovator/issues/181
		// const deltaX = event.deltaX as number;
		// const deltaY = event.deltaY as number;
	}

	panUp() {
		const currentTransform = d3.zoomTransform(this.baseSvgGroup);
		this.transform(currentTransform.translate(0, -PAN_STEP));
	}

	panDown() {
		const currentTransform = d3.zoomTransform(this.baseSvgGroup);
		this.transform(currentTransform.translate(0, PAN_STEP));
	}

	panLeft() {
		const currentTransform = d3.zoomTransform(this.baseSvgGroup);
		this.transform(currentTransform.translate(-PAN_STEP, 0));
	}

	panRight() {
		const currentTransform = d3.zoomTransform(this.baseSvgGroup);
		this.transform(currentTransform.translate(PAN_STEP, 0));
	}

	zoomToFit(isExporting = false) {
		this.zoom.transform(this.svg, d3.zoomIdentity);

		const svgBoundsOnPage = this.svg.node()!.getBoundingClientRect();
		// const svgBounds = this.svg.node()!.getBBox();
		const contentBounds = this.baseSvgGroup.getBoundingClientRect();
		const currentTransform = d3.zoomTransform(this.baseSvgGroup);

		const desiredWidth = svgBoundsOnPage.width * (isExporting ? 0.9 : 0.9); // 90% of screen width
		const minContentWidth = Math.max(contentBounds.width, desiredWidth * currentTransform.k);
		const scaleFactorX = desiredWidth / minContentWidth;

		const desiredHeight = svgBoundsOnPage.height * (isExporting ? 0.9 : 0.75); // 75% of screen height
		const minContentHeight = Math.max(
			800,
			Math.max(contentBounds.height, desiredHeight * currentTransform.k)
		);
		// const minContentHeight = Math.max(contentBounds.height, desiredHeight * currentTransform.k);
		const scaleFactorY = desiredHeight / minContentHeight;

		const scaleFactor = Math.min(scaleFactorY, scaleFactorX);

		const contentBox = this.baseSvgGroup.getBBox();

		// Distance between origin and top-left corner
		const originXOffset = -(svgBoundsOnPage.width / 2) / scaleFactor;
		const originYOffset = -(svgBoundsOnPage.height / 2) / scaleFactor;

		// Distance between top-left corner of content and top-left corner
		const contextXOffset = originXOffset - contentBox.x;
		const contextYOffset = originYOffset - contentBox.y;

		const xPositionFromTopLeft =
			(svgBoundsOnPage.width * (isExporting ? 0.03 : 0.04)) / scaleFactor;
		let yPositionFromTopLeft = (svgBoundsOnPage.height * (isExporting ? 0.05 : 0.2)) / scaleFactor;
		if (!isExporting && yPositionFromTopLeft < 180) {
			yPositionFromTopLeft = 180;
		}

		// Distance to translate by:
		const xOffset = contextXOffset + xPositionFromTopLeft;
		const yOffset = contextYOffset + yPositionFromTopLeft;

		this.zoom.transform(this.svg, d3.zoomIdentity.scale(scaleFactor).translate(xOffset, yOffset));
		setTimeout(() => {
			tutorial.updatePosition();
		}, TRANSITION_MS);
	}

	centerTo({ x, y }: Point) {
		const currentTransform = d3.zoomTransform(this.baseSvgGroup);
		this.transform(
			currentTransform.translate(
				-currentTransform.applyX(x) / currentTransform.k,
				-currentTransform.applyY(y) / currentTransform.k
			)
		);
	}

	sizeToWindow(): void {
		const { width, height } = calcGraphWidthAndHeight();
		this.svg
			.attr('width', width)
			.attr('height', height)
			.attr('viewBox', [-width / 2, -height / 2, width, height]);
	}
}

export default Zoomable;
