import { removeChildren } from './uiUtils';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export const showSingleImageLightbox = (src: string) => {
	const gallery = document.querySelector<HTMLDivElement>('.gallery')!;
	removeChildren(gallery);

	const img = document.createElement('img');
	img.src = src;
	img.width = 0;
	img.height = 0;

	const a = document.createElement('a');
	a.href = src;
	a.target = '_blank';

	a.append(img);
	gallery.append(a);

	setTimeout(() => {
		a.dataset.pswpWidth = `${img.naturalWidth}`;
		a.dataset.pswpHeight = `${img.naturalHeight}`;
		const lightbox = new PhotoSwipeLightbox({
			gallery,
			children: 'a',
			showHideAnimationType: 'fade',
			pswpModule: PhotoSwipe,
			initialZoomLevel: () => 1 / window.devicePixelRatio,
		});
		lightbox.on('uiRegister', function () {
			lightbox.pswp?.ui?.registerElement({
				name: 'download-button',
				order: 8,
				isButton: true,
				tagName: 'a',

				// SVG with outline
				html: {
					isCustomSVG: true,
					inner:
						'<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
					outlineID: 'pswp__icn-download',
				},

				onInit: (el, pswp) => {
					const boardName =
						document.querySelector<HTMLInputElement>('#board-title-form input')!.value;
					el.setAttribute('download', `FlowCreate image - ${boardName}`);
					el.setAttribute('target', '_blank');
					el.setAttribute('rel', 'noopener');

					pswp.on('change', () => {
						if (pswp.currSlide?.data.src) {
							(el as HTMLAnchorElement).href = pswp.currSlide.data.src;
						}
					});
				},
			});
		});
		lightbox.init();
		lightbox.loadAndOpen(0);
	}, 10);
};

export const enableLightboxForImage = (img: HTMLImageElement) => {
	img.onclick = (e) => {
		e.preventDefault();
		showSingleImageLightbox(img.src);
	};
	img.classList.add('clickable');
};
