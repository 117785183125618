import { SYNTHESISE_AI_TASKS, SubTaskId } from '../../../../src/commonConstants';
import { cache, synthesiseAi } from '../apiRequests';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from '../tutorials/tutorialEvents';
import {
	removeChildren,
	showAlert,
	showNonBlockingMessage,
	clearNonBlockingMessage,
} from './uiUtils';
import { tableToString } from '../misc';
import { MoodboardGraph } from '../graphs';
import { EngineId, LikedItem } from '../../../../src/commonTypes';
import { showSynthesiseNodesPrompt } from './toolsUI';
import { WINDOW_BREAKPOINTS } from '../constants';
import { closeNotepad, openNotepad } from './notepad';
import { enableLightboxForImage } from './lightbox';
import { removeDuplicates } from '../../../../src/commonMisc';
import * as tutorialEvents from '../tutorials/tutorialEvents';

const synthesiseAiButton = document.querySelector<HTMLButtonElement>('#synthesise-ai')!;
const synthesiseAiContainer = document.querySelector<HTMLDivElement>('#synthesise-ai-container')!;
const form = synthesiseAiContainer.querySelector<HTMLFormElement>('#synthesise-ai-form')!;
const output = form.querySelector<HTMLDivElement>('.result')!;
const noResult = form.querySelector<HTMLParagraphElement>('.no-result')!;
const loadingIndicator = form.querySelector<HTMLImageElement>('.loading')!;
const likedItemCheckbox = form.querySelector<HTMLInputElement>(
	'.liked-input .checkbox-wrapper input'
)!;
const selectedItemCheckbox = form.querySelector<HTMLInputElement>(
	'.selected-input .checkbox-wrapper input'
)!;
const selectItemsButton = form.querySelector<HTMLInputElement>(
	'.selected-input .selected-item-wrapper button'
)!;
const aiInputImage = document.querySelector<HTMLDivElement>('.ai-input-image')!;
const imageContainer = aiInputImage.querySelector('.ai-input-image .image-container')!;
const aiInput = document.querySelector<HTMLDivElement>('.ai-input')!;
const textInput = form.querySelector<HTMLInputElement>('.ai-input input')!;
const copyButton = document.querySelector<HTMLButtonElement>('.copy-to-note')!;
const taskSelector = synthesiseAiContainer.querySelector<HTMLFieldSetElement>('.first-option')!;
const secondTaskSelectorWrapper = document.querySelector(
	'.second-option-container'
) as HTMLDivElement;
const secondTaskSelector =
	synthesiseAiContainer.querySelector<HTMLFieldSetElement>('.second-option')!;
const likedInputContainer = synthesiseAiContainer.querySelector<HTMLDivElement>('.liked-input')!;
const selectedInputContainer =
	synthesiseAiContainer.querySelector<HTMLDivElement>('.selected-input')!;

type TaskId = keyof typeof SYNTHESISE_AI_TASKS;

const toggleNodeItems = (nodeItemContainer: HTMLDivElement) => {
	const { container } = nodeItemContainer.dataset;
	const checkbox = nodeItemContainer.querySelector<HTMLInputElement>('input')!;
	if (container) {
		const nodeItems = nodeItemContainer.querySelector<HTMLDivElement>(`.${container}`)!;
		if (checkbox.checked) {
			nodeItems.classList.remove('hidden');
		} else {
			nodeItems.classList.add('hidden');
		}
	}
};

export const addNumberOfNodeItems = (nodeItemContainer: HTMLDivElement): void => {
	const count = nodeItemContainer.querySelector<HTMLSpanElement>('.count')!;
	const nodeItems: number = nodeItemContainer.querySelectorAll('li').length;
	count.textContent = '';
	if (nodeItems > 0) {
		count.textContent = `${nodeItems.toString()} `;
	} else {
		count.textContent = '';
	}
};
globalThis.addNumberOfNodeItems = addNumberOfNodeItems;

const updateSelectedNodesInPanel = (): void => {
	selectItemsButton.disabled = !selectedItemCheckbox.checked;
};

const handleSelectItems = (event: Event): void => {
	event.preventDefault();
	tutorialEvents.selectingItems();

	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		graph.startSelectingNodes('synthesiseNodes', []);
		showSynthesiseNodesPrompt(graph.selections.synthesiseNodes!);
	}
};

const updateLikedNodesInPanel = (): void => {
	if (globalThis.neuroCreate.graph) {
		const likedItems = removeDuplicates(globalThis.neuroCreate.graph.getLikedNodeLabels());
		const likedInput = document.querySelector<HTMLDivElement>('.liked-input')!;
		const likedItemWrapper = document.querySelector<HTMLDivElement>('.liked-items')!;
		const noLikedItem = document.querySelector<HTMLParagraphElement>(
			'.liked-item-wrapper .no-item'
		)!;

		removeChildren(likedItemWrapper);

		// add liked items to the list
		if (likedItems.length > 0) {
			noLikedItem.classList.add('hidden');
			for (const likedItem of likedItems) {
				const likedItemElement = document.createElement('li');
				likedItemElement.classList.add('liked-item');
				likedItemElement.innerText = likedItem;
				likedItemWrapper.appendChild(likedItemElement);
			}
		} else {
			noLikedItem.classList.remove('hidden');
		}
		addNumberOfNodeItems(likedInput);
	}
};

const filterAvailableTasks = (variant: SynthesiseVariant): TaskId[] => {
	let filteredObjectKeys: TaskId[];

	const allTaskIds = Object.keys(SYNTHESISE_AI_TASKS) as TaskId[];
	if (variant === 'Variant 1') {
		filteredObjectKeys = allTaskIds.filter(
			(taskId) => taskId !== 'Summary' && taskId !== 'Audience'
		);
	} else if (variant === 'Variant 2' || variant === 'Variant 3') {
		filteredObjectKeys = allTaskIds.filter((taskId) => taskId !== 'Summary');
	} else if (variant === 'Variant 4-A') {
		filteredObjectKeys = allTaskIds.filter((taskId) => taskId !== 'Unite' && taskId !== 'Audience');
	} else if (variant === 'Variant 4') {
		filteredObjectKeys = allTaskIds.filter((taskId) => taskId !== 'Unite');
	} else if (variant === 'Variant 5-A') {
		filteredObjectKeys = allTaskIds.filter(
			(taskId) =>
				taskId !== 'Summary' && taskId !== 'PESTEL' && taskId !== 'Extract' && taskId !== 'Audience'
		);
	} else if (variant === 'Variant 5-B') {
		filteredObjectKeys = allTaskIds.filter(
			(taskId) => taskId !== 'Summary' && taskId !== 'PESTEL' && taskId !== 'Extract'
		);
	} else if (variant === 'Variant 6-Image') {
		filteredObjectKeys = allTaskIds.filter((taskId) => taskId.startsWith('Image'));
	} else {
		filteredObjectKeys = allTaskIds;
	}
	// show variant for debugging
	const variantWrapper = document.querySelector<HTMLSpanElement>(
		'#synthesise-ai-container .variant'
	);
	if (variantWrapper) {
		variantWrapper.innerText = `Debug info: ${variant}`;
	}
	return filteredObjectKeys;
};

type SynthesiseVariant =
	| 'Variant 1' // Selected items, 1-4 words
	| 'Variant 2' // Selected items, 10+ words
	| 'Variant 3' // Selected items, 5-9 words
	| 'Variant 4' // Free text, 4+ words
	| 'Variant 4-A' // Free text, 1-3 words
	| 'Variant 5-A' // Free text + selected items, 1-3 words
	| 'Variant 5-B' // Free text + selected items, 4+ words
	| 'Variant 6-Image'
	| 'Invalid Input';

const getSelectedItems = (): string[] => {
	const likedItemElements = likedItemCheckbox.checked
		? document.querySelectorAll<HTMLLIElement>('.liked-items li')
		: [];
	const selectedItemElements = selectedItemCheckbox.checked
		? document.querySelectorAll<HTMLLIElement>('.selected-items li')
		: [];
	const allItemElements = [...likedItemElements, ...selectedItemElements];
	// Each string will have '\nx' at the end, so we slice it off
	return allItemElements.map((item) => item.innerText.slice(0, -2));
};

const getVariant = (): SynthesiseVariant => {
	const selectedItems = getSelectedItems();
	const hasTextInput = !!textInput.value;
	const hasNodeInput = selectedItems.length > 0;

	if (imageContainer.querySelector('img')) {
		return 'Variant 6-Image';
	} else if (hasTextInput && hasNodeInput) {
		if (selectedItems.length <= 3) {
			return 'Variant 5-A';
		} else {
			return 'Variant 5-B';
		}
	} else if (hasTextInput) {
		const numberOfWords = textInput.value.split(' ').length;
		if (numberOfWords < 4) {
			return 'Variant 4-A';
		}
		return 'Variant 4';
	} else if (hasNodeInput) {
		if (selectedItems.length > 0 && selectedItems.length <= 4) {
			return 'Variant 1';
		} else if (selectedItems.length >= 5 && selectedItems.length <= 9) {
			return 'Variant 3';
		} else {
			return 'Variant 2';
		}
	}
	return 'Invalid Input';
};

const clickLabels = (label: string) => {
	if (label) {
		const labelElement = document
			.querySelector('.options')
			?.querySelector(`label[for="${label}"]`) as HTMLLabelElement;
		labelElement.click();
		if (label === 'SCAMPER' || label === 'SWOT' || label === 'PESTEL') {
			const secondLabelElement = secondTaskSelector.querySelector('label') as HTMLLabelElement;
			secondLabelElement.click();
		}
	}
	return;
};

const preSelectTasks = (
	engine?: string,
	firstRecommender?: string,
	secondRecommender?: string
): void => {
	if (firstRecommender && firstRecommender === 'CREATIVE') {
		clickLabels('SCAMPER');
	} else if (firstRecommender && firstRecommender === 'COPYWRITER') {
		clickLabels('Tagline');
	} else if (firstRecommender && firstRecommender === 'PRODUCT_DESIGN') {
		clickLabels('Prod-ideas');
	} else if (secondRecommender && secondRecommender === 'BRANDING') {
		clickLabels('Strategy');
	} else if (secondRecommender && secondRecommender === 'PRODUCT') {
		clickLabels('Brief');
	} else if (secondRecommender && secondRecommender === 'STORY') {
		clickLabels('Synopsis');
	} else if (engine && engine === 'Games') {
		clickLabels('Game');
	} else {
		// if no option was selected, select the first one
		taskSelector.querySelector<HTMLLabelElement>('label')?.click();
	}
};

function getCurrentlySelectedTaskOptions() {
	// get selected option from task selector
	const selectedOption = taskSelector.querySelector<HTMLInputElement>('input:checked')?.value;
	// get selected sub option from second task selector
	const selectedSubOption =
		secondTaskSelector.querySelector<HTMLInputElement>('input:checked')?.value;
	return { selectedOption, selectedSubOption };
}

function getRecommenderAnswers() {
	const firstRecommenderAnswer = document.querySelector('body')?.dataset
		.firstRecommenderAnswer as string;
	const secondRecommenderAnswer = document.querySelector('body')?.dataset
		.secondRecommenderAnswer as string;
	return { firstRecommenderAnswer, secondRecommenderAnswer };
}

function reconstructTaskOptions(filteredTaskIds: TaskId[]) {
	removeChildren(taskSelector);
	for (const key of filteredTaskIds) {
		const option = document.createElement('input');
		const label = document.createElement('label');
		option.type = 'radio';
		option.name = 'first-option';
		option.id = key;
		option.value = key;
		label.htmlFor = key;
		label.innerText = SYNTHESISE_AI_TASKS[key].label || key;
		label.title = SYNTHESISE_AI_TASKS[key].name;

		taskSelector.appendChild(option);
		taskSelector.appendChild(label);
	}

	const description = document.querySelector('.description') as HTMLParagraphElement;
	const firstOptions = document.querySelectorAll('.first-option label');
	firstOptions.forEach((firstOption) => {
		firstOption.addEventListener('click', (event) => {
			showSubOption(event);
			showDescription(event, description);

			setTimeout(() => {
				const imageSubtask = (
					document.querySelector('.second-option input:checked') as HTMLInputElement
				)?.value;
				handleGenerateImageButton((firstOption as HTMLLabelElement).innerText, imageSubtask);
			}, 100);
		});
	});
}

function setSelectedTaskOptions(
	selectedOption: string | undefined,
	selectedSubOption: string | undefined,
	filteredTaskIds: TaskId[]
) {
	if (selectedOption === 'Image') {
		// trigger handleGenerateImageButton when the variant changed but the selected option is still image
		const imageSubtask = (
			document.querySelector('.second-option input:checked') as HTMLInputElement
		)?.value;
		handleGenerateImageButton(selectedOption, imageSubtask);
	}

	// set selected option because list was repopulated
	if (selectedOption && filteredTaskIds.includes(selectedOption as TaskId)) {
		clickLabels(selectedOption);

		// set selected sub option because list was repopulated
		if (selectedSubOption) {
			addSubTaskOptions(selectedOption as TaskId);
			clickLabels(selectedSubOption);
		}
	} else {
		const { firstRecommenderAnswer, secondRecommenderAnswer } = getRecommenderAnswers();
		const engine = document.querySelector('body')?.dataset.engine as EngineId;

		// pre-select option if there was no a previous selection
		preSelectTasks(engine, firstRecommenderAnswer, secondRecommenderAnswer);
	}
}

export const updateTaskOptionsInPanel = (): void => {
	const { selectedOption, selectedSubOption } = getCurrentlySelectedTaskOptions();

	const variant = getVariant();
	const filteredTaskIds = filterAvailableTasks(variant);

	reconstructTaskOptions(filteredTaskIds);

	document.querySelector<HTMLButtonElement>('#synthesise-ai-form button[type="submit"]')!.disabled =
		variant === 'Invalid Input';

	setSelectedTaskOptions(selectedOption, selectedSubOption, filteredTaskIds);
};

const handleGenerateImageButton = (firstOption: string, subtask?: string) => {
	// remove the previous generateImageButton if it exists
	const buttonContainer = document.querySelector('#synthesise-ai-form .button-container');
	buttonContainer?.querySelector('image-generator-with-quota')?.remove();

	const submit = buttonContainer?.querySelector('button.submit') as HTMLButtonElement;

	if (firstOption === 'Image') {
		// hide the submit button to replace it with the image generator
		submit.classList.add('hidden');

		const generateImageButton = document.createElement('image-generator-with-quota');

		generateImageButton.classList.add('submit');
		generateImageButton.align = 'center';
		generateImageButton.isDisabled = true;

		const variant = getVariant();

		if (subtask !== undefined) {
			generateImageButton.isDisabled = variant === 'Invalid Input';
		} else {
			const subtasks = document.querySelectorAll<HTMLInputElement>(
				'#synthesise-ai-form .second-option input'
			);
			subtasks.forEach((subtask) => {
				subtask.addEventListener('click', () => {
					generateImageButton.isDisabled = !subtask.checked || variant === 'Invalid Input';
				});
			});
		}

		generateImageButton.onClick = () => {
			handleSubmit();
		};
		buttonContainer?.append(generateImageButton);
	} else {
		buttonContainer?.querySelector('image-generator-with-quota')?.remove();
		if (submit.classList.contains('hidden')) {
			submit.classList.remove('hidden');
		}
	}
};

const showSubOption = (event: Event): void => {
	const firstOption = event.target as HTMLLabelElement;
	addSubTaskOptions(firstOption.htmlFor as TaskId);
};

const addSubTaskOptions = (taskId: TaskId): void => {
	const prompt = secondTaskSelectorWrapper.querySelector('.prompt') as HTMLSpanElement;
	prompt.innerText = '';
	const task = SYNTHESISE_AI_TASKS[taskId];
	const description = document.querySelector('.second-description') as HTMLParagraphElement;

	description.closest('div')!.style.display = taskId === 'Image' ? 'block' : 'flex';

	removeChildren(secondTaskSelector);

	if (task.options) {
		if (task['prompt']) {
			prompt.innerText = '';
			prompt.innerText = task['prompt'];
		}

		secondTaskSelectorWrapper.classList.add('show');
		for (const [key, subTask] of Object.entries(task.options)) {
			const option = document.createElement('input');
			const label = document.createElement('label');
			option.type = 'radio';
			option.value = key;
			option.id = key;
			option.name = 'second-option';
			label.htmlFor = key;
			label.innerText = subTask['optionName'];
			label.title = subTask['name'];
			secondTaskSelector.appendChild(option);
			secondTaskSelector.appendChild(label);
			if (subTask.requiresAdvanced && document.body.dataset.hasAdvancedAi !== 'true') {
				label.classList.add('soft-disabled');
				label.title += ' Upgrade your plan to enable this feature!';
			}
		}

		const secondOptions = document.querySelectorAll('.second-option label');

		removeChildren(description);
		secondOptions.forEach((secondOption) => {
			secondOption.addEventListener('click', (event) => {
				showDescription(event, description);
			});
		});
	} else {
		secondTaskSelectorWrapper.classList.remove('show');
		removeChildren(description);
	}
};

const setLoading = (): void => {
	output.innerText = '';
	noResult.classList.add('hidden');
	loadingIndicator.classList.remove('hidden');
	synthesiseAiContainer.scrollTop = synthesiseAiContainer.scrollHeight;
};

const removeLoading = (): void => {
	loadingIndicator.classList.add('hidden');
};

const displayOutput = (
	inputPhrase: string,
	taskId: TaskId | SubTaskId,
	firstOutput: string,
	secondOutput?: string
): void => {
	copyButton.classList.remove('hidden');

	if (taskId.includes('Image')) {
		if (firstOutput) {
			output.innerHTML = '';
			const img = document.createElement('img');
			img.src = secondOutput || firstOutput;
			img.alt = 'Synthesise AI generated image';
			img.width = 240;
			enableLightboxForImage(img);
			output.append(img);
		} else {
			output.innerHTML = '';
		}
	} else {
		let result = '';
		if (secondOutput) {
			result = `${firstOutput}

${secondOutput}`;
		} else {
			result = firstOutput;
		}
		output.innerHTML = result;
	}

	output.dataset.task = taskId;
	const outputToSave = secondOutput || firstOutput;
	output.dataset.outputToSave = outputToSave;

	loadingIndicator.classList.add('hidden');
	output.innerText === '' && output.innerHTML === ''
		? output.classList.add('hidden')
		: output.classList.remove('hidden');

	// Cache the result if it's a valid task with a 'likeType'
	let likeType = SYNTHESISE_AI_TASKS[taskId as TaskId]?.likeType as LikedItem['type'];
	if (!likeType) {
		const [parentTaskId] = taskId.split('-');
		const parentTask = SYNTHESISE_AI_TASKS[parentTaskId as TaskId];
		if (parentTask?.options) {
			likeType = parentTask.options[taskId as SubTaskId]?.likeType as LikedItem['type'];
		}
	}
	if (likeType) {
		// No need to await
		cache(document.body.dataset.boardId!, likeType, outputToSave, null, inputPhrase);
	}

	tutorialEvents.synthesiseResult();
};

copyButton.addEventListener('click', () => {
	const copiedOutput = output.cloneNode(true) as HTMLElement;
	const tableContent = copiedOutput.querySelector('table');
	if (copiedOutput && tableContent) {
		const div = document.createElement('div');
		div.innerHTML = tableToString(tableContent);

		copiedOutput.insertBefore(div, tableContent);
		tableContent.remove();
		copyResultToNotePad(copiedOutput.innerText, output.dataset.task as TaskId | SubTaskId);
	} else if (output.dataset.task?.includes('Image')) {
		const img = copiedOutput.querySelector('img') as HTMLImageElement;
		copyResultToNotePad(img.src, output.dataset.task as TaskId);
	} else {
		copyResultToNotePad(output.dataset.outputToSave as string, output.dataset.task as TaskId);
	}
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.addedToNote);
});

const copyResultToNotePad = (result: string, task: TaskId | SubTaskId): void => {
	if (result !== '' && globalThis.neuroCreate.graph) {
		openNotepad();

		if (task.includes('Image')) {
			(globalThis.neuroCreate.graph as MoodboardGraph).copyImageToNote(result);
		} else {
			(globalThis.neuroCreate.graph as MoodboardGraph).copyToNote(result);
		}

		// Like the result if it's a valid task with a 'likeType' (subtasks not currently supported)
		// const likeType = SYNTHESISE_AI_TASKS[task as TaskId]?.likeType as LikedItem['type'];
		// if (likeType) {
		// 	like(document.body.dataset.boardId!, likeType, result, null, false).then(() => {
		// 		refreshHeartedList();
		// 	});
		// }
	}
};

// Fetch result from Synthesise AI
const getSynthesiseAiResult = async (
	task: TaskId | SubTaskId,
	input: string,
	includeResult = false, // if true add the previous result into the output as well as the new result
	imageUrl?: string
): Promise<string> => {
	try {
		const { result } = await synthesiseAi(document.body.dataset.boardId!, task, input, imageUrl);
		console.log(`Debug output from [${task}]: ${result}`);
		if (includeResult && !task.includes('Image')) {
			return `${input}

${result}`;
		}
		return result;
	} catch (e) {
		removeLoading();
		showAlert(
			'Unable to generate result. Please try again later or contact us if this problem continues.'
		);
		return '';
	}
};

async function fetchResultVariant1(task: TaskId | SubTaskId, likedItems: string) {
	if (task.includes('SCAMPER')) {
		const extractedText = await getSynthesiseAiResult('Extract', likedItems);

		displayOutput(
			likedItems,
			task,
			extractedText,
			await getSynthesiseAiResult(task, extractedText)
		);
	} else if (task.includes('Image')) {
		const unitedText = await getSynthesiseAiResult('Unite', likedItems);
		displayOutput(unitedText, task, unitedText, await getSynthesiseAiResult(task, unitedText));
	} else {
		displayOutput(likedItems, task, await getSynthesiseAiResult(task, likedItems));
	}
}

async function fetchResultVariant2(task: TaskId | SubTaskId, likedItems: string) {
	if (task.includes('SCAMPER')) {
		const extractedText = await getSynthesiseAiResult('Extract', likedItems);

		displayOutput(
			likedItems,
			task,
			extractedText,
			await getSynthesiseAiResult(task, extractedText)
		);
	} else {
		const unitedText = await getSynthesiseAiResult('Unite', likedItems);

		displayOutput(likedItems, task, unitedText, await getSynthesiseAiResult(task, unitedText));
	}
}

async function fetchResultVariant3(task: TaskId | SubTaskId, likedItems: string) {
	displayOutput(likedItems, task, await getSynthesiseAiResult(task, likedItems));
	showNonBlockingMessage('Are you happy with the result?', [
		{
			label: 'Yes',
			onClick: () => {
				clearNonBlockingMessage();
			},
		},
		{
			label: 'No, try an alternative',
			onClick: async () => {
				clearNonBlockingMessage();
				const unitedText = await getSynthesiseAiResult('Unite', likedItems);

				displayOutput(likedItems, task, unitedText, await getSynthesiseAiResult(task, unitedText));
			},
		},
	]);
}
// calling for variant 4-a
async function fetchResultVariant4(task: TaskId | SubTaskId, freeText: string) {
	if (task === 'Summary' || task === 'Extract' || task === 'Post' || task.startsWith('Image')) {
		displayOutput(freeText, task, await getSynthesiseAiResult(task, freeText));
	} else {
		const extractedText = await getSynthesiseAiResult('Extract', freeText);

		displayOutput(freeText, task, extractedText, await getSynthesiseAiResult(task, extractedText));
	}
}

async function fetchResultVariant5A(
	task: TaskId | SubTaskId,
	freeText: string,
	likedItems: string
) {
	const extractedText = await getSynthesiseAiResult('Extract', freeText);
	const mergeInput = ` - ${extractedText || ''}
${likedItems}`;
	const unitedInput = await getSynthesiseAiResult('Unite', mergeInput);
	displayOutput(unitedInput, task, await getSynthesiseAiResult(task, unitedInput));
}

async function fetchResultVariant5B(
	task: TaskId | SubTaskId,
	freeText: string,
	likedItems: string
) {
	const extractFromText = await getSynthesiseAiResult('Extract', freeText);
	const uniteFromNodes = await getSynthesiseAiResult('Unite', likedItems);

	const mergeInput = ` - ${extractFromText || ''}
${uniteFromNodes || ''}`;
	const taskResult = await getSynthesiseAiResult(task, mergeInput);
	displayOutput(mergeInput, task, uniteFromNodes, taskResult);
}

async function fetchResultVariant6Image(task: TaskId | SubTaskId, imageUrl: string) {
	const taskResult = await getSynthesiseAiResult(task, '', false, imageUrl);
	displayOutput('', task, taskResult);
}

const fetchResult = async (
	variant: SynthesiseVariant,
	task: TaskId | SubTaskId,
	freeText: string,
	likedItems: string | undefined,
	imageUrl: string | undefined
): Promise<void> => {
	if (likedItems !== undefined) {
		setLoading();

		if (variant === 'Variant 1') {
			await fetchResultVariant1(task, likedItems);
		} else if (variant === 'Variant 2') {
			await fetchResultVariant2(task, likedItems);
		} else if (variant === 'Variant 3') {
			await fetchResultVariant3(task, likedItems);
		} else if (variant === 'Variant 4' || variant === 'Variant 4-A') {
			await fetchResultVariant4(task, freeText);
		} else if (variant === 'Variant 5-A') {
			await fetchResultVariant5A(task, freeText, likedItems);
		} else if (variant === 'Variant 5-B') {
			await fetchResultVariant5B(task, freeText, likedItems);
		} else if (variant === 'Variant 6-Image' && imageUrl) {
			console.log(imageUrl);
			await fetchResultVariant6Image(task, imageUrl);
		}
	}
};

const showDescription = (event: Event, description: HTMLElement): void => {
	const label = event.target as HTMLLabelElement;
	removeChildren(description);
	if (label) {
		const prompt = document.createElement('span');
		description.appendChild(prompt);
		prompt.innerText = label.title;
	}
};

function validateSelectedOptions(
	variant: SynthesiseVariant,
	taskOption: TaskId | SubTaskId,
	subTaskOption: string
): boolean {
	if (variant === 'Invalid Input') {
		showAlert('Invalid Input. Please enter some text or select liked items.');
		return false;
	}

	if (taskOption === null) {
		showAlert('Please select a task', [
			{
				label: 'Close',
				onClick: () => {
					clearNonBlockingMessage();
				},
			},
		]);
		return false;
	}

	const subTaskContainer = document.querySelector('.second-option') as HTMLFieldSetElement;
	if (subTaskContainer.hasChildNodes() && subTaskOption === null) {
		showAlert('Please select a sub task', [
			{
				label: 'Close',
				onClick: () => {
					clearNonBlockingMessage();
				},
			},
		]);
		return false;
	} else if (
		subTaskContainer.hasChildNodes() &&
		document.querySelector('#synthesise-ai-form fieldset input:checked+label.soft-disabled')
	) {
		showAlert('This option is not available on your plan. See billing settings to upgrade.', [
			{
				label: 'Open Billing Settings',
				onClick: () => {
					clearNonBlockingMessage();
					window.location.href = '/settings/billing';
				},
			},
			{
				label: 'Close',
				onClick: () => {
					clearNonBlockingMessage();
				},
			},
		]);
		return false;
	}

	return true;
}

const handleSubmit = (event?: Event) => {
	event?.preventDefault();

	const variant = getVariant();

	const formData = new FormData(form);
	const taskOption = formData.get('first-option') as TaskId;
	const subTaskOption = formData.get('second-option') as SubTaskId;

	if (validateSelectedOptions(variant, taskOption, subTaskOption)) {
		const textInput = formData.get('input') as string;
		const likedItemsAsText =
			variant === 'Variant 4' || variant === 'Variant 4-A'
				? ''
				: getSelectedItems()
						.map((i) => ' - ' + i.replace(/\n/g, ' '))
						.join('\n');
		const imageUrl = imageContainer.querySelector('img')?.src;

		fetchResult(variant, subTaskOption || taskOption, textInput, likedItemsAsText, imageUrl);
	}
};

export const resetSynthesiseAiPanel = () => {
	synthesiseAiContainer.scrollTop = 0;

	if (!synthesiseAiContainer.classList.contains('hidden')) {
		textInput.value = '';
		textInput.innerText = '';
		synthesiseAiButton.classList.add('buttonActive');
		output.classList.add('hidden');
		noResult.classList.remove('hidden');
	}
};

export const copySelectedTextFromNotepadToPanel = () => {
	if (globalThis.neuroCreate.graph) {
		const selection = globalThis.neuroCreate.graph.getCurrentlySelectedText();

		if (selection && selection.length > 1) {
			textInput.value = selection;
			textInput.innerText = selection;
		}

		if (selection && selection.length <= 1) {
			showAlert('Please select some text in the note to generate Synthesise AI');
		}
		return selection;
	}
};

const setupSynthesiseAiPanel = () => {
	updateLikedNodesInPanel();
	updateTaskOptionsInPanel();
	copyButton.classList.add('hidden');

	tutorialEmitter.on(TUTORIAL_EVENT_TYPES.likedNode, () => {
		updateLikedNodesInPanel();
		updateTaskOptionsInPanel();
	});

	addNumberOfNodeItems(likedInputContainer);
	addNumberOfNodeItems(selectedInputContainer);

	// toggle the liked items and selected items once the panel is opened
	toggleNodeItems(likedInputContainer);
	toggleNodeItems(selectedInputContainer);
};

export const openSynthesiseAiPanelWithImage = (url: string) => {
	synthesiseAiContainer.classList.remove('hidden');
	resetSynthesiseAiPanel();

	aiInput.classList.add('hidden');
	aiInputImage.classList.remove('hidden');

	removeChildren(imageContainer);
	const img = document.createElement('img');
	img.src = url;
	img.alt = 'Selected image';
	img.width = 240;
	imageContainer.appendChild(img);

	setupSynthesiseAiPanel();
};

export const openSynthesiseAiPanel = (input?: string | undefined) => {
	synthesiseAiContainer.classList.remove('hidden');
	resetSynthesiseAiPanel();
	copySelectedTextFromNotepadToPanel();
	if (input) {
		textInput.value = input;
		textInput.innerText = input;
	}

	aiInput.classList.remove('hidden');
	aiInputImage.classList.add('hidden');

	removeChildren(imageContainer);

	setupSynthesiseAiPanel();
};

if (synthesiseAiButton) {
	synthesiseAiButton.addEventListener('click', () => {
		if (synthesiseAiButton.classList.contains('buttonActive')) {
			synthesiseAiButton.classList.remove('buttonActive');
			synthesiseAiContainer.classList.add('hidden');
			resetSynthesiseAiPanel();
		} else {
			openSynthesiseAiPanel();
		}

		if (window.innerWidth < WINDOW_BREAKPOINTS.laptop) {
			closeNotepad();
		}
	});
	selectItemsButton.addEventListener('click', handleSelectItems);
	form.addEventListener('submit', handleSubmit);
	textInput.addEventListener('input', () => {
		updateTaskOptionsInPanel();
	});
	likedItemCheckbox.addEventListener('change', () => {
		updateLikedNodesInPanel();
		updateTaskOptionsInPanel();
		toggleNodeItems(likedInputContainer);
	});
	selectedItemCheckbox.addEventListener('change', () => {
		updateSelectedNodesInPanel();
		updateTaskOptionsInPanel();
		toggleNodeItems(selectedInputContainer);
	});
}

globalThis.updateTaskOptionsInPanel = updateTaskOptionsInPanel;
