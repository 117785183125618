import { PresentationInfo } from '../../../../../src/commonTypes';
import { createLabel, createPreviewParagraph } from './utils';

export const getVisionSlideContent = (presentation: PresentationInfo) => {
	const visionContent = document.createElement('div');

	visionContent.append(createLabel('Project vision'));
	visionContent.append(createPreviewParagraph(presentation.vision || 'Missing'));

	visionContent.append(createLabel('Project summary'));
	visionContent.append(createPreviewParagraph(presentation.summary || 'Missing'));

	return visionContent;
};
