import { AvailableColour, NCNode } from '../../../../src/commonTypes';
import { showAlert } from '../ui/uiUtils';
import { NCNodeDefinition } from '../../types';
import { generateNodeId } from './graphNodeUtils';

export const isSameNode = (node1: NCNode | undefined, node2: NCNode | undefined) => {
	if (!node1 || !node2) {
		return false;
	}
	return node1?.uid === node2?.uid;
};

export const addToNodeList = (node: NCNode, nodesList: Array<NCNode>, textNodesOnly = true) => {
	if (textNodesOnly && !node.label) {
		showAlert('Please choose a node containing a label');
		return false;
	}

	const nodeIndex = nodesList.findIndex((n) => n.uid === node.uid);
	if (nodeIndex !== -1) {
		// Remove the node
		nodesList.splice(nodeIndex, 1);
	} else {
		// Add the node
		nodesList.push(node);
	}

	return nodesList;
};

export const constructNodeFromDefinition = (
	nodeDefinition: NCNodeDefinition,
	userColourClass: AvailableColour
): NCNode => {
	const newNode: NCNode = {
		uid: generateNodeId(),
		created: new Date().getTime(),
		x: nodeDefinition.x,
		y: nodeDefinition.y,
		colour: userColourClass,
	};
	if (nodeDefinition.label) {
		newNode.label = nodeDefinition.label;
		if (
			nodeDefinition.label.length > 40 ||
			nodeDefinition.label.split(' ').filter((s) => s).length > 2
		) {
			newNode.style = 'rect';
		}
	}
	if (nodeDefinition.isVideo) {
		newNode.style = 'rect';
		newNode.isVideo = nodeDefinition.isVideo;
	}
	if (nodeDefinition.nodeColour) {
		newNode.nodeColour = nodeDefinition.nodeColour;
	}
	if (nodeDefinition.src) {
		newNode.src = nodeDefinition.src;
	}
	if (nodeDefinition.href) {
		newNode.href = nodeDefinition.href;
	}
	if (nodeDefinition.like) {
		newNode.likes = { [userColourClass]: true };
	}
	if (nodeDefinition.dotted) {
		newNode.dotted = true;
	}
	if (nodeDefinition.topics) {
		newNode.topics = nodeDefinition.topics;
	}
	return newNode;
};
