import { closeAllPanels, removeChildren, addToolTipWithTitle } from './uiUtils';
import { getUserLikes } from '../apiRequests';
import {
	GenerateTask,
	LikedItem,
	Motivations,
	NCCluster,
	UserPersona,
} from '../../../../src/commonTypes';
import * as tutorialEvents from '../tutorials/tutorialEvents';
import { filterLikesToType, userPersonaToString } from '../../../../src/commonMisc';
import { like, reorderLikes } from '../apiRequests';
import IdeateGraph from '../graphs/IdeateGraph';
import * as SortableDefault from 'sortablejs';
import { createStakeholderDiv } from './generateUi';
import { showSingleImageLightbox } from './lightbox';
// Fix for ESM
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Sortable = SortableDefault.default as typeof SortableDefault;

/* "Hearted list" is now labelled as "Idea Box" in the UI */

const ideaBoxButton = document.getElementById('board-menu-hearted')!;
const ideaBoxContainer = document.getElementById('hearted-container')!;
const ideaBoxOverrideSelect = document.querySelector<HTMLSelectElement>('#idea-box-override');

const createAddIcon = (div: HTMLDivElement, likedItem: LikedItem) => {
	if (['moodboard', 'inspire'].includes(globalThis.neuroCreate.graph?.graphMode || '')) {
		const add = document.createElement('div');
		add.className = 'add';
		add.innerText = '+';
		add.title = 'Add to board';

		add.onclick = () => {
			globalThis.neuroCreate.graph!.addToBoard(likedItem).then((node) => {
				if (node) {
					globalThis.neuroCreate.graph!.updateActive(node);
				}
			});
		};
		div.appendChild(add);
	}
};

const deleteHeartedItem = (item: LikedItem) => {
	const { boardId } = document.body.dataset;

	if (!boardId || !item) {
		return;
	}

	like(boardId, item.type, item.text || null, item.url || null, true).then(() => {
		refreshHeartedList();
		// if the item is deleted in idea box, toggle like of the node in graph
		if (item.type === 'node' && item.text) {
			const node = globalThis.neuroCreate.graph?.getNodeWithLabel(item.text);
			if (node) {
				globalThis.neuroCreate.graph?.toggleLike(node);
			}
		}

		// if cluster is deleted in idea box, toggle like of the cluster in graph
		if (item.type === 'cluster' && item.text) {
			// get value of the group from item.text to get cluster with label
			const group = (JSON.parse(item.text) as { group: string; value: string[] }).group;

			if (!group) {
				return;
			}

			const cluster = (globalThis.neuroCreate.graph as IdeateGraph).getClusterWithLabel(group);

			if (cluster) {
				(globalThis.neuroCreate.graph as IdeateGraph).toggleLikeCluster(cluster.cluster);
			}
		}
	});
};

export const displayLikedSection = (
	className: string,
	likedItems: Array<LikedItem>,
	itemMapper: (div: HTMLDivElement, item: LikedItem) => void,
	isOwner = true
) => {
	const likedContainer = ideaBoxContainer.querySelector(`.${className}`) as HTMLDivElement;
	removeChildren(likedContainer);

	if (likedItems.length > 0) {
		likedContainer.classList.remove('hidden');
		likedContainer.previousElementSibling!.classList.remove('hidden');
		likedItems.map((item) => {
			const likedItemWrapper = document.createElement('div');
			const div = document.createElement('div');
			likedItemWrapper.classList.add('like-item-wrapper');
			itemMapper(div, item);

			div.dataset.type = item.type;
			div.dataset.text = item.text || '';
			div.dataset.url = item.url || '';
			div.dataset.title = item.title || '';

			likedContainer.appendChild(likedItemWrapper);
			likedItemWrapper.appendChild(div);
			if (isOwner) {
				createAddIcon(div, item);
				const deleteButton = document.createElement('button');
				deleteButton.classList.add('delete');
				deleteButton.setAttribute('title', 'Delete item');
				deleteButton.addEventListener('click', () => {
					deleteHeartedItem(item);
					if (likedItems.length === 1) {
						likedContainer.classList.add('hidden');
						likedContainer.previousElementSibling!.classList.add('hidden');
					}
				});
				likedItemWrapper.appendChild(deleteButton);
			}
		});

		addToolTipWithTitle('div.add');
		addToolTipWithTitle('button.delete');

		new Sortable(likedContainer, {
			animation: 150,
			onEnd: () => {
				const { boardId } = document.body.dataset;
				if (!boardId) {
					return;
				}

				const likedItems = Array.from(
					likedContainer.querySelectorAll('.like-item-wrapper > div')
				).map((div): LikedItem | null => {
					if (div instanceof HTMLDivElement && div.dataset.type) {
						return {
							type: div.dataset.type as LikedItem['type'],
							text: div.dataset.text,
							url: div.dataset.url,
							title: div.dataset.title,
						};
					}
					return null;
				});

				if (likedItems.includes(null)) {
					return;
				}

				if (likedItems.length > 1 && likedItems[0]) {
					reorderLikes(boardId, likedItems[0].type, likedItems as LikedItem[]);
				}
			},
		});

		return likedContainer;
	} else {
		likedContainer.classList.add('hidden');
		likedContainer.previousElementSibling!.classList.add('hidden');
	}
};

export const refreshHeartedList = (callback: undefined | (() => void) = undefined) => {
	if (!ideaBoxContainer.classList.contains('hidden')) {
		// remove empty list
		ideaBoxContainer.querySelector('.empty-list')?.remove();

		const loading = ideaBoxContainer.querySelector('.loading')!;
		loading.classList.remove('hidden');
		const itemsContainer = ideaBoxContainer.querySelector<HTMLDivElement>('div.like-items')!;
		const previousScrollY = document.querySelector('.items-wrap')!.scrollTop;
		const { boardId } = document.body.dataset;
		if (!boardId) {
			return;
		}

		const overrideUserId =
			ideaBoxOverrideSelect && ideaBoxOverrideSelect?.value !== document.body.dataset.userId
				? ideaBoxOverrideSelect.value
				: undefined;

		getUserLikes(boardId, overrideUserId).then((likedItems) => {
			loading.classList.add('hidden');

			if (!likedItems.length) {
				// hide container, if user toggled the last liked item in graph
				const itemLists = itemsContainer.querySelectorAll('* > div:not(.hidden)');
				itemLists?.forEach((div) => {
					if (!div || !div.previousElementSibling) {
						return;
					}
					div.classList.add('hidden');
					div.previousElementSibling.classList.add('hidden');
				});

				const p = document.createElement('p');
				p.classList.add('empty-list');
				p.innerHTML = overrideUserId
					? 'Empty list! This user has not liked anything yet.'
					: "Empty list! You can add to this list wherever you see the <img src='/assets/graph/heart-blue.svg' alt='heart' style='margin-top: 6px; vertical-align: baseline'> icon.";
				itemsContainer.appendChild(p);
			} else {
				const likesOfType = (likeType: LikedItem['type']) =>
					filterLikesToType(likedItems, likeType);
				const textItemMapper = (div: HTMLDivElement, item: LikedItem) => {
					div.innerText = item.text || '';
				};

				const imageLikes = [...likesOfType('search-image'), ...likesOfType('image-inspire')];
				displayLikedSection(
					'image-container',
					imageLikes,
					(item, n) => {
						const a = document.createElement('a');
						a.target = '_blank';
						const images = document.createElement('img');
						if (n.url) {
							a.href = n.url;
							images.src = n.url;
						}
						a.onclick = (e) => {
							e.preventDefault();
							showSingleImageLightbox(n.url!);
						};
						a.appendChild(images);
						item.appendChild(a);
					},
					!overrideUserId
				);
				displayLikedSection(
					'link-container',
					[...likesOfType('search-page'), ...likesOfType('article')],

					(item, n) => {
						const a = document.createElement('a');
						if (n.text) {
							a.innerText = n.text;
						}
						if (n.url) {
							a.href = n.url;
						}
						a.target = '_blank';
						item.appendChild(a);
					},
					!overrideUserId
				);
				displayLikedSection('node-container', likesOfType('node'), textItemMapper, !overrideUserId);
				displayLikedSection(
					'cluster-container',
					likesOfType('cluster'),

					(item, n) => {
						if (n.text) {
							const { group, values } = JSON.parse(n.text) as NCCluster;
							item.innerText = `${group} - ${values.join(', ')}`;
						}
					},
					!overrideUserId
				);
				displayLikedSection(
					'haiku-container',
					[...likesOfType('haiku'), ...likesOfType('ai-haiku')],
					textItemMapper,
					!overrideUserId
				);
				displayLikedSection(
					'proverb-container',
					likesOfType('proverb'),
					textItemMapper,
					!overrideUserId
				);
				displayLikedSection(
					'brainstorm-container',
					likesOfType('brainstorm'),
					textItemMapper,
					!overrideUserId
				);
				displayLikedSection(
					'common-container',
					likesOfType('common'),
					textItemMapper,
					!overrideUserId
				);
				displayLikedSection(
					'persona-container',
					likesOfType('persona'),
					(div, item) => {
						try {
							const persona = JSON.parse(item.text!) as UserPersona;
							div.innerText = userPersonaToString(persona);
							if (persona.imageUrl) {
								const img = document.createElement('img');
								img.src = persona.imageUrl;
								img.width = 128;
								img.classList.add('persona-image');
								const br = document.createElement('br');
								div.prepend(img, br);
							}
						} catch (e) {
							// Old plain text format
							div.innerText = item.text || '';
						}
					},
					!overrideUserId
				);
				displayLikedSection(
					'audience-container',
					likesOfType('audience'),
					textItemMapper,
					!overrideUserId
				);
				displayLikedSection(
					'motivations-container',
					likesOfType('motivations'),
					(div, item) => {
						try {
							const motivations = JSON.parse(item.text!) as Motivations;
							for (const stakeholder of motivations.stakeholders) {
								div.append(createStakeholderDiv(stakeholder));
							}
						} catch (e) {
							// Old plain text format
							div.innerText = item.text || '';
						}
					},
					!overrideUserId
				);
				displayLikedSection(
					'product-brief-container',
					likesOfType('product-brief'),
					textItemMapper,
					!overrideUserId
				);
				displayLikedSection(
					'product-idea-container',
					likesOfType('product-idea'),
					textItemMapper,
					!overrideUserId
				);
				displayLikedSection(
					'brand-strategy-container',
					likesOfType('brand-strategy'),
					textItemMapper,
					!overrideUserId
				);
				document.querySelector('.items-wrap')!.scrollTo(0, previousScrollY);
				if (callback) {
					callback();
				}
			}
		});
	}
};

export const heartedListScrollTo = (
	container: GenerateTask | 'image' | 'link' | 'node' | 'cluster'
): void => {
	const containerUl = document.querySelector(
		`#hearted-container .items-wrap div.${container}-container`
	);
	if (containerUl) {
		containerUl.scrollIntoView({ block: 'nearest' });
	}
};

export const openHeartedList = (callback: undefined | (() => void) = undefined) => {
	closeAllPanels();

	ideaBoxButton.classList.add('buttonActive');
	ideaBoxContainer.classList.remove('hidden');

	tutorialEvents.openedIdeaBox();

	refreshHeartedList(callback);
};

const closeHeartedList = () => {
	ideaBoxContainer.classList.add('hidden');
	ideaBoxButton.classList.remove('buttonActive');
};

export const isHeartedListOpen = () => {
	return !ideaBoxContainer.classList.contains('hidden');
};

export const hasHeartedItems = () => {
	return ideaBoxContainer.querySelector('.empty-list') === null;
};

/**
 * Opens and closes idea box
 */
if (ideaBoxButton) {
	ideaBoxButton.addEventListener('click', () => {
		if (!isHeartedListOpen()) {
			openHeartedList();
		} else {
			closeHeartedList();
		}
	});
}

if (ideaBoxOverrideSelect) {
	ideaBoxOverrideSelect.addEventListener('change', () => {
		refreshHeartedList();
	});
}
