import { AdditionalUserColour, BaseUserColour } from '../../../src/commonTypes';
import { AnalysisKey } from '../../../src/commonConstants';

export const COLOUR_HEART_POSITIONS: Record<
	BaseUserColour | AdditionalUserColour,
	{ x: number; y: number }
> = {
	red: {
		x: 30,
		y: 20,
	},
	green: {
		x: 10,
		y: 30,
	},
	orange: {
		x: -30,
		y: 30,
	},
	fuchsia: {
		x: -50,
		y: 20,
	},
	cyan: {
		x: -50,
		y: -35,
	},
	violet: {
		x: -30,
		y: -50,
	},
	yellow: {
		x: 10,
		y: -50,
	},
	dark: {
		x: 30,
		y: -35,
	},
};

export const IDEATE_RECOMMENDATIONS = [
	{
		name: 'Brand Values',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Storytelling: Character and Plot Design',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Plots.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Target Audience',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Tone of Voice & Communication',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Plots.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Stakeholder, User, Player Motivation',
		recommendations: ['Motivations.cluster', 'Themes.cluster'] as Array<AnalysisKey>,
	},
	{
		name: 'Innovation Strategy',
		recommendations: [
			'colors.cluster',
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
];

export const WINDOW_BREAKPOINTS = {
	mobile: 800,
	tablet: 950,
	laptop: 1200,
	desktop: 1400,
};
