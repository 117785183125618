import JsPDF from 'jspdf';
import { startLoading } from '../ui/uiUtils';
import { ClusterAnalysis, GraphMode } from '../../../../src/commonTypes';
import { isChromiumBrowser, sleep } from '../misc';
import { PageName } from './pdfContent';
import {
	cachedDataUrls,
	cleanupImageCache,
	exportCanvasToImage,
	preloadBoardImages,
} from './exportUtils';
import html2canvas from 'html2canvas';
import IdeateGraph from '../graphs/IdeateGraph';
import { ANALYSES_TYPES, AnalysisKey } from '../../../../src/commonConstants';
import PQueue from 'p-queue';

const exportBoardToCanvas = async (): Promise<HTMLCanvasElement> => {
	const chartEl = document.querySelector<HTMLElement>('#board-content .chart')!;
	chartEl.classList.add('exported-graph');

	const contentBounds = document.querySelector('#all-content')!.getBoundingClientRect();

	// We need to set dimensions on Chromium but not Firefox
	const setDimensions = isChromiumBrowser();
	const scaleFactor = contentBounds.height < 250 ? 1.4 : 1.3;
	const width = setDimensions ? contentBounds.width * scaleFactor : undefined;
	const height = setDimensions ? contentBounds.height * scaleFactor : undefined;
	const windowWidth = setDimensions ? contentBounds.width * 2.6 : undefined;
	const windowHeight = setDimensions ? contentBounds.height * 2.6 : undefined;

	let scale = height && height < 500 ? 8 : 4;
	if (globalThis.neuroCreate.graph!.graphProps.scale < 0.25) {
		scale = scale * 2;
	}

	const canvas = await html2canvas(chartEl, {
		allowTaint: false,
		// backgroundColor: '#fdd',
		foreignObjectRendering: true,
		useCORS: false,
		scale,
		// proxy: '/export-images',
		logging: true,
		onclone: (d, el) => {
			// Performance: remove hidden nodes
			el.querySelectorAll<HTMLElement>(
				'.hidden,.menu,.delete-link,.link-delete-circle,.link-holder,.mouselink,.cursor'
			).forEach((hiddenEl) => {
				hiddenEl.parentNode?.removeChild(hiddenEl);
			});
			// Update image URLs to use data URL
			el.querySelectorAll<HTMLDivElement>('.node-image div').forEach((div) => {
				const rawUrl = div.style.backgroundImage.substring(5, div.style.backgroundImage.length - 2);
				const startOfPath = rawUrl.indexOf('/export-image');
				div.style.backgroundImage = `url(${cachedDataUrls[rawUrl.substring(startOfPath)]})`;
			});
		},
		width,
		height,
		windowWidth,
		windowHeight,
	});
	chartEl.classList.remove('exported-graph');
	return canvas;
};

async function exportIdeatePages(
	doc: JsPDF,
	page: 'ideate-team' | 'ideate-personal',
	boardName: string,
	chartEl: HTMLElement
) {
	const ideateGraph = globalThis.neuroCreate.graph as IdeateGraph;

	// Up to 6 analyses per page
	const analysisEntries = Object.entries(ANALYSES_TYPES) as Array<[AnalysisKey, ClusterAnalysis]>;
	const analysesPages = [
		analysisEntries.slice(0, 4), // 4 on first page
		analysisEntries.slice(4, 7), // 3 on second page
		analysisEntries.slice(7), // 6 on third page
	];

	const queue = new PQueue({ concurrency: 1 });

	for (const analyses of analysesPages) {
		doc.addPage();
		const currentPageNumber = doc.getNumberOfPages();
		let x = 15;
		let y = 20;

		const width = analyses.length > 4 ? 95 : 135;
		const height = 90;
		for (const [analysisKey, analysis] of analyses) {
			const analysisName = `${analysis.category ? `${analysis.category} > ` : ''}${analysis.name}`;

			const li = document.querySelector<HTMLLIElement>(
				`.analyses-types li[data-analysis='${analysisKey}']`
			)!;

			console.time(analysisKey);
			const emptyAnalysis = await ideateGraph.loadAnalysis(analysisKey, analysis, li);
			console.timeEnd(analysisKey);

			await startLoading(`Exporting ${boardName} - ${analysisName}...`, true);

			const hasNoNodes = emptyAnalysis || chartEl.querySelectorAll('.node').length === 0;

			const textX = x;
			const textY = y + 15;
			const titleX = x;
			const titleY = y + 5;
			if (!hasNoNodes) {
				doc.setPage(currentPageNumber);
				doc.setFontSize(10);
				doc.text('This analysis is empty', textX, textY);
				doc.setFontSize(12);
				doc.text(analysisName, titleX, titleY);
				console.log(`${analysisName} empty`);
			} else {
				const boardX = x - 5;
				const boardY = analyses.length > 4 ? y + 4 : y + 1;
				console.time(`${analysisKey} canvas`);

				queue.add(() =>
					exportBoardToCanvas().then(async (canvas) => {
						console.timeEnd(`${analysisKey} canvas`);
						console.time(`${analysisKey} image`);
						const isEmpty = await exportCanvasToImage(
							canvas,
							doc,
							currentPageNumber,
							boardX,
							boardY,
							width,
							height
						);
						console.timeEnd(`${analysisKey} image`);
						doc.setPage(currentPageNumber);
						if (isEmpty) {
							doc.setFontSize(10);
							doc.text('This analysis is empty.', textX, textY);
						}

						doc.setFontSize(12);
						doc.text(analysisName, titleX, titleY);
					})
				);
			}

			x += width;
			if (x > 250) {
				x = 15;
				y += height;
			}
		}

		// Page title
		doc.setFontSize(22);
		doc.text(boardName, 35, 15);
	}
	console.log('Awaiting Ideate image exports');
	await queue.onIdle();
	console.log('Awaiting Ideate image exports: done!');
}

const exportBoardAsImage = async (page: PageName, boardName: string, doc: JsPDF) => {
	const chartEl = document.querySelector<HTMLElement>('#board-content .chart')!;

	if (page === 'ideate-personal' || page === 'ideate-team') {
		await exportIdeatePages(doc, page, boardName, chartEl);
	} else {
		// Single board for the page
		await preloadBoardImages();
		const canvas = await exportBoardToCanvas();
		cleanupImageCache();
		doc.addPage();

		const isEmpty = await exportCanvasToImage(canvas, doc, doc.getNumberOfPages());
		if (isEmpty || chartEl.querySelectorAll('.node').length === 0) {
			doc.setFontSize(16);
			doc.text('This board is empty', 35, 35);
		}

		// Page title
		doc.setFontSize(22);
		doc.text(boardName, 35, 15);
	}
};
export const exportSpecificBoard = async (page: PageName, boardName: string, doc: JsPDF) => {
	return new Promise<void>((resolve) => {
		startLoading(`Loading ${boardName}...`, true).then(() => {
			const graphMode = page.split('-')[0] as GraphMode;

			const isCollaborative = !page.includes('personal');
			if (globalThis.neuroCreate.switchToMode) {
				globalThis.neuroCreate.switchToMode(graphMode, isCollaborative, true, async () => {
					await startLoading(`Exporting ${boardName}...`, true);
					await sleep(500);
					await exportBoardAsImage(page, boardName, doc);
					resolve();
				});
			}
		});
	});
};
