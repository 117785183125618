import { NCLink, NCNode } from '../../../../src/commonTypes';
import { addForce, getDistanceBetweenPoints } from './geometryUtils';
import { getLinksInvolvingNode } from './linkUtils';

const shiftNodeTowards = (
	node: NCNode,
	toNode: NCNode,
	linksForNode: number
): { xShift: number; yShift: number } => {
	const xDistance = toNode.x - node.x;
	const yDistance = toNode.y - node.y;

	const weight = Math.max(linksForNode, 1); // Reduce the force if the node is linked to more nodes

	const xShift = (xDistance > 500 ? 2 * xDistance : xDistance) / weight / 100;
	const yShift = (yDistance > 500 ? 2 * yDistance : yDistance) / weight / 100;

	return { xShift, yShift };
};

export default function (allLinks: Array<NCLink>) {
	let links: Array<NCLink> = allLinks,
		strength = 1;

	function force(alpha: number) {
		for (const link of links) {
			const { source, target } = link;
			const lineLength = getDistanceBetweenPoints(source, target);
			if (lineLength > 500) {
				const linksForSource = getLinksInvolvingNode(source, links);
				const linksForTarget = getLinksInvolvingNode(target, links);

				const { xShift: xShiftSource, yShift: yShiftSource } = shiftNodeTowards(
					link.source,
					link.target,
					linksForSource.length
				);
				addForce(link.source, {
					x: xShiftSource * alpha * strength,
					y: yShiftSource * alpha * strength,
				});

				const { xShift: xShiftTarget, yShift: yShiftTarget } = shiftNodeTowards(
					link.target,
					link.source,
					linksForTarget.length
				);
				addForce(link.target, {
					x: xShiftTarget * alpha * strength,
					y: yShiftTarget * alpha * strength,
				});
			}
		}
	}

	force.initialize = function () {
		// Nothing to do
	};

	force.links = function (_: Array<NCLink>) {
		links = _;
	};

	force.strength = function (_: number) {
		return arguments.length ? ((strength = +_), force) : strength;
	};

	return force;
}
