import { html, css, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';

@customElement('text-field')
export class TextField extends LitElement {
	@property({ type: Number })
	rows: number = 2;

	@property({ type: Function })
	load: () => Promise<string> = async () => await Promise.resolve('');

	@state()
	private _isLoading = true;

	reload = async () => {
		this._textField.value = '';
		this._isLoading = true;
		this._retryButton.disabled = true;
		const result = await this.load();
		this._textField.value = result;
		this._isLoading = false;
		this._retryButton.disabled = false;
	};

	static styles = css`
		div.text-field-wrapper {
			margin-top: 0.5rem;
			position: relative;
		}

		textarea.text-field {
			width: 100%;
			padding: 0.5em 2.5em 1em 1em;
			font-size: 15px;
			font-family: var(--main-font);
			box-sizing: border-box;
		}

		span.loading-indicator {
			display: block;
			width: 50px;
			height: 50px;
			position: absolute;
			top: calc(50% - 25px);
			left: calc(50% - 25px);

			img {
				width: 100%;
			}
		}

		span.hidden {
			display: none;
		}

		button.retry-button,
		button:not(:disabled) {
			width: 25px;
			height: 25px;
			background-color: rgb(255, 255, 255);
			border: 1px solid var(--grey4);
			border-radius: 6px;
			padding: 3px;
			top: 5px;
			position: absolute;
			right: 5px;
		}

		button.retry-button:hover,
		button.retry-button:focus,
		button:not(:disabled):hover,
		button:not(:disabled):focus {
			background-color: var(--BLUE);
			cursor: pointer;
		}

		button.retry-button:disabled {
			border: 1px solid var(--grey6);
			background-color: var(--grey6);
			cursor: not-allowed;
		}

		img {
			width: 100%;
		}

		button.retry-button:hover img,
		button.retry-button:focus img,
		button.retry-button:disabled img {
			filter: brightness(2.5);
		}
	`;

	render() {
		return html`
			<div class="text-field-wrapper">
				<span class="loading-indicator ${this._isLoading ? '' : 'hidden'}"
					><img src="/assets/loading.gif" alt="loading indicator"
				/></span>
				<textarea class="text-field" rows=${this.rows}></textarea>
				<button class="retry-button" title="Retry" @click="${this.reload}" disabled>
					<img src="/assets/icon/board/retry.png" />
				</button>
				<div></div>
			</div>
		`;
	}

	@query('textarea.text-field')
	_textField: HTMLTextAreaElement;

	@query('button.retry-button')
	_retryButton: HTMLButtonElement;
}

declare global {
	interface HTMLElementTagNameMap {
		'text-field': TextField;
	}
}
