import * as d3 from 'd3';
import { NCGroup, Point } from '../../../../src/commonTypes';
import { removeEl } from './graphUtils';
import { showAlert } from '../ui/uiUtils';

const groupKeyFn = (d: NCGroup) => d.uid;

const RECT_X_MULTIPLIER = 1.2;
const RECT_Y_MULTIPLIER = 0.8;

const getMaxX = (d: NCGroup) => {
	if (d.nodes.length === 0) {
		return 0;
	}

	return Math.max(
		...d.nodes.map(
			(n) => n.x + (n.style === 'rect' ? RECT_X_MULTIPLIER * ADDITIONAL_GROUP_RADIUS : 0)
		)
	);
};

const getMinX = (d: NCGroup) => {
	if (d.nodes.length === 0) {
		return 0;
	}

	return Math.min(
		...d.nodes.map(
			(n) => n.x - (n.style === 'rect' ? RECT_X_MULTIPLIER * ADDITIONAL_GROUP_RADIUS : 0)
		)
	);
};

const getMaxY = (d: NCGroup) => {
	if (d.nodes.length === 0) {
		return 0;
	}

	return Math.max(
		...d.nodes.map(
			(n) => n.y + (n.style === 'rect' ? RECT_Y_MULTIPLIER * ADDITIONAL_GROUP_RADIUS : 0)
		)
	);
};

const getMinY = (d: NCGroup) => {
	if (d.nodes.length === 0) {
		return 0;
	}

	return Math.min(
		...d.nodes.map(
			(n) => n.y - (n.style === 'rect' ? RECT_Y_MULTIPLIER * ADDITIONAL_GROUP_RADIUS : 0)
		)
	);
};

const getCenterX = (d: NCGroup) => (getMaxX(d) + getMinX(d)) / 2;

const getCenterY = (d: NCGroup) => (getMaxY(d) + getMinY(d)) / 2;

export const getGroupCenter = (d: NCGroup): Point => ({
	x: getCenterX(d),
	y: getCenterY(d),
});

export const getRadiusX = (d: NCGroup) => (getMaxX(d) - getMinX(d)) / 2;

export const getRadiusY = (d: NCGroup) => (getMaxY(d) - getMinY(d)) / 2;

export const ADDITIONAL_GROUP_RADIUS = 120;

const enterGroupWithDrag = (
	elem: d3.Selection<d3.EnterElement, NCGroup, SVGGElement, unknown>,
	dragBehavior: d3.DragBehavior<SVGGElement, NCGroup, unknown>
): d3.Selection<SVGGElement, NCGroup, SVGGElement, unknown> => {
	const group = elem.append('g').attr('class', 'group');
	group
		.append('ellipse')
		.classed('group', true)
		.attr('cx', getCenterX)
		.attr('cy', getCenterY)
		.attr('rx', (d) => getRadiusX(d) + ADDITIONAL_GROUP_RADIUS)
		.attr('ry', (d) => getRadiusY(d) + ADDITIONAL_GROUP_RADIUS);

	if (dragBehavior) {
		group.call(dragBehavior);
	}

	return group;
};

export const updateGroupPositions = (
	elem: d3.Selection<SVGGElement, NCGroup, SVGGElement, unknown>
): d3.Selection<SVGGElement, NCGroup, SVGGElement, unknown> => {
	elem
		.selectAll<SVGEllipseElement, NCGroup>('ellipse')
		.attr('cx', getCenterX)
		.attr('cy', getCenterY)
		.attr('rx', (d) => getRadiusX(d) + ADDITIONAL_GROUP_RADIUS)
		.attr('ry', (d) => getRadiusY(d) + ADDITIONAL_GROUP_RADIUS);
	return elem;
};

export const updateGroup = (
	elem: d3.Selection<SVGGElement, NCGroup, SVGGElement, unknown>
): d3.Selection<SVGGElement, NCGroup, SVGGElement, unknown> => {
	updateGroupPositions(elem);
	elem
		.selectAll<SVGEllipseElement, NCGroup>('ellipse')
		.classed('active', (d) => !!d.active)
		.attr('stroke', (d) => (d.active ? d.colour : 'none'))
		.attr('fill', (d) => {
			const colour = d.overrideColour || d.colour;
			return colour?.startsWith('#') ? colour : colour && `var(--${colour})`;
		});
	return elem;
};

export const createGroups = (
	groupsElement: d3.Selection<SVGGElement, NCGroup, SVGGElement, null>,
	groups: Array<NCGroup>,
	dragBehavior: d3.DragBehavior<SVGGElement, NCGroup, unknown>
): d3.Selection<SVGGElement, NCGroup, SVGGElement, null> => {
	const enterGroup = (elem: d3.Selection<d3.EnterElement, NCGroup, SVGGElement, unknown>) => {
		const group = enterGroupWithDrag(elem, dragBehavior);
		updateGroup(group);
		return group;
	};

	return groupsElement
		.data<NCGroup>(groups, groupKeyFn)
		.join<SVGGElement>(enterGroup, updateGroup, removeEl);
};

export const confirmRemoveGroup = (onConfirm: () => void) => {
	showAlert(
		'Are you sure you want to un-group these concepts? The group will be removed but the concepts within will be kept.',
		[
			{
				label: 'Cancel',
				onClick: () => {
					// Do nothing
				},
			},
			{
				label: 'Un-group',
				primary: true,
				onClick: onConfirm,
			},
		]
	);
};

const GROUP_COLORS = [
	'#FFC107',
	'#FF9800',
	'#FF5722',
	'#F44336',
	'#E91E63',
	'#9C27B0',
	'#673AB7',
	'#3F51B5',
	'#2196F3',
	'#03A9F4',
	'#00BCD4',
	'#009688',
	'#4CAF50',
	'#8BC34A',
	'#CDDC39',
	'#FFEB3B',
] as const;
export const getRandomGroupColor = () => {
	return GROUP_COLORS[Math.floor(Math.random() * GROUP_COLORS.length)];
};
