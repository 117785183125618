import { startLoading, finishLoading } from './ui/uiUtils';
import {
	EmpathiseColour,
	EngineId,
	GenerateTask,
	GraphMode,
	LikedItem,
	NCCluster,
	PresentationInfo,
	SearchEntry,
	TopicAnalysis,
	UserPersona,
} from '../../../src/commonTypes';
import { AnalysisKey, SubTaskId, SYNTHESISE_AI_TASKS } from '../../../src/commonConstants';

const getPostOptions = (parameters: { [paramName: string]: string }) => {
	return {
		method: 'POST',
		body: new URLSearchParams(parameters),
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPostJsonOptions = (jsonData: any) => {
	return {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(jsonData),
	};
};

/**
 * Call spark in the API
 */
export const spark = async (boardId: string, label: string): Promise<Array<TopicAnalysis>> => {
	const response = await fetch(`/board/${boardId}/spark`, getPostOptions({ label }));
	return response.json() as unknown as Array<TopicAnalysis>;
};

export const undo = async (boardId: string): Promise<boolean> => {
	const response = await fetch(`/board/${boardId}/undo`, getPostOptions({}));
	const result = (await response.json()) as unknown as { success: boolean };
	return result.success;
};

/**
 * Call Synthesise AI in the API
 */

type TaskId = keyof typeof SYNTHESISE_AI_TASKS | 'Vision';

export const synthesiseAi = async (
	boardId: string,
	task: TaskId | SubTaskId,
	prompt: string,
	imageUrl: string = ''
): Promise<{ result: string }> => {
	const response = await fetch(
		`/board/${boardId}/synthesise`,
		getPostOptions({ task, prompt, imageUrl })
	);
	if (response.status !== 200) {
		throw new Error('Unable to generate');
	}
	return response.json() as unknown as { result: string };
};

/**
 * Call generate in the API
 */
export const generate = async (
	boardId: string,
	type: GenerateTask,
	label: string
): Promise<Array<{ snippet: string; title: string }>> => {
	const response = await fetch(
		`/board/${boardId}/generate`,
		getPostOptions({ searchTerm: label, type })
	);
	if (response.status !== 200) {
		throw new Error('Unable to generate');
	}
	return response.json() as unknown as Array<{ snippet: string; title: string }>;
};

const getPersonaText = (userPersona: UserPersona): string => {
	return `
Biography: ${userPersona.biography} Goals: ${userPersona.goals} Needs & Wants: ${userPersona.needs} Pain points: ${userPersona.painPoints} Motivations: ${userPersona.motivations} Influences: ${userPersona.influences}
`;
};
export const userPersonaImage = async (
	boardId: string,
	persona: UserPersona
): Promise<{ snippet: string; title: string }> => {
	startLoading('Generating persona image...');
	const personaText = getPersonaText(persona);
	const response = await fetch(
		`/board/${boardId}/user-persona-images`,
		getPostOptions({ personaText })
	);
	finishLoading();
	if (response.status !== 200) {
		throw new Error('Unable to generate');
	}
	return response.json() as unknown as { snippet: string; title: string };
};

export const motivationsStakeholderImage = async (
	boardId: string,
	name: string,
	description: string,
	title: string
): Promise<{ imageUrl: string }> => {
	const response = await fetch(
		`/board/${boardId}/motivations-images`,
		getPostOptions({ name, description, title })
	);
	if (response.status !== 200) {
		throw new Error('Unable to generate');
	}
	return response.json() as unknown as { imageUrl: string };
};

export type FilterOptions = 'Filter0' | 'Filter1' | 'Filter2' | 'Filter3' | 'Filter4';

/**
 * Call empathise in the API
 */
export const empathise = async (
	boardId: string,
	filter: FilterOptions,
	label: string
): Promise<Array<{ color: EmpathiseColour; values: TopicAnalysis[] }>> => {
	const response = await fetch(
		`/board/${boardId}/empathise`,
		getPostOptions({
			label,
			filter,
		})
	);
	const results = (await response.json()) as Promise<
		Array<{ color: EmpathiseColour; values: TopicAnalysis[] }>
	>;
	return results;
};

/**
 * Call the API linker
 */
export const linker = async (boardId: string, word: Array<string>): Promise<string> => {
	const response = await fetch(
		`/board/${boardId}/linker`,
		getPostOptions({
			word: word.join(' '),
		})
	);
	const { suggestion } = (await response.json()) as { suggestion: string };
	return suggestion;
};

/**
 * Call the search API
 */
export const search = async (boardId: string, label: string): Promise<Array<SearchEntry>> => {
	const response = await fetch(
		`/board/${boardId}/search`,
		getPostOptions({
			searchTerm: label,
		})
	);
	return response.json() as unknown as Array<SearchEntry>;
};

export const splitTextIntoWords = async (
	boardId: string,
	text: string,
	mode: 'keywords' | 'auto' | 'topics' = 'auto'
): Promise<Array<string>> => {
	if (mode === 'topics') {
		startLoading(`Identifying key topics...`);
	}
	const response = await fetch(
		`/board/${boardId}/split-words`,
		getPostOptions({
			text,
			mode,
		})
	);
	if (mode === 'topics') {
		finishLoading();
	}
	return ((await response.json()) as unknown as { words: Array<string> }).words;
};

export const searchImage = async (boardId: string, src: string): Promise<Array<SearchEntry>> => {
	const response = await fetch(
		`/board/${boardId}/search`,
		getPostOptions({
			searchSrc: src,
		})
	);
	return response.json() as unknown as Array<SearchEntry>;
};

/**
 * Like or unlike something, record this via API
 */
export const like = async (
	boardId: string,
	type: LikedItem['type'],
	text: string | null,
	url: string | null,
	remove: boolean,
	title?: string,
	topics?: string[]
): Promise<void> => {
	const requestData = {
		boardId,
		type,
		remove,
		topics: topics?.join(','),
	} as {
		boardId: string;
		type: LikedItem['type'];
		text?: string;
		url?: string;
		title?: string;
		topics: string;
	};
	if (text) {
		requestData.text = text;
	}
	if (url) {
		requestData.url = url;
	}
	if (title) {
		requestData.title = title;
	}
	await fetch(`/board/${boardId}/user-likes`, getPostOptions(requestData));
};

export const cache = async (
	boardId: string,
	type: LikedItem['type'],
	text: string | null,
	url: string | null,
	title?: string
): Promise<void> => {
	const requestData = {
		boardId,
		type,
	} as { boardId: string; type: LikedItem['type']; text?: string; url?: string; title?: string };
	if (text) {
		requestData.text = text;
	}
	if (url) {
		requestData.url = url;
	}
	if (title) {
		requestData.title = title;
	}
	await fetch(`/board/${boardId}/cached-items`, getPostOptions(requestData));
};

export const reorderLikes = async (
	boardId: string,
	type: LikedItem['type'],
	likes: Array<LikedItem>
): Promise<void> => {
	if (!boardId || !likes) {
		return;
	}

	await fetch(`/board/${boardId}/user-likes-order`, getPostJsonOptions({ type, likes }));
};

export const clusters = async (
	boardId: string,
	cluster: AnalysisKey,
	labels: string
): Promise<Array<NCCluster>> => {
	startLoading();
	const response = await fetch(
		`/board/${boardId}/clusters`,
		getPostOptions({
			boardId,
			filter: 'Filter0',
			cluster,
			labels,
		})
	);

	finishLoading();
	const clusters = (await response.json()) as Array<NCCluster>;
	return clusters;
};

export const searchForUser = async (
	searchText: string
): Promise<Array<{ username: string; email: string; id: string }>> => {
	return await fetch(`/search/user?query=${encodeURIComponent(searchText)}`).then(
		async (results) =>
			(await results.json()) as Array<{ username: string; email: string; id: string }>
	);
};

export const toggleAi = async (boardId: string, aiEnabled: boolean): Promise<void> => {
	await fetch(
		`/board/${boardId}/user-settings`,
		getPostOptions({
			ai_enabled: `${aiEnabled ? 'true' : 'false'}`,
		})
	);
};

export const setTutorialShown = async (
	userId: string,
	tutorial: GraphMode | 'dashboard'
): Promise<void> => {
	await fetch(`/users/${userId}`, getPostJsonOptions({ shownTutorial: true, tutorial }));
};

export const createSubscription = async (
	paypalPlanId: string,
	quantity?: string,
	customPlanCode?: string,
	referralCode?: string
): Promise<string> => {
	const response = await fetch(
		'/api/create-subscription',
		getPostJsonOptions({ paypalPlanId, quantity, customPlanCode, referralCode })
	);
	const { subscriptionId } = (await response.json()) as { subscriptionId: string };
	return subscriptionId;
};

export const changeSubscription = async (
	paypalPlanId: string,
	quantity?: string,
	customPlanCode?: string
): Promise<{ approveLink: string; subscriptionId: string }> => {
	const response = await fetch(
		'/api/change-subscription',
		getPostJsonOptions({ paypalPlanId, quantity, customPlanCode })
	);
	if (response.status !== 200) {
		throw new Error(await response.text());
	}
	const data = (await response.json()) as {
		approveLink: string;
		subscriptionId: string;
	};
	return data;
};

export const newSubscriptionApproved = async (subscriptionId: string): Promise<void> => {
	await fetch('/api/new-subscription', getPostJsonOptions({ subscriptionId }));
};

export const freeTrailSignUp = async (emailAddress: string) => {
	startLoading('Signing up...');
	const response = await fetch(
		'/api/free-trial',
		getPostOptions({
			email: emailAddress,
		})
	);

	finishLoading();
	if (response.status === 400) {
		return await response.text();
	}
	return undefined;
};

export const updateSubscription = async (userId: string, subscriptionId: string): Promise<void> => {
	await fetch(`/users/${userId}`, getPostJsonOptions({ subscriptionId }));
};

export const updateGoogleToken = async (userId: string, googleToken: string): Promise<void> => {
	await fetch(`/users/${userId}`, getPostJsonOptions({ googleToken }));
};

export const updateUserFullName = async (userId: string, name: string): Promise<void> => {
	await fetch(`/users/${userId}`, getPostJsonOptions({ name }));
};

export const cancelCurrentSubscription = async (userId: string): Promise<void> => {
	startLoading('Cancelling subscription...');
	const result = await fetch(`/users/${userId}/subscription`, {
		method: 'DELETE',
	});
	finishLoading();
	console.log(result.json());
};

export const suspendCurrentSubscription = async (userId: string): Promise<void> => {
	startLoading('Suspending subscription...');
	await fetch(`/users/${userId}/subscription`, getPostJsonOptions({ suspended: true }));
	finishLoading();
};

export const activateCurrentSubscription = async (userId: string): Promise<void> => {
	startLoading('Activating subscription...');
	await fetch(`/users/${userId}/subscription`, getPostJsonOptions({ activated: true }));
	finishLoading();
};

export const updateCompanyName = async (companyId: string, name: string): Promise<void> => {
	await fetch(
		`/companies/${companyId}`,
		getPostJsonOptions({
			name,
		})
	);
};

export const updateBoardTitle = async (boardId: string, boardTitle: string): Promise<void> => {
	startLoading('Updating board title...');
	await fetch(
		`/board/${boardId}/settings`,
		getPostOptions({
			boardTitle,
		})
	);
	finishLoading();
};

export const updateBoardEngine = async (boardId: string, engineId: EngineId): Promise<void> => {
	startLoading('Updating board engine...');
	await fetch(
		`/board/${boardId}/settings`,
		getPostOptions({
			engine: engineId,
		})
	);
	finishLoading();
};

export const updateBoardVisionAndSummary = async (
	boardId: string,
	input: string,
	vision: string,
	summary: string
): Promise<void> => {
	startLoading('Updating board input, vision and summary...');
	await fetch(
		`/board/${boardId}/settings`,
		getPostOptions({
			input,
			vision,
			summary,
		})
	);
	finishLoading();
};

export const getPresentationInfo = async (boardId: string): Promise<PresentationInfo> => {
	startLoading('Loading presentation preview...');
	const response = await fetch(`/board/${boardId}/presentation-info`);
	finishLoading();
	return (await response.json()) as PresentationInfo;
};

export const savePresentationInfo = async (
	boardId: string,
	presentation: PresentationInfo
): Promise<void> => {
	await fetch(`/board/${boardId}/presentation-info`, getPostJsonOptions(presentation));
};

export const deleteBoard = async (boardId: string): Promise<void> => {
	startLoading('Deleting board...');
	await fetch(`/board/${boardId}`, {
		method: 'DELETE',
	});
	finishLoading();
};

export const moveIntoProject = async (boardId: string, projectId: string): Promise<void> => {
	startLoading('Move into project...');
	await fetch(
		`/board/${boardId}/settings`,
		getPostOptions({
			projectId,
		})
	);
	finishLoading();
};

export const updateProjectTitle = async (
	projectId: string,
	projectTitle: string
): Promise<void> => {
	startLoading('Updating project title...');
	await fetch(
		`/project/${projectId}/settings`,
		getPostOptions({
			projectTitle,
		})
	);
	finishLoading();
};

export const deleteProjectFromDB = async (projectId: string): Promise<void> => {
	startLoading('Deleting project...');
	await fetch(`/project/${projectId}`, {
		method: 'DELETE',
	});
	finishLoading();
};

export const getUserLikes = async (
	boardId: string,
	overrideUserId?: string,
	isTeam = false
): Promise<Array<LikedItem>> => {
	let url = overrideUserId
		? `/board/${boardId}/user-likes/${overrideUserId}`
		: `/board/${boardId}/user-likes`;
	url = url + '?isTeam=' + isTeam;
	const response = await fetch(url);

	return (await response.json()) as Array<LikedItem>;
};

export const getBoardImages = async (
	boardId: string
): Promise<Array<{ url: string; description: string }>> => {
	const response = await fetch(`/board/${boardId}/images`);
	return (await response.json()) as Array<{ url: string; description: string }>;
};

export const uploadBoardImage = async (boardId: string, image: File): Promise<string> => {
	const formData = new FormData();
	formData.append('image', image);
	const response = await fetch(`/board/${boardId}/images`, {
		method: 'POST',
		body: formData,
	});
	return ((await response.json()) as { url: string }).url;
};

export const saveBoardImageFromUrl = async (boardId: string, url: string): Promise<string> => {
	const response = await fetch(
		`/board/${boardId}/images-from-web`,
		getPostOptions({
			url,
		})
	);
	return ((await response.json()) as { url: string }).url;
};

export const removeUserFromBoard = async (boardId: string, userId: string): Promise<void> => {
	startLoading('Removing user...');
	await fetch(`/board/${boardId}/user/${userId}`, {
		method: 'DELETE',
	});
	finishLoading();
};

export const requestAccessToBoard = async (boardId: string, userId: string): Promise<void> => {
	startLoading('Requesting access...');
	await fetch(
		`/board/${boardId}/requests`,
		getPostOptions({
			userId,
		})
	);
	finishLoading();
};

export const decideAccessRequest = async (
	boardId: string,
	requestId: string,
	accept: boolean
): Promise<{
	success: boolean;
	message: string;
}> => {
	startLoading(accept ? 'Adding collaborator...' : 'Declining request...');
	console.log(`/board/${boardId}/requests/${requestId}`);
	const result = await fetch(
		`/board/${boardId}/requests/${requestId}`,
		getPostJsonOptions({
			accept,
		})
	).then(
		(res) =>
			res.json() as unknown as {
				success: boolean;
				message: string;
			}
	);
	finishLoading();
	return result;
};

export const inviteUserWithUsername = async (
	inviteAction: string,
	username: string
): Promise<{
	success: boolean;
	message: string;
	colour: string;
	username: string;
	email: string;
	id: string;
}> => {
	startLoading('Inviting user...');
	const result = await fetch(
		inviteAction,
		getPostOptions({
			username,
		})
	).then(
		(res) =>
			res.json() as unknown as {
				success: boolean;
				message: string;
				colour: string;
				username: string;
				email: string;
				id: string;
			}
	);
	finishLoading();
	return result;
};

export const addNonSignedUpMemberToTeam = async (companyId: string, email: string) => {
	startLoading('Inviting team member...');
	const result = await fetch(
		`/companies/${companyId}/members`,
		getPostOptions({
			email,
		})
	).then(
		(res) =>
			res.json() as unknown as {
				success: boolean;
				message: string;
				colour?: string;
			}
	);
	finishLoading();
	return result;
};

export const addMemberToTeam = async (companyId: string, username: string) => {
	startLoading('Adding team member...');
	const result = await fetch(
		`/companies/${companyId}/members`,
		getPostOptions({
			username,
		})
	).then(
		(res) =>
			res.json() as unknown as {
				success: boolean;
				message: string;
				colour?: string;
			}
	);
	finishLoading();
	return result;
};

export const removeMemberFromTeam = async (companyId: string, userId: string) => {
	startLoading('Removing team member...');
	await fetch(`/companies/${companyId}/members/${userId}`, {
		method: 'DELETE',
	});
	finishLoading();
};

export const removeMemberInviteFromTeam = async (companyId: string, inviteId: string) => {
	startLoading('Cancelling team member invite...');
	await fetch(`/companies/${companyId}/member-invites/${inviteId}`, {
		method: 'DELETE',
	});
	finishLoading();
};

export const inviteWithNonSignedUpEmail = async (
	inviteAction: string,
	email: string
): Promise<{
	success: boolean;
	message: string;
	colour: string;
	email: string;
	id: string;
}> => {
	startLoading('Sending invite...');
	const result = await fetch(
		inviteAction,
		getPostOptions({
			email,
		})
	).then(
		(res) =>
			res.json() as unknown as {
				success: boolean;
				message: string;
				colour: string;
				email: string;
				id: string;
			}
	);
	finishLoading();
	return result;
};

export const getFullSubscriptionPlan = async (planId: string, quantity: number) => {
	const response = await fetch(`/settings/plans/${planId}?quantity=${quantity}`, {
		method: 'GET',
	});
	return (await response.json()) as {
		id: string;
		price: number;
		hasQuantity: boolean;
		discount: number;
		frequency: 'MONTH' | 'YEAR';
	};
};

export const removeUserFromProject = async (projectId: string, userId: string): Promise<void> => {
	startLoading('Removing user...');
	await fetch(`/project/${projectId}/users/${userId}`, {
		method: 'DELETE',
	});
	finishLoading();
};

export const getChatbotSuggestions = async (
	currentEventType: string,
	userGoal: string,
	availableRules: object,
	board: string,
	timeSpent: number
) => {
	const response = await fetch('/chatbot/suggestions', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			currentEventType,
			userGoal,
			availableRules,
			board,
			timeSpent,
		}),
	});
	return (await response.json()) as {
		data: string;
	};
};

export const getInterestingNode = async (nodeLabels: string[], projectVision: string) => {
	const response = await fetch('/chatbot/interesting-node', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			nodeLabels,
			projectVision,
		}),
	});
	return (await response.json()) as {
		data: string;
	};
};

// Analyses the array of suggestions and returns the most interesting one based on the project vision
export const getInterestingSuggestion = async (
	suggestions: string[],
	projectVision: string,
	aiTool: string,
	activeNode: string,
	templateId: string
) => {
	const response = await fetch('/chatbot/interesting-suggestion', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			suggestions,
			projectVision,
			aiTool,
			activeNode,
			templateId,
		}),
	});
	return (await response.json()) as {
		data: string;
	};
};

export const getPdfContextFromFileSearch = async (boardId: string): Promise<string> => {
	startLoading('Fetching context from the pdf...');

	const response = await fetch(`/board/${boardId}/contexts`, {
		method: 'GET',
	});

	console.log(response);

	const res = (await response.json()) as {
		context: string;
	};

	finishLoading();
	return res.context;
};
