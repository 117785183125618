import {
	deactivateTutorialButton,
	isTutorialActive,
	startTutorial,
	onExitShowInstruction,
} from './tutorial';
import { clearNonBlockingMessage, closeAllPanels } from '../ui/uiUtils';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from './tutorialEvents';
import IdeateGraph from '../graphs/IdeateGraph';
import { clickOnTarget } from './tutorialAutomation';

const templateId = 'branding';

const hideInstruction = () => {
	clearNonBlockingMessage();
};

export const startBrandingTutorialIdeate = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		closeAllPanels();

		tutorialEmitter.removeAllListeners();

		startTutorial(
			{
				tooltipClass: 'welcomeTutorial',
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Branding tutorial - Ideate',
						tooltipClass: 'wide-tooltip',
						intro: `Let's explore Ideate mode for our branding board!`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: 'Analyses panel',
						element: '#analyses-container',
						intro: `The Analyses panel offers a range of analysis types we can use to generate clusters of ideas based on what we created in Inspire`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: 'Analyses: Personality',
						element: '#analyses-container li[data-analysis="Psych.cluster"]',
						intro: `Every customer and/or brand has a certain personality. You can use this tool to shed light on communication styles to these different personalities, and associations that would appeal to them in your content creation, design and strategy.
<br><br>
<strong>➡ Click 'Personality' to load the analysis</strong>`,
						completeStep: clickOnTarget,
					},
				],
			},
			onExitShowInstruction(`Click 'Personality' to load the analysis`)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterNewAnalysis, afterPersonalityAnalysis);
	}
};

const afterPersonalityAnalysis = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId) && globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.centerTo(
			(globalThis.neuroCreate.graph as IdeateGraph).getClusterWithLabel('Playful')!.props
		);
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Analysis ready',
						element: '#all-content .nodes',
						intro: `You'll see the analysis loaded with a number of clusters representing different personalities`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: 'Cluster',
						element: '#board-content #all-content .clusters g[data-cluster="Playful"]',
						intro: `For example the 'Playful' cluster.`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: 'Like a cluster',
						element:
							'#board-content #all-content .clusters g[data-cluster="Playful"] .cluster-heart',
						intro: `<strong>➡ Press the heart to like the 'Playful' cluster and add to your Idea Box</strong>`,
						disableInteraction: false,
						completeStep: clickOnTarget,
					},
				],
			},
			onExitShowInstruction(
				`Press the heart to like the 'Playful' cluster and add to your Idea Box`
			)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedCluster, afterLikePersonality);
	}
};

const afterLikePersonality = () => {
	hideInstruction();
	const graph = globalThis.neuroCreate.graph;

	if (isTutorialActive('ideate', templateId) && graph) {
		graph.setHintsShown();
		const assertiveCluster = (graph as IdeateGraph).getClusterWithLabel('Assertive');
		if (!assertiveCluster) {
			// There is no assertive cluster (due to user deviating from instructions?), so move on
			afterLikeAssertive();
		} else {
			graph.centerTo(assertiveCluster.props);
			startTutorial(
				{
					disableInteraction: false,
					hideNext: true,
					steps: [
						{
							title: 'Like another cluster',
							element:
								'#board-content #all-content .clusters g[data-cluster="Assertive"] .cluster-heart',
							intro: `<strong>➡ Press the heart to like the 'Assertive' cluster and add to your Idea Box</strong>`,
							completeStep: clickOnTarget,
						},
					],
				},
				onExitShowInstruction(
					`Press the heart to like the 'Assertive' cluster and add to your Idea Box`
				)
			);
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedCluster, afterLikeAssertive);
		}
	}
};

const afterLikeAssertive = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId) && globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.centerTo(
			(globalThis.neuroCreate.graph as IdeateGraph).getClusterWithLabel('Comfortable')!.props
		);
		const nodeToLike = (globalThis.neuroCreate.graph.getNodeWithLabel('wine') ||
			globalThis.neuroCreate.graph.nodes.at(4))!;
		globalThis.neuroCreate.graph.updateActive(nodeToLike, true, true);
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Liking nodes',
						element:
							'#board-content #all-content .clusters g[data-cluster="comfortable"] .cluster-heart',
						intro: `You can also like any of the nodes within a cluster`,
						disableInteraction: true,
						completeStep: async () => {},
					},
					{
						title: 'Like a node',
						element: '.menu .arc[data-action=like]',
						intro: `<strong>➡ Click to 'Like' on the '${nodeToLike.label}' node to add to your Idea Box</strong>`,
						completeStep: clickOnTarget,
					},
				],
			},
			onExitShowInstruction(
				`Click to 'Like' on the '${nodeToLike.label}' node to add to your Idea Box`
			)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedNode, afterLikeNode);
	}
};

const afterLikeNode = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId) && globalThis.neuroCreate.graph) {
		startTutorial(
			{
				hideNext: true,
				disableInteraction: false,
				steps: [
					{
						title: 'Analyses: Visual',
						element: '#analyses-container li[data-analysis="colors.cluster"]',
						completeStep: clickOnTarget,
						intro: `Let's take a look at another of the analyses.
<br><br>
Your project will have visual associations of colour, texture and opacity that you can identify by analysing the language.
<br><br>
<strong>➡ Click 'Visual' to load the analysis</strong>
`,
					},
				],
			},
			onExitShowInstruction(`Click 'Visual' to load the analysis`)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterNewAnalysis, afterVisualAnalysis);
	}
};

const afterVisualAnalysis = () => {
	hideInstruction();
	const firstClusterName = globalThis.neuroCreate.graph!.nodes[0].label!;
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				hideNext: true,
				disableInteraction: false,
				steps: [
					{
						title: `Like ${firstClusterName} cluster`,
						element: `#board-content #all-content .clusters g[data-cluster="${firstClusterName}"] .cluster-heart`,
						completeStep: clickOnTarget,
						intro: `As you will see, Visual generates colour-focused clusters based on your Inspire board
<br><br>
<strong>➡ Press the heart to like the '${firstClusterName}' cluster and add to your Idea Box</strong>`,
					},
				],
			},
			onExitShowInstruction(
				`Press the heart to like the '${firstClusterName}' cluster and add to your Idea Box`
			)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedCluster, afterLikeVisualCluster);
	}
};

const afterLikeVisualCluster = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				hideNext: true,
				disableInteraction: false,
				steps: [
					{
						title: 'Analyses: Themes',
						element: '#analyses-container li[data-analysis="Themes.cluster"]',
						intro: `Let's take a look at another of the analyses.
<br><br>
Themes will find commonalities in the language you used in Inspire and group them together.
<br><br>
<strong>➡ Click 'Themes' to load the analysis</strong>
`,
					},
				],
			},
			onExitShowInstruction(`Click 'Themes' to load the analysis`)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterNewAnalysis, afterThemesAnalysis);
	}
};

const afterThemesAnalysis = () => {
	hideInstruction();
	const firstClusterCentralNode = globalThis.neuroCreate.graph!.nodes[0];
	globalThis.neuroCreate.graph!.updateActive(firstClusterCentralNode, true, true);
	globalThis.neuroCreate.graph!.centerTo(firstClusterCentralNode);
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				hideNext: true,
				disableInteraction: false,
				steps: [
					{
						title: `User personas`,
						element: '.menu .arc[data-action=generate]',
						intro: `Themes will group together commonalities in the language you used in Inspire.
<br><br>
We can use these clusters to generate user personas.
<br><br>
<strong>➡ Press 'Generate' on '${firstClusterCentralNode.label}'</strong>`,
					},
				],
			},
			onExitShowInstruction(`Press 'Generate' on '${firstClusterCentralNode.label}'`)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedGenerate, afterGenerateThemes);
	}
};

const afterGenerateThemes = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Generate a user persona`,
					element: 'button[data-task=persona]',
					intro: `The AI can generate a user persona for the cluster in just a few seconds.
<br><br>
<strong>➡ Click 'User Persona'</strong>`,
					disableInteraction: false,
					position: 'left',
					completeStep: clickOnTarget,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedGenerateUserPersona, afterClickUserPersona);
	}
};

const afterClickUserPersona = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `User Persona generated!`,
						element: '#generate-container .results-list',
						intro: `A persona has been generated from the cluster.`,
						completeStep: async () => {},
					},
					{
						title: `Add to Idea Box`,
						element: '#generate-container .results-list .heart',
						intro: `<strong>➡ Press the heart to like the persona and add to your Idea Box</strong>`,
						completeStep: clickOnTarget,
					},
					{
						title: 'Analyses: Archetype',
						element: '#analyses-container li[data-analysis="Archetype.cluster"]',
						intro: `You can use this tool to pick and choose elements to inform the characterisation of your storytelling
<br><br>
<strong>➡ Click 'Archetype' to load the analysis</strong>`,
						completeStep: clickOnTarget,
					},
				],
			},
			{
				onbeforechange: (target) => {
					if (target.dataset.analysis === 'Archetype.cluster') {
						closeAllPanels();
					}
					return true;
				},
				...onExitShowInstruction(`Click 'Archetype' to load the analysis`),
			}
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterNewAnalysis, afterArchetypeAnalysis);
	}
};

const afterArchetypeAnalysis = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Analyses: Motivations',
						element: '#analyses-container li[data-analysis="Motivations.cluster"]',
						intro: `AI analyses of different motivations for players, stakeholders, participants or attendees of the game, event and/or experience you have created.
<br><br>
<strong>➡ Click 'Motivations' to load the analysis</strong>`,
					},
				],
			},
			onExitShowInstruction(`Click 'Motivations' to load the analysis`)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterNewAnalysis, afterMotivationAnalysis);
	}
};

const afterMotivationAnalysis = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Analyses: Motivations - Challenge',
						element: '#analyses-container li[data-analysis="Motivation-Challenge.cluster"]',
						intro: `We can also drill down into specific motivational perspectives such as challenge, novelty, stimulation, or threat.
<br><br>
<strong>➡ Click 'Motivations - Challenge' to load the analysis</strong>`,
					},
				],
			},
			onExitShowInstruction(`Click 'Motivations - Challenge' to load the analysis`)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterNewAnalysis, afterMotivationChallenge);
	}
};

const afterMotivationChallenge = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Like a cluster',
						element:
							'#board-content #all-content .clusters g[data-cluster="Achievement"] .cluster-heart',
						intro: `<strong>➡ Press the heart to like the 'Achievement' cluster and add to your Idea Box</strong>`,
					},
				],
			},
			onExitShowInstruction(
				`Press the heart to like the 'Achievement' cluster and add to your Idea Box`
			)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedCluster, likedAchievementsCluster);
	}
};

const likedAchievementsCluster = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Analyses: Motivations - Stimulation',
						element: '#analyses-container li[data-analysis="Motivation-Stimulation.cluster"]',
						intro: `Let's take a look at one more analyses.
<br><br>
<strong>➡ Click 'Motivations - Stimulation' to load the analysis</strong>`,
					},
				],
			},
			onExitShowInstruction(`Click 'Motivations - Stimulation' to load the analysis`)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.afterNewAnalysis, afterMotivationStimulation);
	}
};

const afterMotivationStimulation = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Like a cluster',
						element:
							'#board-content #all-content .clusters g[data-cluster="Excitement"] .cluster-heart',
						intro: `<strong>➡ Press the heart to like the 'Excitement' cluster and add to your Idea Box</strong>`,
					},
				],
			},
			onExitShowInstruction(
				`Press the heart to like the 'Excitement' cluster and add to your Idea Box`
			)
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedCluster, completedIdeate);
	}
};

const completedIdeate = () => {
	hideInstruction();
	if (isTutorialActive('ideate', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				steps: [
					{
						title: 'Completed 🎉',
						element: '#board-stage-switcher',
						intro: `You have completed the Ideate tutorial!
<br><br>
Feel free to explore further and like concepts or clusters, then when ready:
<br><br>
<strong>➡ Click on 'Synthesise' to progress to the next stage.</strong>`,
					},
				],
			},
			{
				onexit: () => {
					deactivateTutorialButton();
				},
			}
		);
	}
};
