import {
	endTutorial,
	isTutorialActive,
	onExitShowInstruction,
	openCollapsedMenuForTutorial,
	startTutorial,
} from './tutorial';
import {
	clearNonBlockingMessage,
	closeAllPanels,
	showAlert,
	showWordInputPanel,
} from '../ui/uiUtils';
import { WINDOW_BREAKPOINTS } from '../constants';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from './tutorialEvents';
import { getPointsSortedByClosestToCenter } from '../graphs/geometryUtils';
import { IntroStep } from 'intro.js/src/core/steps';
import { ensureMenuIsOpen } from '../ui/menuUi';

// IntroJS types are out-of-date so we need some overrides
/* eslint-disable @typescript-eslint/ban-ts-comment */

export const startInspireTutorial = () => {
	if (isTutorialActive('inspire') && globalThis.neuroCreate.graph) {
		closeAllPanels();

		tutorialEmitter.removeAllListeners();

		if (globalThis.neuroCreate.graph.nodes.length > 0) {
			const pointsFromCenter = getPointsSortedByClosestToCenter(globalThis.neuroCreate.graph.nodes);
			let [centralNode] = pointsFromCenter.filter((node) => node.style !== 'rect');
			if (!centralNode) {
				[centralNode] = pointsFromCenter;
			}
			globalThis.neuroCreate.graph.updateActive(centralNode, true, true);
			startTutorial(
				{
					tooltipClass: 'welcomeTutorial',
					doneLabel: 'OK',
					steps: [
						{
							title: 'Tutorial 👋',
							// element: '#all-content .nodes .menu-open',
							intro: `Welcome to the tutorial!
<br><br>
Typically, we would start the tutorial by adding words, but since you've already done that, we'll move on to show you the tools available.`,
						},
					],
				},
				{
					onexit: () => {
						if (globalThis.neuroCreate.graph) {
							globalThis.neuroCreate.graph.updateActive(centralNode, true, true);
							nodeActivated();
						}
					},
				}
			);
		} else {
			console.log("startInspireTutorial")
			showWordInputPanel();

			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedWordsFromPanel, afterAddedWordsFromPanel);

			// 			startTutorial({
			// 				tooltipClass: 'welcomeTutorial',
			// 				disableInteraction: false,
			// 				steps: [
			// 					{
			// 						title: 'Tutorial 👋',
			// 						intro: `Welcome to the tutorial! We'll guide you through the basics.
			// <br><br>At each step, press <strong>Next</strong> or perform the action indicated by <strong>➡</strong>`,
			// 						position: 'bottom',
			// 					},
			// 					{
			// 						title: 'Type a few words',
			// 						intro: `Each time you create a new board, you start by typing a paragraph as explained above.`,
			// 						element: '.alert-show textarea',
			// 						position: 'bottom',
			// 					},
			// 					{
			// 						title: `Let's add these words to the board`,
			// 						element: '.alert-show button.primary',
			// 						position: 'left',
			// 						intro: `Have you finished your sentence? When you have...<br><br>
			//  <strong>➡ Click 'Go'</strong>`,
			// 					},
			// 				],
			// 			});
		}
	}
};

export const afterAddedWordsFromPanel = (): void => {
	if (!isTutorialActive('inspire')) {
		return;
	}

	if ((globalThis.neuroCreate.graph?.nodes.length || 0) < 1) {
		showAlert(
			`Oops, you did not add any words. We'll show the panel again so you can type a paragraph to start off the tutorial`,
			[
				{
					label: 'OK',
					primary: true,
					onClick: () => {
						startInspireTutorial();
					},
				},
			]
		);
		return;
	}

	startTutorial(
		{
			disableInteraction: false,
			showButtons: true,
			hideNext: true,
			steps: [
				{
					title: 'AI-powered brainstorming',
					element: '#all-content .nodes',
					tooltipClass: 'medium-tooltip',
					intro: `Our AI-powered enhancement is automatically enabled, and has separated out the key concepts into individual nodes.
						<br><br>
						Think of each node like a Lego block for your thoughts, and the nodes on the board help you to map out your thoughts clearly, so you can combine, connect, spark and recombine these individual nodes into a larger mind-map.
						<br><br>
						Commonplace words such as 'is' and 'with' are omitted.
						`,
					position: 'right',
				},
				{
					title: 'FlowCreate tools',
					element: '#all-content .nodes g:last-child',
					intro:
						'<strong>➡ To learn about our tools, click one of the nodes you created.</strong>',
				},
			],
		},
		onExitShowInstruction('To learn about our tools, click one of the nodes you created')
	);

	tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedNodeMenu, nodeActivated);
};

export const nodeActivated = (): void => {
	clearNonBlockingMessage();
	if (!isTutorialActive('inspire')) {
		return;
	}

	const steps: Array<Partial<IntroStep>> = [];
	if (!globalThis.neuroCreate.graph?.isAiEnabled()) {
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.aiEnabled, aiToolsTutorial);
		steps.push({
			title: 'Enable the AI',
			element: '#ai-toggle-button',
			disableInteraction: false,
			intro: `First, we'll take a look at the AI-powered tools. However, the AI is currently disabled.
<br><br>
<strong>➡ Please enable the AI to continue.</strong>`,
		});
		startTutorial({ showButtons: false, disableInteraction: false, steps });
	} else {
		aiToolsTutorial();
	}
};

export const aiToolsTutorial = () => {
	if (!isTutorialActive('inspire')) {
		return;
	}

	const steps: Array<Partial<IntroStep>> = [];
	steps.push(
		{
			title: 'AI tools',
			element: '#all-content .nodes .menu-open',
			intro: `First, we'll take a look at the AI-powered tools. These are only available when the AI is switched on.`,
		},
		{
			title: 'Spark',
			element: '.menu .arc[data-action=spark]',
			intro:
				'The Spark AI tool suggests 2 ideas based on the concept. These will be associations within the domains related to the engine you selected e.g. the ‘Creative’ engine, gives associations within culture, lifestyle and trends',
		},
		{
			title: 'Empathise',
			element: '.menu .arc[data-action=empathise]',
			tooltipClass: 'medium-tooltip',
			intro:
				'The Empathise AI tool suggests different perspectives related to your concept. <br><br>Suggestions are labelled by colour: <br><span class="colour-demo GREEN">Bright Green - positive</span><br> <span class="colour-demo RED">Red - negative</span><br> <span class="colour-demo FUSCHIA">Pink - emotions</span><br> <span class="colour-demo BLUE">Blue - co-occurence</span><br><br> <span class="colour-demo FOREST">Dark Green</span>/<span class="colour-demo TAN">Tan</span>/<span class="colour-demo SALMON">Salmon</span>: different semantic and contextual associations.',
		},
		{
			title: 'Generate',
			element: '.menu .arc[data-action=generate]',
			intro:
				'Generate allows you to find taglines and haikus that are related to your concept. Coming soon: generative images!',
		},
		{
			title: 'Link',
			element: '.menu .arc[data-action=link]',
			intro:
				'The Link tool will find a linking concept from the node you have created and one or more others. Think of it as finding a convergence between concepts in a multi-dimensional Venn diagram!',
		},
		{
			title: 'Non-AI tools',
			element: '#all-content .nodes .menu-open',
			intro: 'Now let’s have a look at the non-AI tools!',
		},
		{
			title: 'Search',
			element: '.menu .arc[data-action=search]',
			intro:
				'You can search for associated images and trending articles for the ‘Creative’ engine on the web by clicking search button; and further research papers, design awards, and business market analytics for the ‘Innovative’ engine; and games databases for the ‘Games’ engine.',
			position: 'left',
		},
		{
			title: 'Connect',
			element: '.menu .arc[data-action=connect]',
			intro:
				'Connect a node to another by clicking connect and the second node that you want to connect',
			position: 'left',
		},
		{
			title: 'Merge',
			element: '.menu .arc[data-action=merge]',
			intro:
				'You can merge two nodes by clicking merge icon and select a second node that you want to merge',
			position: 'left',
		},
		{
			title: 'Like',
			element: '.menu .arc[data-action=like]',
			tooltipClass: 'likeTutorial',
			intro:
				'You can click the heart button for the elements you like and would like to save to an ordered list on the top right ( <span class="heartedListIcon"></span> ).<br><br> You can check the list of the elements you like in this list at any time and add it to your moodboard and notepad once you get to the Synthesise stage of your ideation.',
			position: 'left',
		},
		{
			title: 'Delete',
			// @ts-ignore https://introjs.com/docs/intro/options#showbuttons
			showButtons: false,
			tooltipClass: 'deleteTutorial',
			element: '#all-content .nodes .menu-open circle',
			intro:
				'You can delete a node by clicking the trash icon ( <span class="deleteIcon"></span> ).',
		},
		{
			title: 'Board Menu',
			element: '#top-menu-wrapper',
			intro: 'You have explored our radial toolbar. Now, let’s have a look at our board menu 🔍',
		},
		{
			title: 'Engine indicator',
			element: '#engine-select',
			intro:
				'This shows the engine you selected for the board earlier (Creative, Innovative, or Experiential). <br><br>You may click on the icon to change the engine and see different suggestions from the AI.',
		},
		{
			title: 'Timer',
			element: '#timerButton',
			intro: 'Set a timer to be more focused and efficient ⏰',
		},
		{
			title: 'Collaborator',
			element: '.board-user-icon-list',
			intro: 'You can see your collaborators here.',
		},
		{
			title: 'Invite',
			element: '#board-menu-invite',
			intro: 'Invite others to the board to collaborate.',
		},
		{
			title: 'Idea Box',
			tooltipClass: 'medium-tooltip',
			element: '#board-menu-hearted',
			intro: `Idea Box shows the elements you have clicked the heart icon for.
			<br><br>
			Add and collect any concept you like to your Idea Box, which will be a repository for your conceptual nuggets of inspiration to use later.`,
		},
		{
			title: 'Create Group',
			element: '#create-group',
			intro: `You can select multiple concepts and create a group. It allows you to keep your concepts together on the board.`,
		},
		{
			title: 'Ungroup Group',
			element: '#remove-group',
			intro: `You can select a group on the board and click 'Ungroup' to remove it. It will remove the group, but the concepts within will remain on the board.`,
		},
		{
			title: 'Edit Group',
			element: '#edit-group',
			intro: `You can add or remove concepts from a group that you select. You can also change the colour of the group by clicking 'Edit group' button.`,
		},
		{
			title: 'Zoom Control',
			element: '#board-zoom-controls',
			intro:
				'You can zoom the board by clicked "+" or "-" button. <br><br>Alternatively scroll / pinch to zoom.',
		},
		{
			title: 'Board Guide',
			element: '#guideButton',
			intro:
				'FlowCreate is inspired by design-thinking. You can learn more about each creative AI tool and how it fits within and augments the design-thinking process by clicking the guide icon.',
		},
		{
			title: 'Completed 🎉',
			element: '#board-stage-switcher',
			intro:
				'You have completed the Inspire tutorial! <br><br>After adding some ideas to the board, navigate to the Ideate stage.',
		}
	);

	startTutorial(
		{
			steps,
		},
		{
			onexit: endTutorial,
			onbeforechange: ensureMenuIsOpen,
			onafterchange: function (target: HTMLElement) {
				if (target.id === 'top-menu-wrapper') {
					// Open the toolbar collapsed menu which is ai menu is merged into
					if (window.innerWidth < WINDOW_BREAKPOINTS.tablet) {
						openCollapsedMenuForTutorial(
							document.querySelector('#ai-menu-wrapper .board-icon')!,
							document.querySelector('#toolbar-menu-wrapper .expand-menu')!
						);
					}
				}

				if (target.id === 'engine-select') {
					// Open the toolbar collapsed menu
					if (window.innerWidth > WINDOW_BREAKPOINTS.tablet) {
						openCollapsedMenuForTutorial(
							document.querySelector('#timerButton')!,
							document.querySelector('#toolbar-menu-wrapper .expand-menu')!
						);
					}
				}

				if (target.id === 'board-zoom-controls') {
					openCollapsedMenuForTutorial(
						document.querySelector('#guideButton')!,
						document.querySelector('#main-menu-wrapper .expand-menu')!
					);
				}
			},
		}
	);
};
