import { NCGroup, NCNode, Point } from '../../../../src/commonTypes';
import { getCentralPoint, isPointInEllipse, addForce } from './geometryUtils';
import { ADDITIONAL_GROUP_RADIUS, getRadiusX, getRadiusY } from './graphGroupUtils';

const shiftNodeTowards = (node: NCNode, toPoint: Point): { xShift: number; yShift: number } => {
	const xDistance = toPoint.x - node.x;
	const yDistance = toPoint.y - node.y;

	const absoluteXDistance = Math.abs(xDistance);
	const absoluteYDistance = Math.abs(yDistance);

	const xShift = (absoluteXDistance > 200 ? 2 * xDistance : xDistance) / 20;
	const yShift = (absoluteYDistance > 200 ? 2 * yDistance : yDistance) / 20;

	return { xShift, yShift };
};

export default function (allGroups: Array<NCGroup>) {
	let nodes: Array<NCNode>,
		groups: Array<NCGroup> = allGroups,
		strength = 1;

	function force(alpha: number) {
		for (const group of groups) {
			const centralPoint = getCentralPoint(group.nodes);
			// neuroCreate.graph!.debugMarker = centralPoint;

			const radiusX = getRadiusX(group) + 1.5 * ADDITIONAL_GROUP_RADIUS;
			const radiusY = getRadiusY(group) + 1.5 * ADDITIONAL_GROUP_RADIUS;

			// Move nodes in the group towards the central point
			if (!group.lock) {
				for (const node of group.nodes) {
					const { xShift, yShift } = shiftNodeTowards(node, centralPoint);

					if (Math.hypot(xShift, yShift) > 10) {
						addForce(node, { x: xShift * alpha, y: yShift * alpha });
					}
				}
				if (alpha < 0.2) {
					group.lock = true;
				}
			}

			// Move nodes outside the group away from the central point
			for (const node of nodes) {
				if (!group.nodes.some((n) => n.uid === node.uid)) {
					// If the node is within the bounds of the group oval as defined by radiusX and radiusY
					// We need to calculate the hypotenuse of the triangle formed by the node and the central point
					// If the hypotenuse is less than the radius, we need to move the node away from the central point

					if (isPointInEllipse(node, centralPoint, radiusX, radiusY)) {
						const { xShift, yShift } = shiftNodeTowards(node, centralPoint);

						addForce(node, { x: -xShift * alpha, y: -yShift * alpha });
					}
				}
			}
		}
	}

	force.initialize = function (_: Array<NCNode>) {
		nodes = _;
	};

	force.groups = function (_: Array<NCGroup>) {
		groups = _;
	};

	force.strength = function (_: number) {
		return arguments.length ? ((strength = +_), force) : strength;
	};

	return force;
}
