import { enableLightboxForImage } from '../lightbox';
import { LikedItem, PresentationInfo, SelectableItem } from '../../../../../src/commonTypes';
import { savePresentationInfo } from '../../apiRequests';
import { capitalizeFirstLetter } from '../../../../../src/commonMisc';

export const createLabel = (text: string) => {
	const label = document.createElement('h5');
	label.append(text);
	return label;
};

export const createPreviewParagraph = (text: string, compact = false) => {
	const preview = document.createElement('p');
	preview.classList.add(compact ? 'text-preview-compact' : 'text-preview');
	preview.innerText = text;
	return preview;
};

export const createTextField = (
	text: string,
	rows: number,
	changeListener?: (event: Event) => void
) => {
	const field = document.createElement('textarea');
	field.rows = rows;
	field.innerText = text;
	if (changeListener) {
		field.addEventListener('change', changeListener);
	}
	return field;
};

export const createDateField = (date?: string, changeListener?: (event: Event) => void) => {
	const field = document.createElement('input');
	field.type = 'date';
	if (date) {
		field.value = date.substring(0, 10);
	}
	if (changeListener) {
		field.addEventListener('change', changeListener);
	}
	return field;
};

export const createDetails = (summaryText: string): [HTMLDetailsElement, HTMLElement] => {
	const details = document.createElement('details');
	const summary = document.createElement('summary');
	const span = document.createElement('span');
	span.classList.add('summary-text');
	span.innerText = summaryText;
	summary.append(span);
	details.append(summary);
	return [details, summary];
};

export const createBudgetField = (value: string, changeListener?: (event: Event) => void) => {
	const field = document.createElement('input');
	field.type = 'number';
	field.placeholder = '1000';
	field.step = '0.01';
	field.min = '0';
	field.value = value;
	if (changeListener) {
		field.addEventListener('change', changeListener);
	}

	return field;
};

export const createCurrencyField = (name: string, value: string) => {
	const currencies = [
		{ value: 'GBP', sign: '£' },
		{ value: 'USD', sign: '$' },
		{ value: 'EUR', sign: '€' },
		{ value: 'JPY', sign: '¥' },
	];
	const fieldset = document.createElement('select');
	fieldset.name = name;
	currencies.forEach((option) => {
		const field = document.createElement('option');
		field.value = option.value;
		field.innerText = option.sign;
		fieldset.append(field);
	});
	if (value) {
		fieldset.value = value;
	}

	return fieldset;
};

export const createImage = (src: string, width: number, className?: string) => {
	const image = document.createElement('img');
	image.src = src;
	image.width = width;
	if (className) {
		image.classList.add(className);
	}
	enableLightboxForImage(image);
	return image;
};

export const createGenerateIdeateLabel = () => {
	const label = document.createElement('span');
	label.classList.add('ideate-label');
	label.append(`Created with Ideate > Generate`);
	return label;
};

export const createGenerateInspireLabel = (source: 'Generate' | 'Search', noCheckbox = false) => {
	const label = document.createElement('span');
	label.classList.add('inspire-label');
	if (noCheckbox) {
		label.classList.add('no-checkbox');
	}
	label.append(`Created with Inspire > ${source}`);
	return label;
};

export const createConceptualTerritoryLabel = (concept: string, noCheckbox = false) => {
	const label = document.createElement('span');
	label.classList.add('synthesise-label');
	if (noCheckbox) {
		label.classList.add('no-checkbox');
	}
	label.append(`Concept: ${capitalizeFirstLetter(concept)}`);
	return label;
};

export const createAutogeneratedLabel = () => {
	const label = document.createElement('span');
	label.classList.add('inspire-label');
	label.append(`Autogenerated from project vision`);
	return label;
};

export const createInspireGenerateAndSynthesiseLabel = () => {
	const taglineLabel = document.createElement('span');
	taglineLabel.classList.add('source-label');
	taglineLabel.classList.add('no-checkbox');
	taglineLabel.innerHTML = `Created with <span class="inspire-key">Inspire > Generate</span> or <span class="synthesise-key">Synthesise AI</span>`;
	return taglineLabel;
};

export const createInspireGenerateSearchAndSynthesiseLabel = () => {
	const taglineLabel = document.createElement('span');
	taglineLabel.classList.add('source-label');
	taglineLabel.classList.add('no-checkbox');
	taglineLabel.innerHTML = `Created with <span class="inspire-key">Inspire > Generate</span>, <span class="inspire-key">Inspire > Search</span>, <span class="synthesise-key">Synthesise AI</span> or <span class="synthesise-key">Synthesise notebook</span>`;
	return taglineLabel;
};

export const getConceptualTerritoryResultsOfType = (
	presentation: PresentationInfo,
	type: LikedItem['type']
): Array<
	[concept: PresentationInfo['conceptualTerritories'][0], result: SelectableItem<LikedItem>]
> => {
	const results: Array<
		[concept: PresentationInfo['conceptualTerritories'][0], result: SelectableItem<LikedItem>]
	> = [];
	presentation.conceptualTerritories?.forEach((concept) => {
		concept.value.results.forEach((result) => {
			if (result.value.type === type) {
				results.push([concept, result]);
			}
		});
	});
	return results;
};

export const getUncachedItems = <T>(items: Array<SelectableItem<T>>) => {
	return items.filter((item) => !item.cached);
};

export const createLinkList = (
	links: Array<SelectableItem<{ url: string; text: string }>>,
	presentation: PresentationInfo
) => {
	const linkList = document.createElement('ul');
	linkList.classList.add('link-list');
	links.forEach((link) => {
		const listItem = document.createElement('li');
		const checkbox = createSelectableCheckbox(link, presentation)[0];
		listItem.append(checkbox);
		const linkElement = document.createElement('a');
		linkElement.target = '_blank';
		linkElement.href = link.value.url;
		linkElement.innerText = link.value.text;
		listItem.append(linkElement);
		linkList.append(listItem);
	});
	return linkList;
};

export const createImageList = (
	presentation: PresentationInfo,
	images: Array<SelectableItem<{ url: string }>>
) => {
	const imageList = document.createElement('ul');
	imageList.classList.add('image-list');
	images.forEach((image) => {
		const listItem = document.createElement('li');
		const [checkbox, eventListener] = createSelectableCheckbox(image, presentation);
		listItem.append(checkbox);
		const imageElement = createImage(image.value.url, 200);
		imageElement.addEventListener('click', eventListener);
		listItem.append(imageElement);
		imageList.append(listItem);
	});
	return imageList;
};

export const createTextList = (
	links: Array<SelectableItem<{ text: string }>>,
	presentation: PresentationInfo
) => {
	const linkList = document.createElement('ul');
	linkList.classList.add('text-list');
	links.forEach((link) => {
		const listItem = document.createElement('li');
		const checkbox = createSelectableCheckbox(link, presentation)[0];
		listItem.append(checkbox);
		listItem.append(createPreviewParagraph(link.value.text));
		linkList.append(listItem);
	});
	return linkList;
};

export const createSelectableCheckbox = (
	selectable: SelectableItem<unknown>,
	presentation: PresentationInfo
): [HTMLInputElement, (event: Event) => void] => {
	const checkbox = document.createElement('input');
	checkbox.type = 'checkbox';
	if (selectable.selected) {
		checkbox.checked = true;
	}
	const eventListener = (event: Event) => {
		document.querySelector('.nothing-selected-warning')?.classList.add('invisible');
		if (event.target !== checkbox) {
			checkbox.checked = !checkbox.checked;
		}
		selectable.selected = checkbox.checked;
		const childCheckboxes =
			(checkbox.parentElement?.parentElement?.nodeName === 'DETAILS' &&
				checkbox.parentElement?.parentElement?.querySelectorAll<HTMLInputElement>(
					'input[type="checkbox"]'
				)) ||
			[];
		if (childCheckboxes.length > 1) {
			// If there are child checkboxes, check/uncheck them all
			const conceptualTerritoryResults = (
				selectable.value as PresentationInfo['conceptualTerritories'][0]['value']
			).results;
			childCheckboxes.forEach((childCheckbox, index) => {
				childCheckbox.checked = checkbox.checked;
				if (conceptualTerritoryResults && index > 0) {
					conceptualTerritoryResults[index - 1].selected = checkbox.checked;
				}
			});
		}
		savePresentationInfo(document.body.dataset.boardId!, presentation);
	};
	checkbox.addEventListener('change', eventListener);

	return [checkbox, eventListener];
};

export const createCountTag = (count: number) => {
	const tag = document.createElement('span');
	tag.classList.add('count-tag');
	if (count === 0) {
		tag.classList.add('count-tag-zero');
	}
	tag.innerText = count.toString();
	return tag;
};
