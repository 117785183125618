import { deleteBoard, moveIntoProject } from '../apiRequests';
import {
	clearNonBlockingMessage,
	getBoardIdFromUrl,
	getProjectIdFromUrl,
	showAlert,
} from './uiUtils';

/* Board menus on dashboard / project pages */
const dashboardPage = document.querySelector('body.dashboard');
const projectPage = document.querySelector('body.project');
const boardMenu = document.querySelectorAll<HTMLElement>('.board-component .menu-list');

const hideAllMenus = () => {
	boardMenu.forEach((menus) => {
		menus.setAttribute('style', 'display:none');
	});
};

const perBoardMenuButton = document.querySelectorAll<HTMLButtonElement>(
	'.board-component .menu-button'
);

perBoardMenuButton.forEach((menuButton) => {
	menuButton.addEventListener('click', (e) => {
		e.preventDefault();
		hideAllMenus();
		menuButton.classList.add('button-active');
		menuButton.nextElementSibling?.setAttribute('style', 'display:block');
		document.addEventListener('click', (event: Event) => {
			const target = event.target as HTMLElement;
			if (!target.className.includes('menu-button')) {
				hideAllMenus();
				menuButton.classList.remove('button-active');
			}
		});
	});
});

const deleteButtons = document.querySelectorAll<HTMLButtonElement>(
	'.board-component .menu-list .delete-button'
);
deleteButtons.forEach((deleteButton) => {
	deleteButton.addEventListener('click', (e) => {
		const button = e.target as HTMLButtonElement;
		const boardIdToDelete = getBoardIdFromUrl(button.closest('a')!.href);
		const boardToDelete = button.closest('.user-boards-item')!;
		showAlert('Are you sure you would like to delete this board?', [
			{
				label: 'Cancel',
				onClick: () => {
					hideAllMenus();
					clearNonBlockingMessage();
				},
			},
			{
				label: 'Delete',
				primary: true,
				onClick: () => {
					hideAllMenus();
					deleteBoard(boardIdToDelete).then(() => {
						boardToDelete.remove();
					});
					clearNonBlockingMessage();
				},
			},
		]);
		e.preventDefault();
	});
});

const openMoveProjectPanel = (event: Event) => {
	event.preventDefault();
	const button = event.target as HTMLButtonElement;
	const boardToMove = button.closest('.user-boards-item')!;
	const moveProjectContainer = document.querySelector('#move-project-container')!;
	moveProjectContainer.classList.remove('hidden');
	const linkToBoard = button.closest('a')!;
	const boardIdToMove = getBoardIdFromUrl(linkToBoard.href);
	const oldBoardProjectId = linkToBoard.dataset.projectId as string;
	const projectSelect = moveProjectContainer.querySelector<HTMLSelectElement>('#newProjectId')!;
	projectSelect.value = oldBoardProjectId;
	const projectSubmit = moveProjectContainer.querySelector(
		".submit-container input[type='submit']"
	)!;
	const submitMoveProject = (e: Event) => {
		moveIntoProject(boardIdToMove, projectSelect.value).then(() => {
			// Update the project name
			if (dashboardPage) {
				const newProjectName = projectSelect.selectedOptions[0].innerText.trim();
				linkToBoard.querySelector<HTMLElement>('.board-project')!.innerHTML = newProjectName;
			}

			if (projectPage) {
				const currentProjectId = getProjectIdFromUrl();
				if (currentProjectId !== projectSelect.value) {
					boardToMove.remove();
				}
			}
		});
		e.preventDefault();
		projectSubmit.removeEventListener('click', submitMoveProject);
		moveProjectContainer.classList.add('hidden');
	};

	projectSubmit.addEventListener('click', submitMoveProject);
};

/*
 *	Add event handler for each move project button
 */
const moveToProjectButtons = document.querySelectorAll<HTMLButtonElement>('.move-to-project');
if (moveToProjectButtons) {
	moveToProjectButtons.forEach((button) => {
		button.addEventListener('click', openMoveProjectPanel);
	});
}
