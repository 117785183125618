import * as d3 from 'd3';
import Graph from './Graph';
import * as ncApi from '../apiRequests';
import { removeEl, removeNoDeleteAnimation } from './graphUtils';
import {
	createClusters,
	appendClusterCircle,
	clusterKeyFn,
	updateCluster,
} from './graphClusterUtils';
import { removeChildren, showAlert } from '../ui/uiUtils';
import { ClusterProps } from '../../types';
import { refreshHeartedList } from '../ui/ideaBox';
import { startIdeateTutorial } from '../tutorials/ideateTutorial';
import * as tutorialEvents from '../tutorials/tutorialEvents';
import * as templateTutorial from '../tutorials/templateTutorial';
import {
	AvailableColour,
	NCCluster,
	NCGraphData,
	BoardUser,
	GraphMode,
	UserSelection,
	ClusterAnalysis,
	NCNode,
} from '../../../../src/commonTypes';
import { ANALYSES_TYPES, AnalysisKey } from '../../../../src/commonConstants';
import * as hints from '../tutorials/hints';
import { disableChatbot, enableChatbot } from '../tutorials/tutorialEvents';

const CLUSTER_SPACING = 350;
const CLUSTER_Y_START = -CLUSTER_SPACING / 2;
const CLUSTER_X_START = -CLUSTER_SPACING;
const CLUSTER_PROPS: Array<ClusterProps> = [
	{
		x: CLUSTER_X_START,
		y: CLUSTER_Y_START,
		colourClass: 'green',
	},
	{
		x: CLUSTER_X_START,
		y: CLUSTER_Y_START + 2 * CLUSTER_SPACING,
		colourClass: 'red',
	},
	{
		x: CLUSTER_X_START + 1 * CLUSTER_SPACING,
		y: CLUSTER_Y_START + 1 * CLUSTER_SPACING,
		colourClass: 'fuchsia',
	},
	{
		x: CLUSTER_X_START + 2 * CLUSTER_SPACING,
		y: CLUSTER_Y_START,
		colourClass: 'orange',
	},
	{
		x: CLUSTER_X_START + 2 * CLUSTER_SPACING,
		y: CLUSTER_Y_START + 2 * CLUSTER_SPACING,
		colourClass: 'green',
	},
	{
		x: CLUSTER_X_START + 3 * CLUSTER_SPACING,
		y: CLUSTER_Y_START + 1 * CLUSTER_SPACING,
		colourClass: 'red',
	},
	{
		x: CLUSTER_X_START + 4 * CLUSTER_SPACING,
		y: CLUSTER_Y_START,
		colourClass: 'green',
	},
	{
		x: CLUSTER_X_START + 4 * CLUSTER_SPACING,
		y: CLUSTER_Y_START + 2 * CLUSTER_SPACING,
		colourClass: 'fuchsia',
	},
	{
		x: CLUSTER_X_START + 5 * CLUSTER_SPACING,
		y: CLUSTER_Y_START + 1 * CLUSTER_SPACING,
		colourClass: 'orange',
	},
];

class IdeateGraph extends Graph {
	public clusters: Array<NCCluster>;
	protected analyses: Array<AnalysisKey>;
	public activeAnalysis: AnalysisKey | undefined;
	protected inspireLabels: Array<string>;
	protected labelString: string;
	protected includeAiToolResults: boolean;
	protected vision: string;
	protected pdfSummary: string;

	constructor(
		graphData: NCGraphData,
		boardUsers: Array<BoardUser>,
		userSelections: Array<UserSelection>,
		colourClass: AvailableColour,
		labels: Array<string>,
		activeAnalysis: AnalysisKey | undefined,
		isCollaborative: boolean,
		automation: boolean,
		vision: string,
		pdfSummary: string
	) {
		super(
			graphData,
			boardUsers,
			userSelections,
			colourClass,
			'#767680',
			isCollaborative,
			automation,
			function (this: IdeateGraph) {
				this.setClustersAndAnalyses(graphData);

				this.groups.clusters = createClusters(
					this.baseElement,
					this.clusters,
					this.enterCluster.bind(this),
					this.graphProps
				);
			}
		);

		this.vision = vision;
		this.pdfSummary = pdfSummary;
		this.inspireLabels = labels;
		this.labelString = labels.join(' ');

		this.includeAiToolResults = document.querySelector<HTMLInputElement>(
			'input#ideate-use-ai-results'
		)!.checked;

		const lastActiveAnalysis = document.querySelector<HTMLLIElement>('.analyses-types li.clicked')
			?.dataset.analysis as AnalysisKey | undefined;
		if (lastActiveAnalysis) {
			// We switched between personal/team, so stay on the last selected analysis
			this.activeAnalysis = lastActiveAnalysis;
		}

		this.setupAnalysisList();

		const activeLi = this.getActiveLi(activeAnalysis);
		if (activeLi) {
			const analysesKey = activeLi.dataset.analysis as AnalysisKey;
			this.loadAnalysis(analysesKey, ANALYSES_TYPES[analysesKey], activeLi, false);
		}

		document.querySelector('#analyses-container')!.classList.remove('hidden');

		if (this.shouldShowRecommenderForm(true)) {
			// Disable chatbot when showing recommender form
			console.log('Disable chatbot for ideate');
			disableChatbot();
			// Show recommendation form on first load
			document.querySelector('#analyse-form-container')!.classList.remove('hidden');
		}
	}

	getActiveLi(activeAnalysis: AnalysisKey | undefined): HTMLLIElement | null {
		let activeLi: HTMLLIElement | null = null;
		if (this.activeAnalysis) {
			activeLi = document.querySelector<HTMLLIElement>('.analyses-types li.clicked')!;
		}
		if (!activeLi && activeAnalysis && activeAnalysis !== this.activeAnalysis) {
			activeLi = document.querySelector<HTMLLIElement>(
				`.analyses-types li[data-analysis='${activeAnalysis}']`
			);
		}
		return activeLi;
	}

	shouldShowRecommenderForm(shouldShowAlert = false): boolean {
		return (
			!this.automation &&
			this.validate(shouldShowAlert) &&
			!(this.analyses && this.analyses.length > 0)
		);
	}

	validate(shouldShowAlert = false): boolean {
		if (this.inspireLabels.length === 0) {
			if (shouldShowAlert) {
				showAlert(
					"Welcome to Ideate! Before you can use the analyses, please go back to 'Inspire' to add some ideas."
				);
			}
			return false;
		}
		return true;
	}

	refreshCurrentAnalysis(): Promise<boolean> {
		if (this.activeAnalysis) {
			const activeLi = document.querySelector<HTMLLIElement>('.analyses-types li.clicked')!;
			if (activeLi) {
				const analysesKey = activeLi.dataset.analysis as AnalysisKey;
				return this.loadAnalysis(analysesKey, ANALYSES_TYPES[analysesKey], activeLi, true);
			}
		}
		return Promise.resolve(false);
	}

	loadAnalysis(
		analysisKey: AnalysisKey,
		analysis: ClusterAnalysis,
		li: HTMLLIElement,
		isNew = false
	): Promise<boolean> {
		const analysesList = document.querySelector('#analyses-container .analyses-types')!;

		for (const otherLi of analysesList.children) {
			otherLi.classList.remove('clicked');
		}
		li.classList.toggle('clicked');

		let inputStringForClusters = this.includeAiToolResults
			? `${this.labelString} ${this.userSelectionsToUseInAnalysis().join(' ')}`
			: this.labelString;

		if (!inputStringForClusters.trim()) {
			inputStringForClusters = this.vision || this.pdfSummary || '';
		}
		return new Promise<boolean>((resolve) => {
			ncApi.clusters(this.boardId, analysisKey, inputStringForClusters).then((clusters) => {
				if (clusters.length === 0) {
					if (!this.automation && this.validate()) {
						showAlert(
							`No clusters were generated by '${analysisKey}'. You may like to try another analysis type.`
						);
					}
					resolve(false);
				} else {
					if (neuroCreate.saveNodes && neuroCreate.saveLinks) {
						neuroCreate.saveLinks?.delete(this.links, () => {
							neuroCreate.saveNodes?.delete(this.nodes, () => {
								neuroCreate.saveClusters?.delete(this.clusters, () => {
									this.activeAnalysis = analysisKey;
									clusters.forEach((cluster, index) => {
										if (index < CLUSTER_PROPS.length) {
											this.spawnCluster(cluster, {
												...CLUSTER_PROPS[index],
												colourClass: this.isCollaborative ? 'blue' : this.userColourClass,
											});
											if (neuroCreate.saveClusters) {
												neuroCreate.saveClusters.create([cluster]);
											}
										}
									});
									this.save(() => {
										this.zoomToFit(this.automation);
										if (isNew) {
											const analysisName = ANALYSES_TYPES[analysisKey].name;
											tutorialEvents.loadedNewAnalysis(analysisName);
										}
										resolve(true);
									});
								});
							});
						});
					}
				}
			});
		});
	}

	finishedRecommenderForm() {
		this.setupAnalysisList();
		if (document.body.dataset.showTutorial === 'true') {
			this.startTutorial();
		} else {
			this.startHints();
		}
	}

	userSelectionsToUseInAnalysis(): Array<string> {
		return this.userSelections
			.flatMap((item) =>
				item.words.map((word) => {
					const mapping = item.topicMapping?.find(
						(mappingItem) => mappingItem.key.trim() === word.trim()
					);
					if (mapping) return `${mapping.key} ${mapping.topics.join(' ')}`;
					return '';
				})
			)
			.filter((item) => item.trim() !== '');
	}

	toggleIncludeAiToolResults(): void {
		this.includeAiToolResults = !this.includeAiToolResults;
		document.querySelector<HTMLInputElement>('input#ideate-use-ai-results')!.checked =
			this.includeAiToolResults;
		this.refreshCurrentAnalysis();
		this.setupExplainerMessage();
	}

	setupExplainerMessage(): void {
		const userSelectionsString = this.includeAiToolResults
			? `, and ${this.userSelectionsToUseInAnalysis().length} additional AI-suggested concepts`
			: '';
		document.querySelector<HTMLParagraphElement>('.analyses-description')!.innerHTML = this
			.isCollaborative
			? `Using all ${this.inspireLabels.length} concepts on the Inspire board <span class='inspire-key'>highlighted</span>${userSelectionsString}`
			: `Using ${this.inspireLabels.length} concepts you created in Inspire (manually or with AI tools) <span class='inspire-key'>highlighted</span>${userSelectionsString}`;
	}

	setupAnalysisList() {
		const analysesList = document.querySelector('#analyses-container .analyses-types')!;
		removeChildren(analysesList);

		this.setupExplainerMessage();

		const recommendedAnalysisTypes = this.analyses || ([] as Array<AnalysisKey>);
		Object.entries(ANALYSES_TYPES).forEach(([key, analysis]) => {
			const analysisKey = key as AnalysisKey;
			const li = document.createElement('li');
			li.dataset.analysis = analysisKey;
			if (recommendedAnalysisTypes.includes(analysisKey)) {
				li.classList.add('recommended');
				li.title = 'Recommended option';
			}
			if (analysis.category) {
				li.innerHTML = `<div>${analysis.category}</div><div>${analysis.name}</div>`;
			} else {
				li.innerText = analysis.name;
			}
			if (this.activeAnalysis === analysisKey) {
				li.classList.add('clicked');
			}
			li.onclick = () => {
				this.loadAnalysis(analysisKey, analysis, li, true);
			};
			analysesList.append(li);
		});
	}

	save(callback?: () => void) {
		if (globalThis.neuroCreate.saveGraph) {
			globalThis.neuroCreate.saveGraph(
				this.graphMode,
				{
					nodes: this.nodes,
					links: this.links,
					clusters: this.clusters,
					analyses: this.analyses,
					activeAnalysis: this.activeAnalysis,
				},
				callback
			);
		}
	}

	toggleLikeCluster(clusterData: NCCluster): void {
		this.clusters.forEach((c) => {
			if (clusterData.group === c.group) {
				c.liked = !clusterData.liked;
			}
		});
		this.save();

		// Add to hearted list
		ncApi
			.like(
				this.boardId,
				'cluster',
				JSON.stringify({
					group: clusterData.group,
					values: clusterData.values,
				}),
				null,
				!clusterData.liked
			)
			.then(() => {
				refreshHeartedList();
				tutorialEvents.likedCluster(clusterData);
			});
	}

	/**
	 * Function to use with .join to create a new cluster
	 */
	enterCluster(
		elem: d3.Selection<d3.EnterElement, NCCluster, SVGGElement, null>
	): d3.Selection<SVGGElement, NCCluster, SVGGElement, null> {
		const cluster = elem
			.append('g')
			.attr('data-cluster', (d) => d.group)
			.attr('transform', (d, i) => {
				const coords = [CLUSTER_PROPS[i].x, CLUSTER_PROPS[i].y];
				return `translate(${coords[0]}, ${coords[1]})`;
			});

		appendClusterCircle(cluster);

		return cluster;
	}

	setClustersAndAnalyses(data: NCGraphData) {
		this.clusters = data.clusters || [];
		this.analyses = data.analyses || ([] as Array<AnalysisKey>);
		this.activeAnalysis = data.activeAnalysis || undefined;
	}

	/**
	 * Update graph with new data from collaborators / self (via ShareDB)
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	loadNewGraphData(graphData: NCGraphData, preDrawFunction: null | (() => void) = null) {
		super.loadNewGraphData(graphData, () => {
			this.setClustersAndAnalyses(graphData);

			if (this.groups.clusters) {
				this.groups.clusters = this.groups.clusters
					.data<NCCluster>(this.clusters, clusterKeyFn)
					.join<SVGGElement>(
						this.enterCluster.bind(this),
						updateCluster,
						this.graphProps.isDeleteAnimated ? removeEl : removeNoDeleteAnimation
					);
			}
		});
	}

	getMenuOptions() {
		return [
			{ label: 'Generate', enabled: true, requiresAi: true, longText: true, shortText: true },
			{ label: 'Search', enabled: true, longText: true, shortText: true },
			{ label: 'Like', enabled: true, longText: true, shortText: true },
		];
	}

	isMovable() {
		return false;
	}

	isPhysicsActive() {
		return false;
	}

	areNodesColoured() {
		return true;
	}

	areLinksColoured() {
		return false;
	}

	getClusterWithLabel(label: string): { cluster: NCCluster; props: ClusterProps } | undefined {
		const index = this.clusters.findIndex((node) => node.group === label);
		if (index >= 0) {
			const props = CLUSTER_PROPS[index];
			const cluster = this.clusters[index];
			return { cluster, props };
		}
		return undefined;
	}

	get graphMode(): GraphMode {
		return 'ideate';
	}

	startTutorial() {
		super.startTutorial();
		if (!this.shouldShowRecommenderForm()) {
			if (this.templateId) {
				templateTutorial.start(this.templateId, this, true);
			} else {
				startIdeateTutorial();
			}
		}
	}

	startHints() {
		super.startHints();
		if (!this.shouldShowRecommenderForm()) {
			if (!this.currentUser.settings.shown_ideate) {
				// console.log("Start hints for ideate");
				hints.beginIdeateHint();
			} else {
				this.setHintsShown();
			}
		} 
	}

	setHintsShown() {
		super.setHintsShown();
		this.currentUser.settings.shown_ideate = true;
		this.saveUser();
	}

	getHintsShown(): boolean {
		return this.currentUser.settings.shown_ideate || false;
	}

	addToBoard(): Promise<NCNode> {
		throw new Error('Method not available for Ideate.');
	}

	get graphData(): NCGraphData {
		return {
			nodes: this.nodes,
			links: this.links,
			groups: this.nodeGroups,
			clusters: this.clusters,
			analyses: this.analyses,
			activeAnalysis: this.activeAnalysis,
		};
	}

	getGreenNodesForCluster(cluster: NCCluster): Array<NCNode> {
		const clusterNodes = this.nodes.filter((node) => node.parent?.label === cluster.group);
		return clusterNodes.filter((node) => !!node.nodeColour && node.nodeColour === 'IDEATE');
	}
}

export default IdeateGraph;
