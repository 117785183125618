import { exitTutorial, startTutorial } from './tutorial';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from './tutorialEvents';
import { IntroStep } from 'intro.js/src/core/steps';

export const shouldShowInviteTutorial = () => {
	return document.querySelectorAll('.board-user-icon-list .board-component-user-item').length === 1;
};

export const getInviteStep = (): Partial<IntroStep> => {
	return {
		title: `Collaboration`,
		intro: `Invite a collaborator to join you in your creative process.
<br><br>
<strong>➡ Click 'Invite'</strong>`,
		element: '#board-menu-invite',
	};
};

export const useInviteTutorial = (afterCloseInvite: () => void) => {
	let invitedUser = false;
	let openedInvite = false;
	let closedInvite = false;

	const ensureClosedAndContinue = () => {
		const inviteContainer = document.querySelector('#invite-container')!;
		if (!inviteContainer.classList.contains('hidden')) {
			document.querySelector<HTMLButtonElement>('#board-menu-invite')!.click();
		}
		if (!closedInvite) {
			closedInvite = true;
			exitTutorial();
			afterCloseInvite();
		}
	};

	const afterOpenInvite = () => {
		if (openedInvite) {
			return;
		}
		openedInvite = true;
		exitTutorial();
		const inviteContainer = document.querySelector('#invite-container')!;
		if (inviteContainer.classList.contains('hidden')) {
			document.querySelector<HTMLButtonElement>('#board-menu-invite')!.click();
		}
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.closedInvite, ensureClosedAndContinue);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.invitedCollaborator, afterInvited);
		startTutorial(
			{
				disableInteraction: false,
				doneLabel: 'Skip for now',
				steps: [
					{
						title: `Add a collaborator`,
						intro: `<strong>➡ Enter the email address of the person you want to collaborate with you on this project.</strong>
<br><br>
If you prefer to work alone for now, you can skip this step (click "Next").`,
						element: '#user-search-input',
						position: 'top',
					},
					{
						title: `Send invite`,
						intro: `<strong>➡ Click 'Send Invite'</strong>
<br><br>
If you prefer to work alone for now, click "Skip for now".`,
						element: '#invite-container button.primary',
						position: 'top',
					},
				],
			},
			{
				onexit: () => {
					if (!invitedUser) {
						ensureClosedAndContinue();
					}
				},
			}
		);
	};

	const afterInvited = () => {
		invitedUser = true;
		exitTutorial();

		const inviteContainer = document.querySelector('#invite-container')!;
		if (!inviteContainer.classList.contains('hidden')) {
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.closedInvite, ensureClosedAndContinue);

			startTutorial(
				{
					disableInteraction: false,
					steps: [
						{
							title: `Invite sent`,
							intro: `Your collaborator will receive an email with a link to join you in this project.
<br><br>
<strong>➡ Click the close button</strong>`,
							element: '#invite-container .close-panel',
						},
					],
				},
				{
					onexit: () => {
						ensureClosedAndContinue();
					},
				}
			);
		} else {
			ensureClosedAndContinue();
		}
	};

	return afterOpenInvite;
};
