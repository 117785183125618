import Graph from '../graphs/Graph';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from '../tutorials/tutorialEvents';
import { BehaviorTree } from './BehaviorTree';

export default class ChatbotAssistant {
    private behaviorTree: BehaviorTree;
    private eventListeners: any[] = [];

    public currentEventData: any = {};
    public currentEventType: string = '';
    private isVisible: boolean = true;


    private widget: HTMLElement; // The main container of the chatbot
    private header: HTMLElement; // The header container of the chatbot
    private headerLogo: HTMLImageElement; // The logo of the chatbot
    private title: HTMLElement; // The title of the chatbot
    private closeButton: HTMLElement; // The close button of the chatbot
    private closeIcon: HTMLImageElement; // The close icon of the chatbot
    private messageElement: HTMLElement; // The message element of the chatbot
    public graph: Graph | undefined;

    private isDragging = false;
    private offsetX: number = 0;
    private offsetY: number = 0;
    
    private attachedEventTypes: Set<string> = new Set(); // Track attached event types

    constructor(graph: Graph | undefined) {
        this.widget = this.createWidget();
        this.attachEventListeners();
        
        this.behaviorTree = new BehaviorTree(this);
        if (graph) {
            this.graph = graph;
            this.suggest_next_step();
        }

        // Add mouseleave event listener to reset dragging
        document.addEventListener('mouseleave', this.stopDragging);
    }

    private toggleVisibility(visible: boolean): void {
        // if (visible) {
        //     this.attachEventListeners();
        // } else {
        //     this.detachEventListeners();
        // }
        console.log("ChatbotAssistant: Toggling visibility", visible);
        this.isVisible = visible;
        this.widget.classList.toggle('hidden', !visible);
        this.widget.style.pointerEvents = visible ? 'auto' : 'none';
        // if (visible) {
        //     this.updateWidget('Hi, I\'m Flowy, your AI assistant. Let\'s get started!');
        // }
    }

    private createWidget(): HTMLElement {
        this.widget = document.createElement('div');
        this.widget.id = 'chatbot-assistant-widget';
        this.widget.classList.add('flowy-widget');
        
        // Add a header container with the logo, a h3 element for the title and a close button
        this.header = document.createElement('div');
        this.header.id = 'chatbot-assistant-header';
        this.header.classList.add('flowy-header');
        this.widget.appendChild(this.header);

        this.headerLogo = document.createElement('img');
        this.headerLogo.src = '/assets/neurocreate_icon_only.png';
        this.headerLogo.classList.add('flowy-header-logo');
        this.header.appendChild(this.headerLogo);
        
        this.title = document.createElement('h2');
        this.title.classList.add('flowy-header-title');
        this.title.innerHTML = 'Flowy';
        this.header.appendChild(this.title);

         // Add a button to close the chatbot
        this.closeButton = document.createElement('button');
        this.closeIcon = document.createElement('img');
        this.closeButton.appendChild(this.closeIcon);
        this.closeButton.classList.add('flowy-button');
        this.header.appendChild(this.closeButton);

        // Add a span element for the message. This will only be visible when the chatbot is maximized
        this.messageElement = document.createElement('span');
        // this.messageElement.style.width = '100%';
        this.messageElement.id = 'chatbot-assistant-message';
        this.widget.appendChild(this.messageElement);
        
        this.maximizeChatbot();

        document.body.appendChild(this.widget);
        return this.widget;
    }

    private startDragging = (e: PointerEvent) => {
        this.isDragging = true;
        const rect = this.widget.getBoundingClientRect();
        this.offsetX = e.clientX - rect.left;
        this.offsetY = e.clientY - rect.top;
        this.widget.style.transition = 'none'; // Disable smooth transition while dragging
    };

    private stopDragging = () => {
        this.isDragging = false;
        this.widget.style.transition = ''; // Re-enable smooth transition
    };

    private drag = (e: PointerEvent) => {
        if (!this.isDragging) return;
        
        const x = e.clientX - this.offsetX;
        const y = e.clientY - this.offsetY;

        // Get the dimensions of the widget
        const widgetWidth = this.widget.offsetWidth;
        const widgetHeight = this.widget.offsetHeight;

        // Calculate the new position, ensuring it stays within the screen boundaries
        const boundedX = Math.max(0, Math.min(x, window.innerWidth - widgetWidth));
        const boundedY = Math.max(0, Math.min(y, window.innerHeight - widgetHeight)); // Use y directly for top position


        this.widget.style.left = `${boundedX}px`;
        this.widget.style.top = `${boundedY}px`;
        // this.widget.style.hei
        this.widget.style.transform = 'none'; // Remove the initial transform
    };

    private setCloseButtonOpen() {
        this.closeIcon.src = '/assets/icon/board/close.png';
        this.closeButton.classList.add('close-panel');
        // this.closeButton.classList.remove('flowy-close-button-closed');
        this.closeButton.style.transform = 'rotate(0deg)';
        this.closeButton.style.transition = 'transform 0.3s ease-in-out';
    }

    private setCloseButtonClosed() {
        this.closeIcon.src = '/assets/icon/board/close.png';
        
        // this.closeButton.classList.remove('close-panel');
        // this.closeButton.classList.add('flowy-close-button-closed');
        // Rotate the close button by 45 degrees
        this.closeButton.style.transform = 'rotate(45deg)';
        this.closeButton.style.transition = 'transform 0.3s ease-in-out';
    }
    
    public minimizeChatbot() {
        // Move the chatbot to the bottom left corner of the screen
        this.widget.style.left = `16em`;
        // Top position is the bottom of the screen minus 2em minus the height of the widget
        const offset = 28 + 30;
        this.widget.style.top = `${window.innerHeight - offset}px`;

        // Turn the chatbot into a small round pill
        this.widget.classList.remove('flowy-widget-maximized');
        
        this.widget.classList.add('flowy-widget-minimized');
        
        // Hide all text
        this.messageElement.style.visibility = 'hidden';
        // this.title.style.visibility = 'hidden';

         // Fade out all text and buttons
        this.messageElement.classList.add('fade-out');

        this.widget.removeEventListener('pointerdown', this.startDragging);
        this.widget.removeEventListener('pointermove', this.drag);
        this.widget.removeEventListener('pointerup', this.stopDragging);
        this.widget.removeEventListener('pointercancel', this.stopDragging);

        this.setCloseButtonClosed();
        this.closeButton.addEventListener('click', () => {
            this.maximizeChatbot();
        });

        this.headerLogo.onclick = this.headerClickEventListener;
        this.title.onclick = this.headerClickEventListener;

        // Maximize the chatbot when the header is clicked
        // this.header.addEventListener('click', this.headerClickEventListener);
        
        this.showHideButtons(false);
        
    }

    private headerClickEventListener = () => {
        this.maximizeChatbot();
    }

    public maximizeChatbot() {
        
        // this.toggleVisibility(true);

        this.headerLogo.onclick = null;
        this.title.onclick = null;
        // Move the chatbot to the center of the screen (a bit below the center to not cover the currently selected node)
        

        this.widget.classList.remove('flowy-widget-minimized');
        this.widget.classList.add('flowy-widget-maximized');
        
        
        this.messageElement.classList.add('fade-in');
        this.messageElement.style.visibility = 'visible';
        
        this.closeButton.classList.add('fade-in');
        this.showHideButtons(true);

        

        this.widget.addEventListener('pointerdown', this.startDragging);
        this.widget.addEventListener('pointermove', this.drag);
        this.widget.addEventListener('pointerup', this.stopDragging);
        this.widget.addEventListener('pointercancel', this.stopDragging);
    
        this.setCloseButtonOpen();
        this.closeButton.addEventListener('click', () => {
			// Minimize the chatbot
            // The chatbot becomes a small round bubble with the logo of the tool
            this.minimizeChatbot();
		});
        this.widget.style.left = `50%`;
        
        
        this.updateWidgetHeight();
        setTimeout(() => {
            this.updateWidgetHeight();
        }, 500);
    
    }



    private attachEventListeners(): void {
        Object.values(TUTORIAL_EVENT_TYPES).forEach(eventType => {
            if (!this.attachedEventTypes.has(eventType)) { // Check if listener is already attached
                const listener = (data: any) => {
                    this.handleEvent(eventType, data);
                };
                tutorialEmitter.on(eventType, listener);
                this.eventListeners.push(listener); // Store the listener for detachment
                this.attachedEventTypes.add(eventType); // Mark this event type as attached
            }
        });
    }

    private detachEventListeners(): void {
        this.eventListeners.forEach((listener, index) => {
            const eventType = Object.values(TUTORIAL_EVENT_TYPES)[index];
            tutorialEmitter.off(eventType, listener);
        });
        this.eventListeners = []; // Clear the stored listeners
        this.attachedEventTypes.clear(); // Clear the attached event types
    }

    public addButton(text: string, onClick: () => void): void {
        const button = document.createElement('button');
        button.innerHTML = text;
        button.style.cssText = `
            margin-top: 8px;
            width: 100%;
            padding: 8px;
            border-radius: 6px;
            border: 1px solid #ccc;
            background-color: #007bff;
            color: #fff;
        `;
        button.addEventListener('click', onClick);
        this.widget.appendChild(button);
        this.updateWidgetHeight();
    }

    private handleEvent(eventType: string, data: any): void {
        console.log("ChatbotAssistant: Handling event", eventType, data);
        // Update current state based on the event
        // this.currentState = {eventType}, Data: ${JSON.stringify(data)}`;
        this.currentEventType = eventType;  
        this.currentEventData = data;
        // console.log("ChatbotAssistant: Handling event", eventType, data);
        
        if (eventType === TUTORIAL_EVENT_TYPES.disableChatbot) {
            this.minimizeChatbot();
        } else if (eventType === TUTORIAL_EVENT_TYPES.enableChatbot) {
            this.maximizeChatbot();
            return;
        }

        console.log("ChatbotAssistant: isVisible", this.isVisible, this.graph?.getHintsShown());

        if (this.isVisible) {
            // Only suggest next step if hints have been shown
            if (eventType !== TUTORIAL_EVENT_TYPES.closedInvite) {
                // Right after finishing the tutorial, we don't want to suggest next steps
                this.suggest_next_step();
            }
        } else {
            if (eventType === TUTORIAL_EVENT_TYPES.addedWordsFromPanel) {
                console.log("ChatbotAssistant: Adding words from panel", data);
                this.toggleVisibility(true);
                    // this.suggest_next_step();
            }
        }
    }


    private async suggest_next_step(): Promise<void> {
        console.log("ChatbotAssistant: Suggesting next step", this.currentEventType, this.currentEventData);
        this.behaviorTree.handleUserAction();
          
    }

    public updateWidget(message: string): void {
        this.clearButtons();
        this.messageElement.innerHTML = message;
        this.updateWidgetHeight();
    }

    private clearButtons(): void {
        const buttons = this.widget.querySelectorAll('button');
        buttons.forEach(button => {
            // Don't remove the close button
            if (!button.classList.contains('close-panel') && !button.classList.contains('flowy-close-button-closed')) {
                button.remove();
            }
        });
    }

    public updateWidgetHeight(): void {
        // Do not update if Flowy is minimized
        if (this.widget.classList.contains('flowy-widget-minimized')) {
            return;
        }
        const widgetHeight = this.widget.getBoundingClientRect().height;
        this.widget.style.top = `${window.innerHeight - widgetHeight - 10}px`;
        
    }

    private showHideButtons(visible: boolean): void {
        const buttons = this.widget.querySelectorAll('button');
        buttons.forEach(button => {
            // Don't remove the close button
            if (!button.classList.contains('close-panel') && !button.classList.contains('flowy-close-button-closed')) {
                button.style.visibility = visible ? 'visible' : 'hidden';
            }
        });
        this.updateWidgetHeight();
    }
}

// Usage
// export const chatbotAssistant = new ChatbotAssistant();

