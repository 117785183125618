import {
	GenerateTask,
	LikedItem,
	SearchTask,
	SynthesiseSubTask,
	SynthesiseTask,
	TargetAudience,
} from '../../../../src/commonTypes';
import { capitalizeFirstLetter } from '../../../../src/commonMisc';

const convertToTitleCase = (input: string): string => {
	const words = input.split('-');

	for (let i = 0; i < words.length; i++) {
		words[i] = capitalizeFirstLetter(words[i]);
	}

	return words.join(' ');
};

export const getTaskTitle = (item: LikedItem): string => {
	const taskName = getTaskReadableName(item.type);

	const shortSummary = getTaskShortSummary(item);

	if (shortSummary) {
		return `${taskName} - ${shortSummary}`;
	}
	return taskName;
};

function truncate(str: string, n: number) {
	return str.length > n ? str.slice(0, n - 1) + '…' : str;
}

export const getTaskShortSummary = (item: LikedItem): string => {
	switch (item.type) {
		case 'audience': {
			const audience = JSON.parse(item.text!) as TargetAudience;
			return audience.name;
		}
		case 'brainstorm': {
			const [summary] = item.text!.split(':');
			return summary;
		}
		case 'taglines':
			return item.text ? truncate(item.text, 45) : '';
		default:
			return item.text ? truncate(item.text, 35) : '';
	}
};

export const getTaskReadableName = (
	taskId: GenerateTask | SynthesiseTask | SynthesiseSubTask | SearchTask | 'node' | 'cluster'
): string => {
	switch (taskId) {
		case 'image-synthesise':
			return 'Image';

		case 'synopsis':
			return 'Story Synopsis';
		case 'brainstorm':
			return 'Brainstorming';
		case 'audience':
			return 'Target Audiences';

		case 'pestel-p':
			return 'PESTEL: Political';
		case 'pestel-e':
			return 'PESTEL: Economic';
		case 'pestel-s':
			return 'PESTEL: Social';
		case 'pestel-t':
			return 'PESTEL: Technological';
		case 'pestel-env':
			return 'PESTEL: Environmental';
		case 'pestel-l':
			return 'PESTEL: Legal';

		case 'swot-s':
			return 'SWOT: Strengths';
		case 'swot-w':
			return 'SWOT: Weaknesses';
		case 'swot-o':
			return 'SWOT: Opportunities';
		case 'swot-t':
			return 'SWOT: Threats';

		case 'scamper-s':
			return 'SCAMPER: Substitute';
		case 'scamper-c':
			return 'SCAMPER: Combine';
		case 'scamper-a':
			return 'SCAMPER: Adapt';
		case 'scamper-m':
			return 'SCAMPER: Modify';
		case 'scamper-p':
			return 'SCAMPER: Put to another use';
		case 'scamper-e':
			return 'SCAMPER: Eliminate';
		case 'scamper-r':
			return 'SCAMPER: Reverse';

		case 'taglines':
			return 'Taglines';
		case 'linkedinpost':
			return 'Post';

		default:
			return convertToTitleCase(taskId);
	}
};

export const isPlural = (taskId: GenerateTask | SynthesiseTask | SynthesiseSubTask | SearchTask | 'node' | 'cluster') => {
	return ["taglines", "audience"].includes(taskId);
}
