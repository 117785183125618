const form = document.querySelector<HTMLFormElement>("form[method='post']");

if (form) {
	form.addEventListener('submit', (event) => {
		if (event.preventDefault) {
			event.preventDefault();
		}

		const formData = new FormData(form);
		// For files, use their names
		const convertedFormEntries = Array.from(formData, ([key, value]) => [
			key,
			typeof value === 'string' ? value : value.name,
		]);
		const body = new URLSearchParams(convertedFormEntries);

		fetch(form.action, {
			method: 'post',
			body,
		}).then(async (response) => {
			const json = (await response.json()) as { message: string; redirect?: string };
			if (json.redirect) {
				const urlParams = new URLSearchParams(window.location.search);
				const redirectPath = urlParams.get('r');
				window.location.href = redirectPath || json.redirect;
			} else {
				const errorMessage = document.querySelector<HTMLParagraphElement>('p.form-error')!;
				errorMessage.innerText = json.message;
				if (response.status < 400) {
					errorMessage.classList.add('success');
				} else {
					errorMessage.classList.remove('success');
				}
			}
		});
	});
}
