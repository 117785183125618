import { NCNode, NCLink, NCLinkMinimal, Point } from '../../../../src/commonTypes';
import { intersection } from './geometryUtils';

export const isSameLink = (l1: NCLink | NCLinkMinimal, l2: NCLink | NCLinkMinimal) => {
	return (
		(l1.source.uid === l2.source.uid && l1.target.uid === l2.target.uid) ||
		(l1.target.uid === l2.source.uid && l1.source.uid === l2.target.uid)
	);
};

export const isSameLinkUnidirectional = (
	l1: NCLink,
	l2: { source: Point & { uid: string }; target: Point & { uid: string } }
) => {
	return l1.source.uid === l2.source.uid && l1.target.uid === l2.target.uid;
};

export const isNodeInLink = (node: NCNode, link: NCLink) => {
	return link.source.uid === node.uid || link.target.uid === node.uid;
};

export const getLinksInvolvingNode = (node: NCNode, links: Array<NCLink>): Array<NCLink> => {
	return links.filter((l) => {
		return l.source.uid === node.uid || l.target.uid === node.uid;
	});
};

export const getLinksNotInvolvingNode = (node: NCNode, links: Array<NCLink>): Array<NCLink> => {
	return links.filter((l) => {
		return l.source.uid !== node.uid || l.target.uid !== node.uid;
	});
};

export const minimizeLinks = (links: NCLink[]): NCLinkMinimal[] => {
	return links.map((l) => {
		return {
			source: { uid: l.source.uid },
			target: { uid: l.target.uid },
			colour: l.colour,
		};
	});
};

export const dedupeLinks = (links: NCLink[]): NCLink[] => {
	return links.filter((l, index) => {
		return !links.some((l2, index2) => isSameLink(l, l2) && index > index2);
	});
};

export const areLinksCrossing = (
	link: NCLink,
	link2: { source: Point & { uid: string }; target: Point & { uid: string } }
) => {
	if (isSameLinkUnidirectional(link, link2)) {
		// Same link, so not crossing
		return false;
	}
	if (
		link.target.uid === link2.target.uid ||
		link.source.uid === link2.source.uid ||
		link.source.uid === link2.target.uid ||
		link2.source.uid === link.target.uid
	) {
		// Link shares an end node, so cannot possibly be crossing
		return false;
	}

	const intersectionPoint = intersection(link.source, link.target, link2.source, link2.target);
	return !!intersectionPoint;
};

export const numberOfCrossingLinks = (
	link: { source: Point & { uid: string }; target: Point & { uid: string } },
	links: Array<NCLink>
): number => {
	return links.filter((l) => areLinksCrossing(l, link)).length;
};
