import { updateBoardTitle } from '../apiRequests';
import { showAlert } from './uiUtils';
import { isBoardOwner } from './toolsUI';

const boardTitleForm = document.querySelector('#board-title-form');
if (boardTitleForm) {
	const boardTitleInput = boardTitleForm.querySelector('input')!;
	const changeUntitledBoardColour = () => {
		boardTitleInput.dataset.state = 'invalid';
	};
	const initialBoardTitle = boardTitleInput.getAttribute('value')!;
	boardTitleInput.value = initialBoardTitle;
	if (initialBoardTitle === 'Untitled Board') {
		changeUntitledBoardColour();
	}
	boardTitleForm.addEventListener('submit', (e) => {
		e.preventDefault();

		if (!isBoardOwner()) {
			showAlert('Only the owner can rename the board');
			return;
		}

		const newBoardTitle = boardTitleInput.value.trim();
		if (newBoardTitle === '') {
			boardTitleInput.value = 'Untitled Board';
			changeUntitledBoardColour();
		} else {
			boardTitleInput.value = newBoardTitle;
			boardTitleInput.dataset.state = undefined;
		}
		boardTitleInput.blur();
		updateBoardTitle(document.body.dataset.boardId!, newBoardTitle).then(() => {
			// Do nothing, update will be via shareDB
		});
	});
}
