import { PresentationInfo } from '../../../../../src/commonTypes';
import { createLabel, createPreviewParagraph } from './utils';

export const getTitleSlideContent = (presentationInfo: PresentationInfo) => {
	const graph = globalThis.neuroCreate.graph!;

	const titleContent = document.createElement('div');
	titleContent.append(createLabel('Presentation title'));
	titleContent.append(createPreviewParagraph(graph.boardTitle));

	titleContent.append(createLabel('Author'));
	if (presentationInfo.fullName) {
		titleContent.append(createPreviewParagraph(presentationInfo.fullName));
	} else {
		const link = document.createElement('a');
		link.href = '/settings/general';
		link.innerText = 'Add your name in settings';
		const p = createPreviewParagraph('Missing ');
		titleContent.append(p);
		p.append(link);
	}

	titleContent.append(createLabel('Date'));
	titleContent.append(createPreviewParagraph(presentationInfo.date));

	return titleContent;
};
