import JsPDF from 'jspdf';
import { finishLoading, startLoading } from '../ui/uiUtils';
import { exportNotepad } from './pdfNotepad';
import { exportIdeabox } from './pdfIdeabox';
import { exportSpecificBoard } from './pdfBoard';

export type PageName =
	| 'inspire'
	| 'ideate-team'
	| 'ideate-personal'
	| 'moodboard-team'
	| 'moodboard-personal'
	| 'ideabox'
	| 'notepad-team'
	| 'notepad-personal';

const getBoardName = (page: PageName): string => {
	switch (page) {
		case 'ideabox':
			return 'Idea Box';
		case 'notepad-personal':
			return 'Notepad (Personal)';
		case 'notepad-team':
			return 'Notepad (Team)';
		case 'inspire':
			return 'Inspire Board';
		case 'ideate-personal':
			return 'Ideate Board (Personal)';
		case 'ideate-team':
			return 'Ideate Board (Team)';
		case 'moodboard-personal':
			return 'Synthesise Board (Personal)';
		case 'moodboard-team':
			return 'Synthesise Board (Team)';
	}
};

const getBoardNameSimplified = (page: PageName): string => {
	switch (page) {
		case 'ideabox':
			return 'Idea Box';
		case 'notepad-personal':
			return 'Notepad';
		case 'inspire':
			return 'Inspire Board';
		case 'ideate-personal':
			return 'Ideate Board';
		case 'moodboard-personal':
			return 'Synthesise Board';
		default:
			return '';
	}
};

const getFileName = (boardName: string, pages: Array<PageName>): string => {
	return `${boardName} - ${pages
		.map((p) => getBoardNameSimplified(p))
		.filter((n) => Boolean(n))
		.join(', ')}`;
};

// Filters out pages that have no content (mutates the array)
const filterExportPages = (pages: Array<PageName>): void => {
	if (pages.length > 1) {
		// Filters are only applied when more than one page was selected

		if (
			!globalThis.neuroCreate.doc!.data.inspire.data.nodes.some(
				(g) => g.colour !== globalThis.neuroCreate.graph!.currentUser.colour
			)
		) {
			// No team nodes exist so remove Ideate Team page
			const index = pages.indexOf('ideate-team');
			if (index >= 0) {
				pages.splice(index, 1);
			}
		}

		if (globalThis.neuroCreate.doc!.data.moodboard.data.nodes.length === 0) {
			// No team nodes exist so remove Synthesise Team page
			const index = pages.indexOf('moodboard-team');
			if (index >= 0) {
				pages.splice(index, 1);
			}

			// Also remove team notepad
			const indexN = pages.indexOf('notepad-team');
			if (indexN >= 0) {
				pages.splice(indexN, 1);
			}
		}
	}
};

export const exportAll = async (
	pages: Array<PageName> = [
		'inspire',
		'ideate-personal',
		'ideate-team',
		'moodboard-personal',
		'moodboard-team',
		'ideabox',
		'notepad-personal',
		'notepad-team',
	]
) => {
	console.time('exportAll');
	const priorGraphMode = globalThis.neuroCreate.graph!.graphMode;
	const priorIsCollaborative = globalThis.neuroCreate.graph!.isCollaborative;

	const boardName = document.querySelector<HTMLInputElement>('#board-title-form input')!.value;
	let promise = Promise.resolve();
	const doc: JsPDF = new JsPDF({ orientation: 'landscape' });
	doc.setFontSize(20);
	doc.setTextColor('#aaa');
	doc.text(`Export from FlowCreate™ web app`, 35, 65);
	doc.setTextColor('#333');
	doc.setFontSize(36);
	doc.text(boardName, 35, 85);
	doc.setFontSize(12);

	doc.text(
		`Your export includes the following pages:\n\n${pages
			.map((page) => `\u2022 ${getBoardName(page)}`)
			.join('\n')}`,
		35,
		105
	);

	filterExportPages(pages);

	for (const page of pages) {
		const boardName = getBoardName(page);
		if (page === 'ideabox') {
			promise = promise.then(() => exportIdeabox(doc, boardName));
		} else if (page.includes('notepad')) {
			promise = promise.then(() => exportNotepad(page, boardName, doc));
		} else {
			promise = promise.then(() => exportSpecificBoard(page, boardName, doc));
		}
	}
	await promise;

	await startLoading('Generating PDF...', true);
	await doc.save(getFileName(boardName, pages), {
		returnPromise: true,
	});
	finishLoading();

	globalThis.neuroCreate.switchToMode!(priorGraphMode, priorIsCollaborative, false);
	console.timeEnd('exportAll');
};
