import Graph from '../graphs/Graph';
import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from '../tutorials/tutorialEvents';
import { BehaviorTree } from './BehaviorTree';

export class ChatbotAssistant {
    private behaviorTree: BehaviorTree;
    private eventListeners: any[] = [];

    public currentEventData: any = {};
    public currentEventType: string = '';
    private isVisible: boolean = false;
    private creationTime: number = Date.now();

    private availableTime: string = 'short';


    // Goals depending on how much time the user has
    private goals: { [key: string]: { [key: string]: number } } = {
        "short": {
            "nodesCreated": 10,
            "conceptsLiked": 3,
            "sparksUsed": 3,
            "empathiseUsed": 2,
            "generateUsed": 2,
            "clustersCreated": 1,
            "ideasGenerated": 1,
            "userPersonasCreated": 1,
            "stakeholderMotivationsExplored": 1,
        }, 
        "medium": {
            "nodesCreated": 20,
            "conceptsLiked": 5,
            "sparksUsed": 5,
            "empathiseUsed": 3,
            "generateUsed": 3,
            "clustersCreated": 2,
            "ideasGenerated": 2,
            "userPersonasCreated": 2,
            "stakeholderMotivationsExplored": 2,
        }, 
        "long": {
            "nodesCreated": 25,
            "conceptsLiked": 8,
            "sparksUsed": 8,
            "empathiseUsed": 5,
            "generateUsed": 5,
            "clustersCreated": 3,
            "ideasGenerated": 3,
            "userPersonasCreated": 3,
            "stakeholderMotivationsExplored": 3,
        },
    };

    private title: HTMLElement;
    private widget: HTMLElement;
    private messageElement: HTMLElement;
    private closeButton: HTMLElement;
    private closeIcon: HTMLImageElement;
    public graph: Graph | undefined;

    private isDragging = false;
    private offsetX: number = 0;
    private offsetY: number = 0;

    private attachedEventTypes: Set<string> = new Set(); // Track attached event types

    constructor(graph: Graph | undefined) {
        this.widget = this.createWidget();
        this.attachEventListeners();
        
        this.behaviorTree = new BehaviorTree(this);
        if (graph) {
            this.graph = graph;
            this.suggest_next_step();
            
        }
    }

    private toggleVisibility(visible: boolean): void {
        // if (visible) {
        //     this.attachEventListeners();
        // } else {
        //     this.detachEventListeners();
        // }
        console.log("ChatbotAssistant: Toggling visibility", visible);
        this.isVisible = visible;
        this.widget.classList.toggle('hidden', !visible);
        this.widget.style.pointerEvents = visible ? 'auto' : 'none';
        // if (visible) {
        //     this.updateWidget('Hi, I\'m Flowy, your AI assistant. Let\'s get started!');
        // }
    }

    private createWidget(): HTMLElement {
        this.widget = document.createElement('div');
        this.widget.id = 'chatbot-assistant-widget';
        
        // Add a h3 element for the title
        this.title = document.createElement('h3');
        this.title.innerHTML = 'Flowy';
        this.widget.appendChild(this.title);

        // Add a span element for the message
        this.messageElement = document.createElement('span');
        // this.messageElement.style.width = '100%';
        this.messageElement.id = 'chatbot-assistant-message';
        this.widget.appendChild(this.messageElement);
        
        // Add a button to close the chatbot
        this.closeButton = document.createElement('button');
		this.closeIcon = document.createElement('img');
		this.closeButton.appendChild(this.closeIcon);
		this.widget.appendChild(this.closeButton);

        this.maximizeChatbot();
        
        const startDragging = (e: PointerEvent) => {
            this.isDragging = true;
            const rect = this.widget.getBoundingClientRect();
            this.offsetX = e.clientX - rect.left;
            this.offsetY = e.clientY - rect.top;
            this.widget.style.transition = 'none'; // Disable smooth transition while dragging
        };

        const stopDragging = () => {
            this.isDragging = false;
            this.widget.style.transition = ''; // Re-enable smooth transition
        };

        const drag = (e: PointerEvent) => {
            if (!this.isDragging) return;
            
            const x = e.clientX - this.offsetX;
            const y = e.clientY - this.offsetY;

            // Get the dimensions of the widget
            const widgetWidth = this.widget.offsetWidth;
            const widgetHeight = this.widget.offsetHeight;

            // Calculate the new position, ensuring it stays within the screen boundaries
            const boundedX = Math.max(0, Math.min(x, window.innerWidth - widgetWidth));
            const boundedY = Math.max(0, Math.min(y, window.innerHeight - widgetHeight)); // Use y directly for top position


            this.widget.style.left = `${boundedX}px`;
            this.widget.style.top = `${boundedY}px`;
            // this.widget.style.hei
            this.widget.style.transform = 'none'; // Remove the initial transform
        };

        this.widget.addEventListener('pointerdown', startDragging);
        this.widget.addEventListener('pointermove', drag);
        this.widget.addEventListener('pointerup', stopDragging);
        this.widget.addEventListener('pointercancel', stopDragging);
    
        document.body.appendChild(this.widget);
        return this.widget;
    }

    private setCloseButtonOpen() {
        this.closeIcon.src = '/assets/icon/board/close.png';
        this.closeButton.classList.add('close-panel');
        this.closeButton.classList.remove('flowy-close-button-closed');
        this.closeButton.style.transform = 'rotate(0deg)';
        this.closeButton.style.transition = 'transform 0.3s ease-in-out';
    }

    private setCloseButtonClosed() {
        this.closeIcon.src = '/assets/icon/board/close.png';
        
        // this.closeButton.classList.remove('close-panel');
        this.closeButton.classList.add('flowy-close-button-closed');
        // Rotate the close button by 45 degrees
        this.closeButton.style.transform = 'rotate(45deg)';
        this.closeButton.style.transition = 'transform 0.3s ease-in-out';
    }
    
    public minimizeChatbot() {
        // Make the chatbot a small round bubble with the logo of the tool
        this.widget.classList.remove('flowy-widget-maximized');
        this.widget.classList.add('flowy-widget-minimized');
        // Hide all text
        this.messageElement.style.visibility = 'hidden';
        this.title.style.visibility = 'hidden';

         // Fade out all text and buttons
        this.messageElement.classList.add('fade-out');
        this.title.classList.add('fade-out');

        

        this.setCloseButtonClosed();
        this.closeButton.addEventListener('click', () => {
            this.maximizeChatbot();
        });
        this.showHideButtons(false);
        
    }
    maximizeChatbot() {
        this.toggleVisibility(true);
        this.widget.classList.add('flowy-widget-maximized');
        this.widget.classList.remove('flowy-widget-minimized');
        
        this.messageElement.classList.add('fade-in');
        this.title.classList.add('fade-in');
        this.closeButton.classList.add('fade-in');
        this.messageElement.style.visibility = 'visible';
        this.title.style.visibility = 'visible';    


        this.setCloseButtonOpen();
        this.closeButton.addEventListener('click', () => {
			// Minimize the chatbot
            // The chatbot becomes a small round bubble with the logo of the tool
            this.minimizeChatbot();
		});
        this.showHideButtons(true);
    }



    private attachEventListeners(): void {
        Object.values(TUTORIAL_EVENT_TYPES).forEach(eventType => {
            if (!this.attachedEventTypes.has(eventType)) { // Check if listener is already attached
                const listener = (data: any) => {
                    this.handleEvent(eventType, data);
                };
                tutorialEmitter.on(eventType, listener);
                this.eventListeners.push(listener); // Store the listener for detachment
                this.attachedEventTypes.add(eventType); // Mark this event type as attached
            }
        });
        // console.log("ChatbotAssistant: Attached event listeners", this.eventListeners);
    }

    private detachEventListeners(): void {
        this.eventListeners.forEach((listener, index) => {
            const eventType = Object.values(TUTORIAL_EVENT_TYPES)[index];
            tutorialEmitter.off(eventType, listener);
        });
        this.eventListeners = []; // Clear the stored listeners
        this.attachedEventTypes.clear(); // Clear the attached event types
    }

    public addButton(text: string, onClick: () => void): void {
        const button = document.createElement('button');
        button.innerHTML = text;
        button.style.cssText = `
            margin-top: 8px;
            width: 100%;
            padding: 8px;
            border-radius: 6px;
            border: 1px solid #ccc;
            background-color: #007bff;
            color: #fff;
        `;
        button.addEventListener('click', onClick);
        this.widget.appendChild(button);
    }

    private handleEvent(eventType: string, data: any): void {
        console.log("ChatbotAssistant: Handling event", eventType, data);
        // Update current state based on the event
        // this.currentState = {eventType}, Data: ${JSON.stringify(data)}`;
        this.currentEventType = eventType;  
        this.currentEventData = data;
        // console.log("ChatbotAssistant: Handling event", eventType, data);
        
        if (eventType === TUTORIAL_EVENT_TYPES.disableChatbot) {
            this.toggleVisibility(false);
        } else if (eventType === TUTORIAL_EVENT_TYPES.enableChatbot) {
            this.toggleVisibility(true);
            return;
        }

        console.log("ChatbotAssistant: isVisible", this.isVisible, this.graph?.getHintsShown());

        if (this.isVisible) {
            // Only suggest next step if hints have been shown
            if (eventType !== TUTORIAL_EVENT_TYPES.closedInvite) {
                // Right after finishing the tutorial, we don't want to suggest next steps
                this.suggest_next_step();
            }
        } else {
            if (eventType === TUTORIAL_EVENT_TYPES.addedWordsFromPanel) {
                console.log("ChatbotAssistant: Adding words from panel", data);
                this.toggleVisibility(true);
                    // this.suggest_next_step();
            }
        }
    }


    private async suggest_next_step(): Promise<void> {
        console.log("ChatbotAssistant: Suggesting next step", this.currentEventType, this.currentEventData);
        this.behaviorTree.handleUserAction();
          
    }

    public updateWidget(message: string): void {
        this.clearButtons();
        this.messageElement.innerHTML = message;
    }

    private clearButtons(): void {
        const buttons = this.widget.querySelectorAll('button');
        buttons.forEach(button => {
            // Don't remove the close button
            if (!button.classList.contains('close-panel') && !button.classList.contains('flowy-close-button-closed')) {
                button.remove();
            }
        });
    }

    private showHideButtons(visible: boolean): void {
        const buttons = this.widget.querySelectorAll('button');
        buttons.forEach(button => {
            // Don't remove the close button
            if (!button.classList.contains('close-panel') && !button.classList.contains('flowy-close-button-closed')) {
                button.style.visibility = visible ? 'visible' : 'hidden';
            }
        });
    }
}

// Usage
// export const chatbotAssistant = new ChatbotAssistant();

