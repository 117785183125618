import { isHeartedListOpen, openHeartedList } from '../ui/ideaBox';
import { openNotepad } from '../ui/notepad';
import {
	endTutorial,
	isTutorialActive,
	startTutorial,
	openCollapsedMenuForTutorial,
} from './tutorial';
import { ensureMenuIsOpen } from '../ui/menuUi';

export const startSynthesiseTutorial = () => {
	if (isTutorialActive('moodboard')) {
		if (!isHeartedListOpen()) {
			openHeartedList();
		}
		startTutorial(
			{
				tooltipClass: 'synthesiseTutorial',
				steps: [
					{
						title: 'Tutorial 👋',
						intro: 'Welcome to Synthesise mode tutorial ✨',
					},
					{
						title: 'Synthesise',
						element: '#hearted-container',
						intro:
							'You can check the contents that you have clicked the like button for in Idea Box.<br><br>You can click the ‘+’ icon in the top right corner of each content to add them to the board.',
					},
					{
						title: 'Personal and Team',
						element: '#synthesise-switch',
						intro:
							'The contents on the Personal board are only visible to you. <br><br>Team board is where you can share the contents with your team members. Share from your personal board or add directly.',
					},
					{
						title: 'Notepad',
						element: '#notepad-button',
						intro:
							'On the Personal board, the notepad is only visible to you. <br><br>On the Team board, the notepad allows you and other collaborators to create and share notes together.',
					},
					{
						title: 'Sharing Notepad to Team',
						element: '#share-note',
						intro:
							'You can share your notepad from Personal to Team. Select text or image you would like to share with your team in the note and click sharing icon ( <span class="shareNotePadIcon"></span> ).',
					},
					{
						title: 'Complete 🎉',
						intro: 'Synthesise mode tutorial has been completed!',
					},
				],
			},
			{
				onexit: endTutorial,
				onbeforechange: ensureMenuIsOpen,
				onafterchange: function (target: HTMLElement) {
					if (target.id === 'synthesise-switch') {
						openCollapsedMenuForTutorial(
							document.querySelector('#local-menu #notepad-button')!,
							document.querySelector('#local-menu .expand-menu')!
						);
					}
					if (target.id === 'notepad-button') {
						openNotepad();
					}
				},
			}
		);
	}
};
