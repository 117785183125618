import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('admin-feature')
export class AdminFeature extends LitElement {
	static styles = css`
		div.admin-feature {
			background-color: #fed;
			border: #bb160d 1px solid;
			border-radius: 0.5em;
			padding: 0.5em 1em;
		}

		div.admin-feature > h5 {
			margin: 0 0 0.5em 0;
		}
	`;

	render() {
		return html`<div class="admin-feature">
			<h5>Admin-only feature</h5>
			<slot></slot>
		</div>`;
	}
}
