import { finishLoading } from '../../lib/ui/uiUtils';
import { updateUserFullName } from '../../lib/apiRequests';

const fullNameForm = document.querySelector('#full-name');
if (fullNameForm) {
	const submitButton = fullNameForm.querySelector('button')!;
	const nameInput = fullNameForm.querySelector('input')!;
	const loadingIndicator = fullNameForm.querySelector('.loading-indicator')!;
	submitButton.addEventListener('click', (e) => {
		e.preventDefault();

		loadingIndicator.classList.remove('hidden');
		updateUserFullName(document.body.dataset.userId!, nameInput.value)
			.then(() => {
				submitButton.setAttribute('disabled', 'true');
				loadingIndicator.classList.add('hidden');
			})
			.catch((err) => {
				console.error(err);
			});
	});
	nameInput.addEventListener('input', () => {
		submitButton.removeAttribute('disabled');
	});
}

finishLoading();
