import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from './tutorialEvents';
import {
	deactivateTutorialButton,
	isTutorialActive,
	startTutorial,
	updatePosition,
	exitTutorial,
} from './tutorial';
import { closeAlert, showAlert } from '../ui/uiUtils';
import { heartedListScrollTo, openHeartedList } from '../ui/ideaBox';
import { ClusterProps } from '../../types';
import { NCNode } from '../../../../src/commonTypes';
import { ensureMenuIsOpen } from '../ui/menuUi';

const templateId = 'branding';

export const startBrandingTutorialSynthesise = () => {
	if (isTutorialActive('moodboard', templateId)) {
		tutorialEmitter.removeAllListeners();

		// Ensure idea box is open for next tutorial step
		openHeartedList();

		startTutorial(
			{
				tooltipClass: 'welcomeTutorial',
				doneLabel: "Let's go",
				steps: [
					{
						title: 'Branding tutorial - Synthesise',
						tooltipClass: 'wide-tooltip',
						intro: `Finally, let's explore Synthesise mode for our branding board!`,
					},
				],
			},
			{
				onexit: () => {
					ideaBox();
				},
			}
		);
	}
};

const ideaBox = () => {
	if (isTutorialActive('moodboard', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Synthesise',
						element: '#hearted-container',
						disableInteraction: true,
						intro:
							'You can check the contents that you have clicked the like button for in Idea Box.<br><br>You can click the ‘+’ icon in the top right corner of each content to add them to the board.',
					},
					{
						title: 'Add to board',
						element: '#hearted-container .node-container',
						disableInteraction: false,
						intro: `<strong>➡ Click the '+' button to add 'Whiskey' to the board.</strong>`,
					},
				],
			},
			{
				onafterchange: (target) => {
					if (target.classList.contains('node-container')) {
						heartedListScrollTo('node');
						updatePosition();
					}
				},
			}
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, addedWhiskey);
	}
};

const addedWhiskey = () => {
	const graph = globalThis.neuroCreate.graph;
	if (isTutorialActive('moodboard', templateId) && graph) {
		const whiskeyNode = graph.getNodeWithLabel('Whiskey');
		if (!whiskeyNode) {
			exitTutorial();
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, addedWhiskey);
			showAlert("Please add 'Whiskey' to the board to proceed with the tutorial");
		} else {
			graph.setHintsShown();
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Add another',
						element: '#hearted-container .node-container',
						disableInteraction: false,
						intro: `<strong>➡ Click the '+' button to add 'memoirs' to the board.</strong>`,
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, addedMemoirs);
		}
	}
};

const addedMemoirs = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		const memoirsNode = globalThis.neuroCreate.graph.getNodeWithLabel('memoirs');
		if (!memoirsNode) {
			exitTutorial();
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, addedMemoirs);
			showAlert("Please add 'memoirs' to the board to proceed with the tutorial");
		} else {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Connect the nodes',
						element: '.menu .arc[data-action=connect]',
						intro: `<strong>➡ Click 'connect' to start connecting 'memoirs' to 'Whiskey'.</strong>`,
						position: 'left',
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedConnect, clickedConnect);
		}
	}
};

const clickedConnect = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		closeAlert();
		const node = globalThis.neuroCreate.graph.getNodeWithLabel('Whiskey')!;
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Choose another node`,
					intro: `<strong>➡ Click the 'Whiskey' node to connect to it.</strong>`,
					element: `#node-${node.uid}`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.connectedNodes, connectedNodes);
	}
};

const connectedNodes = () => {
	if (isTutorialActive('moodboard', templateId)) {
		globalThis.neuroCreate.graph!.zoomOut();
		heartedListScrollTo('cluster');
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: 'Moodboard',
					intro: `We'll create a brand around 'Whiskey Memoirs'.
<br><br>
By adding to the board and arranging as you wish, you can create a moodboard for the branding ideas you have found.`,
					disableInteraction: true,
				},
				{
					title: 'Add a cluster',
					element: '#hearted-container .cluster-container',
					position: 'left',
					disableInteraction: false,
					intro: `<strong>➡ Click the '+' button to add the 'Playful' cluster to the board.</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedCluster, addedPlayfulCluster);
	}
};

const addedPlayfulCluster = ({ props }: { props: ClusterProps }) => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.zoomOut();
		setTimeout(() => {
			globalThis.neuroCreate.graph!.centerTo(props);
		}, 500);
		heartedListScrollTo('haiku');
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: 'Add a haiku',
					element: '#hearted-container .haiku-container',
					position: 'left',
					disableInteraction: false,
					intro: `<strong>➡ Click the '+' button to add the haiku to the board.</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, addedHaiku);
	}
};

const addedHaiku = ({ node }: { node: NCNode }) => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.zoomOut();
		setTimeout(() => {
			globalThis.neuroCreate.graph!.centerTo(node);
		}, 500);
		heartedListScrollTo('cluster');
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: 'Add a cluster',
					element: '#hearted-container .cluster-container',
					intro: `<strong>➡ Click the '+' button to add the 'Golden' cluster to the board.</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedCluster, addedGoldenCluster);
	}
};

const addedGoldenCluster = ({ props }: { props: ClusterProps }) => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.zoomOut();
		setTimeout(() => {
			globalThis.neuroCreate.graph!.centerTo(props);
		}, 500);
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: 'Add a cluster',
					element: '#hearted-container .cluster-container',
					intro: `<strong>➡ Click the '+' button to add the 'Excitement' cluster to the board.</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedCluster, addedExcitementCluster);
	}
};

const addedExcitementCluster = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: 'Add a user persona',
					element: '#hearted-container .persona-container',
					intro: `<strong>➡ Click the '+' button to add user persona text to the board.</strong>
<br><br>
You may have generated an image for the user persona. In this case, you will have the choice to add either image or text to the board, but for this tutorial you should choose the 'Text' option.`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedSingleNode, addedUserPersona);
	}
};

const addedUserPersona = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		const enthusiasmNode = globalThis.neuroCreate.graph.getNodeWithLabel('enthusiasm')!;
		globalThis.neuroCreate.graph.updateActive(enthusiasmNode, true, true);

		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Connect node',
						element: '.menu .arc[data-action=connect]',
						intro: `After placing items on the moodboard we can connect them.
<br><br>
<strong>➡ Click 'connect' to begin connecting 'enthusiasm'.</strong>`,
						position: 'left',
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedConnect, clickedConnectEnthusiasm);
		}, 200);
	}
};

const clickedConnectEnthusiasm = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		closeAlert();
		const node = globalThis.neuroCreate.graph.getNodeWithLabel('Playful')!;
		globalThis.neuroCreate.graph.centerTo(node);
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Choose another node`,
					intro: `<strong>➡ Click the 'Playful' node to connect to it.</strong>`,
					element: `#node-${node.uid}`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.connectedNodes, connectedPlayfulNode);
	}
};

const connectedPlayfulNode = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.updateActive(
			globalThis.neuroCreate.graph.getNodeWithLabel('memoirs')!,
			true,
			true
		);

		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Like nodes`,
						intro: `We can also like nodes we wish to highlight on our moodboard.

<strong>➡ Click 'Like'.</strong>`,
						element: '.menu .arc[data-action=like]',
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.likedNode, notepadTutorial);
		}, 200);
	}
};

const notepadTutorial = () => {
	if (isTutorialActive('moodboard', templateId)) {
		heartedListScrollTo('cluster');
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Notepad',
						element: '#notepad-button',
						intro: `You can use the Notepad to save text and images that you’d like to take away from this branding exercise.
<br><br>
<strong>➡ Click to open the notepad</strong>`,
					},
				],
			},
			{
				onbeforechange: ensureMenuIsOpen,
			}
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedNotepad, openedNotepad);
	}
};

const openedNotepad = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		let haikuNode = globalThis.neuroCreate.graph.getNodeWithLabel(
			'flickering shadows \n' + '  the way children imitate \n' + '  ballet dancers on TV'
		)!;
		if (!haikuNode) {
			haikuNode = globalThis.neuroCreate.graph.nodes.find((n) => n.label && n.label.length > 30)!;
		}
		globalThis.neuroCreate.graph.updateActive(haikuNode, true, true);
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: 'Notepad',
					element: '#notepad-container',
					disableInteraction: true,
					intro: `For this tutorial, we pre-filled some branding ideas centering around the concept of "Whiskey memoirs".
<br><br>
Typically, you can add to the notepad by typing, uploading images, or...
`,
				},
				{
					title: 'Add to notepad from board',
					element: '.menu .arc[data-action=note]',
					disableInteraction: false,
					intro: `You can add any item from the moodboard to the notepad.
<br><br>
Let's finish off the note by adding this haiku to the notepad.
<br><br>
<strong>➡ Click 'Note' to add it to the notepad.</strong>
`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.addedToNote, afterNoteTutorial);
	}
};

const afterNoteTutorial = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		globalThis.neuroCreate.graph.updateActive(
			globalThis.neuroCreate.graph.getNodeWithLabel('whiskey')!,
			true,
			true
		);

		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Let's generate a Brand Strategy from our ideas`,
						intro: `We will use the <strong>Synthesise AI</strong> feature to do this.
<br><br>
<strong>➡ Click 'Synthesise'.</strong>`,
						element: '.menu .arc[data-action=synthesise]',
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.clickedSynthesise, clickedSynthesise);
		}, 200);
	}
};

const clickedSynthesise = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Synthesise AI panel`,
						intro: `The Synthesise AI panel allows you to use your exists ideas to generate new ones.`,
						element: '#synthesise-ai-container',
					},
					{
						title: `Synthesise AI input`,
						intro: `First, we select the ideas we want to use as input for the AI.`,
						element: `#synthesise-ai-container .ai-input`,
					},
					{
						title: `Select additional nodes`,
						intro: `Synthesise AI can be used on a single node, but we will select some others to give more direction to the AI.
<br><br>
<strong>➡ Click 'Include selected items in input'.</strong>`,
						element: '#synthesise-ai-container .selected-input',
					},
					{
						title: `Select additional nodes`,
						intro: `
<strong>➡ Click 'Select items'.</strong>`,
						element: '#synthesise-ai-container .selected-item-wrapper button',
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.selectingItems, afterSelectingItems);
		}, 200);
	}
};

const afterSelectingItems = () => {
	if (isTutorialActive('moodboard', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Select additional nodes`,
					position: 'left',
					intro: `<strong>➡ Select 'memoirs'.</strong>
<br><br>
<strong>➡ Also, select the user persona that you added to the board.</strong>
<br><br>
<strong>➡ Then, click on "Done"</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.doneSelectingItems, doneSelectingItems);
	}
};

const doneSelectingItems = () => {
	if (isTutorialActive('moodboard', templateId) && globalThis.neuroCreate.graph) {
		setTimeout(() => {
			startTutorial({
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Synthesise AI methodology`,
						intro: `Next we will select the methodology for the AI to use, which determines the output.`,
						element: '#synthesise-ai-container .option-container',
					},
					{
						title: `Select 'Brand Strategy'`,
						intro: `<strong>➡ Click 'Brand Strategy'</strong>`,
						element: `#synthesise-ai-container .option-container label[for="Strategy"]`,
					},
					{
						title: `Let's go!`,
						intro: `<strong>➡ Click 'Submit'</strong>
<br><br>
Within a few seconds, the Brand Strategy will be created.`,
						element: `#synthesise-ai-container .button-container button.submit`,
						position: 'right',
					},
				],
			});
			tutorialEmitter.once(TUTORIAL_EVENT_TYPES.synthesiseResult, synthesiseResult);
		}, 200);
	}
};

const synthesiseResult = () => {
	if (isTutorialActive('moodboard', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: `Synthesise AI result`,
					intro: `The AI has generated a Brand Strategy based on your ideas.`,
					element: `#synthesise-ai-container .result-container`,
					position: 'right',
				},
				{
					title: `Copy to notepad`,
					intro: `If you like it, you can use this button to copy the result to the notepad.`,
					element: `#synthesise-ai-container .button-container .copy-to-note`,
					position: 'right',
				},
				{
					title: `Synthesise AI image`,
					intro: `Now, let's generate an image.
<br><br>
<strong>➡ Click 'Image'</strong>`,
					element: `#synthesise-ai-container .option-container label[for="Image"]`,
				},
				{
					title: `Select the kind of image`,
					intro: `<strong>➡ Choose 'Artistic' or one of the other options</strong>`,
					element: `#synthesise-ai-container .second-option-container`,
					position: 'right',
				},
				{
					title: `Let's go!`,
					intro: `<strong>➡ Click 'Generate Image'</strong>
<br><br>
You will wait around 10 seconds for the image to be generated.
`,
					element: `#synthesise-ai-container .button-container`,
					position: 'right',
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.synthesiseResult, synthesiseImageResult);
	}
};

const synthesiseImageResult = () => {
	if (isTutorialActive('moodboard', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: `Synthesise AI image result`,
						intro: `The AI has generated an image based on your input.`,
						element: `#synthesise-ai-container .result-container`,
						position: 'right',
					},
					{
						title: `Export as slides`,
						intro: `Next, let's export our vision, conceptual territories and assets as a slide deck.`,
					},
					{
						title: 'Export as PDF or generate Google Slides',
						element: '#exportButton',
						intro: `To take away your ideas and output, you can export as PDF with your moodboard, notes and idea box, as well as the Ideate and Inspire boards.
<br><br>
You can also generate a Google Slides presentation from items in your idea box.
<br><br>
<strong>➡ Click the export button</strong>`,
					},
				],
			},
			{
				onbeforechange: ensureMenuIsOpen,
			}
		);
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedExport, openedExport);
	}
};

const openedExport = () => {
	if (isTutorialActive('moodboard', templateId)) {
		startTutorial({
			disableInteraction: false,
			hideNext: true,
			steps: [
				{
					title: 'Create presentation',
					element: '#export-container .export-slides',
					intro: `We will prepare a Google Slides presentation.
<br><br>
<strong>➡ Click 'Create Presentation'</strong>`,
				},
			],
		});
		tutorialEmitter.once(TUTORIAL_EVENT_TYPES.openedPresentationPreview, openedPresentationPreview);
	}
};

const openedPresentationPreview = () => {
	if (isTutorialActive('moodboard', templateId)) {
		startTutorial(
			{
				disableInteraction: false,
				hideNext: true,
				steps: [
					{
						title: 'Welcome to the presentation preview',
						element: '.alert-window',
						intro: `Each tab allows you to preview and edit what will be included in the slides. We will highlight just a few of them.
<br><br>
<strong>➡ Click 'Vision'</strong>`,
					},
					{
						title: 'Vision',
						element: '.alert-window',
						intro: `The Vision tab shows the project vision that you generated at the beginning of this tutorial. This will be included in the slides.
<br><br>
<strong>➡ Next, click 'Conceptual Territories'</strong>`,
					},
					{
						title: 'Conceptual Territories',
						element: '.alert-window',
						intro: `Conceptual Territories shows various ideas and concepts that you have generated from the Synthesise AI.
<br><br>
You may select some to include in the slides.
<br><br>
<strong>➡ Click 'Inspiration'</strong>`,
					},
					{
						title: 'Inspiration',
						element: '.alert-window',
						intro: `Inspiration shows images that you have generated from in Synthesise AI (as well as other elements from your Idea Box).
<br><br>
You may select some to include in the slides.`,
					},
					{
						title: 'Complete 🎉',
						intro: `Synthesise mode tutorial has been completed
<br><br>
When you're done selecting, simply click on <strong>Generate Presentation</strong> - you'll be guided to connect to your Google account if you haven't already done so.`,
					},
				],
			},
			{
				onexit: () => {
					deactivateTutorialButton();
				},
			}
		);
	}
};
