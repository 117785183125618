import * as d3 from 'd3';
// @ts-expect-error this import works
import debounce from 'lodash.debounce';
import {
	ClusterBoundElement,
	NCNodeDefinition,
	GraphProps,
	LinkBoundElement,
	MenuOption,
	NodeBoundElement,
} from '../../types';
import {
	ButtonDefinition,
	clearNonBlockingMessage,
	createButtons,
	createDialog,
	getUserByColour,
	showAlert,
	showNonBlockingMessage,
	updateUsersOnlineStatus,
} from '../ui/uiUtils';
import {
	getParent,
	getDistanceForConnection,
	createSvg,
	calcGraphWidthAndHeight,
	getLabels,
	getAllLinkedNodes,
	findClosestNodeToLinkTo,
	getUpdatedNodesLinksGroups,
	getMouseTargetInfo,
	updateCursorAndMouseLink,
	updateMouseLink,
	createMouseLink,
	createCustomCursor,
	getRadialMenuButtonArc,
} from './graphUtils';
import { addToNodeList, constructNodeFromDefinition, isSameNode } from './nodeUtils';
import {
	createNodes,
	updateLabelInput,
	updateNodeAndMenu,
	enterNode,
	generateNodeId,
	updateNodePositions,
	updateNodes,
} from './graphNodeUtils';
import { setPresentationLink } from '../ui/slidesUI';
import Zoomable from './Zoomable';
import * as toolsUi from '../ui/toolsUI';
import * as searchUi from '../ui/searchUi';
import * as generateUi from '../ui/generateUi';
import * as sparkUi from '../ui/sparkUi';
import * as empathiseUi from '../ui/empathiseUi';
import * as ncApi from '../apiRequests';
import * as tutorial from '../tutorials/tutorial';
import * as templateTutorial from '../tutorials/templateTutorial';
import * as tutorialEvents from '../tutorials/tutorialEvents';
import { createLinkLines, updateActiveLink, updateLinkPositions } from './graphLinkUtils';
import { createClusterNodes } from './graphClusterUtils';
import MoodboardGraph from './MoodboardGraph';
import InspireGraph from './InspireGraph';
import IdeateGraph from './IdeateGraph';
import { refreshHeartedList } from '../ui/ideaBox';
import { FilterOptions, setTutorialShown, splitTextIntoWords } from '../apiRequests';
import {
	AvailableColour,
	NCCluster,
	NCGraphData,
	NCLink,
	NCNode,
	BoardUser,
	GraphMode,
	UserSelection,
	Point,
	NCGroup,
	LikedItem,
	Motivations,
	UserPersona,
	EmpathiseColour,
	TopicAnalysis,
} from '../../../../src/commonTypes';
import {
	showCommonIdeasNodesPrompt,
	showCreateGroupPrompt,
	showLinkingNodesPrompt,
	showSynthesiseNodesPrompt,
	toggleGroupEditControls,
	updateInspireProgress,
} from '../ui/toolsUI';
import { findFreeSpace, findFreeSpaceRadial, getCentralPoint } from './geometryUtils';
import {
	confirmRemoveGroup,
	createGroups,
	getRandomGroupColor,
	updateGroupPositions,
} from './graphGroupUtils';
import { initPhysics, updatePhysics } from './graphPhysics';
import { splitNodes } from '../../mergeSplitUtils';
import { initNodeDragBehavior, initGroupDragBehavior } from './dragUtils';
import { capitalizeFirstLetter, splitWords, userPersonaToString } from '../../../../src/commonMisc';
import { showSingleImageLightbox } from '../ui/lightbox';
import { getLinksInvolvingNode, isSameLink, minimizeLinks } from './linkUtils';
import {
	getGroupsWhereNodeIsOnlyNode,
	getNodesWithinGroupArea,
	minimizeGroups,
} from './groupUtils';
import { disableChatbot, enableChatbot } from '../tutorials/tutorialEvents';

/**
 * The Graph class performs all calculations related to the placement of nodes on the screen.
 * The graph is inserted in a div in the DOM with class '.chart' and appends a svg of a given width and height
 * to that div where all the nodes are placed in.
 */
class Graph extends Zoomable {
	protected userColourClass: AvailableColour;
	protected userColourHex: string;
	public templateId: string | undefined;
	protected aiStatus: boolean;
	public readonly isCollaborative: boolean;
	protected automation: boolean;
	protected mouse:
		| null
		| (Point & { targetId?: string; targetClass?: string; element?: HTMLElement });
	public debugMarker: null | Point;
	public nodes: Array<NCNode>;
	public links: Array<NCLink>;
	public nodeGroups: Array<NCGroup>;
	protected parentElement: d3.Selection<HTMLDivElement, null, HTMLElement, unknown>;
	protected nodeDragBehavior: d3.DragBehavior<SVGGElement, NCNode, unknown>;
	protected groupDragBehavior: d3.DragBehavior<SVGGElement, NCGroup, unknown>;
	public simulation: d3.Simulation<NCNode, NCLink> | undefined;
	protected groups: {
		nodes?: d3.Selection<SVGGElement, NCNode, SVGGElement, null>;
		links?: d3.Selection<SVGGElement, NCLink, SVGGElement, null>;
		groups?: d3.Selection<SVGGElement, NCGroup, SVGGElement, null>;
		clusters?: d3.Selection<SVGGElement, NCCluster, SVGGElement, null>;
		cursor?: d3.Selection<SVGCircleElement, null, HTMLElement, null>;
		mouselink?: d3.Selection<SVGGElement, NCNode, SVGGElement, null>;
		debugMarker?: d3.Selection<SVGCircleElement, null, HTMLElement, null>;
	};
	protected activeLink: undefined | { source: string; target: string };
	public selections = {
		connectFirstNode: undefined as NCNode | undefined,
		mergeFirstNode: undefined as NCNode | undefined,
		addToGroup: undefined as NCNode[] | undefined,
		removeFromGroup: undefined as NCNode[] | undefined,
		linkingNodes: undefined as NCNode[] | undefined,
		commonIdeasNodes: undefined as NCNode[] | undefined,
		groupingNodes: undefined as NCNode[] | undefined,
		synthesiseNodes: undefined as NCNode[] | undefined,
	};

	protected onlineStatusIntervalId: NodeJS.Timeout;
	public boardUsers: Array<BoardUser>;
	public userSelections: Array<UserSelection>;
	private afterLoadGraphCallback: null | (() => void) = null;
	private debouncedOnTick: () => void;

	constructor(
		graphData: NCGraphData,
		boardUsers: Array<BoardUser>,
		userSelections: Array<UserSelection>,
		colourClass: AvailableColour,
		colourHex: string,
		isCollaborative: boolean,
		automation = false,
		createBackgroundGroups: undefined | (() => void) = undefined
	) {
		super();

		this.boardUsers = boardUsers;
		this.userSelections = userSelections;
		this.userColourClass = colourClass;
		this.userColourHex = colourHex;
		this.aiStatus = document.querySelector<HTMLInputElement>('#ai-setting')!.checked;
		this.isCollaborative = isCollaborative;
		this.automation = automation;
		this.updateGraphWithNewData(graphData);
		this.mouse = null;

		// this.parentElement refers to the <div class='chart' /> in the DOM.
		this.parentElement = d3
			.select<HTMLDivElement, null>('#board-content')
			.select<HTMLDivElement>('.chart');

		const { width, height } = calcGraphWidthAndHeight();
		// this.svg is the SVG element that is appended to the div.
		this.svg = createSvg(this.parentElement, width, height)
			// Event listeners:
			.on('mouseleave', this.onMouseLeave.bind(this))
			.on('mousemove', this.onMouseMove.bind(this))
			.on('click', this.onClick.bind(this));

		this.svg.classed(this.graphMode, true);

		this.baseElement = this.svg.append('g').attr('id', 'all-content');

		// Enable this line to see origin:
		// this.baseElement.append('rect').attr('width', 10).attr('height', 10);

		if (this.isMovable()) {
			this.nodeDragBehavior = initNodeDragBehavior(this);
			this.groupDragBehavior = initGroupDragBehavior(this);
		}

		this.groups = {};
		if (createBackgroundGroups) {
			createBackgroundGroups.call(this);
		}
		if (this.isEditable()) {
			this.groups.mouselink = createMouseLink(this.baseElement);
			this.groups.cursor = createCustomCursor(this.baseElement, this.userColourHex);
			// this.groups.debugMarker = createDebugMarker(this.baseElement);

			this.svg.attr('cursor', 'crosshair');
		}
		Object.assign(this.groups, {
			groups: createGroups(
				this.baseElement.append('g').attr('class', 'groups').selectAll('g'),
				this.nodeGroups,
				this.groupDragBehavior
			),
			links: createLinkLines(
				this.baseElement.append('g').attr('class', 'links').selectAll('g.link'),
				this.links,
				this.graphProps
			),
			nodes: createNodes(
				this.baseElement.append('g').attr('class', 'nodes').selectAll('g'),
				this.nodes,
				this.enterNode.bind(this),
				this.updateNode.bind(this),
				this.isMovable()
			),
		});

		this.onTick();

		this.simulation = initPhysics(this);
		enableChatbot();
		if (!this.automation) {
			setTimeout(() => {
				if (graphData.template && graphData.template !== 'board') {
					this.templateId = graphData.template;
					templateTutorial.start(this.templateId, this);
				} else if (
					document.body.dataset[`showTutorial${capitalizeFirstLetter(this.graphMode)}`] ===
						'true' &&
					this.nodes.length === 0
				) {
					// Start tutorial if user was not yet shown it for any board
					this.startTutorial();

					setTutorialShown(document.body.dataset.userId!, this.graphMode);
				} else {
					this.startHints();
				}
			}, 250);
		}

		tutorial.deactivateTutorialButton();

		if (this.activeNode) {
			this.updateActive(this.activeNode);
		}

		const shouldZoomToFit = this.nodes.length > 0;
		this.initZoom(shouldZoomToFit, this.automation);
		this.deleteRogueNodes();
		setTimeout(() => {
			if (shouldZoomToFit) {
				this.zoomToFit(this.automation);
			}
			toggleGroupEditControls(this);
		}, 200);

		if (!this.automation) {
			// Update user active status every 30 seconds
			this.onlineStatusIntervalId = setInterval(() => {
				this.saveUser();
			}, 30000);

			this.saveUser();
		}

		if (this.currentUser.settings.presentationId) {
			setPresentationLink(this.currentUser.settings.presentationId, this.boardTitle);
		}
	}

	get boardTitle(): string {
		return document.querySelector<HTMLInputElement>('#board-title-form input')!.value;
	}

	getTemplateId() {
		return this.templateId;
	}

	setTemplateId(templateId: string) {
		this.templateId = templateId;
	}

	// For debugging, gets midpoints of links for visualizing
	get linkMidpoints(): Array<Point> {
		return this.links.map((l) => ({
			x: (l.target.x + l.source.x) / 2,
			y: (l.target.y + l.source.y) / 2,
		}));
	}

	validate(): boolean {
		// Can be overridden to show alerts depending on state
		return true;
	}

	cleanUp() {
		clearNonBlockingMessage();
		if (this.onlineStatusIntervalId) {
			clearInterval(this.onlineStatusIntervalId);
		}
	}

	get graphMode(): GraphMode {
		throw new Error('graphMode should be overridden');
	}

	updateGraphWithNewData(graphData: NCGraphData) {
		const { nodes, links, groups } = graphData;

		const { updatedNodes, updatedLinks, updatedGroups } = getUpdatedNodesLinksGroups(
			structuredClone(nodes),
			structuredClone(links),
			this.links,
			groups ? structuredClone(groups) : [],
			this.nodeGroups || []
		);
		this.nodes = updatedNodes;
		this.links = updatedLinks;
		this.rebuildLinks();
		this.nodeGroups = updatedGroups;
	}

	/**
	 * Removes the node from the board
	 */
	delete(nodeToDelete: NCNode) {
		if (nodeToDelete.colour !== this.userColourClass) {
			console.log("Cannot delete another user's node");
			return;
		}

		if (this.nodes.some((node) => node.uid === nodeToDelete?.uid)) {
			const linksToDelete = getLinksInvolvingNode(nodeToDelete, this.links);
			if (neuroCreate.saveLinks) {
				neuroCreate.saveLinks.delete(linksToDelete);
			}

			const groupsToDelete = getGroupsWhereNodeIsOnlyNode(nodeToDelete, this.nodeGroups);
			if (neuroCreate.saveGroups) {
				neuroCreate.saveGroups.delete(groupsToDelete);
			}

			if (neuroCreate.saveNodes) {
				neuroCreate.saveNodes.delete([nodeToDelete]);
			}
		} else {
			console.error(`Failed to find a node with uid ${nodeToDelete?.uid}`);
		}
	}

	/**
	 * Updates the position of the mouse
	 */
	onMouseMove = (event: MouseEvent) => {
		const { id: targetId } = event.target as SVGElement;
		const [x, y] = this.getMousePosition(event);
		this.mouse = { x, y, targetId };
		this.onMouseMoved();
	};

	onMouseLeave = () => {
		this.mouse = null;
		this.onMouseMoved();
	};

	/**
	 * Connect to another node
	 */
	connect = (node: NCNode) => {
		this.startSelectingNodes('connectFirstNode', [node]);

		showNonBlockingMessage('Select another node to connect to', [
			{
				label: 'Cancel',
				onClick: () => {
					delete this.selections.connectFirstNode;
					this.baseElement.attr('class', '');
					clearNonBlockingMessage();
				},
			},
		]);

		tutorialEvents.clickedConnect(node);
	};

	mergeNodes(mergeFirstNode: NCNode, mergeSecondNode: NCNode): void {
		const mergedNode = this.addSingleNode(
			mergeFirstNode,
			`${mergeFirstNode.label || ''} ${mergeSecondNode.label || ''}`.trim(),
			undefined,
			[],
			[mergeSecondNode]
		);
		tutorialEvents.mergedNodes(mergedNode);
	}

	findNearestFreeSpace(proposedPoint: Point, linkedPoint: NCNode | undefined): Point {
		return findFreeSpaceRadial(this.nodes, this.links, proposedPoint, linkedPoint, this.nodeRadius);
	}

	/**
	 * Adds a new node below the current node.
	 */
	addSingleNode(
		node: NCNode,
		label: string,
		nodeColour?: EmpathiseColour | undefined,
		topics: string[] = [],
		additionalNodesToLink: Array<NCNode> = [],
		src?: string
	): NCNode {
		const allNodesToLink =
			additionalNodesToLink.length === 0 && this.activeNode
				? [this.activeNode]
				: [node, ...additionalNodesToLink];
		let newNodeProposedPoint = {
			x: allNodesToLink[0].x + 100 * Math.random() - 50,
			y: allNodesToLink[0].y + 100,
		};
		if (allNodesToLink.length >= 2) {
			// Place new node at the mid-point between the linked nodes
			const avg = (arr: Array<number>) => arr.reduce((acc, v, i, a) => acc + v / a.length, 0);
			newNodeProposedPoint = {
				x: avg(allNodesToLink.map((n) => n.x)),
				y: avg(allNodesToLink.map((n) => n.y)),
			};
		}
		newNodeProposedPoint = this.findNearestFreeSpace(newNodeProposedPoint, node);
		const newNode = this.spawnNewNode(
			{ ...newNodeProposedPoint, label, src, nodeColour, topics: topics },
			allNodesToLink
		);
		tutorialEvents.addedSingleNode(newNode);
		return newNode;
	}

	connectNodes(connectFirstNode: NCNode, connectSecondNode: NCNode): void {
		const newLink: NCLink = {
			source: connectFirstNode,
			target: connectSecondNode,
			colour: this.userColourClass,
		};
		if (!this.links.some((l) => isSameLink(l, newLink))) {
			if (neuroCreate.saveLinks) {
				neuroCreate.saveLinks.create([newLink]);
			}
		} else {
			showAlert('The nodes are already connected');
			clearNonBlockingMessage();
		}

		this.removeActiveStatus();
		this.activeLink = { source: connectFirstNode.uid, target: connectSecondNode.uid };
		this.appendMenuElements();
		clearNonBlockingMessage();

		setTimeout(() => {
			tutorialEvents.connectedNodes(connectSecondNode);
		}, 50);
	}

	addNodeToSelection(
		key: keyof typeof this.selections &
			(
				| 'commonIdeasNodes'
				| 'groupingNodes'
				| 'linkingNodes'
				| 'synthesiseNodes'
				| 'addToGroup'
				| 'removeFromGroup'
			),
		node: NCNode
	) {
		const selection = this.selections[key];
		if (selection) {
			const textNodesOnly = ['commonIdeasNodes', 'linkingNodes', 'synthesiseNodes'].includes(key);
			const newNodesList = addToNodeList(node, selection, textNodesOnly);
			if (newNodesList) {
				this.selections[key] = newNodesList;
				return true;
			}
		}
		return false;
	}

	completeNodeSelection(node: NCNode) {
		tutorialEvents.selectedNode(node);

		if (this.selections.mergeFirstNode || this.selections.connectFirstNode) {
			if (this.selections.mergeFirstNode) {
				this.mergeNodes(this.selections.mergeFirstNode, node);
			} else if (this.selections.connectFirstNode) {
				this.connectNodes(this.selections.connectFirstNode, node);
			}

			this.stopSelectingNodes();
			return;
		}

		if (this.addNodeToSelection('commonIdeasNodes', node)) {
			showCommonIdeasNodesPrompt(this.selections.commonIdeasNodes!);
		} else if (this.addNodeToSelection('groupingNodes', node)) {
			showCreateGroupPrompt();
		} else if (this.addNodeToSelection('linkingNodes', node)) {
			showLinkingNodesPrompt(this.selections.linkingNodes!);
		} else if (this.addNodeToSelection('synthesiseNodes', node)) {
			showSynthesiseNodesPrompt(this.selections.synthesiseNodes!);
		} else if (this.addNodeToSelection('addToGroup', node)) {
			const node = this.selections.addToGroup![0];
			if (this.activeGroup?.nodes.some((n) => n.uid === node.uid)) {
				showAlert('This node is already in the group so cannot be added again');
				this.stopSelectingNodes();
			} else {
				if (this.activeGroup) {
					this.addNodesToGroup(this.activeGroup, [node]);
				}
				this.stopSelectingNodes();
			}
		} else if (this.addNodeToSelection('removeFromGroup', node)) {
			const node = this.selections.removeFromGroup![0];
			if (!this.activeGroup?.nodes.some((n) => n.uid === node.uid)) {
				showAlert('This node is not in the group so cannot be removed');
				this.stopSelectingNodes();
			} else {
				const newGroupNodes = this.activeGroup.nodes.filter((n) => n.uid !== node.uid);
				if (newGroupNodes.length < 2) {
					// Remove group if only one node left
					if (neuroCreate.saveGroups) {
						neuroCreate.saveGroups.delete([this.activeGroup]);
					}
					this.removeActiveStatus();
				} else {
					if (this.activeGroup) {
						this.removeNodesFromGroup(this.activeGroup, [node]);
					}
				}
				this.stopSelectingNodes();
			}
		} else {
			console.error('No action to complete node selection');
		}
	}

	/**
	 * Places a new node in the graph and updates this.data
	 */
	spawnNewNode(
		nodeDefinition: NCNodeDefinition,
		nodesToLink: null | Array<NCNode> = null,
		noLinking = false
	): NCNode {
		const newNode = constructNodeFromDefinition(nodeDefinition, this.userColourClass);
		const newLinks: Array<NCLink> = [];

		if (nodesToLink) {
			newLinks.push(
				...nodesToLink.map((nodeToLink) => ({
					source: newNode,
					target: nodeToLink,
					colour: this.userColourClass,
				}))
			);
			if (nodesToLink.length === 1) {
				newNode.parent = {
					uid: nodesToLink[0].uid,
					label: nodesToLink[0].label || '',
				};
			}
		} else if (!noLinking) {
			const closestNodeToLinkTo = findClosestNodeToLinkTo(newNode, this.nodes, this.nodeRadius);

			if (closestNodeToLinkTo) {
				newLinks.push({
					source: newNode,
					target: closestNodeToLinkTo,
					colour: this.userColourClass,
				});
			}
		}

		// Save new node & links
		if (neuroCreate.saveNodes) {
			neuroCreate.saveNodes.create([newNode]);
		}
		if (neuroCreate.saveLinks) {
			neuroCreate.saveLinks.create(newLinks);
		}

		this.removeActiveStatus();

		return newNode;
	}

	startSelectingNodes(key: keyof typeof this.selections, nodes: Array<NCNode>, extraClasses = '') {
		for (const key of Object.keys(this.selections)) {
			this.selections[key as keyof typeof this.selections] = undefined;
		}

		if (key === 'mergeFirstNode' || key === 'connectFirstNode') {
			this.selections[key] = nodes.length > 0 ? nodes[0] : undefined;
		} else {
			if (this.activeNode) {
				this.updateActive(this.activeNode);
			}

			this.selections[key] = nodes;
		}

		this.baseElement.classed(`selecting ${extraClasses}`, true);
	}

	stopSelectingNodes(extraClasses = '') {
		this.baseElement.classed(`selecting ${extraClasses}`, false);

		for (const key of Object.keys(this.selections)) {
			this.selections[key as keyof typeof this.selections] = undefined;
		}
		clearNonBlockingMessage();
		tutorialEvents.doneSelectingItems();
	}

	/**
	 * onClick is called when the user clicks anywhere inside the svg.
	 * The event parameter holds information on the target that is clicked on.
	 * Using a switch case we write out the logic that is called on each specific target.
	 */
	onClick(event: MouseEvent) {
		const { targetClass, targetId, element } = getMouseTargetInfo(event);

		const isSelectingNode = this.baseElement.classed('selecting');

		if (isSelectingNode && !['node', 'img-div', 'input'].includes(targetClass)) {
			showAlert('Please select another node');
			return;
		}

		const completeSelection = () => {
			if (isSelectingNode) {
				const node = this.getNodeFromCircle(event);
				if (!this.selections.groupingNodes && this.activeNode && this.activeNode.uid === node.uid) {
					showAlert('Please select a different node');
				} else {
					this.completeNodeSelection(node);
				}
			}
		};

		switch (targetClass) {
			case 'link-delete-circle':
			case 'delete-link': {
				this.deleteLink(event);
				return;
			}
			case 'link-holder':
			case 'link-line': {
				if (this.isMovable()) {
					this.updateActiveLink(event);
				}
				return;
			}
			case 'cluster-heart': {
				const clusterData = (element as unknown as ClusterBoundElement).__data__;
				(this as unknown as IdeateGraph).toggleLikeCluster(clusterData);
				return;
			}
			case 'delete': {
				const parent = getParent(event);
				const node = this.getNode(parent.datum().uid);
				if (node) {
					this.delete(node);
				}
				return;
			}
			case 'input': {
				this.updateActive(event, false);
				completeSelection();
				return;
			}
			case 'group': {
				const parent = getParent(event);
				this.activateGroup(this.nodeGroups.find((group) => group.uid === parent.datum().uid));
				return;
			}
			case 'img-div':
			case 'node': {
				this.updateActive(event);
				completeSelection();
				return;
			}
			case 'menu-rect':
			case 'arc':
			case 'menu-button': {
				const action = getRadialMenuButtonArc(event).attr('data-action');
				const node = this.getNodeFromButton(event);

				switch (action) {
					case 'like':
						this.toggleLike(node);
						break;
					case 'spark':
						(this as unknown as InspireGraph).spark(node);
						break;
					case 'empathise':
						(this as unknown as InspireGraph).empathise(node);
						break;
					case 'generate':
						this.generate(node);
						break;
					case 'synthesise':
						(this as unknown as MoodboardGraph).synthesise(node);
						break;
					case 'search':
						(this as unknown as InspireGraph).search(node);
						break;
					case 'link':
						(this as unknown as InspireGraph).linker(node);
						break;
					case 'merge':
						(this as unknown as InspireGraph).merge(node);
						break;
					case 'delete':
						this.delete(node);
						break;
					case 'note':
						(this as unknown as MoodboardGraph).addToNote(node);
						break;
					case 'view':
						this.viewImage(node);
						break;
					case 'connect':
						this.connect(node);
						break;
					case 'share':
						(this as unknown as MoodboardGraph).share(node);
						break;
					case 'explore':
						this.explore(node);
						break;
				}
				return;
			}
		}

		switch (targetId) {
			case 'graph-background':
				this.onMouseMove.call(this, event);
				if (this.isEditable() && this.mouse) {
					// if (this.activeNode) {
					// 	// First disable the active node
					// 	this.updateActive(this.activeNode);
					// } else
					if (
						!this.nodes.some(
							(n) =>
								this.mouse && getDistanceForConnection(this.mouse, n, this.nodeRadius).isTooClose
						)
					) {
						// Add a new node
						const newNode = this.spawnNewNode({ x: this.mouse.x, y: this.mouse.y });
						tutorialEvents.userCreatedNode(newNode);
					}
				}
				return;
		}
	}

	createNewEmptyNode(): NCNode | undefined {
		if (this.isEditable()) {
			const point = findFreeSpace(
				this.nodes,
				this.mouse || this.nodes.at(-1) || { x: 0, y: 0 },
				this.nodeRadius
			);
			const newNode = this.spawnNewNode({ x: point.x, y: point.y }, null, true);
			tutorialEvents.userCreatedNode(newNode);
			this.centerTo(newNode);
			return newNode;
		}
		return undefined;
	}

	updateNodesInGroup(group: NCGroup, nodes: Array<NCNode>) {
		group.nodes = nodes;
		this.activateGroup(group);
	}

	createGroup(nodes: Array<NCNode>): NCGroup {
		const group: NCGroup = {
			uid: generateNodeId(),
			nodes: nodes,
			colour: this.userColourClass,
			overrideColour: getRandomGroupColor(),
		};

		if (neuroCreate.saveGroups) {
			neuroCreate.saveGroups.create([group], () => {
				this.activateGroup(group);
			});
		}

		return group;
	}

	addNodesToGroup(group: NCGroup, nodes: Array<NCNode>) {
		const updatedGroup = {
			...group,
			nodes: group.nodes.concat(nodes),
		};
		if (neuroCreate.saveGroups) {
			neuroCreate.saveGroups.update([updatedGroup]);
		}
		return updatedGroup;
	}

	removeNodesFromGroup(group: NCGroup, nodes: Array<NCNode>) {
		const updatedGroup = {
			...group,
			nodes: group.nodes.filter((n) => !nodes.some((node) => node.uid === n.uid)),
		};
		if (neuroCreate.saveGroups) {
			neuroCreate.saveGroups.update([updatedGroup]);
		}
		return updatedGroup;
	}

	spawnCluster(
		cluster: NCCluster,
		clusterProps: { x: number; y: number; colourClass: AvailableColour; radius?: number },
		isRectangular?: boolean | undefined
	): NCNode {
		const { centralNode, linkedNodes } = createClusterNodes(
			cluster,
			clusterProps,
			this.userColourClass,
			this.graphMode === 'ideate' ? globalThis.neuroCreate.doc!.data.inspire.data : undefined
		);
		if (isRectangular) {
			centralNode.style = 'rect';
		}
		if (neuroCreate.saveNodes) {
			neuroCreate.saveNodes.create([centralNode, ...linkedNodes]);
		}
		if (neuroCreate.saveLinks) {
			neuroCreate.saveLinks.create(
				linkedNodes.map((n) => ({ source: centralNode, target: n, colour: this.userColourClass }))
			);
		}
		tutorialEvents.addedCluster(cluster, clusterProps);

		return centralNode;
	}

	get graphProps(): GraphProps {
		return {
			areNodesColoured: this.areNodesColoured(),
			areLinksColoured: this.areLinksColoured(),
			userColourClass: this.userColourClass,
			userColourHex: this.userColourHex,
			nodeRadius: this.nodeRadius,
			currentUser: this.currentUser,
			isEditable: this.isEditable(),
			menuOptions: this.getMenuOptions(),
			boardUsers: this.boardUsers,
			aiStatus: this.aiStatus,
			activeLink: this.activeLink,
			isDeleteAnimated: this.isMovable(),
			automation: this.automation,
			scale: this.zoomTransform?.k || 1,
			graphMode: this.graphMode,
		};
	}

	onMouseMoved() {
		const graphProps = this.graphProps;
		if (graphProps.isEditable) {
			updateCursorAndMouseLink(this.groups.cursor, this.mouse, this.activeNode);

			if (this.groups.debugMarker && this.debugMarker) {
				this.groups.debugMarker
					.attr('cx', (this.debugMarker && this.debugMarker.x) || 0)
					.attr('cy', (this.debugMarker && this.debugMarker.y) || 0);
			}

			this.groups.mouselink = updateMouseLink(
				this.groups.mouselink,
				this.mouse,
				graphProps,
				this.nodes
			);
		}
	}

	onTick() {
		if (!this.debouncedOnTick) {
			// Debounce onTick to avoid performance issues if multiple updates are called in quick succession
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
			this.debouncedOnTick = debounce(
				() => {
					if (this.groups.nodes) {
						// Ensure selected nodes are highlighted
						this.groups.nodes.classed('selected', (d) => {
							return Object.values(this.selections)
								.filter((selectedNodes): selectedNodes is Array<NCNode> =>
									Array.isArray(selectedNodes)
								)
								.some((selectedNodes) => selectedNodes.some((n) => n.uid === d.uid));
						});
					}

					if (this.groups.nodes && this.groups.links && this.groups.groups) {
						updateNodePositions(this.groups.nodes);
						updateLinkPositions(this.groups.links);
						updateGroupPositions(this.groups.groups);
						if (this.simulation?.alpha && this.simulation.alpha() > 0.2) {
							tutorial.updatePosition();
						}
					}

					this.onMouseMoved();
					if (this.simulation?.alpha && this.simulation.alpha() < 0.5) {
						this.lockLowVelocityNodesInPlace();
					}
				},
				2,
				{ maxWait: 20 }
			);
		}

		this.debouncedOnTick();
	}

	get nodeRadius(): number {
		return 48;
	}

	enterNode(
		elem: d3.Selection<d3.EnterElement, NCNode, SVGGElement, null>
	): d3.Selection<SVGGElement, NCNode, SVGGElement, null> {
		return enterNode(elem, this.graphProps, this.updateLabel.bind(this), this.nodeDragBehavior);
	}

	/**
	 * Check that AI is enabled and node can be used with AI
	 */
	checkNodeReadyForAI(node: NCNode, toolName: string) {
		if (!this.aiStatus) {
			showAlert(`Please enable AI to use ${toolName}`);
			return false;
		}
		const { label } = node;
		if (!label) {
			showAlert(`Please enter a label to use ${toolName}`);
			return false;
		}

		return true;
	}

	/**
	 * Search tool
	 */
	search(node: NCNode) {
		const { label, src } = node;
		if (!label && !src) {
			showAlert(`Please enter a label to use Search`);
			return false;
		}

		if (node.label || node.src) {
			toolsUi.toggleToolContainer('search', node);
			searchUi.resetSearch();

			if (node.label) {
				ncApi.search(this.boardId, node.label).then((entries) => {
					searchUi.showSearchResults(entries, node);
					tutorialEvents.clickedSearch(node);
					tutorialEvents.searchResultsGenerated(entries);
					this.setToolUsed('search');
				});
			} else if (node.src) {
				ncApi.searchImage(this.boardId, node.src).then((entries) => {
					searchUi.showSearchResults(entries, node);
					tutorialEvents.clickedSearch(node);
					tutorialEvents.searchResultsGenerated(entries);
					this.setToolUsed('search');
				});
			}
		}
	}

	toggleLike(node: NCNode) {
		const newLikesValue = node.likes || {};
		newLikesValue[this.userColourClass] = !newLikesValue[this.userColourClass];

		if (neuroCreate.saveNodes) {
			neuroCreate.saveNodes.update([
				{
					...node,
					likes: newLikesValue,
				},
			]);
		}

		tutorialEvents.likedNode(node);

		// Add to hearted list
		if (node.label || node.src) {
			ncApi
				.like(
					this.boardId,
					node.label ? 'node' : 'image-inspire',
					node.label || null,
					node.src || null,
					!newLikesValue[this.userColourClass],
					undefined,
					node.topics
				)
				.then(() => {
					refreshHeartedList();
				});
		}
	}

	/**
	 * Generate AI tool
	 */
	generate(node: NCNode) {
		if (!this.checkNodeReadyForAI(node, 'Generate')) {
			return;
		}

		toolsUi.toggleToolContainer('generate', node);
		generateUi.fetchGenerateResults([node], 'proverb').then(() => {
			tutorialEvents.clickedGenerate(node);
			this.setToolUsed('generate');
		});
	}

	setToolUsed(tool: 'spark' | 'empathise' | 'generate' | 'search' | 'link' | 'explore'): void {
		this.currentUser.settings.used = this.currentUser.settings.used || {};

		const valueBefore = this.currentUser.settings.used[tool];
		this.currentUser.settings.used[tool] = true;

		if (valueBefore !== this.currentUser.settings.used[tool]) {
			this.saveUser();
		}
	}

	removeGroup(group: NCGroup, skipConfirm = false) {
		if (skipConfirm) {
			if (neuroCreate.saveGroups) {
				neuroCreate.saveGroups.delete([group]);
			}
		} else {
			confirmRemoveGroup(() => {
				if (neuroCreate.saveGroups) {
					neuroCreate.saveGroups.delete([group], () => {
						this.removeActiveStatus();
					});
				}
			});
		}
	}

	deleteLink(event: Event) {
		const linkToDelete = (event.target as LinkBoundElement).__data__;
		if (neuroCreate.saveLinks) {
			neuroCreate.saveLinks.delete([linkToDelete]);
		}
	}

	removeLink(linkToDelete: NCLink) {
		if (neuroCreate.saveLinks) {
			neuroCreate.saveLinks.delete([linkToDelete]);
		}
	}

	removeActiveStatus() {
		if (this.activeNode && this.currentUser) {
			this.currentUser.activeNode = undefined;
			this.saveUser();
			this.appendMenuElements();
		}
		if (this.activeLink) {
			this.activeLink = undefined;
			updateActiveLink(this.groups.links!, this.graphProps);
		}
		if (neuroCreate.saveGroups) {
			neuroCreate.saveGroups.update(
				this.nodeGroups
					.filter((g) => g.active)
					.map((g) => {
						const updatedGroup = { ...g };
						delete updatedGroup.active;
						return updatedGroup;
					})
			);
		}
		toggleGroupEditControls(this);
	}

	getGroupForNode(node: NCNode) {
		return this.nodeGroups.find((g) => g.nodes.some((n) => n.uid === node.uid));
	}

	activateGroup(group: NCGroup | undefined) {
		const updatedGroups: Array<NCGroup> = [];

		for (const g of this.nodeGroups) {
			if (group?.uid === g.uid) {
				if (!g.active) {
					updatedGroups.push({
						...g,
						active: true,
					});

					if (neuroCreate.saveNodes) {
						// Unfix all nodes within the area of the group
						neuroCreate.saveNodes.update(
							getNodesWithinGroupArea(group, this.nodes)
								.filter((n) => n.fx)
								.map((n) => {
									const updatedNode = { ...n };
									delete updatedNode.fx;
									delete updatedNode.fy;
									return updatedNode;
								})
						);
					}
				}
			} else {
				if (g.active) {
					const updatedGroup = { ...g };
					delete updatedGroup.active;
					updatedGroups.push(updatedGroup);
				}
			}
		}

		if (neuroCreate.saveGroups) {
			neuroCreate.saveGroups.update(updatedGroups);
		}

		if (group) {
			const activeNode = this.activeNode;
			if (activeNode) {
				if (!group?.nodes.some((n) => n.uid === activeNode.uid)) {
					// Disable active node if not in this group
					this.updateActive(activeNode);
				}
			}
		}

		toggleGroupEditControls(this);
	}

	updateActiveLink(event: Event) {
		this.removeActiveStatus();

		const {
			source: { uid: source },
			target: { uid: target },
		} = (event.target as LinkBoundElement).__data__;
		if (this.activeLink && this.activeLink.source === source && this.activeLink.target === target) {
			this.activeLink = undefined;
		} else {
			this.activeLink = { source, target };
		}
		updateActiveLink(this.groups.links!, this.graphProps);
	}

	/**
	 * Specifies which of all nodes is active (can only be one at a time)
	 */
	updateActive(eventOrNode: MouseEvent | NCNode, showMenu = true, onlyIfNotActive = false) {
		clearNonBlockingMessage();

		const isSelectingNode = this.baseElement.classed('selecting');
		if (isSelectingNode) {
			return; // Do nothing
		}

		this.activeLink = undefined;
		updateActiveLink(this.groups.links!, this.graphProps);

		let node: NCNode | undefined = undefined;
		if ((eventOrNode as NCNode).uid) {
			node = eventOrNode as NCNode;
		} else {
			const uid = ((eventOrNode as MouseEvent).target as NodeBoundElement)?.__data__.uid;
			if (uid) {
				node = this.getNode(uid);
			}
		}

		if (!node) {
			return;
		}

		if (onlyIfNotActive) {
			if (isSameNode(this.activeNode, node)) {
				return; // Do nothing
			}
		}
		const wasActive = this.currentUser.activeNode === node.uid;
		const isActivatingNow = showMenu && !wasActive;

		if (isActivatingNow) {
			this.activateGroup(this.getGroupForNode(node));
		}

		const valueBefore = this.currentUser.activeNode;
		this.currentUser.activeNode = isActivatingNow ? node.uid : undefined;
		if (valueBefore !== this.currentUser.activeNode) {
			this.saveUser();
		}

		if (node.dotted) {
			const updatedNode = { ...node };
			delete updatedNode.dotted;
			if (neuroCreate.saveNodes) {
				neuroCreate.saveNodes.update([updatedNode]);
			}
		}

		this.appendMenuElements();

		if (isActivatingNow) {
			tutorialEvents.openedNodeMenu(node);
		}

		if (isActivatingNow && !this.isActiveNodeOwnedByCurrentUser() && this.graphMode !== 'ideate') {
			const nodeOwner = getUserByColour(node.colour);

			showNonBlockingMessage(
				`This node was added by ${
					nodeOwner ? `<strong>${nodeOwner.username}</strong>` : 'someone else'
				}. You cannot edit, delete, or merge this node.`
			);
		}

		this.onTick();
		this.centerTo(node);
	}

	/**
	 * Takes the ID of a button and returns the matching node
	 */
	getNodeFromButton(event: MouseEvent): NCNode {
		const target = event.target as SVGElement;
		const arc = target.classList.contains('arc')
			? (target as SVGGElement)
			: ((target as SVGPathElement).parentNode as SVGGElement);
		const menu = arc.parentNode as SVGGElement;
		const nodeEl = menu.parentNode as SVGGElement;
		const id = d3
			.select(nodeEl)
			.select<HTMLElement>('foreignObject > a > .input, foreignObject > div')
			.attr('id');
		return this.getNode(id)!;
	}

	/**
	 * Takes the ID of a circle and returns the matching node
	 */
	getNodeFromCircle(event: MouseEvent): NCNode {
		const target = (event.target as SVGCircleElement).parentNode as SVGGElement;
		const id = d3
			.select<SVGGElement, NCNode>(target)
			.select<HTMLElement>('foreignObject > a > .input, foreignObject > div')
			.attr('id');
		return this.getNode(id)!;
	}

	getMenuOptions(): Array<MenuOption> {
		return [{ label: 'Like', enabled: true, longText: true, shortText: true }];
	}

	getCurrentUserNodes(): Array<NCNode> {
		return this.nodes.filter(
			(n) => n.colour === this.userColourClass || n.colour === this.userColourHex
		);
	}

	/**
	 * Split up all nodes for user, or a single node
	 *
	 * Return true if nodes were split up
	 */
	async splitNodes(
		node: undefined | NCNode = undefined,
		force = false,
		splitKeyPhrases = false
	): Promise<boolean> {
		const nodesIdsToSplit = node
			? [node.uid]
			: this.getCurrentUserNodes()
					.filter((n) => n.style !== 'rect')
					.map((n) => n.uid);

		const { nodesToUpdate, nodesToDelete, nodesToAdd, linksToAdd, linksToDelete } =
			await splitNodes(
				nodesIdsToSplit,
				this.nodes,
				this.links,
				this.userColourClass,
				force,
				splitKeyPhrases
			);

		if (neuroCreate.saveNodes) {
			neuroCreate.saveNodes.update(nodesToUpdate);
			neuroCreate.saveNodes.delete(nodesToDelete, () => {
				neuroCreate.saveNodes?.create(nodesToAdd, () => {
					if (neuroCreate.saveLinks) {
						neuroCreate.saveLinks.create(linksToAdd, () => {
							neuroCreate.saveLinks?.delete(linksToDelete);
						});
					}
				});
			});
		}

		const haveNodesChanged =
			nodesToDelete.length > 0 || nodesToAdd.length > 0 || nodesToUpdate.length > 0;
		if (haveNodesChanged && this.activeNode) {
			// Deactivate active node (if any)
			this.updateActive(this.activeNode);
		}

		return haveNodesChanged;
	}

	preservePhraseNode(node: NCNode) {
		if (neuroCreate.saveNodes && node.style !== 'rect') {
			neuroCreate.saveNodes.update([
				{
					...node,
					style: 'rect',
				},
			]);
		}
	}

	preserveAndExpandPhraseNode(node: NCNode, words: Array<string>) {
		this.preservePhraseNode(node);

		words.forEach((word) => {
			this.addSingleNode(node, word, undefined);
		});
	}

	toggleAi() {
		this.aiStatus = !this.aiStatus;
		console.log(`AI is now ${this.aiStatus ? 'on' : 'off'}`);
		this.appendMenuElements();
		ncApi.toggleAi(this.boardId, this.aiStatus);
	}

	isAiEnabled() {
		return this.aiStatus;
	}

	saveSuggestions(tool: 'spark' | 'empathise', suggestions: Array<TopicAnalysis>) {
		const selections = suggestions.slice(0, 3);
		this.userSelections.push({
			tool,
			words: selections.map((item) => item.key),
			topicMapping: selections,
			timestamp: new Date().getTime(),
		});

		if (globalThis.neuroCreate.saveUserAnalytics) {
			globalThis.neuroCreate.saveUserAnalytics(this.userSelections);
		}
	}

	rebuildLinks() {
		// Ensure each link refers to a current node object
		for (const l of this.links) {
			const source = this.nodes.find((n) => n.uid === l.source.uid);
			if (source) {
				l.source = source;
			}
			const target = this.nodes.find((n) => n.uid === l.target.uid);
			if (target) {
				l.target = target;
			}
		}
	}

	/**
	 * @deprecated
	 * Generally this should not be used as it will update the entire graph rather than the specific parts that updated.
	 **/
	save(callback?: () => void) {
		this.rebuildLinks();

		if (globalThis.neuroCreate.saveGraph) {
			globalThis.neuroCreate.saveGraph(
				this.graphMode,
				{
					nodes: this.nodes,
					links: minimizeLinks(this.links),
					groups: minimizeGroups(this.nodeGroups),
				},
				callback
			);
		}
	}

	saveUser() {
		const saveSingleUser = globalThis.neuroCreate.saveSingleUser;
		if (saveSingleUser) {
			this.currentUser.lastSeen = new Date().getTime();
			this.currentUser.isOnline = true;

			const oneMinuteAgo = new Date(Date.now() - 60_000);
			this.boardUsers.forEach((boardUser) => {
				const newOnlineStatus = Boolean(
					boardUser.lastSeen && oneMinuteAgo.getTime() < new Date(boardUser.lastSeen).getTime()
				);
				if (newOnlineStatus !== boardUser.isOnline) {
					boardUser.isOnline = newOnlineStatus;
					saveSingleUser(boardUser);
				}
			});

			saveSingleUser(this.currentUser);
		}
	}

	appendMenuElements() {
		updateNodeAndMenu(this.groups.nodes!, this.graphProps);
		tutorial.updatePosition();
	}

	updateNodeLabel(node: NCNode, newLabel: string) {
		if (neuroCreate.saveNodes) {
			neuroCreate.saveNodes.update([
				{
					...node,
					label: newLabel,
				},
			]);
		}
	}

	/**
	 * Updates the label of the input field inside the node
	 */
	updateLabel(event: InputEvent, finishedEditing = false): void {
		const targetEl = event.target as HTMLTextAreaElement;
		const node = this.getNode(targetEl.id)!;

		const isReallyFinishedEditing = finishedEditing || targetEl.value.endsWith('\n');
		const newLabelValue = isReallyFinishedEditing ? targetEl.value.trim() : targetEl.value;
		this.updateNodeLabel(node, newLabelValue);

		if (isReallyFinishedEditing) {
			targetEl.value = newLabelValue;
			targetEl.blur();

			const words = splitWords(newLabelValue, false);
			if (this.aiStatus && finishedEditing && node.style !== 'rect') {
				if (words.length > 1) {
					this.promptPreserveOrExpand(node);
				} else if (this.graphMode === 'inspire') {
					this.splitNodes(node);
				}
			} else if (!this.aiStatus && words.length > 1) {
				this.preservePhraseNode(node);
			}

			tutorialEvents.updatedLabel(node);
		}
	}

	async promptExplore(node: NCNode) {
		if (!node.label) {
			return;
		}

		const wordsOrTopics = node.label.length > 300 ? 'topics' : 'words';

		const keyWords =
			wordsOrTopics === 'words' ? await splitTextIntoWords(this.boardId, node.label) : [];

		const explainerP = document.createElement('p');
		explainerP.classList.add('minimal-message');
		if (wordsOrTopics === 'words') {
			explainerP.innerHTML = `<strong>Expand</strong> will only add the key words ${keyWords.map((w) => `"${w}"`).join(', ')} in separate nodes to the board.<br/>
<strong>Preserve and Expand</strong> will keep and display both the full phrase and add the key words in separate nodes.<br/>`;
		} else {
			explainerP.innerHTML = `<strong>Expand</strong> will only add the key topics in separate nodes to the board.<br/>
<strong>Preserve and Expand</strong> will keep and display both the full phrase and add the key topics in separate nodes.<br/>`;
		}

		const sparkText = `The <strong>Spark</strong> AI tool suggests 2 ideas based on the concept. These will be associations within the domains related to the engine you selected. E.g., the ‘Creative’ engine gives associations within culture, lifestyle and trends.<br/>`;
		const empathiseText = `The <strong>Empathise</strong> AI tool suggests different perspectives related to your concept.<br>Suggestions are labelled by colour: <br><span class="colour-demo GREEN">Bright Green - positive</span><br> <span class="colour-demo RED">Red - negative</span><br> <span class="colour-demo FUSCHIA">Pink - emotions</span><br> <span class="colour-demo BLUE">Blue - co-occurence</span><br><br>`;

		explainerP.innerHTML = explainerP.innerHTML + sparkText + empathiseText;
		createDialog(
			'How would you like to explore this phrase using the AI?',
			[explainerP],
			createButtons(
				[
					{
						label: 'Expand',
						onClick: () => {
							const nodeCurrent = this.getNode(node.uid);
							if (nodeCurrent) {
								this.splitNodes(nodeCurrent, true, true);
							}
						},
					},
					{
						label: 'Preserve & Expand',
						primary: true,
						onClick: async () => {
							const nodeCurrent = this.getNode(node.uid);
							if (nodeCurrent) {
								if (wordsOrTopics === 'words') {
									this.preserveAndExpandPhraseNode(nodeCurrent, keyWords);
								} else {
									// Fetch topics from the API
									const keyTopics = await splitTextIntoWords(this.boardId, node.label!, 'topics');
									this.preserveAndExpandPhraseNode(nodeCurrent, keyTopics);
								}
							}
						},
					},
					{
						label: 'Spark',
						primary: true,
						onClick: async () => {
							const nodeCurrent = this.getNode(node.uid);
							if (nodeCurrent) {
								this.spark(nodeCurrent);
							}
						},
					},
					{
						label: 'Empathise',
						primary: true,
						onClick: async () => {
							const nodeCurrent = this.getNode(node.uid);
							if (nodeCurrent) {
								this.empathise(nodeCurrent);
							}
						},
					},
				],
				true
			)
		);
	}

	async promptPreserveOrExpand(node: NCNode) {
		if (!node.label) {
			return;
		}

		const wordsOrTopics = node.label.length > 300 ? 'phrases' : 'words';

		const keyWords =
			wordsOrTopics === 'words' ? await splitTextIntoWords(this.boardId, node.label) : [];

		const explainerP = document.createElement('p');
		explainerP.classList.add('minimal-message');
		if (wordsOrTopics === 'words') {
			explainerP.innerHTML = `<small><strong>Preserve</strong> will keep and display the full phrase ${node.label.length > 100 ? '' : `"${node.label}"`} on the board.<br/>
<strong>Expand</strong> will only add the key words ${keyWords.map((w) => `"${w}"`).join(', ')} in separate nodes to the board.<br/>
<strong>Preserve and Expand</strong> will keep and display both the full phrase and add the key words in separate nodes.<br/><br/></small>`;
		} else {
			explainerP.innerHTML = `<small><strong>Preserve</strong> will keep and display the full phrase ${node.label.length > 100 ? '' : `"${node.label}"`} on the board.<br/>
<strong>Expand</strong> will only add the key topics in separate nodes to the board.<br/>
<strong>Preserve and Expand</strong> will keep and display both the full phrase and add the key topics in separate nodes.<br/><br/></small>`;
		}

		createDialog(
			'Would you like to preserve this phrase and/or<br> allow it to be expanded by AI?',
			[explainerP],
			createButtons(
				[
					{
						label: 'Preserve',
						onClick: () => {
							// Get latest node with the ID in case it has changed (e.g. moved)
							const nodeCurrent = this.getNode(node.uid);
							if (nodeCurrent) {
								this.preservePhraseNode(nodeCurrent);
							}
						},
					},
					{
						label: 'Expand',
						onClick: () => {
							const nodeCurrent = this.getNode(node.uid);
							if (nodeCurrent) {
								this.splitNodes(nodeCurrent, true, true);
							}
						},
					},
					{
						label: 'Preserve & Expand',
						primary: true,
						onClick: async () => {
							const nodeCurrent = this.getNode(node.uid);
							if (nodeCurrent) {
								if (wordsOrTopics === 'words') {
									this.preserveAndExpandPhraseNode(nodeCurrent, keyWords);
								} else {
									// Fetch topics from the API
									const keyTopics = await splitTextIntoWords(this.boardId, node.label!, 'topics');
									this.preserveAndExpandPhraseNode(nodeCurrent, keyTopics);
								}
							}
						},
					},
				],
				true
			)
		);
	}

	fetchEmpathiseResults(node: NCNode): Promise<void> {
		const filterSelect = document.querySelector<HTMLSelectElement>('#empathise-container .filter')!;
		let filterValue = '' as FilterOptions;
		if (filterSelect) {
			filterValue = filterSelect.value as FilterOptions;
		}
		return new Promise((resolve) => {
			if (node.label) {
				ncApi.empathise(this.boardId, filterValue, node.label).then((allResults) => {
					this.saveSuggestions(
						'empathise',
						allResults.map((a) => a.values[0] || '').filter((s) => s)
					);
					empathiseUi.setupEmpathiseResults(this.addSingleNode.bind(this, node), allResults);
					resolve();
				});
			} else {
				resolve();
			}
		});
	}

	/**
	 * Adds two new nodes below the current node.
	 */
	addTwoNodes(node: NCNode, auto = false, wordPair: [TopicAnalysis, TopicAnalysis]) {
		const nodeToLink = auto ? node : this.activeNode || node;
		const newNodeProposedPoint1 = this.findNearestFreeSpace(
			{
				x: nodeToLink.x - 60,
				y: nodeToLink.y + 120,
			},
			nodeToLink
		);
		const firstNode = this.spawnNewNode(
			{
				...newNodeProposedPoint1,
				dotted: auto,
				label: wordPair[0].key,
				topics: wordPair[0].topics,
			},
			[nodeToLink]
		);

		const newNodeProposedPoint2 = this.findNearestFreeSpace(
			{
				x: newNodeProposedPoint1.x + 120,
				y: newNodeProposedPoint1.y,
			},
			nodeToLink
		);
		const secondNode = this.spawnNewNode(
			{
				...newNodeProposedPoint2,
				dotted: auto,
				label: wordPair[1].key,
				topics: wordPair[1].topics,
			},
			[nodeToLink]
		);

		tutorialEvents.addedTwoNodes(firstNode, secondNode);
	}

	/**
	 * Empathise AI tool
	 */
	empathise(node: NCNode) {
		if (!this.checkNodeReadyForAI(node, 'Empathise')) {
			return;
		}

		toolsUi.toggleToolContainer('empathise', node);
		empathiseUi.resetEmpathise(this.fetchEmpathiseResults.bind(this, node));

		this.fetchEmpathiseResults(node).then(() => {
			tutorialEvents.clickedEmpathise(node);
			this.setToolUsed('empathise');
		});
	}

	/**
	 * Spark AI tool
	 */
	spark(node: NCNode, auto = false): Promise<void> {
		if (!this.checkNodeReadyForAI(node, 'Spark')) {
			return Promise.resolve();
		}

		return new Promise((resolve) => {
			toolsUi.toggleToolContainer('spark', node);
			if (node.label) {
				sparkUi.resetSpark();

				ncApi.spark(this.boardId, node.label).then((suggestions) => {
					sparkUi.setupSparkResults(suggestions, this.addTwoNodes.bind(this, node, auto));
					this.saveSuggestions('spark', suggestions);
					tutorialEvents.clickedSpark(node);
					this.setToolUsed('spark');
					resolve();
				});
			} else {
				resolve();
			}
		});
	}

	/**
	 * Finds a node by its ID
	 */
	getNode(id: string): NCNode | undefined {
		return this.nodes.find((node) => node.uid === id);
	}

	getNodeWithLabel(label: string): NCNode | undefined {
		return this.nodes.find((node) => node.label?.toLowerCase() === label.toLowerCase());
	}

	getConnectedNodes(node: NCNode): Array<NCNode> {
		return getAllLinkedNodes([node], this.links).filter((n) => n.uid !== node.uid);
	}

	/**
	 * Function to use with .join to update nodes
	 */
	updateNode(
		nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>
	): d3.Selection<SVGGElement, NCNode, SVGGElement, null> {
		updateNodes(nodes, this.graphProps);
		updateLabelInput(nodes, this.nodeRadius);
		return nodes;
	}

	loadNewUserData(userData: Array<BoardUser>) {
		this.boardUsers = userData;
		this.appendMenuElements();
		updateUsersOnlineStatus(this.boardUsers);
		updateInspireProgress(this);
	}

	onLoadNewGraphData(callback: null | (() => void) = null) {
		this.afterLoadGraphCallback = callback;
	}

	/**
	 * Update graph with new data from collaborators / self (via ShareDB)
	 */
	loadNewGraphData(
		graphData: NCGraphData,
		preDrawFunction: null | (() => void) = null,
		resetPhysics = true
	) {
		this.updateGraphWithNewData(graphData);

		if (preDrawFunction) {
			preDrawFunction();
		}

		if (this.groups.links && this.groups.nodes && this.groups.groups) {
			this.groups.groups = createGroups(
				this.groups.groups,
				this.nodeGroups,
				this.groupDragBehavior
			);
			this.groups.links = createLinkLines(this.groups.links, this.links, this.graphProps);
			this.groups.nodes = createNodes(
				this.groups.nodes,
				this.nodes,
				this.enterNode.bind(this),
				this.updateNode.bind(this),
				this.isMovable()
			);
		}

		if (resetPhysics) {
			updatePhysics(this);
		}

		this.appendMenuElements();
		this.onTick();
		tutorial.updatePosition();

		if (this.afterLoadGraphCallback) {
			const callback = this.afterLoadGraphCallback;
			this.afterLoadGraphCallback = null;
			setTimeout(callback, 0);
		}
	}

	get boardId(): string {
		return document.body.dataset.boardId!;
	}

	isEditable(): boolean {
		return false;
	}

	isMovable(): boolean {
		return true;
	}

	isPhysicsActive(): boolean {
		return true;
	}

	areNodesColoured(): boolean {
		return false;
	}

	areLinksColoured(): boolean {
		return true;
	}

	get activeNode(): NCNode | undefined {
		return this.nodes.find((node) => this.currentUser && this.currentUser.activeNode === node.uid);
	}

	get activeGroup(): NCGroup | undefined {
		return this.nodeGroups.find((group) => group.active);
	}

	get graphData(): NCGraphData {
		return {
			nodes: this.nodes,
			links: this.links,
			groups: this.nodeGroups,
		};
	}

	isActiveNodeOwnedByCurrentUser() {
		return this.currentUser.colour === this.activeNode?.colour;
	}

	get currentUser(): BoardUser {
		const currentUser = this.boardUsers.find((u) => this.userColourClass === u.colour);
		if (!currentUser) {
			throw new Error('Could not find current user');
		}
		return currentUser;
	}

	get currentUserIndex(): number {
		const currentUserIndex = this.boardUsers.findIndex((u) => this.userColourClass === u.colour);
		if (currentUserIndex === -1) {
			throw new Error('Could not find current user');
		}
		return currentUserIndex;
	}

	get labels(): Array<string> {
		return getLabels(this.nodes);
	}

	getLikedNodes(): Array<NCNode> {
		return this.nodes.filter((n) => n.likes?.[this.userColourClass]);
	}

	getLikedNodeLabels(): Array<string> {
		return getLabels(this.getLikedNodes());
	}

	getCurrentlySelectedText(): string {
		return window.getSelection()?.toString() || '';
	}

	getSelection(): Selection | null {
		return window.getSelection() || null;
	}

	startTutorial(): void {
		const tutorialButton = document.querySelector<HTMLButtonElement>('#tutorial')!;
		tutorialButton.classList.add('tutorialActive');
		console.log('Start tutorial for', this.graphMode);
		disableChatbot();

		// Should be overridden by sub-classes to actually start the tutorial
	}

	startHints(): void {
		// Overridden by sub-classes
		console.log('Start hints for', this.graphMode);
		disableChatbot();
	}

	setHintsShown(): void {
		document
			.querySelector(`#board-stage-switcher li[data-mode='${this.graphMode}']`)!
			.classList.add('completed');
		console.log('Set hints shown for', this.graphMode);
		enableChatbot();
	}

	// Returns if hints have been shown for this graph mode
	getHintsShown(): boolean {
		// Overridden by sub-classes
		return false;
	}

	setPresentationId(presentationId: string): void {
		this.currentUser.settings.presentationId = presentationId;
		setPresentationLink(presentationId, this.boardTitle);
		this.saveUser();
	}

	clearGraph(): void {
		this.nodes = [];
		this.links = [];
		this.nodeGroups = [];
		this.save();
	}

	/**
	 * Adds a new node at or near a specified point.
	 */
	addNodeNearPoint(
		point: Point,
		label: string | undefined,
		src: string | undefined,
		href: string | undefined,
		like = false
	): NCNode {
		const newNodeProposedPoint = findFreeSpace(this.nodes, point, this.nodeRadius);
		const node = this.spawnNewNode({ ...newNodeProposedPoint, label, src, href, like }, null, true);
		tutorialEvents.addedSingleNode(node);
		return node;
	}

	async addToBoard(likedItem: LikedItem): Promise<NCNode> {
		let newPosition = { x: -380, y: 50 };
		if (likedItem.type === 'search-image' || likedItem.type.startsWith('image')) {
			return this.addNodeNearPoint(newPosition, undefined, likedItem.url, undefined);
		} else if (likedItem.type === 'cluster' && likedItem.text) {
			const { group, values } = JSON.parse(likedItem.text) as {
				group: string;
				values: Array<string>;
			};
			newPosition = findFreeSpace(this.nodes, newPosition, this.nodeRadius, true);
			const centralNode = this.spawnCluster(
				{ group, values },
				{ ...newPosition, colourClass: this.userColourClass, radius: 20 }
			);
			return centralNode;
		} else if (likedItem.type === 'search-page' || likedItem.type === 'article') {
			return this.addNodeNearPoint(newPosition, likedItem.text, undefined, likedItem.url);
		} else if (likedItem.type === 'motivations') {
			try {
				const motivations = JSON.parse(likedItem.text!) as Motivations;
				return new Promise<NCNode>((resolve) => {
					showAlert('Which element would you like to add to the board?', [
						...motivations.stakeholders.flatMap((stakeholder) => {
							const buttons: Array<ButtonDefinition> = [];
							buttons.push({
								label: `${stakeholder.name} - Description`,
								minWidth: '320px',
								onClick: () => {
									resolve(
										this.addNodeNearPoint(
											newPosition,
											`${stakeholder.name}: ${stakeholder.description}`,
											undefined,
											undefined
										)
									);
								},
							});
							if (stakeholder.imageUrl && this.graphMode === 'moodboard') {
								buttons.push({
									label: `Image`,
									iconUrl: stakeholder.imageUrl,
									onClick: () => {
										resolve(
											this.addNodeNearPoint(newPosition, undefined, stakeholder.imageUrl, undefined)
										);
									},
								});
							}
							return buttons;
						}),
					]);
				});
			} catch (e) {
				// handle old plain text motivations
				return this.addNodeNearPoint(newPosition, likedItem.text, undefined, undefined);
			}
		} else if (likedItem.type === 'persona') {
			try {
				const persona = JSON.parse(likedItem.text!) as UserPersona;
				if (persona.imageUrl) {
					return new Promise<NCNode>((resolve) => {
						const buttons: Array<ButtonDefinition> = [];
						if (this.graphMode === 'moodboard') {
							buttons.push({
								label: 'Image',
								onClick: () => {
									resolve(
										this.addNodeNearPoint(newPosition, undefined, persona.imageUrl, undefined)
									);
								},
							});
						}
						buttons.push({
							label: 'Text',
							onClick: () => {
								resolve(
									this.addNodeNearPoint(
										newPosition,
										userPersonaToString(persona),
										undefined,
										undefined
									)
								);
							},
						});
						showAlert(
							'Which element of the user persona would you like to add to the board?',
							buttons
						);
					});
				} else {
					return this.addNodeNearPoint(
						newPosition,
						userPersonaToString(persona),
						undefined,
						undefined
					);
				}
			} catch (e) {
				// handle old plain text personas
				return this.addNodeNearPoint(newPosition, likedItem.text, undefined, undefined);
			}
		} else {
			return this.addNodeNearPoint(newPosition, likedItem.text, undefined, undefined);
		}
	}

	viewImage(node: NCNode): void {
		showSingleImageLightbox(node.src!);
	}

	explore(node: NCNode): void {
		this.checkNodeReadyForAI(node, 'Explore');
		this.promptExplore(node);
		this.setToolUsed('explore');
	}

	centerOnCentralPoint() {
		const centralPoint = getCentralPoint(this.nodes);
		this.centerTo(centralPoint);
	}

	undo(): void {
		ncApi.undo(this.boardId).then((success) => {
			if (!success) {
				showAlert('No more actions to undo');
			}
		});
	}

	deleteRogueNodes(): Promise<void> {
		// "Rogue nodes" are empty nodes that were typically placed accidentally or forgotten about.
		// We also include nodes without a UID, these should not exist but could be the result of a bug.
		// By automatically deleting these we avoid clutter and ensure that only relevant nodes are shown on page load.
		const rogueNodes = this.nodes.filter((n) => (!n.label && !n.src) || !n.uid);
		if (rogueNodes.length > 0 && neuroCreate.saveNodes) {
			return new Promise<void>((resolve) => {
				neuroCreate.saveNodes!.delete(rogueNodes, () => {
					setTimeout(resolve, 500);
				});
			});
		} else {
			return Promise.resolve();
		}
	}

	centerTo(point: Point) {
		this.deleteRogueNodes();
		super.centerTo(point);
	}

	async zoomToFit(isExporting = false) {
		const mouse = this.mouse;
		if (mouse) {
			// The cursor can interfere with zooming, so we briefly disable it.
			this.mouse = null;
			this.onMouseMoved();
		}

		await this.deleteRogueNodes();

		super.zoomToFit(isExporting);

		if (mouse) {
			this.mouse = mouse;
			this.onMouseMoved();
		}
	}

	lockLowVelocityNodesInPlace() {
		if (globalThis.neuroCreate.saveNodes && this.isPhysicsActive()) {
			const sd = this.nodes.filter(
				(n) => !n.moving && typeof n.fx !== 'number' && n.vx && n.vy && Math.hypot(n.vx, n.vy) < 1
			);
			if (sd.length > 0) {
				console.log('Locking low velocity nodes in place', sd);
			}
			globalThis.neuroCreate.saveNodes.update(
				this.nodes
					.filter(
						(n) =>
							!n.moving && typeof n.fx !== 'number' && n.vx && n.vy && Math.hypot(n.vx, n.vy) < 1
					)
					.map((n) => ({
						...n,
						fx: n.x,
						fy: n.y,
					}))
			);
		}
	}

	lockAllNodesInPlace() {
		if (globalThis.neuroCreate.saveNodes && this.isPhysicsActive()) {
			globalThis.neuroCreate.saveNodes.update(
				this.nodes
					.filter((n) => typeof n.fx !== 'number')
					.map((n) => ({
						...n,
						fx: n.x,
						fy: n.y,
					}))
			);
		}
	}
}

export default Graph;
