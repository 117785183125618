import { PresentationInfo, TargetAudience } from '../../../../../src/commonTypes';
import { createCountTag, createDetails, createImage, createSelectableCheckbox } from './utils';
import { getTaskTitle } from '../../slides/slideUtils';
import { appendTargetAudienceInfo } from './audience';
import { capitalizeFirstLetter } from '../../../../../src/commonMisc';

export const getConceptualTerritoriesContent = (presentation: PresentationInfo) => {
	const conceptualTerritoriesContent = document.createElement('div');

	const introP = document.createElement('p');
	introP.innerHTML =
		'<small>When you use the AI in <strong class="synthesise-key">Synthesise</strong> the results are automatically saved and can be selected here as "Conceptual Territories". They are grouped by <strong class="synthesise-key">concept</strong> (the input you used in the AI).</small>';
	conceptualTerritoriesContent.append(introP);

	console.log(presentation.conceptualTerritories);

	presentation.conceptualTerritories?.forEach((item) => {
		const [conceptDetails, conceptHeading] = createDetails(
			`Concept: "${capitalizeFirstLetter(item.value.phrase)}"`
		);
		conceptHeading.querySelector('span')?.append(createCountTag(item.value.results.length));

		const conceptCheckbox = createSelectableCheckbox(item, presentation)[0];
		conceptHeading.prepend(conceptCheckbox);

		item.value.results.forEach((result) => {
			const [resultDetails, resultHeading] = createDetails(getTaskTitle(result.value));

			const resultCheckbox = createSelectableCheckbox(result, presentation)[0];
			resultHeading.prepend(resultCheckbox);

			if (result.value.type === 'audience') {
				appendTargetAudienceInfo(
					resultDetails,
					JSON.parse(result.value.text!) as TargetAudience,
					true
				);
			} else if (result.value.text) {
				const pre = document.createElement('pre');
				pre.className = 'text-preview';
				pre.innerText = result.value.text;
				resultDetails.append(pre);
			} else if (result.value.type.includes('image') && result.value.url) {
				resultDetails.append(createImage(result.value.url, 256));
			}

			conceptDetails.append(resultDetails);
		});

		conceptualTerritoriesContent.append(conceptDetails);
	});

	return conceptualTerritoriesContent;
};
