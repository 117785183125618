import { PresentationInfo, SelectableItem, TextResult } from '../../../../../src/commonTypes';
import {
	createConceptualTerritoryLabel,
	createCountTag,
	createDetails,
	createGenerateInspireLabel,
	createImageList,
	createInspireGenerateAndSynthesiseLabel,
	createInspireGenerateSearchAndSynthesiseLabel,
	createLinkList,
	createPreviewParagraph,
	createSelectableCheckbox,
	createTextList,
	getConceptualTerritoryResultsOfType,
	getUncachedItems,
} from './utils';

const createSourcedListItem = (
	selectableItem: SelectableItem<TextResult>,
	presentation: PresentationInfo
) => {
	const listItem = document.createElement('li');
	listItem.append(createSelectableCheckbox(selectableItem, presentation)[0]);
	const div = document.createElement('div');
	listItem.append(div);
	div.append(createPreviewParagraph(selectableItem.value.text, true));
	return [listItem, div];
};

export const createTaglineList = (presentation: PresentationInfo) => {
	const linkList = document.createElement('ul');
	linkList.classList.add('sourced-list');
	getUncachedItems(presentation.taglines).forEach((tagline) => {
		const [listItem, div] = createSourcedListItem(tagline, presentation);
		div.append(createGenerateInspireLabel('Generate'));
		linkList.append(listItem);
	});
	getConceptualTerritoryResultsOfType(presentation, 'taglines').forEach(([concept, result]) => {
		const [listItem, div] = createSourcedListItem(
			result as SelectableItem<TextResult>,
			presentation
		);
		div.append(createConceptualTerritoryLabel(concept.value.phrase));
		linkList.append(listItem);
	});
	return linkList;
};

export const createBrainstormingList = (presentation: PresentationInfo) => {
	const linkList = document.createElement('ul');
	linkList.classList.add('sourced-list');
	getUncachedItems(presentation.brainstorming).forEach((text) => {
		const [listItem, div] = createSourcedListItem(text, presentation);
		div.append(createGenerateInspireLabel('Generate'));
		linkList.append(listItem);
	});
	getConceptualTerritoryResultsOfType(presentation, 'brainstorm').forEach(([concept, result]) => {
		const [listItem, div] = createSourcedListItem(
			result as SelectableItem<TextResult>,
			presentation
		);
		div.append(createConceptualTerritoryLabel(concept.value.phrase));
		linkList.append(listItem);
	});
	return linkList;
};

export const getInspirationSlideContent = (presentation: PresentationInfo) => {
	const inspirationContent = document.createElement('div');

	const inspirationP = document.createElement('p');
	inspirationP.innerHTML =
		'<small>Images, links, taglines, haikus, brainstorming, and common ideas from your <strong class="inspire-key">Inspire > Generate</strong> or <strong class="synthesise-key">Synthisise AI</strong> will be available to select below.</small>';
	inspirationContent.append(inspirationP);

	const [imagesDetails, imagesSummary] = createDetails('Images');
	imagesSummary.append(createCountTag(presentation.images.length));
	imagesSummary.append(createInspireGenerateSearchAndSynthesiseLabel());
	inspirationContent.append(imagesDetails);
	imagesDetails.append(createImageList(presentation, presentation.images));

	const [linksDetails, linksSummary] = createDetails('Links');
	linksSummary.append(createCountTag(presentation.links.length));
	linksSummary.append(createGenerateInspireLabel('Search', true));
	inspirationContent.append(linksDetails);
	linksDetails.append(createLinkList(presentation.links, presentation));

	const [taglinesDetails, taglineSummary] = createDetails('Taglines');
	const taglineList = createTaglineList(presentation);
	taglineSummary.append(createCountTag(taglineList.childElementCount));
	taglineSummary.append(createInspireGenerateAndSynthesiseLabel());
	inspirationContent.append(taglinesDetails);
	taglinesDetails.append(taglineList);

	const [haikuDetails, haikuSummary] = createDetails('Haikus');
	haikuSummary.append(createCountTag(presentation.haikus.length));
	haikuSummary.append(createGenerateInspireLabel('Generate', true));
	inspirationContent.append(haikuDetails);
	haikuDetails.append(createTextList(presentation.haikus, presentation));

	const [brainstormingDetails, brainstormingSummary] = createDetails('Brainstorming');
	const brainstormList = createBrainstormingList(presentation);
	brainstormingSummary.append(createCountTag(brainstormList.childElementCount));
	brainstormingSummary.append(createInspireGenerateAndSynthesiseLabel());
	inspirationContent.append(brainstormingDetails);
	brainstormingDetails.append(brainstormList);

	const [commonDetails, commonSummary] = createDetails('Common Ideas');
	commonSummary.append(createCountTag(presentation.commonIdeas.length));
	commonSummary.append(createGenerateInspireLabel('Generate', true));
	inspirationContent.append(commonDetails);
	commonDetails.append(createTextList(presentation.commonIdeas, presentation));

	return inspirationContent;
};
