import { showAlert, componentToggle, finishLoading } from '../lib/ui/uiUtils';
import '../lib/ui/boardMenu';
import '../lib/tutorials/dashboardTutorial';
import { setTutorialShown } from '../lib/apiRequests';

const disabledTemplateButtons = document.querySelectorAll<HTMLAnchorElement>(
	'.create-new-board-item .disabled-link'
);
if (disabledTemplateButtons) {
	disabledTemplateButtons.forEach((templateButton) => {
		templateButton.addEventListener('click', (e) => {
			const buttonLabel =
				templateButton.querySelector<HTMLParagraphElement>('.board-label')!.innerText;
			e.preventDefault();
			showAlert(`${buttonLabel} template is coming soon!`);
		});
	});
}

const createNewProject = document.querySelector('.create-project-button');
if (createNewProject) {
	createNewProject.addEventListener('click', () => {
		const newProjectInput = document.querySelector('#user-projects-list li:first-child')!;
		componentToggle(newProjectInput);
	});
}

if (document.body.dataset.showTutorial === 'true') {
	document.querySelector<HTMLButtonElement>('#header-menu-help-button')!.click();
	setTutorialShown(document.body.dataset.userId!, 'dashboard');
}

finishLoading();
