import { TUTORIAL_EVENT_TYPES, tutorialEmitter } from "../tutorials/tutorialEvents";
import ChatbotAssistant from "./ChatbotAssistant";
import { getInterestingNode, getInterestingSuggestion } from "../apiRequests";
import { ANALYSES_TYPES, TOOLS, UseCase } from '../../../../src/commonConstants';
import { EmpathiseColour, GenerateTask, GraphMode, NCNode, Perspectives, SearchEntry, SynthesiseTask } from "../../../../src/commonTypes";
import { IdeateGraph } from "../graphs";
import { hasHeartedItems, isHeartedListOpen } from "../ui/ideaBox";
import { isToolContainerVisible } from "../ui/toolsUI";
import { isGeneratePanelVisible } from "../ui/generateUi";
import { getTaskReadableName, isPlural } from "../slides/slideUtils";


/*

This is the behavior tree that controls the chatbot.
Sequences of AI interactions (e.g. go through a full spark, empathise, brainstorm step) are the building blocks or leaves of the tree. 
For each use case, an array of available sequences is defined per board.
E.g., for 'branding':
- inspire sequences: ['spark', 'empathise', 'brainstorm', 'common ideas']
- ideate sequences: ['personality personas', 'themes personas', 'themes motivations']
- moodboard sequences: ['export', 'synthesise strategy']

Those sequences would be suggested by the chatbot when the user is on the board. To progress through a full use case, the user would need to complete all the given sequences on the board.

Buttons:
For each use case (or template) there is also a set of buttons that are presented to the user when they've completed a certain number of sequences.
E.g., for 'branding':
- 'Define personas' button
- 'Synthesise strategy' button

Each button maps to a sequence. 

*/

enum NodeType {
  Sequence,
  Selector,
  Action,
  PrioritySelector
}


interface ActionNode {
  condition: (event: string) => boolean;
  action: () => void;
}

class TreeNode {
  type: NodeType;
  children: TreeNode[];
  actionNode?: ActionNode;
  currentChildIndex: number;
  priority?: number;
  label?: string;
  event?: string;
  parent?: TreeNode;
  constructor(type: NodeType, children: TreeNode[] = [], actionNode?: ActionNode, priority?: number, label?: string, event?: string) {
    this.type = type;
    this.children = children;
    this.actionNode = actionNode;
    this.currentChildIndex = 0;
    this.priority = priority;
    this.label = label;
    this.event = event; // Event that triggers this node

    // Set the parent for each child node
    for (const child of children) {
      child.parent = this; // Set the parent to the current node
    }
  }

  reset() {
    this.currentChildIndex = 0;
    for (const child of this.children) {
      child.reset();
    }
  }
}

// The chatbot regularly asks the user what they'd like to do next.
// We store the decision the user made in userDecisions
const USER_DECISION_POINTS = {
  START: 'start',
  INSPIRE: 'inspire',
  IDEATE: 'ideate',
  SYNTHESISE: 'synthesise',
  AFTER_IDEATE: 'afterIdeate',
  AFTER_INSPIRE: 'afterInspire',
  EXPORT: 'export'
}


// All the different building blocks of the trees for each use case
const SEQUENCES = {
  // Inspire sequences
  SPARK: 'SparkSequence',
  EMPATHISE: 'EmpathiseSequence',
  BRAINSTORM: 'BrainstormSequence',
  COMMON_IDEAS: 'CommonIdeasSequence',
  TAGLINES: 'TaglinesSequence',
  HAIKUS: 'HaikusSequence',
  AI_HAIKUS: 'AiHaikusSequence',
  IMAGE_GENERATION: 'ImageGenerationSequence',
  MERGING: 'MergingSequence',
  INSPIRE_FREE_EXPLORATION: 'InspireFreeExplorationSequence',
  SEARCH: 'SearchSequence',

  // Ideate sequences
  THEMES_MOTIVATIONS: 'ThemesMotivationsSequence',
  THEMES_PERSONAS: 'ThemesPersonasSequence',
  PERSONALITY_PERSONAS: 'PersonalityPersonasSequence',
  PERSONALITY_MOTIVATIONS: 'PersonalityMotivationsSequence',
  ARCHETYPES_MOTIVATIONS: 'ArchetypesMotivationsSequence',
  ARCHETYPES_PERSONAS: 'ArchetypesPersonasSequence',
  GREEK_PERSONAS: 'GreekPersonasSequence',
  GREEK_MOTIVATIONS: 'GreekMotivationsSequence',
  COLORS_PERSONAS: 'ColorsPersonasSequence',
  COLORS_MOTIVATIONS: 'ColorsMotivationsSequence',
  PLOTS_PERSONAS: 'PlotsPersonasSequence',
  PLOTS_MOTIVATIONS: 'PlotsMotivationsSequence',
  MOTIVATIONS_PERSONAS: 'MotivationsPersonasSequence',
  MOTIVATIONS_MOTIVATIONS: 'MotivationsMotivationsSequence',
  IDEATE_FREE_EXPLORATION: 'IdeateFreeExplorationSequence',

  // Synthesise sequences
  EXPORT: 'ExportSequence',
  SYNTHESISE: 'SynthesiseSequence',
  SYNTHESISE_STRATEGY: 'SynthesiseStrategySequence',
  LINKEDIN_POSTS: 'LinkedInPostsSequence',
  AUDIENCE_PROFILE: 'AudienceProfileSequence',
  PRODUCT_IDEA: 'ProductIdeaSequence',
  PRODUCT_BRIEF: 'ProductBriefSequence',
  GAME: 'GameSequence',
  SUMMARY: 'SummarySequence',
  EXTRACT: 'ExtractSequence',
  UNITE: 'UniteSequence',
  SYNOPSIS: 'SynopsisSequence',
  TAGLINES_SYNTHESISE: 'TaglinesSynthesiseSequence',
  BRAINSTORM_SYNTHESISE: 'BrainstormSynthesiseSequence',
  SYNTHESISE_FREE_EXPLORATION: 'SynthesiseFreeExplorationSequence',

  // Complex sequences
  INITIAL_EXPLORATION: 'InitialExplorationSequence', 
}

const COMPLEX_SEQUENCES = {
    [SEQUENCES.INITIAL_EXPLORATION]: [SEQUENCES.SPARK, SEQUENCES.EMPATHISE, SEQUENCES.SPARK, SEQUENCES.EMPATHISE, SEQUENCES.BRAINSTORM, SEQUENCES.COMMON_IDEAS],
}

const SEQUENCES_BY_USE_CASE: Record<UseCase, Record<string, string[]>> = {
  'branding': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.IMAGE_GENERATION, SEQUENCES.SEARCH, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.SYNTHESISE_STRATEGY, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  },
  'marketing': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.IMAGE_GENERATION, SEQUENCES.SEARCH, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.LINKEDIN_POSTS, SEQUENCES.AUDIENCE_PROFILE, SEQUENCES.TAGLINES_SYNTHESISE, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  },
  'product_idea': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.IMAGE_GENERATION, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.PRODUCT_IDEA, SEQUENCES.PRODUCT_BRIEF, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  },
  'copywriting': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.TAGLINES, SEQUENCES.HAIKUS, SEQUENCES.AI_HAIKUS, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.LINKEDIN_POSTS, SEQUENCES.TAGLINES_SYNTHESISE, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  },
  'product_brief': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.IMAGE_GENERATION, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.PRODUCT_BRIEF, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  },
  'design_thinking': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.IMAGE_GENERATION, SEQUENCES.SEARCH, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.PRODUCT_IDEA, SEQUENCES.SYNTHESISE_STRATEGY, SEQUENCES.TAGLINES_SYNTHESISE, SEQUENCES.GAME, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  },
  'brainstorming': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.IMAGE_GENERATION, SEQUENCES.SEARCH, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.BRAINSTORM_SYNTHESISE, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  },
  'default': {
    'inspire': [SEQUENCES.INITIAL_EXPLORATION, SEQUENCES.IMAGE_GENERATION, SEQUENCES.SEARCH, SEQUENCES.INSPIRE_FREE_EXPLORATION],
    'ideate': [SEQUENCES.PERSONALITY_PERSONAS, SEQUENCES.THEMES_PERSONAS, SEQUENCES.THEMES_MOTIVATIONS, SEQUENCES.IDEATE_FREE_EXPLORATION],
    'moodboard': [SEQUENCES.SYNTHESISE_STRATEGY, SEQUENCES.EXPORT, SEQUENCES.SYNTHESISE_FREE_EXPLORATION],
  }
}


export class BehaviorTree {

  // Buttons for available building blocks 
  protected BUTTON_OPTIONS = {
    [SEQUENCES.INITIAL_EXPLORATION]: {
      label: "Explore the Problem Space",
      condition: () => !this.initialInspireExplorationFinished['inspire'], // Condition to complete this step
      board: 'inspire',
      action: () => this.switchBoard('inspire'),
    },
    [SEQUENCES.PERSONALITY_PERSONAS]: {
      label: "Define Personas",
      condition: () => Object.values(this.completedUserPersonaSteps).reduce((acc, curr) => acc + curr, 0) < 2,
      board: 'ideate',
      action: () => this.switchBoard('ideate')
    },
    [SEQUENCES.THEMES_MOTIVATIONS]: {
      label: "Analyse Motivations",
      condition: () => Object.values(this.completedStakeholderMotivationSteps).reduce((acc, curr) => acc + curr, 0) < 1,
      board: 'ideate',
      action: () => this.switchBoard('ideate')
    },
    [SEQUENCES.SYNTHESISE_STRATEGY]: {
      label: "Synthesise a Brand Strategy",
      condition: () => this.completedSynthesiseSequences['brand-strategy'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.LINKEDIN_POSTS]: {
      label: "Generate a LinkedIn Post",
      condition: () => this.completedSynthesiseSequences['linkedinpost'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.AUDIENCE_PROFILE]: {
      label: "Synthesise an Audience Profile",
      condition: () => this.completedSynthesiseSequences['audience'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.PRODUCT_IDEA]: {
      label: "Generate a Product Idea",
      condition: () => this.completedSynthesiseSequences['product-idea'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.PRODUCT_BRIEF]: {
      label: "Create a Product Brief",
      condition: () => this.completedSynthesiseSequences['product-brief'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.GAME]: {
      label: "Create a Game",
      condition: () => this.completedSynthesiseSequences['game'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.SUMMARY]: {
      label: "Create a Summary",
      condition: () => this.completedSynthesiseSequences['summary'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.EXTRACT]: {
      label: "Extract Insights",
      condition: () => this.completedSynthesiseSequences['extract'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.UNITE]: {
      label: "Unite Insights",
      condition: () => this.completedSynthesiseSequences['unite'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.TAGLINES]: {
      label: "Generate Taglines",
      condition: () => this.completedSynthesiseSequences['taglines'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.BRAINSTORM]: {
      label: "Generate Ideas",
      condition: () => this.completedSynthesiseSequences['brainstorm'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.SYNOPSIS]: {
      label: "Create a Story Synopsis",
      condition: () => this.completedSynthesiseSequences['synopsis'] < 1,
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.EXPORT]: {
      label: "Export",
      condition: () => !this.exportSequenceCompleted && globalThis.neuroCreate.graph?.graphMode === 'moodboard',
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.SYNTHESISE_FREE_EXPLORATION]: {
      label: "Keep exploring on the synthesise board",
      condition: () => globalThis.neuroCreate.graph?.graphMode === 'moodboard',
      board: 'moodboard',
      action: () => this.switchBoard('moodboard')
    },
    [SEQUENCES.INSPIRE_FREE_EXPLORATION]: {
      label: "Keep exploring on the inspire board",
      condition: () => globalThis.neuroCreate.graph?.graphMode === 'inspire',
      board: 'inspire',
      action: () => this.switchBoard('inspire')
    },
    [SEQUENCES.IDEATE_FREE_EXPLORATION]: {
      label: "Keep exploring on the ideate board",
      condition: () => globalThis.neuroCreate.graph?.graphMode === 'ideate',
      board: 'ideate',
      action: () => this.switchBoard('ideate')
    },
  }

  protected BUTTON_OPTIONS_START: Record<UseCase, {
    label: string;
    condition: () => boolean;
    action: () => void;
    board: string;
  }> = {
    marketing: {
      label: "Develop a Marketing Strategy",
      condition: () => this.getCurrentTemplate() !== 'marketing',
      action: () => this.template = 'marketing',
      board: 'inspire'
    },
    'product_idea': {
      label: "Define a Product Idea",
      condition: () => this.getCurrentTemplate() !== 'product_idea',
      action: () => this.template = 'product_idea',
      board: 'inspire'
    },
    branding: {
      label: "Develop a Brand Strategy",
      condition: () => this.getCurrentTemplate() !== 'branding',
      action: () => this.template = 'branding',
      board: 'inspire'
    },
    'product_brief': {
      label: "Create a Product Brief",
      condition: () => this.getCurrentTemplate() !== 'product_brief',
      action: () => this.template = 'product_brief',
      board: 'inspire'
    },
    copywriting: {
      label: "Copywriting",
      condition: () => this.getCurrentTemplate() !== 'copywriting',
      action: () => this.template = 'copywriting',
      board: 'inspire'
    },
    'design_thinking': {
      label: "Design Thinking",
      condition: () => this.getCurrentTemplate() !== 'design_thinking',
      action: () => this.template = 'design_thinking',
      board: 'inspire'
    },
    'brainstorming': {
      label: "Brainstorm ideas",
      condition: () => this.getCurrentTemplate() !== 'brainstorming',
      action: () => this.template = 'brainstorming',
      board: 'inspire'
    },
    default: {
      label: "Explore a problem space",
      condition: () => true,
      action: () => this.template = 'default',
      board: 'inspire'
    }
  }

  private root: TreeNode;
  private currentNode: TreeNode;
  protected currentlyActiveTool = '';
  protected currentlyActiveAnalysis: string | undefined;
  private chatbotAssistant: ChatbotAssistant;
  private template: UseCase = 'default';

  private chatbotStarted = false;
  private lastNode: NCNode | undefined;
  protected lateralSliderValue = 0;


  // Sometimes we prompt the user to make a decision what they'd like to do next
  // We store the decision the user made in userDecisions and the options in optionsPerDecisionPoint
  protected userDecisions: Record<string, string | undefined> = {
    [USER_DECISION_POINTS.INSPIRE]: undefined,
    [USER_DECISION_POINTS.IDEATE]: undefined,
    [USER_DECISION_POINTS.SYNTHESISE]: undefined,
    [USER_DECISION_POINTS.AFTER_IDEATE]: undefined,
    [USER_DECISION_POINTS.AFTER_INSPIRE]: undefined,
    [USER_DECISION_POINTS.EXPORT]: undefined
  };

  // Store the decisions the user made for each board
  protected userDecisionsPerBoard: Record<string, string[]> = {
    "inspire": [],
    "ideate": [],
    "moodboard": []
  }
  // Store current suggestions for each tool so that we can select a good suggestion from there.
  public currentSuggestions = {
    spark: [] as Array<[string, string]>,
    empathise: [] as Array<{ color: string, values: string }>,
    brainstorm: [] as Array<string>,
    commonIdeas: [] as Array<string>,
    [TOOLS.TAGLINES]: [] as Array<{ snippet: string, title: string }>,
    [TOOLS.HAIKUS]: [] as Array<{ snippet: string, title: string }>,
    [TOOLS.Ai_HAIKUS]: [] as Array<{ snippet: string, title: string }>,
    personas: [] as Array<{ snippet: string, title: string }>,
    motivations: [] as Array<string>,
    brandStrategies: [] as Array<string>,
    [TOOLS.IMAGE]: [] as Array<{ snippet: string, title: string }>,
    audience: [] as Array<{ snippet: string, title: string }>,
    search: [] as Array<SearchEntry>,
  }

  public currentSelectedNodes: NCNode[] = []
  public currentSelectionAsString: string = ''; // Save a string of all selected nodes. Used in the brainstorm flow where all selected nodes are concatenated.

  // Keep track of the state of the interactions here

  // Store selected suggestions for the current node to avoid repeating them
  protected selectedSparkSuggestionsForCurrentNode: Record<string, Array<[string, string]>> = {};
  protected selectedEmpathiseSuggestionsForCurrentNode: Record<string, Array<{ color: string, label: string }>> = {};
  protected selectedBrainstormSuggestionsForCurrentNode: Record<string, string[]> = {};
  protected selectedCommonIdeasSuggestionsForCurrentNode: Record<string, string[]> = {};
  protected selectedTaglinesSuggestionsForCurrentNode: Record<string, string[]> = {};
  protected selectedHaikusSuggestionsForCurrentNode: Record<string, string[]> = {};
  protected selectedAiHaikusSuggestionsForCurrentNode: Record<string, string[]> = {};
  protected selectedPersonasSuggestionsForCurrentNode: Record<string, string[]> = {};

  // Inspire state
  protected newNodeCount: number = 0; // All new nodes generated by spark, empathise, and clicking 
  protected newNodeCountExplorationThreshold: number = 20; // How many nodes the user should have generated before we start the idea generation sequence

  protected brainstormedIdeasCount: number = 0;
  protected brainstormedIdeasCountThreshold: number = 3;

  protected commonIdeasCount: number = 0;
  protected commonIdeasCountThreshold: number = 2;

  // How many larger sequences have been completed
  // protected completedSparkSequences = 0;
  // protected completedEmpathiseSequences = 0;
  // protected completedBrainstormSequences = 0;
  // protected completedCommonIdeasSequences = 0;
  protected exportSequenceCompleted = false;

  // All completed sequences state
  protected completedSequences: Record<string, number> = {
    [SEQUENCES.SPARK]: 0,
    [SEQUENCES.EMPATHISE]: 0,
    [SEQUENCES.BRAINSTORM]: 0,
    [SEQUENCES.COMMON_IDEAS]: 0,
    [SEQUENCES.EXPORT]: 0,
    [SEQUENCES.AI_HAIKUS]: 0,
    [SEQUENCES.HAIKUS]: 0,
    [SEQUENCES.TAGLINES]: 0,
    [SEQUENCES.IMAGE_GENERATION]: 0,
    [SEQUENCES.AUDIENCE_PROFILE]: 0,
    [SEQUENCES.SUMMARY]: 0,
    [SEQUENCES.SYNOPSIS]: 0,
    [SEQUENCES.EXTRACT]: 0,
    [SEQUENCES.UNITE]: 0,
    [SEQUENCES.GAME]: 0,
    [SEQUENCES.PRODUCT_IDEA]: 0,
    [SEQUENCES.PRODUCT_BRIEF]: 0,
    [SEQUENCES.SYNTHESISE_STRATEGY]: 0,
    [SEQUENCES.LINKEDIN_POSTS]: 0,
    [SEQUENCES.TAGLINES_SYNTHESISE]: 0,
    [SEQUENCES.SEARCH]: 0,
    [SEQUENCES.SYNTHESISE_FREE_EXPLORATION]: 1, //Just to make sure that this condition is met
    [SEQUENCES.INSPIRE_FREE_EXPLORATION]: 1, //Just to make sure that this condition is met
    [SEQUENCES.IDEATE_FREE_EXPLORATION]: 1, //Just to make sure that this condition is met
  }

  // All completed synthesise sequences state
  protected completedSynthesiseSequences: Record<SynthesiseTask, number> = {
    'brand-strategy': 0,
    'linkedinpost': 0,
    'audience': 0,
    'product-idea': 0,
    'product-brief': 0,
    'game': 0,
    'summary': 0,
    'extract': 0,
    'unite': 0,
    'taglines': 0,
    'brainstorm': 0,
    'synopsis': 0,
  }

  protected initialInspireExplorationFinished: Record<string, boolean> = {
    'inspire': false,
    'ideate': false,
    'moodboard': false
  }

  // Ideate state
  protected completedUserPersonaSteps: Record<string, number> = {
    [ANALYSES_TYPES['Psych.cluster'].name]: 0,
    [ANALYSES_TYPES['Themes.cluster'].name]: 0,
    [ANALYSES_TYPES['Archetype.cluster'].name]: 0,
    [ANALYSES_TYPES['Greek.cluster'].name]: 0,
    [ANALYSES_TYPES['colors.cluster'].name]: 0,
    [ANALYSES_TYPES['Plots.cluster'].name]: 0,
    [ANALYSES_TYPES['Motivations.cluster'].name]: 0
  };

  protected completedStakeholderMotivationSteps: Record<string, number> = {
    [ANALYSES_TYPES['Themes.cluster'].name]: 0,
    [ANALYSES_TYPES['Psych.cluster'].name]: 0,
    [ANALYSES_TYPES['Archetype.cluster'].name]: 0,
    [ANALYSES_TYPES['colors.cluster'].name]: 0,
    [ANALYSES_TYPES['Plots.cluster'].name]: 0,
    [ANALYSES_TYPES['Motivations.cluster'].name]: 0
  };

  constructor(chatbotAssistant: ChatbotAssistant) {
    this.chatbotAssistant = chatbotAssistant;
    this.root = this.buildTree();
    this.currentNode = this.root;
  }

  // Create one tree for all use cases
  private buildTree(): TreeNode {
    console.log("Building tree");
    return new TreeNode(NodeType.Selector, [ // Root: Check which board the user is on

      new TreeNode(NodeType.Action, [], {
        condition: () => !this.chatbotStarted,
        action: async () => this.welcomeUser() // TODO: use the user's goal
      }, 0, "Introduction"),

      new TreeNode(NodeType.Action, [], {
        condition: () => this.chatbotStarted && this.getCurrentTemplate() === 'default',
        // The user has started a board without a template
        action: async () => this.promptChoicesForNextSequence("What would you like to do today?", USER_DECISION_POINTS.START)
      }, 0, "Introduction"),



      new TreeNode(NodeType.Selector, [ // Handle inspire branch

        // Use case specific sub tree

        /* The initial exploration sequence for all use cases consists of
        - Spark
        - Empathise
        - Spark
        - Empathise
        - Brainstorm
        - Common Ideas

        At the end of the initial exploration sequence we ask the user if they want to continue or switch to another board
        */
        this.buildTreeForUseCase(this.getCurrentTemplate(), 'inspire'),


        // After the first problem space exploration on the inspire board ask the user if they want to continue or go to another board
        new TreeNode(NodeType.Action, [],
          {
            condition: () => this.initialInspireExplorationFinished[this.getCurrentBoard()] && this.userDecisions[USER_DECISION_POINTS.INSPIRE] === undefined,
            action: () => {
              let useCaseText = this.getUseCaseText();
              this.promptChoicesForNextSequence(`Nice work! We now have a good amount of ideas and inspirations around the ${useCaseText}. Based on those we can now synthesise user personas and stakeholder motivations or go straight to the synthesise board to ${useCaseText}. What would you like to do next?`, USER_DECISION_POINTS.INSPIRE)
            }
          }, 0, "Suggest next steps"
        ),


        // Inspire Fallback Tree: Cover all available actions on the inspire board
        new TreeNode(NodeType.PrioritySelector, [
          this.buildSparkExpansionSequence(1000),
          this.buildEmpathiseExpansionSequence(1000),
          this.buildIdeaGenerationSequence(1000),
          this.buildFindingCommonIdeasSequence(1000),
          //TODO: Add the rest of the sequences
          this.buildSearchSequence(1000),
          this.buildImageGenerationSequence(1000),
          this.buildInspireGenerateSequence(1000, TOOLS.TAGLINES, SEQUENCES.TAGLINES),
          this.buildInspireGenerateSequence(1000, TOOLS.HAIKUS, SEQUENCES.HAIKUS),
          this.buildInspireGenerateSequence(1000, TOOLS.Ai_HAIKUS, SEQUENCES.AI_HAIKUS),
        ], {
          condition: () => true,
          action: () => { }
        }, 0, "Fallback tree"),
      ], {
        condition: () => this.getCurrentBoard() === 'inspire',
        action: async () => this.chatbotAssistant.updateWidget("Let's explore the problem space and generate ideas.")
      }, 0, "Inspire Sequence"), // End of inspire branch

      new TreeNode(NodeType.PrioritySelector, [ // Handle ideate branch

        // Has user selected what to do on the ideate board?
        new TreeNode(NodeType.Action, [], {
          condition: () => this.userDecisionsPerBoard[this.getCurrentBoard()]?.length === 0,
          action: async () => this.promptChoicesForNextSequence("We’re going to get our insights in a data-driven manner by analysing all our concepts from the 'inspire' board and ideating our user personas & stakeholder motivations around them. Where would you like to start?", USER_DECISION_POINTS.IDEATE)
        }, 0, "User Persona Sequence"),

        // Use case specific sub tree
        this.buildTreeForUseCase(this.getCurrentTemplate(), 'ideate'),

        // User has 2 personas and one motivation. Ask them if they want to continue or go to synthesise
        new TreeNode(NodeType.Action, [], {
          condition: () => Object.values(this.completedUserPersonaSteps).reduce((acc, curr) => acc + curr, 0) === 2 && Object.values(this.completedStakeholderMotivationSteps).reduce((acc, curr) => acc + curr, 0) === 1 && this.userDecisions[USER_DECISION_POINTS.AFTER_IDEATE] === undefined,
          action: () => this.promptChoicesForNextSequence("Amazing! We now have a couple of insights from which we derived user personas and stakeholder motivations. Let's go on to the synthesise board to " + this.getUseCaseText() + " from our insights.", USER_DECISION_POINTS.AFTER_IDEATE)
        }, 0, "Suggest next steps"),

        // Continue on the ideate board
        new TreeNode(NodeType.PrioritySelector, [
          // Fallback tree
          // TODO: Make the selection of the next sequence dynamic based on what kind of sequences the user has completed
          
          // Archetypes
          this.buildUserPersonaSequence(ANALYSES_TYPES['Archetype.cluster'].name, 2, SEQUENCES.ARCHETYPES_PERSONAS),

          // Greek gods
          this.buildUserPersonaSequence(ANALYSES_TYPES['Greek.cluster'].name, 2, SEQUENCES.GREEK_PERSONAS),
          
          // Colors
          this.buildUserPersonaSequence(ANALYSES_TYPES['colors.cluster'].name, 2, SEQUENCES.COLORS_PERSONAS),
          
          // Motivations
          this.buildStakeholderMotivationSequence(ANALYSES_TYPES['Motivations.cluster'].name, 2, SEQUENCES.MOTIVATIONS_MOTIVATIONS),
          
          // Personality
          this.buildUserPersonaSequence(ANALYSES_TYPES['Psych.cluster'].name, 2, SEQUENCES.PERSONALITY_PERSONAS),
          
          // Themes
          this.buildStakeholderMotivationSequence(ANALYSES_TYPES['Themes.cluster'].name, 2, SEQUENCES.THEMES_MOTIVATIONS),
          this.buildUserPersonaSequence(ANALYSES_TYPES['Themes.cluster'].name, 2, SEQUENCES.THEMES_PERSONAS),
          
        ], {
          condition: () => true,
          action: () => { }
        }, 0, "Continue exploring")

      ], {
        condition: () => this.getCurrentBoard() === 'ideate',
        action: async () => this.chatbotAssistant.updateWidget("We’re going to get our insights in a data-driven manner by analysing all our concepts from the 'inspire' board and ideating our user personas & stakeholder motivations around them.")
      }, 0, "Ideate Sequence"), // End of ideate branch
      
      new TreeNode(NodeType.PrioritySelector, [ // Handle synthesise branch
        new TreeNode(NodeType.Action, [], {
          condition: () => this.userDecisionsPerBoard[this.getCurrentBoard()]?.length === 0,
          action: () => this.promptChoicesForNextSequence("Welcome to the synthesise board. Here you can " + this.getUseCaseText() + " from your insights and export your results. What would you like to do next?", USER_DECISION_POINTS.SYNTHESISE)
        }, 0, "Suggest next steps"),

        // Use case specific sub tree
        this.buildTreeForUseCase(this.getCurrentTemplate(), 'moodboard'),
        
        new TreeNode(NodeType.Action, [], {
            condition: () => this.userDecisionsPerBoard[this.getCurrentBoard()]?.length === 1,
            action: () => this.promptChoicesForNextSequence("To take away your output, you can export a slide deck with your " + this.getUseCaseOutputText() + " and the conceptual territories we explored in our session. Would you like to do that now or continue?", USER_DECISION_POINTS.EXPORT)
          }, 0, "Export Sequence: Prompt user to export or continue"),

        // Fallback tree
        new TreeNode(NodeType.PrioritySelector, [
          // TODO: Make the selection of the next sequence dynamic based on which sequences the user has completed
          // The top priority sequence should be given to one of the actions that are associated with the current use case
          // Every x actions we should ask the user what they would like to do next
          this.buildSynthesiseSequence(1000, 'brand-strategy', SEQUENCES.SYNTHESISE_STRATEGY),
          this.buildSynthesiseSequence(1000, 'linkedinpost', SEQUENCES.LINKEDIN_POSTS),
          this.buildSynthesiseSequence(1000, 'product-brief', SEQUENCES.PRODUCT_BRIEF),
          this.buildSynthesiseSequence(1000, 'product-idea', SEQUENCES.PRODUCT_IDEA),
          this.buildSynthesiseSequence(1000, 'brainstorm', SEQUENCES.BRAINSTORM_SYNTHESISE),
          this.buildSynthesiseSequence(1000, 'taglines', SEQUENCES.TAGLINES_SYNTHESISE),
          this.buildSynthesiseSequence(1000, 'audience', SEQUENCES.AUDIENCE_PROFILE),
          this.buildSynthesiseSequence(1000, 'extract', SEQUENCES.EXTRACT),
          this.buildSynthesiseSequence(1000, 'summary', SEQUENCES.SUMMARY),
          this.buildSynthesiseSequence(1000, 'synopsis', SEQUENCES.SYNOPSIS),
          this.buildSynthesiseSequence(1000, 'unite', SEQUENCES.UNITE),
          this.buildSynthesiseSequence(1000, 'game', SEQUENCES.GAME),
        ], {
          condition: () => true,
          action: () => { }
        }, 0, "Fallback tree"),
      ], {
        condition: () => this.getCurrentBoard() === 'moodboard',
        action: async () => this.chatbotAssistant.updateWidget("Let's synthesise results from our inspirations and insights.")
      }, 0, "Synthesise Sequence"),

      new TreeNode(NodeType.Selector, [ // Handle liking of nodes if not already handled

        new TreeNode(NodeType.Action, [], {
          condition: (event) => [TUTORIAL_EVENT_TYPES.likedNode, TUTORIAL_EVENT_TYPES.likedResult, TUTORIAL_EVENT_TYPES.likedCluster].includes(event),
          action: () => this.handleLike()
        }, 0, "Like!")]
      ),

    ])
  }

  // Builds a sub tree for a specific use case (e.g. 'brainstorm', 'marketing', 'strategy') and board
  buildTreeForUseCase(useCase: UseCase, board: string): TreeNode {

    // Reduce the sequences for the use case so that complex sequences are reduced to their subsequences
    const sequencesForUseCase: string[] = [];
    SEQUENCES_BY_USE_CASE[useCase][board].forEach((sequence: string) => {
      if (!Object.keys(COMPLEX_SEQUENCES).includes(sequence)) {
        sequencesForUseCase.push(sequence);
      } else {
        COMPLEX_SEQUENCES[sequence].forEach((subSequence: string) => {
          sequencesForUseCase.push(subSequence);
        });
      }
    });

    console.log("Sequences for use case: ", sequencesForUseCase);

    const sequences: TreeNode[] = [];
    const pushedSequences: string[] = [];

    sequencesForUseCase.forEach((sequence: string) => {
      const previousSequencesCount = pushedSequences.filter((s: string) => s == sequence).length;
      pushedSequences.push(sequence);
      const newSequenceNumber = previousSequencesCount + 1;
      switch (sequence) {
        case SEQUENCES.SPARK:
          sequences.push(this.buildSparkExpansionSequence(newSequenceNumber));
          break;
        case SEQUENCES.EMPATHISE:
          sequences.push(this.buildEmpathiseExpansionSequence(newSequenceNumber));
          break;
        case SEQUENCES.BRAINSTORM:
          sequences.push(this.buildIdeaGenerationSequence(newSequenceNumber));
          break;
        case SEQUENCES.COMMON_IDEAS:
          sequences.push(this.buildFindingCommonIdeasSequence(newSequenceNumber));
          break;
        case SEQUENCES.SEARCH:
          sequences.push(this.buildSearchSequence(newSequenceNumber));
          break;
        case SEQUENCES.IMAGE_GENERATION:
          sequences.push(this.buildImageGenerationSequence(newSequenceNumber));
          break;
        case SEQUENCES.THEMES_MOTIVATIONS:
          sequences.push(this.buildStakeholderMotivationSequence(ANALYSES_TYPES['Themes.cluster'].name, newSequenceNumber, SEQUENCES.THEMES_MOTIVATIONS));
          break;
        case SEQUENCES.THEMES_PERSONAS:
          sequences.push(this.buildUserPersonaSequence(ANALYSES_TYPES['Themes.cluster'].name, newSequenceNumber, SEQUENCES.THEMES_PERSONAS));
          break;
        case SEQUENCES.PERSONALITY_PERSONAS:
          sequences.push(this.buildUserPersonaSequence(ANALYSES_TYPES['Psych.cluster'].name, newSequenceNumber, SEQUENCES.PERSONALITY_PERSONAS));
          break;
        case SEQUENCES.EXPORT:
          sequences.push(this.buildExportSequence());
          break;
        case SEQUENCES.SYNTHESISE_STRATEGY:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'brand-strategy', SEQUENCES.SYNTHESISE_STRATEGY));
          break;
        case SEQUENCES.LINKEDIN_POSTS:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'linkedinpost', SEQUENCES.LINKEDIN_POSTS));
          break;
        case SEQUENCES.PRODUCT_BRIEF:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'product-brief', SEQUENCES.PRODUCT_BRIEF));
          break;
        case SEQUENCES.PRODUCT_IDEA:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'product-idea', SEQUENCES.PRODUCT_IDEA));
          break;
        case SEQUENCES.BRAINSTORM_SYNTHESISE:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'brainstorm', SEQUENCES.BRAINSTORM_SYNTHESISE));
          break;
        case SEQUENCES.TAGLINES_SYNTHESISE:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'taglines', SEQUENCES.TAGLINES_SYNTHESISE));
          break;
        case SEQUENCES.AUDIENCE_PROFILE:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'audience', SEQUENCES.AUDIENCE_PROFILE));
          break;
        case SEQUENCES.EXTRACT:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'extract', SEQUENCES.EXTRACT));
          break;
        case SEQUENCES.SUMMARY:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'summary', SEQUENCES.SUMMARY));
          break;
        case SEQUENCES.SYNOPSIS:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'synopsis', SEQUENCES.SYNOPSIS));
          break;
        case SEQUENCES.UNITE:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'unite', SEQUENCES.UNITE));
          break;
        case SEQUENCES.GAME:
          sequences.push(this.buildSynthesiseSequence(newSequenceNumber, 'game', SEQUENCES.GAME));
          break;
      }
    });
    return new TreeNode(NodeType.PrioritySelector, sequences, {
      condition: () => true,
      action: () => { }
    }, 0, "Use case: " + useCase + " - " + board);
  }
  

  handleLike(): void {

    // if (this.chatbotAssistant.currentEventType === TUTORIAL_EVENT_TYPES.likedResult){
    //   this.chatbotAssistant.updateWidget("Lovely. How about adding this idea to the board?");
    // }
    const likedNodes: number = globalThis.neuroCreate.graph?.getLikedNodes().length ?? 0;
    const likeResponses = ["Lovely choice!", "Fantastic!", "Excellent!", "Great choice!", "Beautiful!", "You're doing great!"];
    const likeResponse = likeResponses[Math.floor(Math.random() * likeResponses.length)];
    // TODO: Handle the different amounts of liked nodes in the tree itself
    // E.g. have a condition in the Inspire Sequence that checks the amount of liked nodes and prompts the user with buttons to different sequences based on that.

    // if (likedNodes == 3) {
    // this.chatbotAssistant.updateWidget(likeResponse + " Now that we have a few ideas we like would you like .")
    // } else if (likedNodes == 5) {
    // this.chatbotAssistant.updateWidget(likeResponse + " Now that we have a good amount of ideas let's explore the problem space further.")
    // } else {
    this.chatbotAssistant.updateWidget(likeResponse);
    // }
    // if (this.chatbotAssistant.currentEventType === TUTORIAL_EVENT_TYPES.likedResult) {

    setTimeout(() => {
      tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.completedChatbotSequence);
    }, 2000);
    // }

  }

  welcomeUser(): void {

    let message = ""; 

    switch (this.getCurrentTemplate()) {
      case 'marketing':
        message = "developing a marketing strategy";
        break;
      case 'product_idea':
        message = "developing a product strategy";
        break;
      case 'branding':
        message = "developing a brand strategy";
        break;
      case 'product_brief':
        message = "developing a product brief";
        break;
      case 'brainstorming':
        message = "brainstorming creative ideas";
        break;
      case 'copywriting':
        message = "creating copywriting materials";
        break;
      default:
        message = "in the creative process";
        break;
    }

    this.chatbotAssistant.updateWidget("Hi, I'm Flowy, your AI assistant. I'm going to help you " + message + ". As a first step let's uncover the blind spots in our understanding of the problem space by using the 'spark' and 'empathise' tools.");
    this.chatbotAssistant.addButton("Let's get started!", () => {
      console.log("Chatbot started");
      tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.chatbotStarted);
    });

  }

  // Inspire sequences
  private buildSparkExpansionSequence(sequenceNumber: number = 1000): TreeNode {
    // The sequence is: 
    // 1. Select a node (no active node selected)
    // 2. Click the spark button (active node selected)
    // 3. Select a suggestion (spark clicked)
    // 4. Select another suggestion (addedTwoNodes && tool = spark && sparkCount == 1)
    // 5. Select another suggestion (addedTwoNodes && tool = spark && sparkCount == 2)
    // 6. Congratulate the user (addedTwoNodes && tool = spark && sparkCount == 3)

    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: (event) => (this.currentlyActiveTool === "" && !globalThis.neuroCreate.graph?.activeNode),
        action: async () => {
          const prompt = sequenceNumber === 1 ? "Let's select a node and spark ideas around it. " : "Let's expand the problem space further using the spark and empathise tools on one of the concepts. ";
          // If we have at least 2 nodes we suggest one of the nodes on the board
          if (globalThis.neuroCreate.graph?.nodes.length && globalThis.neuroCreate.graph?.nodes.length >= 2) {
            this.findInterestingNode(TOOLS.SPARK).then(interestingNode => this.chatbotAssistant.updateWidget(prompt + interestingNode));
          } else if (globalThis.neuroCreate.graph?.nodes.length && globalThis.neuroCreate.graph?.nodes.length === 1 && globalThis.neuroCreate.graph?.nodes[0].label) {
            this.chatbotAssistant.updateWidget("Let's select the node " + globalThis.neuroCreate.graph?.nodes[0].label + " and spark ideas around it.");
          } else {
            this.chatbotAssistant.updateWidget("Let's create a node by clicking on the board and entering a word.");
          }
        }
      }, 0, "Spark + " + sequenceNumber + " Expansion 1"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.nodeMenuOpened(),
        action: async () => {
          const activeNodeFirstSentence = this.getCurrentlyActiveNode().split(". ")[0];
          this.chatbotAssistant.updateWidget("'" + activeNodeFirstSentence + "' - Interesting concept. Let's use the tool 'spark' to explore it a bit deeper.")
        }
      }, 0, "Spark + " + sequenceNumber + " Expansion 2", TUTORIAL_EVENT_TYPES.openedNodeMenu),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === TOOLS.SPARK && this.getSelectedSuggestionsForNode('spark').length == 0,
        action: async () => {
          return this.suggestNextSparkResult();
        }
      }, 0, "Spark + " + sequenceNumber + " Expansion 3", TUTORIAL_EVENT_TYPES.clickedSpark),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.getSelectedSuggestionsForNode('spark').length === 1 && this.currentlyActiveTool === TOOLS.SPARK,
        action: async () => this.suggestNextSparkResult()
      }, 0, "Spark + " + sequenceNumber + " Expansion - Selecting 1st result", TUTORIAL_EVENT_TYPES.addedTwoNodes),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.getSelectedSuggestionsForNode('spark').length <= 2 && this.currentlyActiveTool === TOOLS.SPARK && this.lateralSliderValue === 0,
        action: async () => this.chatbotAssistant.updateWidget("Nice! How about using the laterality slider to get ideas which are a bit more out of the box?")
      }, 0, "Spark + " + sequenceNumber + " Expansion - Try laterality slider", TUTORIAL_EVENT_TYPES.addedTwoNodes),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => (this.getSelectedSuggestionsForNode('spark').length <= 2 && this.currentlyActiveTool === TOOLS.SPARK && this.lateralSliderValue !== 0),
        action: async () => {
          this.checkIfSliderStopped(this.lateralSliderValue).then((sliderStopped: boolean) => {
            if (sliderStopped) {
              this.suggestNextSparkResult();
            } else {
              console.log("Still sliding... Not updating chatbot yet.")
            }
          });
        }
      }, 0, "Spark + " + sequenceNumber + " Expansion - Selecting 2nd result", TUTORIAL_EVENT_TYPES.addedTwoNodes),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => (event === TUTORIAL_EVENT_TYPES.addedTwoNodes && this.getSelectedSuggestionsForNode('spark').length === 3 && this.currentlyActiveTool === TOOLS.SPARK),
        action: async () => this.completeSparkSequence()
      }, 0, "Spark + " + sequenceNumber + " Expansion - Selecting 3rd result", TUTORIAL_EVENT_TYPES.addedTwoNodes)
    ], {
      condition: () => this.completedSequences[SEQUENCES.SPARK] < sequenceNumber && (this.currentlyActiveTool === "" || this.currentlyActiveTool === TOOLS.SPARK),
      action: async () => this.chatbotAssistant.updateWidget("Let's explore the problem space and generate ideas.")
    }, 0, SEQUENCES.SPARK);
  }

  checkIfSliderStopped(sliderValue: number): Promise<boolean> {
    return new Promise((resolve) => {
      // After 100ms check if the slider value is still the same
      setTimeout(() => {
        if (this.chatbotAssistant.currentEventType === TUTORIAL_EVENT_TYPES.lateralSliderMoved) {
          resolve(this.lateralSliderValue === sliderValue);
        } else {
          resolve(true);
        }
      }, 100);
    });
  }


  completeSparkSequence(): void {

    // this.completedSparkSequences++;
    this.completedSequences[SEQUENCES.SPARK]++;
    console.log("Completed spark sequence", this.completedSequences[SEQUENCES.SPARK]);
    // this.selectedSparkSuggestionsForCurrentNode = {};
    this.resetActiveTool();
    // this.currentlyActiveTool = 'empathise';
    this.congratulateUser(0.75)

  }


  suggestNextSparkResult = async (): Promise<void> => {
    this.chatbotAssistant.updateWidget("Thinking...");
    const selectedSuggestions = this.getSelectedSuggestionsForNode('spark');
    console.log("Selected suggestions for tool " + this.currentlyActiveTool, selectedSuggestions)

    // Remove the selected suggestion from the currently shown suggestions
    const currentlyShownSuggestions = this.currentSuggestions['spark'].filter((pair) => !selectedSuggestions.some(selectedPair => selectedPair[0] === pair[0] && selectedPair[1] === pair[1]));

    if (currentlyShownSuggestions.length === 0) {
      this.chatbotAssistant.updateWidget("Hmm, looks like there are no spark results for this node. Let's try again with another node.");
      return;
    }

    // TODO: Use OpenAI to suggest a good next node
    // const suggestion = currentlyShownSuggestions[Math.floor(Math.random() * currentlyShownSuggestions.length)] ?? '';
    const projectVision = globalThis.neuroCreate.graph?.nodes[0].label ?? "";

    // Map the currently shown suggestions to strings of pairs with the format '<...> - <...>'
    const currentlyShownSuggestionsStrings = currentlyShownSuggestions.map(pair => "'" + pair[0] + " - " + pair[1] + "'");
    // const currentlyShownSuggestionsStrings = currentlyShownSuggestions.filter((el, index) => index %2 == 0).map((data, index) => `${data} - ${currentlyShownSuggestions[index*2 + 1]}`);


    const activeNode = this.getCurrentlyActiveNode();
    const suggestion = await getInterestingSuggestion(currentlyShownSuggestionsStrings, projectVision, TOOLS.SPARK, activeNode, this.getCurrentTemplate());

    // const suggestionString = suggestion.split(",").join(" - ");
    if (selectedSuggestions.length < 1) {

      // this.chatbotAssistant.updateWidget("Look at those suggestions... The pair '" + suggestion + "' could be interesting because of ... How about adding it to your board?")
      this.chatbotAssistant.updateWidget("Look at those suggestions... " + suggestion.data)
    } else if (selectedSuggestions.length < 2) {
      // TODO: Find next suggestion
      this.chatbotAssistant.updateWidget("Let's add another suggestion. " + suggestion.data)
    } else {
      this.chatbotAssistant.updateWidget(suggestion.data + "' How about adding it to your board or hearting it to add it to your idea box?")
    }
  }

  suggestNextBrainstormResult = async (): Promise<void> => {
    this.chatbotAssistant.updateWidget("Thinking...");
    const selectedSuggestions = this.getSelectedSuggestionsForNode('brainstorm');
    console.log("Selected suggestions for tool " + this.currentlyActiveTool, selectedSuggestions)
    console.log("Currently shown suggestions", this.currentSuggestions['brainstorm']);
    // Remove the selected suggestion from the currently shown suggestions
    const currentlyShownSuggestions = this.currentSuggestions['brainstorm'].filter((snippet) => !selectedSuggestions.includes(snippet));

    if (currentlyShownSuggestions.length === 0) {
      this.chatbotAssistant.updateWidget("Hmm, looks like there are no brainstorm results for this selection. Let's try again with another selection.");
      return;
    }

    const projectVision = globalThis.neuroCreate.graph?.nodes[0].label ?? "";

    // // Map the currently shown suggestions to strings of pairs with the format '<...> - <...>'
    // const currentlyShownSuggestionsStrings = currentlyShownSuggestions.map(pair => pair[0] + " - " + pair[1]);
    // const currentlyShownSuggestionsStrings = currentlyShownSuggestions.filter((el, index) => index %2 == 0).map((data, index) => `${data} - ${currentlyShownSuggestions[index*2 + 1]}`);

    // Encapsulate the suggestions in a string with the format 'suggestion1','suggestion2','suggestion3'
    const currentlyShownSuggestionsStrings = currentlyShownSuggestions.map(snippet => `'${snippet}'`)
    // const activeNode = this.getCurrentlyActiveNode();
    const suggestion = await getInterestingSuggestion(currentlyShownSuggestionsStrings, projectVision, TOOLS.BRAINSTORM, this.currentSelectionAsString, this.getCurrentTemplate());

    if (selectedSuggestions.length < 1) {
      this.chatbotAssistant.updateWidget("Here we have some new ideas. " + suggestion.data + " How about adding it to your board or hearting it?")
    } else {
      this.chatbotAssistant.updateWidget(suggestion.data + " How about adding it to your board or hearting it?")
    }
  }

  private buildEmpathiseExpansionSequence(sequenceNumber: number = 1000): TreeNode {
    // The sequence is: 
    // 1. Select a node (no active node selected)
    // 2. Click the empathise button (active node selected)
    // 3. Select a suggestion (empathise clicked)
    // 4. Select another suggestion (addedSingleNode && tool = empathise && empathiseCount == 1)
    // 5. Congratulate the user (addedSingleNode && tool = empathise && empathiseCount == 2)
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => (this.currentlyActiveTool === "") || this.nodeMenuOpened(),
        action: async () => {
          const node = globalThis.neuroCreate.graph?.activeNode?.label ?? this.lastNode?.label ?? "";
          this.chatbotAssistant.updateWidget("Let's explore a few different angles on '" + node + "' using the 'empathise' tool.")
        }
      }, 0, "Empathise + " + sequenceNumber + " Expansion 1"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === TOOLS.EMPATHISE && this.getSelectedSuggestionsForNode('empathise').length == 0,
        action: async () => {
          return this.suggestNextEmpathiseResult();
        }
      }, 0, "Empathise + " + sequenceNumber + " Suggesting 1st result", TUTORIAL_EVENT_TYPES.empathiseResultsGenerated),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.getSelectedSuggestionsForNode('empathise').length <= 1 && this.currentlyActiveTool === TOOLS.EMPATHISE && this.lateralSliderValue === 0,
        action: async () => this.chatbotAssistant.updateWidget("Nice! How about using the laterality slider to get related ideas which are a bit more out of the box?")
      }, 0, "Empathise + " + sequenceNumber + " Expansion - Suggesting to use laterality slider", TUTORIAL_EVENT_TYPES.addedSingleNode),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => (this.getSelectedSuggestionsForNode('empathise').length === 1 && this.currentlyActiveTool === TOOLS.EMPATHISE && this.lateralSliderValue !== 0),
        action: async () => {
          this.checkIfSliderStopped(this.lateralSliderValue).then((sliderStopped: boolean) => {
            if (sliderStopped) {
              this.suggestNextEmpathiseResult();
            }
          });
        }
      }, 0, "Empathise + " + sequenceNumber + " Expansion - Suggesting 2nd result", TUTORIAL_EVENT_TYPES.addedSingleNode),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.getSelectedSuggestionsForNode('empathise').length === 2 && this.currentlyActiveTool === TOOLS.EMPATHISE,
        action: async () => this.completeEmpathiseSequence()
      }, 0, "Empathise + " + sequenceNumber + " Expansion - Complete sequence", TUTORIAL_EVENT_TYPES.addedSingleNode)
    ], {
      condition: () => this.completedSequences[SEQUENCES.EMPATHISE] < sequenceNumber && (this.currentlyActiveTool === "" || this.currentlyActiveTool === TOOLS.EMPATHISE),
      action: async () => this.chatbotAssistant.updateWidget("Let's explore the problem space and generate ideas.")
    }, 0, SEQUENCES.EMPATHISE);
  }

  completeEmpathiseSequence(): void {
    // this.completedEmpathiseSequences++;
    this.completedSequences[SEQUENCES.EMPATHISE]++;
    console.log("Completed empathise sequence", this.completedSequences[SEQUENCES.EMPATHISE]);
    this.lastNode = undefined;

    this.resetActiveTool();
    // Randomly decide if we want to congratulate the user with a heart reminder
    this.congratulateUser(0.3)
  }
  suggestNextEmpathiseResult = async () => {
    // TODO: From the currently showing suggestions find a good result that hasn't been selected yet using chatgpt
    // Pass the current available suggestions and the already selected suggestions
    this.chatbotAssistant.updateWidget("Thinking...");
    const selectedSuggestions = this.getSelectedSuggestionsForNode('empathise');

    // Remove the selected suggestion from the currently shown suggestions
    // TODO: Make sure that the suggestions are actually visible. Some colors are hidden for certain modes.
    // Currently we're only selecting the first 12 suggestions
    const currentlyShownSuggestions = this.currentSuggestions['empathise'].slice(0, 12);
    // Filter out the selected suggestions and all other suggestions with the same color
    // 1. Find the colors of the selected suggestions
    const selectedSuggestionsColors = selectedSuggestions.map(({ color }) => color);
    console.log("Selected suggestions colors", selectedSuggestionsColors);
    // 2. Filter out all other suggestions with the same color
    const filteredCurrentlyShownSuggestions = currentlyShownSuggestions.filter(({ color }) => !selectedSuggestionsColors.some((selectedColor) => color === selectedColor));
    const projectVision = globalThis.neuroCreate.graph?.nodes[0].label ?? "";

    // Map the currently shown suggestions to strings of pairs with the format 'perspective: <perspectives[color]> - value: <value>'

    const currentlyShownSuggestionsStrings = filteredCurrentlyShownSuggestions.map(value => "perspective: " + Perspectives[value.color as EmpathiseColour] + " value: " + value.values);


    const activeNode = this.getCurrentlyActiveNode();
    const suggestion = await getInterestingSuggestion(currentlyShownSuggestionsStrings, projectVision, TOOLS.EMPATHISE, activeNode, this.getCurrentTemplate());

    // Get a suggestion from the OpenAI API
    // const result = currentlyShownSuggestions[Math.floor(Math.random() * currentlyShownSuggestions.length)] ?? '';

    if (selectedSuggestions.length < 1) {
      this.chatbotAssistant.updateWidget("Look at those... " + suggestion.data)
    } else {
      // TODO: Find next suggestion
      this.chatbotAssistant.updateWidget("Let's use another perspective. " + suggestion.data)
    }
  }

  private buildIdeaGenerationSequence(sequenceNumber: number = 1000): TreeNode {
    // The sequence is: 
    // 1. Select a node (no active node selected)
    // 2. Click the generate button (active node selected)
    // 3. Select the brainstorm tool (generate clicked)
    // 4. Select a few other nodes (brainstorm selected)
    // 5. Generate brainstorm results (done selecting items && tool = brainstorm)
    // 6. Select a suggestion (brainstormResultsGenerated)
    // 7. Select another suggestion (addedSingleNode && tool = brainstorm && brainstormCount == 1)
    // 8. Congratulate the user (addedSingleNode && tool = brainstorm && brainstormCount == 2)
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === "",
        action: async () => {
          const message = sequenceNumber === 1 ? "Now that we know more of what we don’t know let’s use the new concepts to generate new ideas. Let's select a node that you would like to brainstorm around & then click the 'generate' button." : "What about selecting a node and brainstorming some more ideas by clicking the 'generate' button?"
          this.chatbotAssistant.updateWidget(message)
        }
      }, 0, "Idea Generation 1", TUTORIAL_EVENT_TYPES.openedNodeMenu),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.getCurrentlyActiveNode() !== '' && (!isGeneratePanelVisible() || !this.isStillSameNode()),
        action: async () => {
          console.log("Currently active tool", this.currentlyActiveTool);
          this.chatbotAssistant.updateWidget("Let's brainstorm some ideas around this node by clicking on the 'generate' tool.");
          // this.lateralSliderValue = 0;
        }
      }, 0, "Idea Generation 2", TUTORIAL_EVENT_TYPES.clickedBrainstorm),
      new TreeNode(NodeType.Selector, [
        new TreeNode(NodeType.Action, [], {
          condition: () => this.currentlyActiveTool === TOOLS.GENERATE && this.isStillSameNode(),
          action: async () => this.chatbotAssistant.updateWidget("Click on the 'Brainstorm' button to brainstorm ideas around this node.")
        }, 0, "Idea Generation 3", TUTORIAL_EVENT_TYPES.clickedGenerate),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => event === TUTORIAL_EVENT_TYPES.clickedBrainstorm || event === TUTORIAL_EVENT_TYPES.selectedNode || event === TUTORIAL_EVENT_TYPES.selectingItems,
          action: async () => this.chatbotAssistant.updateWidget("Great! Select the project vision and a few other nodes that you'd like to brainstorm around.")
        }, 0, "Idea Generation 3", TUTORIAL_EVENT_TYPES.clickedBrainstorm),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => event === TUTORIAL_EVENT_TYPES.doneSelectingItems && this.currentlyActiveTool === TOOLS.BRAINSTORM,
          action: async () => this.chatbotAssistant.updateWidget("Nice! Now let's generate new ideas around this cluster of concepts by clicking on the 'Brainstorm ideas' button.")
        }, 0, "Idea Generation 3", TUTORIAL_EVENT_TYPES.doneSelectingItems),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => this.getSelectedSuggestionsForNode('brainstorm').length === 0 && this.currentlyActiveTool === TOOLS.BRAINSTORM,
          action: async () => this.suggestNextBrainstormResult()
        }, 0, "Idea Generation 4", TUTORIAL_EVENT_TYPES.clickedBrainstormResults),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => this.getSelectedSuggestionsForNode('brainstorm').length === 1 && this.currentlyActiveTool === TOOLS.BRAINSTORM,
          action: async () => this.suggestNextBrainstormResult()
        }, 0, "Idea Generation 5", TUTORIAL_EVENT_TYPES.addedSingleNode),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => this.getSelectedSuggestionsForNode('brainstorm').length === 2 && this.currentlyActiveTool === TOOLS.BRAINSTORM,
          action: async () => this.completeBrainstormSequence()
        }, 0, "Idea Generation 6", TUTORIAL_EVENT_TYPES.addedSingleNode)
      ],
        {
          condition: () => isGeneratePanelVisible(),
          action: async () => this.chatbotAssistant.updateWidget("Click on the 'Brainstorm' button to brainstorm ideas around this node.")
        }, 0, "Idea Generation 3", TUTORIAL_EVENT_TYPES.clickedGenerate),
    ], {
      condition: () => this.completedSequences[SEQUENCES.BRAINSTORM] < sequenceNumber && (this.currentlyActiveTool === "" || this.currentlyActiveTool === TOOLS.BRAINSTORM || this.currentlyActiveTool === TOOLS.GENERATE),
      action: async () => this.chatbotAssistant.updateWidget("Let's explore the problem space and generate ideas.")
    }, 0, SEQUENCES.BRAINSTORM);
  }

  completeBrainstormSequence(): void | PromiseLike<void> {
    // this.completedBrainstormSequences++;
    this.completedSequences[SEQUENCES.BRAINSTORM]++;
    console.log("Completed brainstorming sequence", this.completedSequences[SEQUENCES.BRAINSTORM]);
    // this.selectedSparkSuggestionsForCurrentNode = {};
    this.resetActiveTool();
    this.congratulateUser();
  }

  // heartReminderProbability is the probability that we will congratulate the user with a heart reminder
  private congratulateUser(heartReminderProbability: number = 0.0): void {
    // Pick a random message
    const messages = [
      "Awesome!",
      "Well done!",
      "Great job!",
      "Nice one!"
    ];


    const heartReminderMessages = [
      "Remember you can always heart nodes to add them to your idea box.",
      "If you liked any of the ideas you generated don't forget to heart them to add them to your idea box.",
      "Remember to heart nodes that you like.",
      "To use nodes on other boards you can heart them to add them to your idea box.",
      "Feel free to heart nodes that you like to use them on other boards."
    ]


    // Check if the initial exploration sequence per board is completed
    // Go over all sequences for the use case and check if they are completed
    const sequencesForUseCase: string[] = [];
    SEQUENCES_BY_USE_CASE[this.getCurrentTemplate() as UseCase][this.getCurrentBoard()].forEach((sequence: string) => {
      if (!Object.keys(COMPLEX_SEQUENCES).includes(sequence)) {
        sequencesForUseCase.push(sequence);
      } else {
        COMPLEX_SEQUENCES[sequence].forEach((subSequence: string) => {
          sequencesForUseCase.push(subSequence);
        });
      }
    });

    console.log("Sequences for use case", sequencesForUseCase);
    console.log("Completed sequences", this.completedSequences);
    console.log("Initial exploration finished", this.initialInspireExplorationFinished);
    // For each sequence the number of completed sequences should be greater or equal to the number the sequence appears in the use case
    const allSequencesCompleted = sequencesForUseCase.every((sequence) => this.completedSequences[sequence] >= sequencesForUseCase.filter((s) => s === sequence).length);

    if (allSequencesCompleted && !this.initialInspireExplorationFinished[this.getCurrentBoard()]) {
      this.initialInspireExplorationFinished[this.getCurrentBoard()] = true;
      console.log("Initial exploration sequence completed for board", this.getCurrentBoard());
    }

    let message = messages[Math.floor(Math.random() * messages.length)];
    const heartReminder = Math.random() < heartReminderProbability;
    if (heartReminder) {
      message += " " + heartReminderMessages[Math.floor(Math.random() * heartReminderMessages.length)];
    }

    this.chatbotAssistant.updateWidget(message);
    setTimeout(() => {
      tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.completedChatbotSequence);
    }, heartReminder ? 8000 : 2000);
  }

  private buildFindingCommonIdeasSequence(sequenceNumber: number = 1000): TreeNode {
    // The sequence is: 
    // 1. Select a node (no active node selected)
    // 2. Click the generate button (active node selected)
    // 3. Select the common ideas tool (generate clicked)
    // 4. Select a few other nodes (common ideas selected)
    // 5. Generate common ideas (done selecting items && tool = commonIdeas)
    // 6. Select a suggestion (commonIdeasResultsGenerated)
    // 7. Select another suggestion (addedSingleNode && tool = commonIdeas && commonIdeasCount == 1)
    // 8. Congratulate the user (addedSingleNode && tool = commonIdeas && commonIdeasCount == 2)
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === "" && !this.nodeMenuOpened(),
        action: async () => {
          const message = sequenceNumber === 1 ? "How about exploring some common ideas on our board? We can start by selecting a node that we find interesting and then selecting a few other nodes." : "How about exploring some more common ideas on our board?"
          this.chatbotAssistant.updateWidget(message)
        }
      }, 0, "Common Ideas 1: Select a node"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.nodeMenuOpened() && (!isGeneratePanelVisible() || !this.isStillSameNode()),
        action: async () => this.chatbotAssistant.updateWidget("To explore common ideas click the 'Generate' button.")
      }, 0, "Common Ideas 2: Click generate", TUTORIAL_EVENT_TYPES.openedNodeMenu),
      new TreeNode(NodeType.Selector, [
        new TreeNode(NodeType.Action, [], {
          condition: () => this.currentlyActiveTool !== TOOLS.COMMON_IDEAS && this.isStillSameNode(),
          action: async () => this.chatbotAssistant.updateWidget("Let's select the 'Common Ideas' button.")
        }, 0, "Common Ideas 3: Select common ideas", TUTORIAL_EVENT_TYPES.clickedGenerate),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => event === TUTORIAL_EVENT_TYPES.clickedCommonIdeas || event === TUTORIAL_EVENT_TYPES.selectedNode || event === TUTORIAL_EVENT_TYPES.selectingItems,
          action: async () => this.chatbotAssistant.updateWidget("Select a few other nodes that you'd like to generate common ideas around.")
        }, 0, "Common Ideas 4: Select other nodes", TUTORIAL_EVENT_TYPES.clickedCommonIdeas),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => event === TUTORIAL_EVENT_TYPES.doneSelectingItems && this.currentlyActiveTool === TOOLS.COMMON_IDEAS,
          action: async () => this.chatbotAssistant.updateWidget("Great! Now let's generate common ideas around your selection.")
        }, 0, "Common Ideas 5: Generate common ideas", TUTORIAL_EVENT_TYPES.doneSelectingItems),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => [TUTORIAL_EVENT_TYPES.clickedCommonIdeasResults].includes(event),
          action: async () => this.suggestNextCommonIdeaResult()
        }, 0, "Common Ideas 6: Select a suggestion"),
        new TreeNode(NodeType.Action, [], {
          condition: () => this.getSelectedSuggestionsForNode('commonIdeas').length === 1 && this.currentlyActiveTool === TOOLS.COMMON_IDEAS,
          action: async () => this.suggestNextCommonIdeaResult()
        }, 0, "Common Ideas 7: Select another suggestion"),
        new TreeNode(NodeType.Action, [], {
          condition: () => this.getSelectedSuggestionsForNode('commonIdeas').length === 2 && this.currentlyActiveTool === TOOLS.COMMON_IDEAS,
          action: async () => this.completeCommonIdeasSequence()
        }, 0, "Common Ideas 8: Congratulate user")
      ], {
        condition: () => isGeneratePanelVisible() && this.lastNode?.label === this.getCurrentlyActiveNode(),
        action: async () => this.chatbotAssistant.updateWidget("Click on the 'Common Ideas' button to generate common ideas around your selection.")
      }, 0, "Common Ideas 3: Click common ideas", TUTORIAL_EVENT_TYPES.clickedGenerate),
    ], {
      condition: () => this.completedSequences[SEQUENCES.COMMON_IDEAS] < sequenceNumber && (this.currentlyActiveTool === "" || this.currentlyActiveTool === TOOLS.COMMON_IDEAS || this.currentlyActiveTool === TOOLS.GENERATE),
      action: async () => this.chatbotAssistant.updateWidget("Let's explore the problem space and generate ideas.")
    }, 0, SEQUENCES.COMMON_IDEAS);
  }
  completeCommonIdeasSequence(): void | PromiseLike<void> {
    this.completedSequences[SEQUENCES.COMMON_IDEAS]++;
    console.log("Completed common ideas sequence", this.completedSequences[SEQUENCES.COMMON_IDEAS]);
    this.resetActiveTool();
    this.congratulateUser();
  }

  suggestNextCommonIdeaResult = async (): Promise<void> => {
    this.chatbotAssistant.updateWidget("Thinking...");
    const selectedSuggestions = this.getSelectedSuggestionsForNode('commonIdeas');
    console.log("Selected suggestions for tool " + this.currentlyActiveTool, selectedSuggestions)
    console.log("Currently shown suggestions", this.currentSuggestions[TOOLS.COMMON_IDEAS]);
    // Remove the selected suggestion from the currently shown suggestions
    const currentlyShownSuggestions = this.currentSuggestions[TOOLS.COMMON_IDEAS].filter((snippet) => !selectedSuggestions.includes(snippet));

    if (currentlyShownSuggestions.length === 0) {
      this.chatbotAssistant.updateWidget("Hmm, looks like there are no common ideas for this selection. Let's try expanding the selection or selecting a different node.");
      return;
    }

    const projectVision = globalThis.neuroCreate.graph?.nodes[0].label ?? "";

    // Convert the array of suggestions to a string array to match the expected parameter type
    const stringSuggestions = currentlyShownSuggestions.map(suggestion => typeof suggestion === 'string' ? suggestion : '');

    const suggestion = await getInterestingSuggestion(stringSuggestions, projectVision, TOOLS.COMMON_IDEAS, this.currentSelectionAsString, this.getCurrentTemplate());

    if (selectedSuggestions.length < 1) {
      this.chatbotAssistant.updateWidget("Here are some common ideas around your selection. " + suggestion.data)
    } else {
      this.chatbotAssistant.updateWidget(suggestion.data)
    }
  }

  buildSearchSequence(sequenceNumber: number): TreeNode {
    // The search sequence is:
    // 1. Select a node (no active node selected)
    // 2. Click the search button (active node selected)
    // 3. Select a suggestion (search results generated)
    // 4. Congratulate the user (addedSingleNode && tool = search)
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === "" && !this.nodeMenuOpened(),
        action: async () => {
          const message = sequenceNumber === 1 ? "How about searching for news related to a node you find interesting?" : "How about searching for some more information related to one of our nodes online?"
          this.chatbotAssistant.updateWidget(message)
        }
      }, 0, "Search 1: Select a node"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.nodeMenuOpened() && !isGeneratePanelVisible(),
        action: async () => this.chatbotAssistant.updateWidget("To search for news related to the selected concept click the 'Search' button.")
      }, 0, "Search 2: Click search", TUTORIAL_EVENT_TYPES.openedNodeMenu),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => event === TUTORIAL_EVENT_TYPES.searchResultsGenerated,
        action: async () => this.chatbotAssistant.updateWidget("Here are a few articles and images related to '" + this.getCurrentlyActiveNode() + "'. How about adding one or two of them to your board or idea box?")
      }, 0, "Search 3: Show search results"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => event === TUTORIAL_EVENT_TYPES.addedSingleNode && this.currentlyActiveTool === 'search',
        action: async () => this.completeSequence(SEQUENCES.SEARCH)
      }, 0, "Search 4: Congratulate user")
    ], {
      condition: () => this.completedSequences[SEQUENCES.SEARCH] < sequenceNumber && (this.currentlyActiveTool === "" || this.currentlyActiveTool === 'search'),
      action: async () => {}
    }, 0, SEQUENCES.SEARCH);
  }

  buildInspireGenerateSequence(sequenceNumber: number, tool: string, sequenceLabel: string): TreeNode {
    // The generate sequence takes care of the following tasks: Taglines, Haikus, AI Haikus, Image Generation
    // 1. Select a node (no active node selected)
    // 2. Click the generate button (active node selected)
    // 3. Select the <tool> tool (generate clicked)
    // 4. Select a suggestion (results generated)
    // 5. Select another suggestion (addedSingleNode && tool = <tool>)
    // 6. Let user heart if they like the result
    // 7. Congratulate the user (addedSingleNode && tool = <tool>)
    const requiredToolAction = tool === TOOLS.HAIKUS ? TUTORIAL_EVENT_TYPES.haikuResultsGenerated : tool === TOOLS.Ai_HAIKUS ? TUTORIAL_EVENT_TYPES.aiHaikuResultsGenerated : tool === TOOLS.IMAGE ? TUTORIAL_EVENT_TYPES.imageInspireGenerated : tool === TOOLS.GENERATE ? TUTORIAL_EVENT_TYPES.imageSynthesiseGenerated : tool === TOOLS.TAGLINES ? TUTORIAL_EVENT_TYPES.taglineResultsGenerated : tool === TOOLS.PERSONAS ? TUTORIAL_EVENT_TYPES.audiencesGenerated : undefined;
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === "" && !this.nodeMenuOpened(),
        action: async () => {
          const message = sequenceNumber === 1 ? "How about generating some " + tool + "? We can start by selecting a node that we find interesting." : "How about generating some more " + tool + "?"
          this.chatbotAssistant.updateWidget(message)
        }
      }, 0, "Generate 1: Select a node"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.nodeMenuOpened() && (!isGeneratePanelVisible() || !this.isStillSameNode()),
        action: async () => this.chatbotAssistant.updateWidget("To generate " + tool + " click the 'Generate' button.")
      }, 0, "Generate 2: Click generate", TUTORIAL_EVENT_TYPES.openedNodeMenu),
      new TreeNode(NodeType.Selector, [
        new TreeNode(NodeType.Action, [], {
          condition: () => this.currentlyActiveTool === TOOLS.GENERATE && this.isStillSameNode(),
          action: async () => this.chatbotAssistant.updateWidget("Let's select the '" + tool + "' button.")
        }, 0, "Generate 3: Select " + tool, TUTORIAL_EVENT_TYPES.clickedGenerate),
       new TreeNode(NodeType.Action, [], {
          condition: (event) => requiredToolAction === event,
          action: async () => this.suggestNextGenerateResult(tool)
        }, 0, "Generate 4: Select a suggestion"),
        new TreeNode(NodeType.Action, [], {
          condition: () => this.getSelectedSuggestionsForNode(tool).length === 1 && this.currentlyActiveTool === tool,
          action: async () => this.suggestNextGenerateResult(tool)
        }, 0, "Generate 5: Select another suggestion"),
        new TreeNode(NodeType.Action, [], {
          condition: () => this.getSelectedSuggestionsForNode(tool).length === 2 && this.currentlyActiveTool === tool,
          action: async () => this.completeSequence(tool)
        }, 0, "Generate 6: Congratulate user")
      ], {
        condition: () => isGeneratePanelVisible() && this.lastNode?.label === this.getCurrentlyActiveNode(),
        action: async () => this.chatbotAssistant.updateWidget("Click on the '" + tool + "' button to generate inspirations around your selection.")
      }, 0, "Generate 3: Click " + tool, TUTORIAL_EVENT_TYPES.clickedGenerate),
    ], {
      condition: () => this.completedSequences[sequenceLabel] < sequenceNumber && (this.currentlyActiveTool === "" || this.currentlyActiveTool === tool || this.currentlyActiveTool === TOOLS.GENERATE),
      action: async () => this.chatbotAssistant.updateWidget("Let's explore the problem space and generate ideas.")
    }, 0, sequenceLabel);
  }

  suggestNextGenerateResult = async (tool: string): Promise<void> => {
    const projectVision = globalThis.neuroCreate.graph?.nodes[0].label ?? "";
    this.chatbotAssistant.updateWidget("Thinking...");
    const selectedSuggestions = this.getSelectedSuggestionsForNode(tool);
    console.log("Selected suggestions for tool " + this.currentlyActiveTool, selectedSuggestions)
    console.log("Currently shown suggestions", this.currentSuggestions[tool]);

    
    
    if (tool === TOOLS.HAIKUS || tool === TOOLS.Ai_HAIKUS || tool === TOOLS.TAGLINES) {
      // Filter out the suggestions that are already selected or start the same
      const selectedSuggestionsFirstWords = selectedSuggestions.map((suggestion) => suggestion.split(" ")[0]);
      // Filter out suggestions that are not objects with a 'snippet' property
      const snippetSuggestions = this.currentSuggestions[tool]
      .filter((suggestion) => typeof suggestion === 'object' && 'snippet' in suggestion && suggestion.snippet && !selectedSuggestionsFirstWords.includes(suggestion.snippet.split(" ")[0]))
      .map((suggestion) => typeof suggestion === 'object' && 'snippet' in suggestion ? "'" + suggestion.snippet + "'" : '');
      // Get the snippets from the filtered suggestions
      console.log("Snippet suggestions", snippetSuggestions);
      const suggestion = await getInterestingSuggestion(snippetSuggestions, projectVision, tool, this.getCurrentlyActiveNode(), this.getCurrentTemplate());
      this.chatbotAssistant.updateWidget(suggestion.data)
    }

  }

  buildImageGenerationSequence(sequenceNumber: number): TreeNode {
    // The image generation sequence is:
    // 1. Select a node (no active node selected)
    // 2. Click the generate button (active node selected)
    // 3. Select the image generation tool (generate clicked)
    // 4. Click on 'Generate Image'
    // 5. Ask the user if they want to add the image to their board or idea box (results generated)
    // 6. Congratulate the user (addedSingleNode && tool = image generation)

    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === "" && !this.nodeMenuOpened(),
        action: async () => this.chatbotAssistant.updateWidget("How about generating an image related to a node you find interesting?")
      }, 0, "Image Generation 1: Select a node"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.nodeMenuOpened() && !isGeneratePanelVisible(),
        action: async () => this.chatbotAssistant.updateWidget("To generate an image click the 'Generate' button.")
      }, 0, "Image Generation 2: Click generate", TUTORIAL_EVENT_TYPES.openedNodeMenu),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => event === TUTORIAL_EVENT_TYPES.clickedGenerate,
        action: async () => this.chatbotAssistant.updateWidget("Let's select the 'Image' button.")
      }, 0, "Image Generation 3: Select image", TUTORIAL_EVENT_TYPES.clickedGenerate),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => event === TUTORIAL_EVENT_TYPES.clickedGenerateImage,
        action: async () => this.chatbotAssistant.updateWidget("Let's click on 'Generate Image'.")
      }, 0, "Image Generation 4: Click generate image", TUTORIAL_EVENT_TYPES.clickedGenerate),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => event === TUTORIAL_EVENT_TYPES.clickedGenerateImageResult,
        action: async () => this.chatbotAssistant.updateWidget("Here is an image related to '" + this.getCurrentlyActiveNode() + "'. If you like it, add it to your board or give it a heart to save it in your idea box.")
      }, 0, "Image Generation 5: Show image"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => [TUTORIAL_EVENT_TYPES.addedSingleNode, TUTORIAL_EVENT_TYPES.likedNode].includes(event) && this.currentlyActiveTool === TOOLS.IMAGE,
        action: async () => this.completeSequence(SEQUENCES.IMAGE_GENERATION)
      }, 0, "Image Generation 6: Congratulate user")
    ], {
      condition: () => this.completedSequences[SEQUENCES.IMAGE_GENERATION] < sequenceNumber && (this.currentlyActiveTool === "" || this.currentlyActiveTool === TOOLS.GENERATE || this.currentlyActiveTool === TOOLS.IMAGE),
      action: async () => this.chatbotAssistant.updateWidget("Let's generate some images.")
    }, 0, SEQUENCES.IMAGE_GENERATION);
  }

  completeSequence(sequence: string): void {
    this.completedSequences[sequence]++;
    console.log("Completed sequence", sequence, this.completedSequences[sequence]);
    this.resetActiveTool();
    this.congratulateUser();
  }

  // Ideate Tree

  buildStakeholderMotivationSequence(analysisType: string, sequenceNumber: number, label: string): TreeNode {
    /*
    The sequence is:
    1. Click the analysis button for the analysis type (no active analysis)
    2. Suggest a cluster using AI (analysis clicked && no active node)
    3. Suggest to click generate (analysis clicked && active node && no active tool)
    4. Suggest to click "motivations" (generate clicked && active node && active tool = generate)
    5. Suggest result (active tool = "motivations" && no result chosen)
    6. Suggest hearting or selecting another result (active tool = "motivations" && 1 result chosen)
    7. Congratulate the user (active tool = "motivations" && (2 results chosen || event = "likedResult"))
    */
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        // If generate container is not active and the currently active analysis is not the one we're building the sequence for, suggest clicking the analysis button
        condition: () => !isToolContainerVisible(TOOLS.GENERATE) && (!this.currentlyActiveAnalysis) && !this.currentlyActiveTool,
        action: async () => this.chatbotAssistant.updateWidget("Let's explore stakeholder motivations using a " + analysisType + " analysis. Click on the '" + analysisType + "' button to generate clusters based on your inspire board by " + analysisType + ".")
      }, 0, "Stakeholder Motivation 1: Click analysis button"),
      new TreeNode(NodeType.Action, [], {
        // If generate container is active and the currently active analysis is not the one we're building the sequence for, suggest closing the active analysis panel
        condition: (event) => isToolContainerVisible(TOOLS.GENERATE) && (!this.currentlyActiveAnalysis || this.currentlyActiveAnalysis !== analysisType) && event === TUTORIAL_EVENT_TYPES.completedChatbotSequence,
        action: async () => this.chatbotAssistant.updateWidget("Let's explore stakeholder motivations using a " + analysisType + " analysis. Close the active 'Generate' panel by clicking the 'X' button.")
      }, 0, "Stakeholder Motivation 2: Close analysis panel"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveAnalysis === analysisType && !globalThis.neuroCreate.graph?.activeNode,
        action: async () => this.suggestClusterWithMostGreenNodes()
      }, 0, "Stakeholder Motivation 2: Suggest cluster"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.currentlyActiveAnalysis === analysisType && !!globalThis.neuroCreate.graph?.activeNode && this.currentlyActiveTool === "",
        action: async () => {
          const isCluster = this.isActiveNodeCluster();
          if (isCluster) {
            this.chatbotAssistant.updateWidget("Nice. To generate stakeholder motivations around the '" + this.getCurrentlyActiveNode() + "' cluster click on the 'Generate' button.")
          } else {
            this.chatbotAssistant.updateWidget("You've selected a node of a cluster. To generate stakeholder motivations for this node click on the 'Generate' button. Otherwise, you can also select the whole cluster by clicking on its central node.")
          }
        }
      }, 0, "Stakeholder Motivation 3: Suggest clicking generate"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveTool === TOOLS.GENERATE && this.currentlyActiveAnalysis === analysisType,
        action: async () => this.chatbotAssistant.updateWidget("To generate stakeholder motivations click on 'Motivations'.")
      }, 0, "Stakeholder Motivation 4: Suggest clicking motivations"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.currentlyActiveTool === TOOLS.MOTIVATIONS && event === TUTORIAL_EVENT_TYPES.stakeholderMotivationsGenerated,
        action: async () => {
          // Singular of analysis type: if analysis type ends with an s, remove it
          const analysisTypeString = analysisType.endsWith('s') ? analysisType.toLowerCase().slice(0, -1) : analysisType.toLowerCase();
          this.chatbotAssistant.updateWidget("Here we have some stakeholder motivations based on the " + analysisTypeString + " '" + this.getCurrentlyActiveNode() + "'. What do you think about them? Heart them if you like them or select another cluster if you want to explore stakeholder perspectives from a different angle.")
        }
      }, 0, "Stakeholder Motivation 5: Suggest result"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => [TUTORIAL_EVENT_TYPES.likedNode, TUTORIAL_EVENT_TYPES.likedResult, TUTORIAL_EVENT_TYPES.likedCluster].includes(event) && this.currentlyActiveTool === TOOLS.MOTIVATIONS,
        action: async () => this.completeStakeholderMotivationSequence(analysisType)
        // TODO: Integrate liking with finishing the action. action: async () => this.chatbotAssistant.updateWidget("Nice! I've added that motivation to your idea box. ")
      }, 0, "Stakeholder Motivation 6: Like a motivation"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => event === TUTORIAL_EVENT_TYPES.addedSingleNode && this.currentlyActiveTool === TOOLS.MOTIVATIONS,
        action: async () => this.completeStakeholderMotivationSequence(analysisType)
      }, 0, "Stakeholder Motivation 7: Add a motivation to the board")
    ], {
      condition: () => this.completedStakeholderMotivationSteps[analysisType] < sequenceNumber,
      action: async () => this.chatbotAssistant.updateWidget("Let's explore the problem space and generate ideas.")
    }, 0, label);
  }

  suggestClusterWithMostGreenNodes(): void | PromiseLike<void> {

    // Find cluster with most green nodes
    const ideateGraph = globalThis.neuroCreate.graph as IdeateGraph;
    const greenNodesPerCluster = ideateGraph.clusters.map((cluster) => ({ cluster, greenCount: ideateGraph.getGreenNodesForCluster(cluster).length }));
    const clusterWithMostGreenNodes = greenNodesPerCluster.reduce((prev, current) => prev.greenCount > current.greenCount ? prev : current);
    const clusterName = clusterWithMostGreenNodes.cluster.group;
    // console.log("Cluster with most green nodes", clusterName, clusterWithMostGreenNodes.greenCount);

    this.chatbotAssistant.updateWidget("Let's select one of the clusters by clicking on its central node. The more green nodes a cluster contains, the more ideas it has that directly relate to your project vision. How about the cluster around '" + clusterName + "'?")
  }

  completeStakeholderMotivationSequence(analysisType: string): void | PromiseLike<void> {
    this.completedStakeholderMotivationSteps[analysisType]++;
     
    console.log("Completed stakeholder motivation sequence", this.completedSequences[SEQUENCES.THEMES_MOTIVATIONS]);
    this.currentlyActiveTool = TOOLS.GENERATE;
    // this.currentlyActiveAnalysis = "";
    this.lastNode = undefined;
    this.congratulateUser();
  }


  buildUserPersonaSequence(analysisType: string, sequenceNumber: number, label: string): TreeNode {
    /*
    The sequence is:
    1. Click the analysis button for the analysis type (no active analysis)
    2. Suggest a cluster using AI (analysis clicked && no active node)
    3. Suggest to click generate (analysis clicked && active node && no active tool)
    4. Suggest to click "user personas" (generate clicked && active node && active tool = generate)
    5. Suggest result (active tool = "user persona" && no result chosen)
    6. Suggest hearting or selecting another result (active tool = "user persona" && 1 result chosen)
    7. Congratulate the user (active tool = "user persona" && (2 results chosen || event = "likedResult"))
    */
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => (!this.currentlyActiveAnalysis) && !this.currentlyActiveTool && !isToolContainerVisible(TOOLS.GENERATE),
        action: async () => {
          // Get the number of completed user persona steps for all analysis types
          const completedSteps = Object.values(this.completedUserPersonaSteps).reduce((acc, curr) => acc + curr, 0);
          const text = completedSteps === 0 ? "Let's define personas to help us understand our audience. We can cluster our ideas by different types of analyses. How about we start with a " + analysisType + " analysis?" : "Let's define more personas using different analyses. How about we use a " + analysisType + " analysis this time?";
          this.chatbotAssistant.updateWidget(text)
        }
      }, 0, "User Persona 1: Click analysis button"),
      new TreeNode(NodeType.Action, [], {
        // If generate container is active and the currently active analysis is not the one we're building the sequence for, suggest closing the active analysis panel
        condition: (event) => isToolContainerVisible(TOOLS.GENERATE) && (!this.currentlyActiveAnalysis || this.currentlyActiveAnalysis !== analysisType) && event === TUTORIAL_EVENT_TYPES.completedChatbotSequence,
        action: async () => {
          const completedSteps = Object.values(this.completedUserPersonaSteps).reduce((acc, curr) => acc + curr, 0);
          const preposition = completedSteps === 0 ? "a" : "another";
          this.chatbotAssistant.updateWidget("Let's explore " + preposition + " user persona using a " + analysisType + " analysis. Close the active 'Generate' panel by clicking the 'X' button.")
        }
      }, 0, "User Persona 2: Close analysis panel"),
      new TreeNode(NodeType.Action, [], {
        // 
        condition: () => this.currentlyActiveAnalysis === analysisType && !globalThis.neuroCreate.graph?.activeNode,
        action: () => this.suggestClusterWithMostGreenNodes()
      }, 0, "User Persona 2: Suggest cluster"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveAnalysis === analysisType && !!globalThis.neuroCreate.graph?.activeNode && this.currentlyActiveTool === "" && !isToolContainerVisible(TOOLS.GENERATE),
        action: async () => {
          // Is the active node a cluster?
          const isCluster = this.isActiveNodeCluster();
          if (isCluster) {
            this.chatbotAssistant.updateWidget("Nice. To generate a user persona based on the words in the '" + this.getCurrentlyActiveNode() + "' cluster click on the 'Generate' button.")
          } else {
            this.chatbotAssistant.updateWidget("You've selected a node of a cluster. To generate a user persona for this node click on the 'Generate' button. Otherwise, you can also select the whole cluster by clicking on its central node.")
          }
        }
      }, 0, "User Persona 3: Suggest clicking generate"),
      new TreeNode(NodeType.Action, [], {
        condition: () => this.currentlyActiveAnalysis === analysisType && isToolContainerVisible(TOOLS.GENERATE) && this.currentlyActiveTool !== TOOLS.PERSONAS,
        action: async () => this.chatbotAssistant.updateWidget("Let's generate a user persona by clicking on the 'User Persona' button.")
      }, 0, "User Persona 4: Suggest clicking user personas"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.currentlyActiveTool === TOOLS.PERSONAS && event === TUTORIAL_EVENT_TYPES.personaResultsGenerated,
        action: async () => {
          const personaName = JSON.parse(this.currentSuggestions['personas'][0].snippet).name;
          const personaFirstName = personaName.split(" ")[0];
          this.chatbotAssistant.updateWidget("Ok, let's take a look at " + personaName + ". Do they represent a part of your audience or the voice of your brand? If you think so, give " + personaFirstName + " a heart to add their persona to your idea box.")
        }
      }, 0, "User Persona 5: Suggest selecting a user persona"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => [TUTORIAL_EVENT_TYPES.likedNode, TUTORIAL_EVENT_TYPES.likedResult, TUTORIAL_EVENT_TYPES.likedCluster].includes(event) && this.currentlyActiveTool === TOOLS.PERSONAS,
        action: async () => this.completeUserPersonaSequence(analysisType)
        // action: async () => this.chatbotAssistant.updateWidget("Nice! That persona is now in your idea box. Do you want to add them to the board as well?")
      }, 0, "User Persona 6: Like a user persona"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => event === TUTORIAL_EVENT_TYPES.clickedGenerateImage && this.currentlyActiveTool === TOOLS.PERSONAS,
        action: async () => this.completeUserPersonaSequence(analysisType)
      }, 0, "User Persona 7: Add a user persona to the board")
    ], {
      condition: () => this.completedUserPersonaSteps[analysisType] < sequenceNumber,
      action: async () => this.chatbotAssistant.updateWidget("Let's define personas to help us understand our audience. We can cluster our ideas by different types of analyses. How about we run an analysis by " + analysisType + "?")
    }, 0, label);
  }

  private isActiveNodeCluster() {
    return (globalThis.neuroCreate.graph as IdeateGraph).clusters.some(cluster => cluster.group === (globalThis.neuroCreate.graph?.activeNode?.label ?? ""));
  }

  completeUserPersonaSequence(analysisType: string): void | PromiseLike<void> {
    this.completedUserPersonaSteps[analysisType]++;
    
    console.log("Completed user persona sequence", this.completedUserPersonaSteps);
    // this.resetActiveTool();
    this.currentlyActiveTool = TOOLS.GENERATE;
    // this.currentlyActiveAnalysis = "";
    this.lastNode = undefined;
    this.congratulateUser();
  }

  // Synthesise Tree
  /*
  * Builds a branch for a given methodology in the synthesise panel
  * methodology is a SynthesiseTask (e.g. "Brand Strategy", "Post", "Product Design", "Product Brief", "PESTEL", "Scamper", ...)
  */
  buildSynthesiseSequence(sequenceNumber: number = 1000, methodology: SynthesiseTask, label: string): TreeNode {
    /*
    Sequence: 
    1. No node on board? 
    1.1 Suggest to open idea box
    1.2 Suggest an idea to add
    2. Node on board?
    2.1 Suggest to click on the node (no active node)
    2.2 Suggest to click on the node and then click on the "Generate" button (no active tool)
    2.3 Does user have 1 idea, 1 persona and 1 motivation? 
    2.3.1 Yes? Suggest to select all 3. Then click on the tool and submit
    2.3.2 No? Suggest to click on the tool and submit
    2.4 Suggest adding a result to the notepad (active tool = "synthesise" && no result chosen)
    2.5 Congratulate the user (active tool = "synthesise" && (result added || event = "likedResult"))
    */

    let currentJob = "";
    switch (methodology) {
      case "brand-strategy":
        currentJob = "generate a brand strategy";
        break;
      case "linkedinpost":
        currentJob = "generate a LinkedIn post";
        break;
      case "product-idea":
        currentJob = "generate a product idea";
        break;
      case "product-brief":
        currentJob = "generate a product brief";
        break;
      case "game":
        currentJob = "create a game";
        break;
      case "summary":
        currentJob = "generate a summary";
        break;  
      case "extract":
        currentJob = "extract information from a text";
        break;
      case "brainstorm":
        currentJob = "brainstorm ideas";
        break;
      case "taglines":
        currentJob = "generate taglines";
        break;
      case "audience":
        currentJob = "generate an audience profile";
        break;
      case "synopsis":
        currentJob = "generate a story synopsis";
        break;
      default:
        currentJob = "generate a " + methodology;
        break;
    }

    const methodologyReadable = getTaskReadableName(methodology);

    
    
    return new TreeNode(NodeType.Selector, [
      // No node on board selector
      this.buildNoNodeOnBoardSelector(),

      new TreeNode(NodeType.Action, [], {
        condition: () => !this.getCurrentlyActiveNode(),
        action: async () => this.chatbotAssistant.updateWidget("Let's select one of the nodes on the board to " + currentJob + ".")
      }, 0, "Synthesise 2.1: Node on board & no active node"),
      new TreeNode(NodeType.Action, [], {
        condition: () => !!this.getCurrentlyActiveNode() && this.currentlyActiveTool === "",
        action: async () => this.chatbotAssistant.updateWidget("To " + currentJob + " click on the 'Synthesise' button on the node.")
      }, 0, "Synthesise 2.2: Node on board & active tool = generate"),
      new TreeNode(NodeType.Selector, [
        new TreeNode(NodeType.Action, [], {
          condition: (event) => event === TUTORIAL_EVENT_TYPES.clickedSynthesise,
          action: async () => this.chatbotAssistant.updateWidget("Click on '" + methodologyReadable + "' in the 'Synthesise AI' panel on the left. For best results I'd suggest selecting 1 idea, 1 persona and 1 motivation by ticking the 'Include selected items in input' checkbox.")
        }, 0, "Synthesise 2.3.1: Suggest selecting all 3 and clicking on the tool"),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => [TUTORIAL_EVENT_TYPES.selectingItems, TUTORIAL_EVENT_TYPES.selectedNode].includes(event),
          action: async () => this.chatbotAssistant.updateWidget("Select a few nodes on the board and then click on the 'Done' when you're ready.")
        }, 0, "Synthesise 2.3.2: Suggest selecting items"),
        new TreeNode(NodeType.Action, [], {
          condition: (event) => event === TUTORIAL_EVENT_TYPES.doneSelectingItems,
          action: async () => this.chatbotAssistant.updateWidget("Excellent! Now click on '" + methodologyReadable + "' and then on 'Submit' to " + currentJob + ".")
        }, 0, "Synthesise 2.3.3: Suggest clicking submit")
      ], {
        condition: (event) => this.currentlyActiveTool === TOOLS.SYNTHESISE && this.hasIdeaPersonaAndMotivation(),
        action: async () => this.chatbotAssistant.updateWidget("Click on '" + methodologyReadable + "' in the 'Synthesise AI' panel on the left and then on 'Submit'.")
      }, 0, "Synthesise 2.3 Branch: User has 1 idea, 1 persona and 1 motivation"),
      
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.currentlyActiveTool === TOOLS.SYNTHESISE && event === TUTORIAL_EVENT_TYPES.clickedSynthesise && !this.hasIdeaPersonaAndMotivation(),
        action: async () => this.chatbotAssistant.updateWidget("To " + currentJob + " click on '" + methodologyReadable + "' in the 'Synthesise AI' panel on the left and then on 'Submit'.")
      }, 0, "Synthesise 2.4: User doesn't have 1 idea, 1 persona and 1 motivation"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.currentlyActiveTool === TOOLS.SYNTHESISE && event === TUTORIAL_EVENT_TYPES.synthesiseResult,
        action: async () => {
          // For taglines and audiences we generate multiple results
          const resultQuantityText = isPlural(methodology) ? "a few" : "a";
          const resultItThemText = isPlural(methodology) ? "them" : "it";
          const copyToNoteText = this.hasExportBeenSelected() ? "If you want to export " + resultItThemText + " later to your output document add " + resultItThemText + " to your notepad by clicking on the copy button in the 'Synthesise AI' panel." : "What do you think about adding " + resultItThemText + " to your notepad?";
          this.chatbotAssistant.updateWidget("There we have " + resultQuantityText + " " + methodologyReadable + ". " + copyToNoteText)
          
        }
      }, 0, "Synthesise 2.4: Add a result to the notepad"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.currentlyActiveTool === TOOLS.SYNTHESISE && event === TUTORIAL_EVENT_TYPES.addedToNote,
        action: async () => this.completeSynthesiseSequence(methodology)
      }, 0, "Synthesise 2.5: Added a result to the notepad")
    ], {
      condition: () => this.completedSynthesiseSequences[methodology] < sequenceNumber,
      action: async () => this.chatbotAssistant.updateWidget("Let's synthesise our results into a " + methodologyReadable + ".")
    }, 0, label);
  }

  hasIdeaPersonaAndMotivation(): boolean {
    return this.brainstormedIdeasCount > 0 && Object.values(this.completedUserPersonaSteps).reduce((acc, curr) => acc + curr, 0) > 0 && Object.values(this.completedStakeholderMotivationSteps).reduce((acc, curr) => acc + curr, 0) > 0;
  }

  completeSynthesiseSequence(methodology: SynthesiseTask): void | PromiseLike<void> {
    this.completedSynthesiseSequences[methodology]++;
    console.log("Completed synthesise sequence", this.completedSynthesiseSequences[methodology]);
    this.resetActiveTool();
    this.lastNode = undefined;
    this.congratulateUser();
  }

  buildNoNodeOnBoardSelector(): TreeNode {
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: () => !isHeartedListOpen(),
        action: async () => this.chatbotAssistant.updateWidget("Let's add some nodes to the board first. Open your idea box by clicking on the icon on the far right of the menu bar.")
      }, 0, "Synthesise Strategy 1.1: No node on board & idea box not opened"),
      new TreeNode(NodeType.Action, [], {
        condition: () => isHeartedListOpen(),
        action: async () => {
          if (hasHeartedItems()) {
            this.chatbotAssistant.updateWidget("Add a few ideas to your board by clicking on the '+' button on the item.")
          } else {
            this.chatbotAssistant.updateWidget("Your idea box is empty. To add ideas to your idea box go to the 'inspire' or 'ideate' board and heart some nodes.")
          }
        }
      }, 0, "Synthesise Strategy 1.2: No node on board & idea box opened")
    ], {
      condition: () => globalThis.neuroCreate.graph?.nodes.length === 0,
      action: async () => this.chatbotAssistant.updateWidget("Let's add some nodes to the board.")
    }, 0, "Synthesise Strategy 1: No node on board")
  }
  
  buildExportSequence(): TreeNode {
    /*
    If user has not exported yet:
    Sequence:
    1. Ask if they want to export or continue 
    2. Suggest clicking on export button (user has not opened the export modal yet)
    3. Suggest clicking on presentation button -> new tab will open (user has opened the export modal)
    4. Suggest selecting a few ideas, the persona and the stakeholder motivations in the presentation dialogue
    */
    return new TreeNode(NodeType.Selector, [
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.hasExportBeenSelected() && ![TUTORIAL_EVENT_TYPES.openedExport, TUTORIAL_EVENT_TYPES.openedPresentationPreview, TUTORIAL_EVENT_TYPES.clickedGeneratePresentation].includes(event),
        action: () => this.chatbotAssistant.updateWidget("Alright. Let's click on the export button in the middle of the menu bar.")
      }, 0, "Export Sequence: Suggest clicking on export button"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.hasExportBeenSelected() && event === TUTORIAL_EVENT_TYPES.openedExport,
        action: () => this.chatbotAssistant.updateWidget("From here we can export a PDF or a Google Slides presentation. What about creating a presentation for now?")
      }, 0, "Export Sequence: Suggest clicking on presentation button"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.hasExportBeenSelected() && event === TUTORIAL_EVENT_TYPES.openedPresentationPreview,
        action: () => this.chatbotAssistant.updateWidget("This is the presentation preview panel where you can pick your ideas, insights and results. How about you go through the draft presenttaion and then select a few of the motivations, personas and conceptual territories we explored in our session? When you're done you can click on 'Generate presentation' and enjoy.")
      }, 0, "Export Sequence: Suggest selecting ideas, personas and stakeholder motivations in presentation preview"),
      new TreeNode(NodeType.Action, [], {
        condition: (event) => this.hasExportBeenSelected() && event === TUTORIAL_EVENT_TYPES.clickedGeneratePresentation,
        action: () => this.completeExportSequence()
      }, 0, "Export Sequence: Suggest downloading presentation")
    ], {
      condition: () => this.exportSequenceCompleted === false && this.currentlyActiveTool === "",
      action: async () => this.chatbotAssistant.updateWidget("Let's export your " + this.getUseCaseOutputText() + ".")
    }, 0, SEQUENCES.EXPORT)
  }

  private hasExportBeenSelected() {
    return Object.values(this.userDecisions).includes(SEQUENCES.EXPORT);
  }

  completeExportSequence(): void {
    this.exportSequenceCompleted = true;
    this.chatbotAssistant.updateWidget("Great job! You just went through the whole creative process from exploring the problem space to synthesising your " + this.getUseCaseOutputText() + ". Feel free to go back between boards to continue your session.")
  }

  // Returns the last action node for which the condition is still true
  public getNextAction(node: TreeNode = this.currentNode): ActionNode | undefined {
    while (true) {
      if (node.type === NodeType.Selector || node.type === NodeType.PrioritySelector) {
        if (node.actionNode && !node.actionNode.condition(this.chatbotAssistant.currentEventType)) {
          return undefined;
        }

        let children = node.children;

        if (node.type === NodeType.PrioritySelector) {
          // Sort the children based on the priority
          // Higher priority means that the node should be evaluated first
          children = children.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
        }

        // For selectors go over all children until we find a node where the condition is true
        for (const child of children) {
          const result = this.getNextAction(child);
          if (result !== undefined) {
            return result;
          }
        }
        return undefined;

      } else if (node.type === NodeType.Action) {
        // If the condition is true, check if the next action in the sequence is also true
        if (node.actionNode && node.actionNode.condition(this.chatbotAssistant.currentEventType)) {
          // Check if there are more actions in the sequence that are already active
          this.currentNode = node;
          return node.actionNode;
        } else {
          return undefined;
        }
      } else if (node.type === NodeType.Sequence) {
        // For sequences, take the first action of the sequence and get the next action from there
        if (node.actionNode) {
          if (node.actionNode.condition(this.chatbotAssistant.currentEventType)) {
            node.currentChildIndex = 0;
            node = node.children[0];
            return this.getNextAction(node);
          } else {
            return undefined;
          }
        }
        return undefined;
      }
    }
  }


  private async findInterestingNode(tool: string): Promise<string> {
    console.log("Finding interesting node");
    this.chatbotAssistant.updateWidget("Thinking...");
    // We're usually intrested in a node that is close to the project vision or a meaningful node
    // Explain why this is interesting/ explain the link to the goal/ node
    // Currently: Get a random node from the graph 

    const projectVision = globalThis.neuroCreate.graph?.nodes[0].label ?? "";
    let nodesWithoutProjectVision = globalThis.neuroCreate.graph?.nodes.slice(1);

    // Remove all nodes for which the user has already selected a few suggestions in the current tool
    switch (tool) {
      case TOOLS.EMPATHISE:
        nodesWithoutProjectVision = nodesWithoutProjectVision?.filter(node => !this.selectedEmpathiseSuggestionsForCurrentNode[node.label ?? '']);
        break;
      case TOOLS.SPARK:
        const sparkSuggestionWords = Object.keys(this.selectedSparkSuggestionsForCurrentNode);
        console.log("Spark suggestion words: ", sparkSuggestionWords);
        nodesWithoutProjectVision = nodesWithoutProjectVision?.filter(node => !sparkSuggestionWords.some(word => node.label?.toLowerCase().includes(word.toLowerCase())));
        // console.log("Nodes without project vision: ", nodesWithoutProjectVision);
        break;
    }


    const nodeLabels = nodesWithoutProjectVision?.map(node => "'" + node.label + "'") ?? [""];
    console.log("Node labels: ", nodeLabels);
    const interestingNode = await getInterestingNode(nodeLabels, projectVision);
    if (interestingNode) {
      return interestingNode.data;
    } else {
      const randomNode = nodesWithoutProjectVision?.[Math.floor(Math.random() * nodesWithoutProjectVision.length)];
      return randomNode?.label ?? "";
    }
  }

  // The main function of the chatbot that's called on every user interaction
  public async handleUserAction() {

    console.log("Current template: ", this.getCurrentTemplate());
    console.log("Current board: ", this.getCurrentBoard());
    // Start the chatbot if it hasn't been started yet
    if (this.chatbotAssistant.currentEventType === TUTORIAL_EVENT_TYPES.chatbotStarted) {
      this.chatbotStarted = true;
    } else if (this.chatbotAssistant.currentEventType === TUTORIAL_EVENT_TYPES.userDecisionMade) {
      this.handleUserDecision();
    }

    console.log("Handling user action: ", this.chatbotAssistant.currentEventType, this.chatbotAssistant.currentEventData);
    console.log("Currently active board: ", globalThis.neuroCreate.graph?.graphMode);
    // 1. Update current suggestions if an AI tool has generated new suggestions. 
    // This is to make sure that we keep the state of the behavior tree consistent 
    this.handleFinishedAiTools();

    // 2. Handle tool selection
    this.handleToolSelected();

    // 3. Update currently selected suggestions from the suggestion box for each tool
    this.handleNodeAdded();

    console.log("Currently active tool: ", this.currentlyActiveTool);
    console.log("Currenly active node: ", this.getCurrentlyActiveNode());
    console.log("Currently active analysis: ", this.currentlyActiveAnalysis);

    // 4. Get the next action node in the tree
    // This sophisicated check traverses the tree from the root to find the last action node that is still valid
    this.reset();
    const actionNode = this.getNextAction(this.root);
    console.log("Current active node of the tree ", actionNode);
    if (actionNode) {
      actionNode.action();
    } else {
      console.log("No action node found. This means that the tree cannot handle the current event type or that we reached the end of the tree.");
    }
  }

  handleUserDecision() {
    // Store the decision the user made if they pressed a button
    this.userDecisionsPerBoard[this.chatbotAssistant.currentEventData?.board ?? '']?.push(this.chatbotAssistant.currentEventData?.decision);
    console.log("User decisions per board: ", this.userDecisionsPerBoard);
    this.userDecisions[this.chatbotAssistant.currentEventData?.decisionPoint ?? ''] = this.chatbotAssistant.currentEventData?.decision;
    console.log("User decision: ", this.userDecisions);
    console.log("Current decision: ", this.chatbotAssistant.currentEventData?.decision);
    const themeMotivationsNode = this.findNodeByLabel(this.root, SEQUENCES.THEMES_MOTIVATIONS);
    const themePersonasNode = this.findNodeByLabel(this.root, SEQUENCES.THEMES_PERSONAS);
    const personalityPersonasNode = this.findNodeByLabel(this.root, SEQUENCES.PERSONALITY_PERSONAS);

    // Set the priority of the sequences on the current board to 0
    this.resetPriorityOfSequencesOnCurrentBoard();

    // Set the priority of the clicked sequence to 1
    const clickedSequence = this.chatbotAssistant.currentEventData?.decision;
    const clickedSequenceNode = this.findNodeByLabel(this.root, clickedSequence);
    if (clickedSequenceNode) {
      clickedSequenceNode.priority = 1;
      console.log("Clicked sequence: ", clickedSequence, "Node: ", clickedSequenceNode, " Priority: ", clickedSequenceNode.priority);
    }

    // For the motivations and personas we add a bit of extra logic:
    // When the user clicked on themes->motivaions, let them stay in the themes analysis and suggest personas based on themes
    switch (this.chatbotAssistant.currentEventData?.decision) {
      // The user wants to start with the motivations for themes
      case SEQUENCES.THEMES_MOTIVATIONS:
        console.log("Motivations selected");
        if (themePersonasNode) {
          themePersonasNode.priority = 1;
        }
        if (themeMotivationsNode) {
          themeMotivationsNode.priority = 2;
          console.log("Motivations priority: ", themeMotivationsNode.priority);
        }
        if (personalityPersonasNode) {
          personalityPersonasNode.priority = 0;
        }
        break;
      case SEQUENCES.PERSONALITY_PERSONAS:
        console.log("Personas selected");
        if (personalityPersonasNode) {
          personalityPersonasNode.priority = 2;
        }
        if (themePersonasNode) {
          themePersonasNode.priority = 1;
        }
        if (themeMotivationsNode) {
          themeMotivationsNode.priority = 0;
        }
        break;
    }

    this.resetActiveTool();
  }

  // Sets the priority of all sequences for the current board to 0
  resetPriorityOfSequencesOnCurrentBoard() {
    const sequencesForCurrentBoard = Object.values(SEQUENCES_BY_USE_CASE[this.getCurrentTemplate() as UseCase][this.getCurrentBoard()]);
    sequencesForCurrentBoard.forEach(sequence => {
      const node = this.findNodeByLabel(this.root, sequence);
      if (node) {
        node.priority = 0;
      }
    });
  }

  switchBoard(board: GraphMode) {
    if (globalThis.neuroCreate.graph?.graphMode !== board) {
      if (globalThis.neuroCreate.switchToMode) {
        globalThis.neuroCreate.switchToMode(board, true, false);
      }
    }
  }

  findNodeByLabel(node: TreeNode, label: string): TreeNode | undefined {
    // Recursively search through the tree to find the node with the given label
    for (const child of node.children) {
      if (child.label === label) {
        return child;
      }
      if (child.children.length > 0) {
        const found = this.findNodeByLabel(child, label);
        if (found) {
          return found;
        }
      }
    }
  }

  // Update the current state of the suggestions if an AI tool has generated a new set of results
  // Each AI tool has a different way of passing the suggestions. The suggestions are always in the currentEventData.
  handleFinishedAiTools() {
    switch (this.chatbotAssistant.currentEventType) {
      case TUTORIAL_EVENT_TYPES.empathiseResultsGenerated:
        this.currentSuggestions[TOOLS.EMPATHISE] = this.chatbotAssistant.currentEventData?.suggestions;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.sparkResultsGenerated:
        this.currentSuggestions[TOOLS.SPARK] = this.chatbotAssistant.currentEventData?.pairs;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.haikuResultsGenerated:
        this.currentSuggestions[TOOLS.HAIKUS] = this.chatbotAssistant.currentEventData?.results;
        this.currentlyActiveTool = TOOLS.HAIKUS;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.aiHaikuResultsGenerated:
        this.currentSuggestions[TOOLS.Ai_HAIKUS] = this.chatbotAssistant.currentEventData?.results;
        this.currentlyActiveTool = TOOLS.Ai_HAIKUS;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.taglineResultsGenerated:
        this.currentSuggestions[TOOLS.TAGLINES] = this.chatbotAssistant.currentEventData?.results;
        this.currentlyActiveTool = TOOLS.TAGLINES;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.personaResultsGenerated:
        this.currentSuggestions[TOOLS.PERSONAS] = this.chatbotAssistant.currentEventData?.results;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.stakeholderMotivationsGenerated:
        this.currentSuggestions[TOOLS.MOTIVATIONS] = this.chatbotAssistant.currentEventData?.results;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.brainstormResultsGenerated:
        this.currentSelectionAsString = this.chatbotAssistant.currentEventData?.results[0].title;
        this.currentSuggestions[TOOLS.BRAINSTORM] = this.chatbotAssistant.currentEventData?.results.map((result: { snippet: string }) => result.snippet);
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.commonIdeasResultsGenerated:
        this.currentSelectionAsString = this.chatbotAssistant.currentEventData?.results[0].title;
        this.currentSuggestions[TOOLS.COMMON_IDEAS] = this.chatbotAssistant.currentEventData?.results.map((result: { snippet: string }) => result.snippet);
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.imageInspireGenerated:
        this.currentSuggestions['image-inspire'] = this.chatbotAssistant.currentEventData?.entries;
        this.currentlyActiveTool = TOOLS.IMAGE;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.imageSynthesiseGenerated:
        this.currentSuggestions['image-synthesise'] = this.chatbotAssistant.currentEventData?.entries;
        this.currentlyActiveTool = TOOLS.IMAGE;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.audiencesGenerated:
        this.currentSuggestions.audience = this.chatbotAssistant.currentEventData?.entries;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.searchResultsGenerated:
        this.currentSuggestions.search = this.chatbotAssistant.currentEventData?.results;
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.clickedGenerateImageResult:
        console.log("Finished AI tool: ", this.chatbotAssistant.currentEventType, " Current suggestions: ", this.currentSuggestions);
        break;
      case TUTORIAL_EVENT_TYPES.afterNewAnalysis:
        this.currentlyActiveAnalysis = this.chatbotAssistant.currentEventData?.analysisKey;
        break;
    }
  }

  handleNodeAdded() {
    const currentlyActiveNode = this.getCurrentlyActiveNode();
    // Increase node count if we've added a new node
    switch (this.chatbotAssistant.currentEventType) {
      case TUTORIAL_EVENT_TYPES.addedSingleNode:
        const node = this.chatbotAssistant.currentEventData?.node?.label
        switch (this.currentlyActiveTool) {
          case TOOLS.EMPATHISE:
            if (!this.selectedEmpathiseSuggestionsForCurrentNode[currentlyActiveNode]) {
              this.selectedEmpathiseSuggestionsForCurrentNode[currentlyActiveNode] = [];
            }
            this.selectedEmpathiseSuggestionsForCurrentNode[currentlyActiveNode].push({ color: this.chatbotAssistant.currentEventData?.node?.nodeColour ?? '', label: node ?? '' });
            this.newNodeCount++;
            console.log("Added single node to empathise: ", currentlyActiveNode, " : ", this.selectedEmpathiseSuggestionsForCurrentNode[currentlyActiveNode]);
            break;
          case TOOLS.COMMON_IDEAS:
            if (!this.selectedCommonIdeasSuggestionsForCurrentNode[currentlyActiveNode]) {
              this.selectedCommonIdeasSuggestionsForCurrentNode[currentlyActiveNode] = [];
            }
            this.selectedCommonIdeasSuggestionsForCurrentNode[currentlyActiveNode].push(node);
            this.commonIdeasCount++;
            break;
          case TOOLS.BRAINSTORM:
            if (!this.selectedBrainstormSuggestionsForCurrentNode[this.currentSelectionAsString]) {
              this.selectedBrainstormSuggestionsForCurrentNode[this.currentSelectionAsString] = [];
            }
            this.selectedBrainstormSuggestionsForCurrentNode[this.currentSelectionAsString].push(node);
            this.brainstormedIdeasCount++;
            console.log("Added liked brainstorm result: ", this.currentSelectionAsString, " : ", this.selectedBrainstormSuggestionsForCurrentNode[this.currentSelectionAsString]);
            break;
          case TOOLS.TAGLINES:
            if (!this.selectedTaglinesSuggestionsForCurrentNode[currentlyActiveNode]) {
              this.selectedTaglinesSuggestionsForCurrentNode[currentlyActiveNode] = [];
            }
            this.selectedTaglinesSuggestionsForCurrentNode[currentlyActiveNode].push(node);
            // this.taglinesCount++;
            break;
          case TOOLS.HAIKUS:
            if (!this.selectedHaikusSuggestionsForCurrentNode[currentlyActiveNode]) {
              this.selectedHaikusSuggestionsForCurrentNode[currentlyActiveNode] = [];
            }
            this.selectedHaikusSuggestionsForCurrentNode[currentlyActiveNode].push(node);
            // this.haikuCount++;
            break;
          case TOOLS.Ai_HAIKUS:
            if (!this.selectedAiHaikusSuggestionsForCurrentNode[currentlyActiveNode]) {
              this.selectedAiHaikusSuggestionsForCurrentNode[currentlyActiveNode] = [];
            }
            this.selectedAiHaikusSuggestionsForCurrentNode[currentlyActiveNode].push(node);
            // this.haikuCount++;
            break;
        }
        break;
      case TUTORIAL_EVENT_TYPES.addedTwoNodes:
        if (this.currentlyActiveTool === TOOLS.SPARK) {
          if (!this.selectedSparkSuggestionsForCurrentNode[currentlyActiveNode]) {
            this.selectedSparkSuggestionsForCurrentNode[currentlyActiveNode] = [];
          }
          const pair: [string, string] = [this.chatbotAssistant.currentEventData?.firstNode.label ?? '', this.chatbotAssistant.currentEventData?.secondNode.label ?? '']
          this.selectedSparkSuggestionsForCurrentNode[currentlyActiveNode].push(pair);
          // this.selectedSparkSuggestionsForCurrentNode[currentlyActiveNode].push(this.chatbotAssistant.currentEventData?.secondNode.label ?? '');
          this.newNodeCount += 2;
          console.log("Added two nodes to spark: ", currentlyActiveNode, " : ", this.selectedSparkSuggestionsForCurrentNode[currentlyActiveNode]);
        }
        break;
      case TUTORIAL_EVENT_TYPES.createdNode:
        this.newNodeCount++;
        this.currentlyActiveTool = "";
        break;
    }
  }

  // Sets the current AI tool if the user selected an AI tool
  handleToolSelected() {
    switch (this.chatbotAssistant.currentEventType) {
      case TUTORIAL_EVENT_TYPES.clickedEmpathise:
        this.currentlyActiveTool = TOOLS.EMPATHISE;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedSpark:
        this.currentlyActiveTool = TOOLS.SPARK;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedAIHaikus:
        this.currentlyActiveTool = TOOLS.Ai_HAIKUS;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedHaikus:
        this.currentlyActiveTool = TOOLS.HAIKUS;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedTaglines:
        this.currentlyActiveTool = TOOLS.TAGLINES;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedGenerateUserPersona:
        this.currentlyActiveTool = TOOLS.PERSONAS;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedGenerateMotivations:
        this.currentlyActiveTool = TOOLS.MOTIVATIONS;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedBrainstorm:
        this.currentlyActiveTool = TOOLS.BRAINSTORM;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedBrainstormResults:
        this.currentlyActiveTool = TOOLS.BRAINSTORM;
        this.currentSelectedNodes = this.chatbotAssistant.currentEventData.selectedNodes; // Store the currently selected nodes here so that we can access them in the chatgpt prompt
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedCommonIdeas:
        this.currentlyActiveTool = TOOLS.COMMON_IDEAS;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedGenerate:
        this.currentlyActiveTool = TOOLS.GENERATE;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedGenerateImage:
        this.currentlyActiveTool = TOOLS.IMAGE;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.openedNodeMenu:
        // this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        this.currentlyActiveTool = '';
        break;
      case TUTORIAL_EVENT_TYPES.clickedSynthesise:
        this.currentlyActiveTool = TOOLS.SYNTHESISE;
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.clickedSearch:
        this.currentlyActiveTool = 'search';
        this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.doneSelectingItems:
        if (this.currentlyActiveTool === TOOLS.SPARK || this.currentlyActiveTool === TOOLS.EMPATHISE) {
          this.currentlyActiveTool = '';
        }
        // this.lastNode = globalThis.neuroCreate.graph?.activeNode;
        break;
      case TUTORIAL_EVENT_TYPES.lateralSliderMoved:
        // console.log("Lateral slider moved: ", this.chatbotAssistant.currentEventData);
        this.lateralSliderValue = this.chatbotAssistant.currentEventData ?? 0;
        // console.log("Lateral slider moved: ", this.lateralSliderValue);
        break;
      case TUTORIAL_EVENT_TYPES.closedTool:
        this.currentlyActiveTool = '';
        this.currentlyActiveAnalysis = '';
        break;
    }
  }

  // Utils
  getCurrentlyActiveNode(): string {
    const activeNode = globalThis.neuroCreate.graph?.activeNode?.label ?? this.lastNode?.label ?? '';
    return activeNode;
  }

  isStillSameNode(): boolean {
    return this.lastNode?.label === this.getCurrentlyActiveNode()
  }

  getCurrentTemplate(): UseCase {
    return this.template ?? globalThis.neuroCreate.graph?.templateId ?? 'default';
  }
  
  public reset() {
    this.root.reset();
    this.currentNode = this.root;
  }

  private getUseCaseText() {
    let useCaseText = "";
    switch (this.getCurrentTemplate()) {
      case 'marketing':
        useCaseText = "synthesise marketing materials";
        break;
      case 'product_idea':
        useCaseText = "generate product ideas and product briefs";
        break;
      case 'branding':
        useCaseText = "develop a brand strategy";
        break;
      case 'copywriting':
        useCaseText = "generate copywriting materials";
        break;
      case 'product_brief':
        useCaseText = "create a product brief";
        break;
      case 'design_thinking':
        useCaseText = "create design thinking outputs";
        break;
      case 'brainstorming':
        useCaseText = "generate ideas";
        break;
      default:
        useCaseText = "a problem space";
    }
    return useCaseText;
  }

  private getUseCaseOutputText() {
    let useCaseOutputText = "";
    switch (this.getCurrentTemplate()) {
      case 'marketing':
        useCaseOutputText = "marketing materials";
        break;
      case 'product_idea':
        useCaseOutputText = "product ideas and product briefs";
        break;
      case 'branding':
        useCaseOutputText = "brand strategy";
        break;
      case 'copywriting':
        useCaseOutputText = "copywriting materials";
        break;
      case 'product_brief':
        useCaseOutputText = "product brief";
        break;
      case 'design_thinking':
        useCaseOutputText = "design thinking outputs";
        break;
      case 'brainstorming':
        useCaseOutputText = "ideas";
        break;
      default:
        useCaseOutputText = "synthesised ideas";
    }
    return useCaseOutputText;
  }

  
  // Check if the user has made a decision for the current board at any decision point 
  hasUserMadeDecision(): boolean {
    const availableOptionsCurrentBoard = Object.values(this.BUTTON_OPTIONS).filter((option: any) => option.board === this.getCurrentBoard());
    Object.values(USER_DECISION_POINTS).forEach((decisionPoint: string) => {
      if (availableOptionsCurrentBoard.includes(this.userDecisions[decisionPoint] as any)) {
        console.log("User has made a decision for", decisionPoint);
        return true;
      }
    });
    return false;
  }

  getCurrentBoard(): string {
    if (this.chatbotAssistant.currentEventType === TUTORIAL_EVENT_TYPES.switchBoard) {
      return globalThis.neuroCreate.graph?.graphMode ?? '';
    }
    return globalThis.neuroCreate.graph?.graphMode ?? '';
  }

  // Check if the node menu is opened and no tool is selected
  private nodeMenuOpened(): boolean {
    return !!globalThis.neuroCreate.graph?.activeNode && this.currentlyActiveTool === "";
  }
  
    
  // Reset the currently active tool
  resetActiveTool(): void {
    this.currentlyActiveTool = '';
    this.lateralSliderValue = 0;
    // this.currentlyActiveAnalysis = '';
  }
  
  getSelectedSuggestionsForNode(tool: string): any[] {
    const activeNode = this.getCurrentlyActiveNode();
    console.log("Active node", activeNode);
    if (tool === 'spark') {
      return this.selectedSparkSuggestionsForCurrentNode[activeNode] ?? [];
    } else if (tool === 'empathise') {
      return this.selectedEmpathiseSuggestionsForCurrentNode[activeNode] ?? [];
    } else if (tool === 'brainstorm') {
      return this.selectedBrainstormSuggestionsForCurrentNode[this.currentSelectionAsString] ?? [];
    } else if (tool === 'commonIdeas') {
      return this.selectedCommonIdeasSuggestionsForCurrentNode[activeNode] ?? [];
    } else if (tool === 'userPersona') {
      return this.selectedPersonasSuggestionsForCurrentNode[activeNode] ?? [];
    } else if (tool === TOOLS.Ai_HAIKUS) {
      return this.selectedAiHaikusSuggestionsForCurrentNode[activeNode] ?? [];
    } else if (tool === TOOLS.HAIKUS) {
      return this.selectedHaikusSuggestionsForCurrentNode[activeNode] ?? [];
    } else if (tool === TOOLS.TAGLINES) {
      return this.selectedTaglinesSuggestionsForCurrentNode[activeNode] ?? [];
    } 
    return [];
  }


  // Updates the widget and adds buttons for the next sequence
  promptChoicesForNextSequence(message: string, decisionPoint: string): void {
    this.chatbotAssistant.updateWidget(message);
    let buttons = [];
    if (decisionPoint === USER_DECISION_POINTS.START) {
      buttons = Object.entries(this.BUTTON_OPTIONS_START).map(([sequence, action]) => ({sequence, ...action}));
    } else {
      // Get all sequences for the current use case on the current board
      // Returns an array of arrays of sequences for each board
      const sequencesForCurrentUseCase = Object.values(SEQUENCES_BY_USE_CASE[this.getCurrentTemplate() as keyof typeof SEQUENCES_BY_USE_CASE]);
      // Flatten the array of arrays
      const sequencesForCurrentUseCaseFlattened = sequencesForCurrentUseCase.flat();    
      // Make unique to make sure we don't add the same button twice
      const sequencesForCurrentUseCaseUnique = [...new Set(sequencesForCurrentUseCaseFlattened)];
      console.log("Sequences for current use case unique: ", sequencesForCurrentUseCaseUnique);
      // Get all buttons for the required sequences
      const buttonsForRequiredSequences = sequencesForCurrentUseCaseUnique.map(sequence => ({sequence, ...this.BUTTON_OPTIONS[sequence as keyof typeof SEQUENCES_BY_USE_CASE]}));
      console.log("Buttons for required sequences: ", buttonsForRequiredSequences);
      // Remove undefined buttons
      const buttonsForRequiredSequencesFiltered = buttonsForRequiredSequences.filter((action) => action.action !== undefined);
      console.log("Buttons for required sequences filtered: ", buttonsForRequiredSequencesFiltered);

      // Sort buttons by current board
      const buttonsCurrentBoard = buttonsForRequiredSequencesFiltered.filter((action) => action.board === globalThis.neuroCreate.graph?.graphMode);
      const buttonsOtherBoards = buttonsForRequiredSequencesFiltered.filter((action) => action.board !== globalThis.neuroCreate.graph?.graphMode);
      buttons = [...buttonsCurrentBoard, ...buttonsOtherBoards];
    }

    buttons.forEach((action) => {
      console.log("Action: ", action);
      if (action.condition()) {
        this.chatbotAssistant.addButton(action.label, () => {
          if (decisionPoint === USER_DECISION_POINTS.START) {
            action.action();
            // Rebuild the tree to make sure we're using the current use case and board
            this.root = this.buildTree();
          } else {
            if (this.getCurrentBoard() !== action.board) {
              this.switchBoard(action.board as GraphMode);
            }
          }
          tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.userDecisionMade, {  decision: action.sequence, decisionPoint: decisionPoint, board: action.board });
        });
      }
    });
    this.chatbotAssistant.updateWidgetHeight();
    
  }

}

