import IdeateGraph from '../graphs/IdeateGraph';
import { IDEATE_RECOMMENDATIONS } from '../constants';
import { ANALYSES_TYPES, AnalysisKey } from '../../../../src/commonConstants';
import { ClusterAnalysis } from '../../../../src/commonTypes';

const analyseForm = document.querySelector('#analyse-form-container');
if (analyseForm) {
	analyseForm.querySelector('.close-button')!.addEventListener('click', () => {
		analyseForm.classList.add('hidden');
	});
	analyseForm.querySelector('#board-form-submit')!.addEventListener('click', (e: Event) => {
		e.preventDefault();

		const analyseAnswers = Array.from(
			analyseForm.querySelectorAll<HTMLInputElement>('.form-recommend fieldset input:checked')
		)
			.filter((input) => input.checked)
			.map((input) => input.value) as Array<AnalysisKey>;

		if (globalThis.neuroCreate.saveGraph) {
			globalThis.neuroCreate.saveGraph(
				'ideate',
				{ analyses: analyseAnswers, links: [], nodes: [] },
				() => {
					analyseForm.classList.add('hidden');
					(globalThis.neuroCreate.graph as IdeateGraph).finishedRecommenderForm();
				}
			);
		}
	});
}

// Analyses Recommendation
const insights = document.querySelectorAll<HTMLInputElement>('#analysesForm .first-question input');
const recommendedAnalysesContainer = document.querySelector('.recommended-analyses')!;
const analyseContainer = document.querySelector('.analyse-options')!;

insights.forEach((insight) => {
	insight.addEventListener('click', () => {
		IDEATE_RECOMMENDATIONS.forEach((answer) => {
			const insightLabel = insight.labels && insight.labels[0];
			if (insightLabel?.innerText === answer.name) {
				recommendedAnalysesContainer.innerHTML = '';
				analyseContainer.innerHTML = '';
				answer.recommendations.forEach((backendName) => {
					const newAnswer = document.createElement('input');
					newAnswer.id = backendName;
					newAnswer.checked = true;
					const newAnswerLabel = document.createElement('label');
					newAnswerLabel.htmlFor = backendName;
					newAnswerLabel.innerText = ANALYSES_TYPES[backendName].name;
					const infoTip = document.createElement('span');
					// @Chohee TODO
					infoTip.dataset.tooltip = 'Coming Soon';
					infoTip.innerText = 'i';
					newAnswer.type = 'checkbox';
					newAnswer.value = backendName;

					recommendedAnalysesContainer.append(newAnswer, newAnswerLabel);
					newAnswerLabel.appendChild(infoTip);
				});

				const recommendedAnalyses = answer.recommendations;
				const difference = (
					Object.entries(ANALYSES_TYPES) as Array<[AnalysisKey, ClusterAnalysis]>
				).filter(([analysisKey]) => !recommendedAnalyses.includes(analysisKey));

				difference.forEach(([analysisKey, analysis]) => {
					if (analysis.category !== 'Motivations') {
						const newAnswer = document.createElement('input');
						newAnswer.id = analysisKey;
						const newAnswerLabel = document.createElement('label');
						newAnswerLabel.htmlFor = analysisKey;
						newAnswerLabel.innerText = analysis.name;
						const infoTip = document.createElement('span');
						// @Chohee TODO
						infoTip.dataset.tooltip = 'Coming Soon';
						infoTip.innerText = 'i';
						newAnswer.type = 'checkbox';
						newAnswer.value = analysisKey;
						analyseContainer.append(newAnswer, newAnswerLabel);
						newAnswerLabel.appendChild(infoTip);
					}
				});
			}
		});
	});
	if (insight.id === 'q1a1') {
		insight.click();
	}
});
