import { sleep } from '../misc';

export const clickOnTarget = async (target: HTMLOrSVGElement) => {
	await sleep(1000);
	console.log('clicking', target);
	if ((target as HTMLElement).click) {
		(target as HTMLElement).click();
	} else {
		const event = new MouseEvent('click');
		Object.defineProperty(event, 'target', { writable: false, value: target });
		globalThis.neuroCreate.graph!.onClick(event);
	}
};

export const slideLateralThinkingSliderTo = (percent: number) => {
	const sliderInput = document.querySelector('#spark-container .slider') as HTMLInputElement;
	sliderInput.value = `${percent}`;
	sliderInput.dispatchEvent(
		new Event('input', {
			bubbles: true,
		})
	);
};
