import * as d3 from 'd3';
import {
	hasMovedNoticably,
	hexOrNamedColour,
	removeEl,
	removeNoDeleteAnimation,
} from './graphUtils';
import { GraphProps } from '../../types';
import { NCLink } from '../../../../src/commonTypes';

const enterLink = (
	graphProps: GraphProps,
	elem: d3.Selection<d3.EnterElement, NCLink, SVGGElement, unknown>
): d3.Selection<SVGGElement, NCLink, SVGGElement, unknown> => {
	const link = elem.append('g').attr('class', 'link');
	link.append('line').attr('class', 'link-holder');
	link.append('line').attr('class', 'link-line');
	link
		.append('circle')
		.attr('r', 24)
		.attr('class', 'link-delete-circle')
		.append('title')
		.text('Delete connection');
	link
		.append('image')
		.attr('class', 'delete-link')
		.attr('width', 20)
		.attr('height', 20)
		.attr('href', `/assets/graph/delete.svg`);
	updateLink(graphProps, link);
	return link;
};

const getCenterX = (d: NCLink) => d.source.x + (d.target.x - d.source.x) / 2;
const getCenterY = (d: NCLink) => d.source.y + (d.target.y - d.source.y) / 2;
const getDeleteX = (d: NCLink) => d.source.x + (d.target.x - d.source.x) / 2 - 10;
const getDeleteY = (d: NCLink) => d.source.y + (d.target.y - d.source.y) / 2 - 10;
const getLinkColour = (d: NCLink) => (d.colour ? hexOrNamedColour(d.colour) : '#91909A');

export const updateLinkPositions = (
	links: d3.Selection<SVGGElement, NCLink, SVGGElement, unknown>
): d3.Selection<SVGGElement, NCLink, SVGGElement, unknown> => {
	links.each(function (d) {
		const linkHolder = this.querySelector<SVGLineElement>('.link-holder');
		const linkLine = this.querySelector<SVGLineElement>('.link-line');

		if (linkHolder && linkLine) {
			const sourceOldPos = {
				x: linkHolder.x1.baseVal.value || 0,
				y: linkHolder.y1.baseVal.value || 0,
			};
			const targetOldPos = {
				x: linkHolder.x2.baseVal.value || 0,
				y: linkHolder.y2.baseVal.value || 0,
			};

			const sourceMoved = hasMovedNoticably(sourceOldPos, d.source);
			if (sourceMoved) {
				linkHolder.setAttribute('x1', String(d.source.x));
				linkHolder.setAttribute('y1', String(d.source.y));
				linkLine.setAttribute('x1', String(d.source.x));
				linkLine.setAttribute('y1', String(d.source.y));
			}

			const targetMoved = hasMovedNoticably(targetOldPos, d.target);
			if (targetMoved) {
				linkHolder.setAttribute('x2', String(d.target.x));
				linkHolder.setAttribute('y2', String(d.target.y));
				linkLine.setAttribute('x2', String(d.target.x));
				linkLine.setAttribute('y2', String(d.target.y));
			}

			if (sourceMoved || targetMoved) {
				const deleteCircle = this.querySelector<SVGCircleElement>('.link-delete-circle');
				const deleteLink = this.querySelector<SVGImageElement>('.delete-link');
				if (deleteCircle && deleteLink) {
					deleteCircle.setAttribute('cx', String(getCenterX(d)));
					deleteCircle.setAttribute('cy', String(getCenterY(d)));
					deleteLink.setAttribute('x', String(getDeleteX(d)));
					deleteLink.setAttribute('y', String(getDeleteY(d)));
				}
			}
		}
	});

	return links;
};

export const updateActiveLink = (
	links: d3.Selection<SVGGElement, NCLink, SVGGElement, unknown>,
	graphProps: GraphProps
): d3.Selection<SVGGElement, NCLink, SVGGElement, unknown> => {
	// Update active class
	links.filter('.active').classed('active', false);
	if (graphProps.activeLink) {
		links
			.filter(
				(d) =>
					graphProps.activeLink?.source === d.source.uid &&
					graphProps.activeLink?.target === d.target.uid
			)
			.classed('active', true);
	}
	return links;
};

const updateLink = (
	graphProps: GraphProps,
	elem: d3.Selection<SVGGElement, NCLink, SVGGElement, unknown>
): d3.Selection<SVGGElement, NCLink, SVGGElement, unknown> => {
	const updatedLink = elem;

	updateActiveLink(updatedLink, graphProps);

	// Remove hidden class from links where source and target are not hidden
	updatedLink
		.filter('.hidden')
		.filter((d) => Boolean(!d.source.hidden && !d.target.hidden))
		.classed('hidden', false);
	// Add hidden class to links where source or target is hidden
	updatedLink.filter((d) => Boolean(d.source.hidden || d.target.hidden)).classed('hidden', true);

	updatedLink
		.selectAll<SVGLineElement, NCLink>('.link-line')
		.attr('stroke', graphProps.areLinksColoured ? getLinkColour : '#91909A');

	updateLinkPositions(updatedLink);

	return updatedLink;
};

const linkKeyFn = (d: NCLink): string => `${d.source.uid}-${d.target.uid}`;

/**
 * Create line elements inside the group with class '.links'.
 */
export const createLinkLines = (
	linksGroup: d3.Selection<SVGGElement, NCLink, SVGGElement, unknown>,
	links: Array<NCLink>,
	graphProps: GraphProps
) => {
	return linksGroup.data<NCLink>(links, linkKeyFn).join<SVGGElement, NCLink>(
		enterLink.bind(null, graphProps),
		(
			update: d3.Selection<SVGGElement, NCLink, SVGGElement, unknown>
		): d3.Selection<SVGGElement, NCLink, SVGGElement, unknown> => {
			return updateLink(graphProps, update);
		},
		graphProps.isDeleteAnimated ? removeEl : removeNoDeleteAnimation
	);
};

export const updateLinkLines = (
	linksGroup: d3.Selection<SVGGElement, NCLink, SVGGElement, unknown>,
	graphProps: GraphProps
) => {
	return updateLink(graphProps, linksGroup);
};
