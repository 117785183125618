import { v4 as uuidv4 } from 'uuid';

export const createObjectId = (): string => {
	return uuidv4();
};

export const insertText = (objectId: string, text: string) => {
	return {
		insertText: {
			objectId,
			text,
			insertionIndex: 0,
		},
	};
};

export const insertTitleText = (
	objectId: string,
	text: string
): Array<gapi.client.slides.Request> => {
	const requests: Array<gapi.client.slides.Request> = [insertText(objectId, text)];
	if (text.length > 60) {
		requests.push(updateFontSize(objectId, 18));
	}
	return requests;
};

const getTextRange = (
	startIndex: number,
	endIndex: number | undefined
): gapi.client.slides.Range => {
	return {
		type: endIndex ? 'FIXED_RANGE' : 'FROM_START_INDEX',
		startIndex,
		endIndex,
	};
};

export const updateFontSize = (
	objectId: string,
	fontSize: number,
	startIndex = 0,
	endIndex: number | undefined = undefined
): gapi.client.slides.Request => {
	return {
		updateTextStyle: {
			objectId,
			textRange: getTextRange(startIndex, endIndex),
			style: {
				fontSize: {
					magnitude: fontSize,
					unit: 'PT',
				},
			},
			fields: 'fontSize',
		},
	};
};

export const updateFontFamily = (
	objectId: string,
	fontFamily: string,
	startIndex = 0,
	endIndex: number | undefined = undefined
): gapi.client.slides.Request => {
	return {
		updateTextStyle: {
			objectId,
			textRange: getTextRange(startIndex, endIndex),
			style: {
				fontFamily,
			},
			fields: 'fontFamily',
		},
	};
};

export const updateTextToBold = (
	bodyId: string,
	startIndex = 0,
	endIndex: number | undefined = undefined
): gapi.client.slides.Request => {
	return {
		updateTextStyle: {
			objectId: bodyId,
			textRange: getTextRange(startIndex, endIndex),
			style: {
				bold: true,
			},
			fields: 'bold',
		},
	};
};

export const updateTextToItalic = (
	bodyId: string,
	startIndex = 0,
	endIndex: number | undefined = undefined
): gapi.client.slides.Request => {
	return {
		updateTextStyle: {
			objectId: bodyId,
			textRange: getTextRange(startIndex, endIndex),
			style: {
				italic: true,
				foregroundColor: {
					opaqueColor: {
						themeColor: 'ACCENT5' as ThemeColor,
					},
				},
			},
			fields: 'italic',
		},
	};
};

export const updateTextToIncludeLink = (
	bodyId: string,
	url: string,
	startIndex = 0,
	endIndex: number | undefined = undefined
): gapi.client.slides.Request => {
	return {
		updateTextStyle: {
			objectId: bodyId,
			textRange: getTextRange(startIndex, endIndex),
			style: {
				link: {
					url,
				},
			},
			fields: 'link',
		},
	};
};

export const updateToTextColour = (
	objectId: string,
	themeColor: ThemeColor = 'DARK2',
	startIndex = 0,
	endIndex: number | undefined = undefined
): gapi.client.slides.Request => {
	return {
		updateTextStyle: {
			objectId,
			textRange: getTextRange(startIndex, endIndex),
			style: {
				foregroundColor: {
					opaqueColor: {
						themeColor,
					},
				},
			},
			fields: 'foregroundColor',
		},
	};
};

export const createImage = (
	pageId: string,
	url: string,
	width: number,
	height: number,
	x: number,
	y: number
): gapi.client.slides.Request => {
	let fullUrl = url.startsWith('http') ? url : `https://${window.location.host}${url}`;
	// Use a placeholder if this is local development
	if (window.location.host === 'localhost:8080') {
		fullUrl = 'https://picsum.photos/300';
	}

	return {
		createImage: {
			objectId: createObjectId(),
			url: fullUrl,
			elementProperties: {
				pageObjectId: pageId,
				size: {
					height: {
						magnitude: height,
						unit: 'PT',
					},
					width: {
						magnitude: width,
						unit: 'PT',
					},
				},
				transform: {
					scaleX: 1,
					scaleY: 1,
					translateX: x,
					translateY: y,
					unit: 'PT',
				},
			},
		},
	};
};

export const centerParagraph = (objectId: string): gapi.client.slides.Request => {
	return {
		updateParagraphStyle: {
			objectId,
			style: {
				alignment: 'CENTER',
			},
			fields: 'alignment',
		},
	};
};

export const reduceLineSpacing = (
	objectId: string,
	lineSpacing = 100
): gapi.client.slides.Request => {
	return {
		updateParagraphStyle: {
			objectId,
			style: {
				lineSpacing,
				spaceBelow: {
					magnitude: 1,
					unit: 'PT',
				},
			},
			fields: 'lineSpacing,spaceBelow',
		},
	};
};

type ThemeColor =
	| 'DARK1'
	| 'DARK2'
	| 'LIGHT1'
	| 'LIGHT2'
	| 'ACCENT1'
	| 'ACCENT2'
	| 'ACCENT3'
	| 'ACCENT4'
	| 'ACCENT5'
	| 'ACCENT6'
	| 'HYPERLINK'
	| 'FOLLOWED_HYPERLINK'
	| 'TEXT1'
	| 'BACKGROUND1'
	| 'TEXT2'
	| 'BACKGROUND2';

export const createHorizontalLine = (
	pageId: string,
	width: number,
	x: number,
	y: number,
	themeColor: ThemeColor
): Array<gapi.client.slides.Request> => {
	const lineId = createObjectId();

	return [
		{
			createLine: {
				objectId: lineId,
				elementProperties: {
					pageObjectId: pageId,
					size: {
						height: {
							magnitude: 1,
							unit: 'PT',
						},
						width: {
							magnitude: 1,
							unit: 'PT',
						},
					},
					transform: {
						translateX: x,
						translateY: y,
						scaleX: width,
						scaleY: 0,
						unit: 'PT',
					},
				},
				category: 'STRAIGHT',
			},
		},
		{
			updateLineProperties: {
				objectId: lineId,
				lineProperties: {
					lineFill: {
						solidFill: {
							color: {
								themeColor,
							},
						},
					},
					weight: {
						magnitude: 8,
						unit: 'PT',
					},
				},
				fields: 'lineFill,weight',
			},
		},
	];
};

export const placeholderMapping = (
	type: 'CENTERED_TITLE' | 'SUBTITLE' | 'TITLE' | 'BODY',
	objectId: string,
	index = 0
) => {
	return {
		layoutPlaceholder: {
			type,
			index,
		},
		objectId,
	};
};

const addBorder = (shapeId: string): gapi.client.slides.Request => {
	return {
		updateShapeProperties: {
			objectId: shapeId,
			fields: 'outline',
			shapeProperties: {
				outline: {
					outlineFill: {
						solidFill: {
							color: {
								themeColor: 'ACCENT1',
							},
						},
					},
					weight: {
						magnitude: 3,
						unit: 'PT',
					},
				},
			},
		},
	};
};

const verticalAlign = (shapeId: string): gapi.client.slides.Request => {
	return {
		updateShapeProperties: {
			objectId: shapeId,
			fields: 'contentAlignment',
			shapeProperties: {
				contentAlignment: 'MIDDLE',
			},
		},
	};
};

export const createTextBox = (
	pageId: string,
	text: string,
	x: number,
	y: number,
	width: number,
	height: number,
	hasBorder = false,
	centered = false,
	fontSize = 10,
	bold = false
): Array<gapi.client.slides.Request> => {
	const shapeId = createObjectId();

	const requests: Array<gapi.client.slides.Request> = [
		{
			createShape: {
				objectId: shapeId,
				elementProperties: {
					pageObjectId: pageId,
					size: {
						height: {
							magnitude: height,
							unit: 'PT',
						},
						width: {
							magnitude: width,
							unit: 'PT',
						},
					},
					transform: {
						scaleX: 1,
						scaleY: 1,
						translateX: x,
						translateY: y,
						unit: 'PT',
					},
				},
				shapeType: 'TEXT_BOX',
			},
		},
		insertText(shapeId, text || ' '),
		updateToTextColour(shapeId),
		updateFontSize(shapeId, fontSize),
	];

	if (hasBorder) {
		requests.push(addBorder(shapeId));
	}

	if (centered) {
		requests.push(centerParagraph(shapeId));
		requests.push(verticalAlign(shapeId));
	}

	if (bold) {
		requests.push(updateTextToBold(shapeId));
	}

	return requests;
};

export const createRect = (
	pageId: string,
	x: number,
	y: number,
	width: number,
	height: number,
	color: gapi.client.slides.OpaqueColor,
	hasBorder = false
): Array<gapi.client.slides.Request> => {
	const shapeId = createObjectId();

	const requests: Array<gapi.client.slides.Request> = [
		{
			createShape: {
				objectId: shapeId,
				elementProperties: {
					pageObjectId: pageId,
					size: {
						height: {
							magnitude: height,
							unit: 'PT',
						},
						width: {
							magnitude: width,
							unit: 'PT',
						},
					},
					transform: {
						scaleX: 1,
						scaleY: 1,
						translateX: x,
						translateY: y,
						unit: 'PT',
					},
				},
				shapeType: 'RECTANGLE',
			},
		},
		{
			updateShapeProperties: {
				objectId: shapeId,
				shapeProperties: {
					shapeBackgroundFill: {
						solidFill: {
							color,
						},
					},
					outline: {
						outlineFill: {
							solidFill: {
								color,
							},
						},
						weight: {
							magnitude: 1,
							unit: 'PT',
						},
					},
				},
				fields: 'shapeBackgroundFill,outline',
			},
		},
	];

	if (hasBorder) {
		requests.push(addBorder(shapeId));
	}

	return requests;
};

export const createArrow = (
	pageId: string,
	x: number,
	y: number,
	width: number,
	height: number
): Array<gapi.client.slides.Request> => {
	const shapeId = createObjectId();

	return [
		{
			createShape: {
				objectId: shapeId,
				elementProperties: {
					pageObjectId: pageId,
					size: {
						height: {
							magnitude: height,
							unit: 'PT',
						},
						width: {
							magnitude: width,
							unit: 'PT',
						},
					},
					transform: {
						scaleX: 1,
						scaleY: 1,
						translateX: x,
						translateY: y,
						unit: 'PT',
					},
				},
				shapeType: 'RIGHT_ARROW',
			},
		},
	];
};

export const createTitleSlide = () => {
	const pageId = createObjectId();
	const titleId = createObjectId();
	const subtitleId = createObjectId();

	const createSlideRequest: gapi.client.slides.Request = {
		createSlide: {
			objectId: pageId,
			slideLayoutReference: {
				predefinedLayout: 'TITLE',
			},
			placeholderIdMappings: [
				placeholderMapping('CENTERED_TITLE', titleId),
				placeholderMapping('SUBTITLE', subtitleId),
			],
		},
	};

	return {
		pageId,
		titleId,
		subtitleId,
		createSlideRequest,
	};
};

export const createTitleOnlySlide = () => {
	const pageId = createObjectId();
	const titleId = createObjectId();

	const createSlideRequest: gapi.client.slides.Request = {
		createSlide: {
			objectId: pageId,
			slideLayoutReference: {
				predefinedLayout: 'TITLE_ONLY',
			},
			placeholderIdMappings: [placeholderMapping('TITLE', titleId)],
		},
	};

	return {
		pageId,
		titleId,
		createSlideRequest,
	};
};

export const createTitleAndBodySlide = () => {
	const pageId = createObjectId();
	const titleId = createObjectId();
	const bodyId = createObjectId();

	const createSlideRequest: gapi.client.slides.Request = {
		createSlide: {
			objectId: pageId,
			slideLayoutReference: {
				predefinedLayout: 'TITLE_AND_BODY',
			},
			placeholderIdMappings: [
				placeholderMapping('TITLE', titleId),
				placeholderMapping('BODY', bodyId),
			],
		},
	};

	return {
		pageId,
		titleId,
		bodyId,
		createSlideRequest,
	};
};

export const createTwoColumnsSlide = () => {
	const pageId = createObjectId();
	const titleId = createObjectId();
	const leftColumnId = createObjectId();
	const rightColumnId = createObjectId();

	const createSlideRequest: gapi.client.slides.Request = {
		createSlide: {
			objectId: pageId,
			slideLayoutReference: {
				predefinedLayout: 'TITLE_AND_TWO_COLUMNS',
			},
			placeholderIdMappings: [
				{
					layoutPlaceholder: {
						type: 'TITLE',
						index: 0,
					},
					objectId: titleId,
				},
				{
					layoutPlaceholder: {
						type: 'BODY',
						index: 0,
					},
					objectId: leftColumnId,
				},
				{
					layoutPlaceholder: {
						type: 'BODY',
						index: 1,
					},
					objectId: rightColumnId,
				},
			],
		},
	};

	return {
		pageId,
		titleId,
		leftColumnId,
		rightColumnId,
		createSlideRequest,
	};
};

export const removeExtraLines = (text: string) => {
	return text.replace(/\n{2,}/g, '\n');
};

export const removeExtraNewLinesFromNumberedList = (text: string) => {
	return text.replace(/(\n{1,})([0-9]+\.)/g, `\n$2`);
};

export const splitIntoMultiplePages = (text: string, numberOfLines = 10): Array<string> => {
	// split the text into lines and then combine into strings of up to numberOfLines lines
	const lines = text.split('\n');
	const pages: Array<string> = [];
	for (let i = 0; i < lines.length; i++) {
		if (i % numberOfLines === 0) {
			pages.push('');
		}
		pages[pages.length - 1] += `${lines[i]}\n`;
	}
	return pages;
};
