import { PresentationInfo } from '../../../../../src/commonTypes';
import { createCountTag, createDetails, createImageList } from './utils';

export const getAssetsSlideContent = (presentation: PresentationInfo) => {
	const assetsContent = document.createElement('div');

	const assetsP = document.createElement('p');
	assetsP.innerHTML =
		'<small>Photorealistic images from <strong class="synthesise-key">Synthisise AI</strong> will be available to select below.</small>';
	assetsContent.append(assetsP);

	const [imagesDetails, imagesSummary] = createDetails('Photorealistic Images');
	imagesSummary.append(createCountTag(presentation.photos.length));
	assetsContent.append(imagesDetails);
	imagesDetails.open = true;

	imagesDetails.append(createImageList(presentation, presentation.photos));

	return assetsContent;
};
