import '../lib/ui/inviteUser';
import '../lib/ui/boardMenu';
import {
	componentToggle,
	showAlert,
	closeAlert,
	finishLoading,
	getProjectIdFromUrl,
} from '../lib/ui/uiUtils';
import { updateProjectTitle, deleteProjectFromDB } from '../lib/apiRequests';

const projectId = getProjectIdFromUrl();
const settingButton = document.querySelector('.setting-button')!;
const setting = settingButton.querySelector('.project-setting')!;

const hideMenu = () => {
	document.addEventListener('click', (e: Event) => {
		const target = e.target as HTMLElement;
		if (!target.className.includes('setting-button')) {
			setting.classList.add('hidden');
		}
	});
};

const updateProjectName = () => {
	const projectTitleForm = document.querySelector<HTMLFormElement>('#project-title-form')!;
	const projectTitleInput = projectTitleForm.querySelector<HTMLInputElement>('input')!;

	projectTitleInput.classList.remove('disabled');
	projectTitleInput.focus();
	/* Put the mouse cursor at the end of text input */
	const value = projectTitleInput.getAttribute('value');
	projectTitleInput.value = value || '';

	if (projectTitleForm) {
		projectTitleForm.addEventListener('submit', (e: Event) => {
			e.preventDefault();
			const newProjectTitle = projectTitleInput.value;
			projectTitleInput.setAttribute('value', newProjectTitle);
			projectTitleInput.blur();
			projectTitleInput.classList.add('disabled');
			updateProjectTitle(projectId, newProjectTitle);
		});
	}
};

const deleteProject = (e: Event) => {
	e.preventDefault();
	showAlert(
		'Are you sure you would like to delete this project? The boards in this project will not be included in any project.',
		[
			{
				label: 'Cancel',
				onClick: () => {
					closeAlert();
				},
			},
			{
				label: 'Delete',
				primary: true,
				onClick: async () => {
					await deleteProjectFromDB(projectId);
					const userDashboardUrl = document.querySelector<HTMLAnchorElement>('.back-to-user')!.href;
					location.href = userDashboardUrl;
					closeAlert();
				},
			},
		]
	);
};

if (settingButton) {
	settingButton.addEventListener('click', () => {
		componentToggle(setting);
		hideMenu();
	});

	const renameButton = settingButton.querySelector('.rename')!;
	renameButton.addEventListener('click', updateProjectName);

	const deleteButton = settingButton.querySelector('.delete')!;
	deleteButton.addEventListener('click', deleteProject);
}

finishLoading();
