import { PresentationInfo, SelectableItem, TextResult } from '../../../../../src/commonTypes';
import {
	createAutogeneratedLabel,
	createConceptualTerritoryLabel,
	createDetails,
	createPreviewParagraph,
	createSelectableCheckbox,
	getConceptualTerritoryResultsOfType,
	getUncachedItems,
} from './utils';
import { removeExtraNewLinesFromNumberedList } from '../../slides/slideLayoutUtils';

const getStrategy = (
	strategyType: string,
	strategy: SelectableItem<TextResult>,
	presentation: PresentationInfo,
	conceptualTerritorySummary?: string
) => {
	const [details, summary] = createDetails(strategyType);
	const checkbox = createSelectableCheckbox(strategy, presentation)[0];
	summary.prepend(checkbox);

	if (conceptualTerritorySummary) {
		summary.append(createConceptualTerritoryLabel(conceptualTerritorySummary));
	} else if (strategy.autogenerated) {
		summary.append(createAutogeneratedLabel());
	}

	details.append(createPreviewParagraph(removeExtraNewLinesFromNumberedList(strategy.value.text)));

	return details;
};

export const getStrategySlideContent = (presentation: PresentationInfo) => {
	const strategyContent = document.createElement('div');

	const introP = document.createElement('p');
	introP.innerHTML = presentation.vision
		? '<small>Some strategies were <strong class="inspire-key">autogenerated</strong> from your project vision. You can create more from your own <strong class="synthesise-key">concepts</strong> in <strong>Synthesise</strong>.</small>'
		: '<small>You can create strategies from your own <strong class="synthesise-key">concepts</strong> in <strong>Synthesise</strong>.</small>';
	strategyContent.append(introP);

	getUncachedItems(presentation.brandStrategies).forEach((strategy) => {
		strategyContent.append(getStrategy('Brand Strategy', strategy, presentation));
	});
	getUncachedItems(presentation.productIdeas).forEach((strategy) => {
		strategyContent.append(getStrategy('Product Idea', strategy, presentation));
	});
	getUncachedItems(presentation.productBriefs).forEach((strategy) => {
		strategyContent.append(getStrategy('Product Brief', strategy, presentation));
	});

	getConceptualTerritoryResultsOfType(presentation, 'brand-strategy').forEach(
		([concept, result]) => {
			strategyContent.append(
				getStrategy(
					'Brand Strategy',
					result as SelectableItem<TextResult>,
					presentation,
					concept.value.summary
				)
			);
		}
	);

	getConceptualTerritoryResultsOfType(presentation, 'product-idea').forEach(([concept, result]) => {
		strategyContent.append(
			getStrategy(
				'Product Idea',
				result as SelectableItem<TextResult>,
				presentation,
				concept.value.summary
			)
		);
	});

	getConceptualTerritoryResultsOfType(presentation, 'product-brief').forEach(
		([concept, result]) => {
			strategyContent.append(
				getStrategy(
					'Product Brief',
					result as SelectableItem<TextResult>,
					presentation,
					concept.value.summary
				)
			);
		}
	);

	return strategyContent;
};
